import React from 'react'
import { styled } from '@mui/material'

const Button = styled('button', {
  shouldForwardProp: (prop) => prop !== 'type',
  name: 'Button',
})(({ theme, type, height, width }) => ({
  width: width ? width : width,
  height: height ? 45 : 45,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  columnGap: '10px',
  position: 'relative',
  boxSizing: 'border-box',
  border: '1px solid #FFFFFF',
  borderRadius: '3px',
  backgroundClip: 'padding-box',
  background: 'transparent',
  cursor: 'pointer',
  padding: '0 15px',
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  textAlign: 'center',
  textTransform: 'uppercase',
  color: '#FFFFFF',

  '&:hover': {
    borderWidth: '2px',
  },
  '&:active': {
    borderWidth: '2px',
  },
}))

export default function WhiteButton({
  children,
  type,
  height,
  width,
  handleClick,
}) {
  return (
    <Button height={height} width={width} onClick={handleClick} type={type}>
      {children}
    </Button>
  )
}
