import React from "react";
import { Box, FormLabel, Grid, Modal, styled, Typography } from "@mui/material";
import { BorderButtonPurple, CustomButton } from "../buttons";
import lightTheme from "../../styles/theme/lightTheme";
import "../../styles/components/_box.scss";
import "../../styles/components/_grids.scss";
import "../../styles/components/_inputs.scss";
import { TagsInput, TextInputAdmin } from "../inputs";

const ModalContainer = styled(Box)(({theme}) => ({
  width: "calc(100% - 50px)",
  height: 612,
  backgroundColor: "#FFFFFF",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "30px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  rowGap: "35px",

  "&:focus-visible": {
    outline: "none"
  },

  [theme.breakpoints.up("sm")]: {
    width: 620,
    height: 450,
  },


}));

const BoxContainer =  styled(Box)(({theme}) => ({
  width: "100%",
  height: 350,
  backgroundColor: lightTheme.palette.primary.b6,
  padding:"20px",

  [theme.breakpoints.up("sm")]: {
    height: 250,
  },
}));

const EditProfilModal = ({open, onClose, openDelete}) => {
  return (
    <Modal
      open={open}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(51, 51, 51, 0.65)",
        },
      }}
    >
      <ModalContainer>
        <Typography variant={"subtitle2"} textTransform={"uppercase"}>Modifier un profil / poste</Typography>
        <BoxContainer>
          <form>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: {xs: "column", sm: "row"}
              }}
            >
              <Grid item xs={12} sm={6} >
                <FormLabel className={"labelForm"}>Nom du poste / profil</FormLabel>
                <TextInputAdmin
                  placeholder={"Nom du poste / profil"}
                />
              </Grid>
              <TagsInput maxTagCount="5"/>
            </Grid>
          </form>
        </BoxContainer>
        <Box className={"wrapperButtons"} width={"100%"}>
          <BorderButtonPurple displayIcon={"none"} handleClick={openDelete}>Supprimer</BorderButtonPurple>
          <Box className={"blockButtons"}>
            <BorderButtonPurple displayIcon={"none"} handleClick={onClose}>Annuler</BorderButtonPurple>
            <CustomButton displayIcon={"none"}>Enregistrer</CustomButton>
          </Box>
        </Box>

      </ModalContainer>
    </Modal>
  )
}

export default EditProfilModal;