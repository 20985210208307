import React, { lazy, Suspense } from 'react'

const HomeDynamique = lazy(() => import('./HomeDynamique'))

const Home = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <HomeDynamique />
  </Suspense>
)

export default Home
