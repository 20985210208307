import React from "react";
import {Box, styled} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/pro-solid-svg-icons";

const Button = styled(Box)(({theme, width, alignSelf}) => ({
    width: width,
    height: 45,
    backgroundColor: lightTheme.palette.primary.b2,
    color: "white",
    textTransform: "uppercase",
    fontFamily: "Century Gothic Bold",
    fontSize: 10,
    lineHeight: "140%",
    letterSpacing: "0.2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "10px",
    borderRadius: "3px",
    cursor: "pointer",
    padding: "0 10px",
    alignSelf: alignSelf ? alignSelf : alignSelf,

    "&:hover": {
        backgroundColor: lightTheme.palette.primary.main,
    },
    "&:active": {
        backgroundColor: lightTheme.palette.primary.b2
    },

    [theme.breakpoints.up("sm")]: {
        fontSize: 14,
        height: 41
    },

    [theme.breakpoints.up("lg")]: {
        height: 45
    },

}));

const Icon = styled(Box, {
    shouldForwardProp: (prop) => prop !== "displayIcon",
    name: "Icon",
})(({theme, displayIcon}) => ({
    display: displayIcon === "block"
        ? "block"
        : displayIcon === "none"
            ? "none"
            : "",
    "& .arrowRight": {
        /*color: "#FFFFFF"*/
        color: "red"
    },

}));

export default function CustomButton({children, width, displayIcon, alignSelf, handleClick, type}) {
    return (
        <Button width={width} alignSelf={alignSelf} onClick={handleClick} type={type}>
            {children}
            <Icon display={displayIcon}>
                <FontAwesomeIcon icon={faArrowRight} className="arrowIcon" />
            </Icon>
        </Button>
    )
}