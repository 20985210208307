import { Box, FormControl, styled } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/pro-regular-svg-icons";
import lightTheme from "../../styles/theme/lightTheme";
import { useState } from "react";

const Input = styled("input")(({theme}) => ({
  height: 45,
  width: "100%",
  backgroundColor: "#FFFFF",
  borderRadius: "5px",
  border: `1px solid ${lightTheme.palette.grey.main}`,
  fontFamily: "Century Gothic",
  fontSize: 14,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.text.primary,
  padding: "10px 15px",

  "&::placeholder": {
    fontFamily: "Century Gothic",
    fontSize: 14,
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: lightTheme.palette.text.primary,
  },

  "&:active, &:focus": {
    outline: "none"
  },

  [lightTheme.breakpoints.up("md")]: {
    fontSize: 12,
  },

  [lightTheme.breakpoints.up("lg")]: {
    fontSize: 14,
  },

}))

const PasswordInputBasic = ({placeholder, register, onChange}) => {
  const [visible, setVisible] = useState(false);

  const handleVisible = () => {
    setVisible(!visible);
  };

  return (
    <FormControl>
      <Input
        type={visible ? "text" : "password"}
        placeholder={placeholder}
        {...register}
        onChange={onChange}
      />
      <Box
        component={"span"}
        position={"absolute"}
        top={"50%"}
        right={0}
        onClick={handleVisible}
        sx={{
          width: 30,
          height: 30,
          cursor: "pointer",
          transform: "translate(-50%, -50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          "& .icon": {
            color: lightTheme.palette.primary.b3,
            fontSize: 20
          }
        }}
      >
        {visible ? <FontAwesomeIcon icon={faEyeSlash} className={"icon"} /> : <FontAwesomeIcon icon={faEye} className={"icon"} />}
      </Box>
    </FormControl>
  )
}

export default PasswordInputBasic;