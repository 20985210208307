import React, { useEffect, useState } from 'react'
import TextWithMasonryImage from '../../../../../components/TextWithMasonryImage'
import { Markup } from 'interweave'

export default function SearchTalentSection(data) {
  const [searchSections, setSearchSections] = useState([])

  useEffect(() => {
    setSearchSections(data.rechercheTalents)
  }, [data.rechercheTalents])

  return (
    <>
      {searchSections.map((searchSection, index) => (
        <TextWithMasonryImage
          key={index}
          title={searchSection.titre}
          sousTitre={searchSection.sousTitre}
          text1={<Markup content={searchSection.detail} />}
          /*imageLeft={searchSection.photos[0].lien}
                imageRight={searchSection.photos[1].lien}
                subtitleLeft={searchSection.photos[0].titre}
                subtitleRight={searchSection.photos[1].titre}*/

          itemProps={searchSections}
          displayButton={'none'}
        />
      ))}
    </>
  )
}
