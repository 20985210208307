import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../services/api.service";

const user = JSON.parse(localStorage.getItem("user"));

export const userValidation = createAsyncThunk(
  "userFinalisation/userValidationReducer",
  async ({ token, password, valeurs, cv }, thunkAPI) => {
    try {
      const res = await Api.finalisationInscription.postInscription(token,{ password, valeurs, cv });
      localStorage.setItem("user", JSON.stringify({ ...res.data }));
      return { ...res.data };
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);


const initialState = user
  ? { isSignedIn: true, isFetching: false, user, error: null }
  : { isSignedIn: false, isFetching: false, user: null, error: null };

export const userFinalisationSlice = createSlice({
  name: "userFinalisation",
  initialState,
  reducers: {
    reset: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userValidation.fulfilled, (state, action) => {
      state.isSignedIn = true;
      state.isFetching = false;
      state.error = null;
      state.user = action.payload;
    });
    builder.addCase(userValidation.rejected, (state, action) => {
      state.isSignedIn = false;
      state.isFetching = false;
      state.error = action.payload;
    });
    builder.addCase(userValidation.pending, (state) => {
      state.isFetching = true;
      state.error = null;
      state.user = null;
    });

  },
});
const { actions } = userFinalisationSlice;
export const { reset } = actions;
export default userFinalisationSlice.reducer;
