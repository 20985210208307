import React, { useEffect, useState } from 'react'
import { AdminHeading } from '../../../components/headings'
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  styled,
  Typography,
  CircularProgress,
} from '@mui/material'
import { TextInputAdmin } from '../../../components/inputs'
import {
  BorderButtonPurple,
  CustomButton,
} from '../../../components/buttons'
import '../../../styles/components/_layouts.scss'
import '../../../styles/components/_inputs.scss'
import '../../../styles/components/_grids.scss'
import '../../../styles/components/_box.scss'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { phoneRegExp } from '../../../config'
import { useForm } from 'react-hook-form'
import Api from '../../../services/api.service'
import { handlingErrors } from '../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../styles/theme/lightTheme'
import { useSelector } from 'react-redux'
import '../../../styles/components/_errors.scss'
import '../../../styles/components/_toasts.scss'
import { EditPasswordModal } from '../../../components/modals'
import { useNavigate } from 'react-router-dom'

const schema = yup
  .object({
    email: yup
      .string()
      .email('Entrer un email valide')
      .required("L'email est requis"),
    prenom: yup.string().required('Le prénom est requis'),
    nom: yup.string().required('Le nom est requis'),
    telephone: yup
      .string()
      .required('Le telephone est requis')
      .matches(phoneRegExp, 'Entrez un numéro de téléphone valide')
      .nullable(),
    adresse: yup.string().required("L'adresse est requise."),
    ville: yup.string().required('La ville est requise.'),
    codePostal: yup.number().required('Le code postal est requis.'),
    pays: yup.string().required('Le pays est requis.'),
  })
  .required()

const InputContainer = styled(FormControl)(({ theme }) => ({
  rowGap: '5px',
}))

const Dashboard = () => {
  const [loading, setLoading] = useState(true)
  const [editing, setEditing] = useState(false)
  /* const [open, setOpen] = useState(false)
  const [msg, setMsg] = useState('')
  const [err, setErr] = useState(false) */
  const [snapShot, setSnapShot] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: 'onChange' })
  const { data } = useSelector((state) => state.userAuth.user)
  let navigate = useNavigate()

  if (!data) {
    navigate('/')
  }

  /* useEffect(() => {
    //console.log({ errors })
  }, [errors]) */

  useEffect(() => {
    fetchData()
  }, [data.id, reset, unregister])

  useEffect(() => {
    if (editing) {
      setEditMode(false)
    }
  }, [editing])

  const fetchData = async () => {
    try {
      let result = await Api.user.detail(data.id)

      const handling = handlingErrors(result)
      if (handling.status === 401) {
        setLoading(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        reset(res)
        setLoading(false)
      } else {
        setLoading(false)
        /* setMsg(handling.message)
        setErr(true)
        setOpen(true) */
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const onSubmit = async (values) => {

    const {
      admin,
      candidat,
      date,
      entreprise,
      lang,
      offre,
      parent,
      password,
      role,
      status,
      ...rest
    } = values
    setEditing(true)
    try {
      const result = await Api.user.edit(data.id, rest)
      const handling = handlingErrors(result)
      if (handling.status === 401) {
        setEditing(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
      if (handling.status === 0) {
        setEditing(false)
        toast.error(
          "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
        window.scrollTo(0, 0)
      }
      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        setEditing(false)
        toast.success('Le profil à été modifié avec succès !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setEditing(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const [openModal, setOpenModal] = React.useState(false)
  const handleOpen = () => setOpenModal(true)
  const handleClose = () => setOpenModal(false)

  const onSuccessChangePassword = () => {
    toast.success('Le mot de passe a été modifié avec succès ! !', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      icon: ({ theme, type }) => (
        <FontAwesomeIcon
          icon={faCircleCheck}
          fontSize={25}
          color={lightTheme.palette.success.main}
        />
      ),
    })
    window.scrollTo(0, 0)
  }

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <>
      <div style={{ width: '100%' }}>
        <AdminHeading handleClickIcon={() => navigate('/admin')}>
          Mon profil
        </AdminHeading>
        {/*<Box
          sx={{
            marginTop: "70px",
            padding: "25px"
          }}
        >
          <ReturnButton/>
        </Box>*/}
        <Box
          className={'BoxContainer'}
          sx={{
            padding: { xs: '25px', sm: '50px' },
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
            /*marginTop: 0*/
          }}
        >
          <form>
            <Grid container spacing={2} className={'gridContainer'}>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Prénom</FormLabel>
                  <TextInputAdmin
                    placeholder={'Prénom'}
                    width={'100%'}
                    register={{ ...register('prenom') }}
                    resetValue={() => {
                      setValue('prenom', snapShot['prenom'])
                      clearErrors('prenom')
                    }}
                    editMode={editMode}
                  />
                  {errors.prenom && (
                    <Typography className={'error-message'}>
                      {errors.prenom.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Nom</FormLabel>
                  <TextInputAdmin
                    placeholder={'Nom'}
                    width={'100%'}
                    register={{ ...register('nom') }}
                    resetValue={() => {
                      setValue('nom', snapShot['nom'])
                      clearErrors('nom')
                    }}
                    editMode={editMode}
                  />
                  {errors.nom && (
                    <Typography className={'error-message'}>
                      {errors.nom.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Mail</FormLabel>
                  <TextInputAdmin
                    placeholder={'Mail'}
                    width={'100%'}
                    type={'email'}
                    register={{ ...register('email') }}
                    resetValue={() => {
                      setValue('email', snapShot['email'])
                      clearErrors('email')
                    }}
                    editMode={editMode}
                  />
                  {errors.email && (
                    <Typography className={'error-message'}>
                      {errors.email.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Numéro de téléphone
                  </FormLabel>
                  <TextInputAdmin
                    placeholder={'Numéro de téléphone'}
                    width={'100%'}
                    register={{ ...register('telephone') }}
                    resetValue={() => {
                      setValue('telephone', snapShot['telephone'])
                      clearErrors('telephone')
                    }}
                    editMode={editMode}
                  />
                  {errors.telephone && (
                    <Typography className={'error-message'}>
                      {errors.telephone.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={12}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Adresse de l'entreprise
                  </FormLabel>
                  <TextInputAdmin
                    placeholder={"Adresse de l'entreprise"}
                    width={'100%'}
                    register={{ ...register('adresse') }}
                    resetValue={() => {
                      setValue('adresse', snapShot['adresse'])
                      clearErrors('adresse')
                    }}
                    editMode={editMode}
                  />
                  {errors.numeroFixe && (
                    <Typography className={'error-message'}>
                      {errors.numeroFixe.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Ville</FormLabel>
                  <TextInputAdmin
                    placeholder={'Ville'}
                    width={'100%'}
                    register={{ ...register('ville') }}
                    resetValue={() => {
                      setValue('ville', snapShot['ville'])
                      clearErrors('ville')
                    }}
                    editMode={editMode}
                  />
                  {errors.ville && (
                    <Typography className={'error-message'}>
                      {errors.ville.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Code postal</FormLabel>
                  <TextInputAdmin
                    placeholder={'Code postal'}
                    width={'100%'}
                    register={{ ...register('codePostal') }}
                    resetValue={() => {
                      setValue('codePostal', snapShot['codePostal'])
                      clearErrors('codePostal')
                    }}
                    editMode={editMode}
                  />
                  {errors.codePostal && (
                    <Typography className={'error-message'}>
                      {errors.codePostal.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Pays</FormLabel>
                  <TextInputAdmin
                    placeholder={'Pays'}
                    width={'100%'}
                    register={{ ...register('pays') }}
                    resetValue={() => {
                      setValue('pays', snapShot['pays'])
                      clearErrors('pays')
                    }}
                    editMode={editMode}
                  />
                  {errors.pays && (
                    <Typography className={'error-message'}>
                      {errors.pays.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
            </Grid>
          </form>

          <Box className={'wrapperButtons'}>
            <BorderButtonPurple
              width={{ xs: '100%', sm: 240, lg: 300 }}
              handleClick={handleOpen}
            >
              Modifier mon mot de passe
            </BorderButtonPurple>
            <Box className={'blockButtons'}>
              {(!editMode || editing) && (
                <CustomButton
                  handleClick={() => setEditMode(true)}
                  width={{ xs: '100%', sm: 160, lg: 200 }}
                  displayIcon={'none'}
                >
                  Modifier
                </CustomButton>
              )}

              {editing ? (
                <CircularProgress />
              ) : editMode ? (
                <>
                  <BorderButtonPurple
                    width={{ xs: '50%', sm: 160, lg: 200 }}
                    handleClick={(e) => {
                      e.preventDefault()
                      clearErrors()
                      reset(snapShot)
                      setEditMode(false)
                    }}
                  >
                    Annuler
                  </BorderButtonPurple>
                  <CustomButton
                    displayIcon={'none'}
                    width={{ xs: '50%', sm: 160, lg: 200 }}
                    handleClick={handleSubmit(onSubmit)}
                  >
                    Mettre à jour
                  </CustomButton>
                </>
              ) : null}
            </Box>
          </Box>
        </Box>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />

      <EditPasswordModal
        open={openModal}
        onClose={handleClose}
        id={data.id}
        onSuccess={onSuccessChangePassword}
      />
    </>
  )
}

export default Dashboard
