import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowDownToLine} from "@fortawesome/pro-regular-svg-icons";

const InputContainer = styled.div`
  position: relative;
  
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .inputfile + label {
    width: 100%;
    height: 45px;
    border-radius: 5px;
    font-family: "Century Gothic Bold", sans-serif;
    font-size: 14px;
    line-height: 140%;
    color: #FFFFFF;
    background-color: #96A0FF;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
  }

  .inputfile:focus + label ,
  .inputfile + label:hover {
    background-color: #808BF8;
  }

  .inputfile + label {
    cursor: pointer; /* "hand" cursor */
  }

  .inputfile:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }

  .inputfile + label * {
    pointer-events: none;
  }
  
  .icon {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #FFFFFF;
  }
`

const ButtonInputUpload = ({onChange}) => {
  return (
    <InputContainer>
      <input
        type={"file"}
        name={"file"}
        id={"file"}
        accept={".doc,.docx,.pdf"}
        onChange={onChange}
        className={"inputfile"}
      />
      <label htmlFor={"file"}>Télécharger le CV (2M)</label>
      <FontAwesomeIcon icon={faArrowDownToLine} className={"icon"} />
    </InputContainer>
  )
}

export default ButtonInputUpload;