import React, { useState } from 'react'
import {
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  Modal,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import 'dayjs/locale/fr'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { Slide, ToastContainer, toast } from 'react-toastify'
import { faCircleCheck, faClose } from '@fortawesome/pro-solid-svg-icons'
import { SelectInputBasic } from '../inputs'
import { TYPE_RDV } from '../../data'
import { useTranslation } from 'react-i18next'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const AddEvent = ({ open, onClose, onSave, role, id }) => {
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [errorTime, setErrorTime] = useState(false)
  const [errorType, setErrorType] = useState(false)
  const [dateValue, setDateValue] = useState(null)

  const [rdvSelect, setRdvSelect] = useState('')

  const handleRdvSelect = (event) => {
    const inputValue = event.target.value
    setRdvSelect(inputValue)
  }
  const changeDate = (dateValue) => {
    setDateValue(dateValue.target.value)
  }

  const submitNewDate = async () => {
    setErrorTime(false)
    setErrorType(false)
    if (!dateValue) setErrorTime(true)
    if (!rdvSelect) setErrorType(true)
    if (!dateValue || !rdvSelect) return
    setLoading(true)

    let data = {
      dateDebut: dateValue,
      dateFin: dateValue,
      type: rdvSelect,
      detail: 'Demande RDV',
    }
    if (role === 'ROLE_CANDIDAT') data.candidat = '/api/candidats/' + id
    if (role === 'ROLE_ENTREPRISE') data.entreprise = '/api/entreprises/' + id

    try {
      const result = await Api.calendar.add(data)
      const res = handlingErrors(result)

      if (res.ok) {
        toast.success(t('successMessage.appointmentRequestSent'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        onSave()
      }

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoading(false)
  }

  return (
    <>
      <Modal
        open={open}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(51, 51, 51, 0.65)',
          },
        }}
      >
        <ModalContainer>
          <Typography variant={'subtitle2'} textTransform={'uppercase'}>
            {t('profile.appointmentRequest')}
            <Box
              sx={{
                position: 'absolute',
                right: '10px',
                top: '10px',
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
            </Box>
          </Typography>
          <BoxContainer>
            <form>
              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                }}
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={i18n.language}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <FormLabel className={'labelForm'}>
                      {t('list.type')}
                    </FormLabel>

                    <SelectInputBasic
                      items={TYPE_RDV[i18n.language]}
                      value={rdvSelect}
                      handleChange={handleRdvSelect}
                      type={'border'}
                    />

                    {errorType && (
                      <Typography className={'error-message'}>
                        {t('errorMessage.appointment')}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <FormLabel className={'labelForm'}>
                      {t('profile.date')}
                    </FormLabel>
                    <TextField
                      sx={{ padding: 0 }}
                      type="datetime-local"
                      onChange={changeDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 900,
                      }}
                    />

                    {errorTime && (
                      <Typography className={'error-message'}>
                        {t('errorMessage.date')}
                      </Typography>
                    )}
                  </Grid>
                </LocalizationProvider>
              </Grid>
            </form>
          </BoxContainer>
          <Box className={'blockButtons'}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
                  {t('button.cancel')}
                </BorderButtonPurple>
                <CustomButton displayIcon={'none'} handleClick={submitNewDate}>
                  {t('button.sendRequest')}
                </CustomButton>
              </>
            )}
          </Box>
        </ModalContainer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default AddEvent
