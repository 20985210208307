import React, { useState, useEffect } from 'react'
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { EmailInput } from '../inputs'
import { BorderButton, WhiteButton } from '../buttons'
import logo from '../../assets/images/logos/logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import { faCopyright } from '@fortawesome/pro-light-svg-icons'
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
//import hcubeLogo from "../../assets/images/logos/hcube-logo.svg";
import hcubeLogo from '../../assets/images/logos/hcube-logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast, ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../../styles/components/_toasts.scss'
import { BASE_URL_UP } from '../../config'

export default function Footer() {
  const [date, setDate] = useState(null)
  const navigate = useNavigate()
  const { t } = useTranslation()

  /* const [codePays, setCodePays] = useState(
    localStorage.getItem('codePays')
      ? localStorage.getItem('codePays')
      : 'ch'
  )
  const handlePaysChange = (codePays) => {
    setCodePays(codePays)
    localStorage.setItem('codePays', codePays)
    window.location.reload(false)
  } */

  const data_1 = [
    {
      item: `${t('footer.aboutUs')}`,
      to: '/',
    },
    {
      item: `${t('footer.candidates')}`,
      to: '/candidats',
    },
    {
      item: `${t('footer.companies')}`,
      to: '/entreprises',
    },
    {
      item: `${t('footer.articles')}`,
      to: '/articles',
    },
    {
      item: `${t('footer.workshops')}`,
      to: '/ateliers',
    },
  ]

  const data_2 = [
    {
      item: `${t('footer.terms&conditions')}`,
      to: '/cgu-cgv',
    },
    {
      item: `${t('footer.legal')}`,
      to: '/mentions-legales',
    },
    {
      item: `${t('footer.policies')}`,
      to: '/politiques-de-confidentialite',
    },
    /* {
      item: 'Blog',
      to: '/blog',
    }, */
    {
      item: 'F.A.Q',
      to: '/faq',
    },
  ]

  useEffect(() => {
    let currentYear = new Date()
    let date = currentYear.getFullYear()
    setDate(date)
  }, [])

  const [email, setEmail] = useState('')

  let SubmitHandler = async (e) => {
    e.preventDefault()

    try {
      let res = await fetch(`${BASE_URL_UP}newsletters`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          lang: 'fr',
        }),
      })
      let resJson = await res.json()
      console.debug(resJson)
      if (res.status === 200) {
        setEmail('')
      } else if (res.status === 422) {
        toast.error('Vous êtes déjà inscrit !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      } else if (res.status === 500) {
        toast.success('Enregistrée avec succès !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
      } else {
        toast.success('Enregistrée avec succès !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <footer>
        <Box
          sx={{
            height: { xs: 120, sm: 75 },
            backgroundColor: lightTheme.palette.primary.b3,
          }}
        >
          <Container maxWidth={'lg'} sx={{ height: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '100%',
                padding: { xs: '20px 0', md: '0' },
                rowGap: { xs: '20px', md: '0' },
              }}
            >
              <Typography variant={'subtitle1'} sx={{ color: '#FFFFFF' }}>
                {t('footer.newsletter')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: { xs: '10px', lg: '30px' },
                  width: { xs: '100%', sm: 350, md: 450 },
                }}
              >
                <form
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    columnGap: '30px',
                  }}
                  onSubmit={SubmitHandler}
                >
                  <EmailInput
                    placeholder={t('footer.emailAdress')}
                    width={'100%'}
                    display={'none'}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <WhiteButton width={150} type={'button'}>
                    {t('button.register')}
                  </WhiteButton>
                </form>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box
          sx={{
            paddingTop: '80px',
            paddingBottom: '80px',
          }}
        >
          <Container
            maxWidth={'lg'}
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(2, 1fr)',
                sm: 'repeat(2, 1fr)',
                lg: 'repeat(5, 1fr)',
              },
              gridTemplateRows: { xs: 'repeat(2, 1fr)', sm: '1fr', lg: 'none' },
              columnGap: { xs: 0, md: '50px' },
              rowGap: '40px',

              position: 'relative',
            }}
          >
            <Box
              minWidth={'205px'}
              sx={{
                display: { xs: 'none', lg: 'flex' },
                flexDirection: 'column',
                justifyContent: 'space-between',
                rowGap: '50px',
                gridRowStart: 1,
                gridRowEnd: 2,
              }}
            >
              <a href={'/'}>
                <img src={logo} alt={'Jobertech'} width={'100%'} />
              </a>
              {/*{i18n.language === "fr" ? (
                <>
                  {firstFooters
                    .filter(firstFooter => firstFooter.lang === "fr")
                    .map((firstFooter, index) => (
                      <Typography variant={"body1"} color={"#333333"} key={index}>
                        <Markup content={firstFooter.detail} />
                      </Typography>
                    ))}
                </>
              ):(
                <></>
              )}*/}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '25px',

                gridRowStart: { xs: 2, sm: 1, md: 1 },
                gridRowEnd: { xs: 3, sm: 2, md: 2 },
              }}
            >
              <Typography
                variant={'subtitle1'}
                color={lightTheme.palette.primary.main}
              >
                {t('footer.showcase')}
              </Typography>
              <List disablePadding dense={true}>
                {data_1.map((x) => (
                  <ListItem
                    sx={{
                      textAlign: { xs: 'left', md: 'left' },
                      padding: 0,
                    }}
                    key={x.item}
                  >
                    <Link to={x.to}>
                      <ListItemText
                        primary={x.item}
                        primaryTypographyProps={{
                          fontSize: { xs: 12, md: 14 },
                          color: lightTheme.palette.grey.dark,
                        }}
                        sx={{
                          padding: 0,
                          cursor: 'pointer',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                      />
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '25px',

                gridRowStart: { xs: 2, sm: 1, md: 1 },
                gridRowEnd: { xs: 3, sm: 2, md: 2 },
              }}
            >
              <Typography
                variant={'subtitle1'}
                color={lightTheme.palette.primary.main}
              >
                {t('footer.other')}
              </Typography>
              <List disablePadding dense={true}>
                {data_2.map((x) => (
                  <ListItem
                    sx={{
                      textAlign: { xs: 'left', md: 'left' },
                      padding: 0,
                    }}
                    key={x.item}
                  >
                    <Link to={x.to}>
                      <ListItemText
                        primary={x.item}
                        primaryTypographyProps={{
                          fontSize: { xs: 12, md: 14 },
                          color: lightTheme.palette.grey.dark,
                        }}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                      />
                    </Link>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', lg: 'flex' },
                flexDirection: 'column',
                rowGap: '25px',
                minWidth: 'max-content',
              }}
            >
              <Typography
                variant={'subtitle1'}
                color={lightTheme.palette.primary.main}
              >
                {t('header.follow')} !
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  columnGap: '10px',
                }}
              >
                <a
                  href={'https://www.linkedin.com/company/jobertechcorp/?'}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  <FontAwesomeIcon
                    icon={faLinkedinIn}
                    style={{ fontSize: 18 }}
                  />
                </a>
                <a
                  href={'https://www.instagram.com/jobertech/'}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    style={{ fontSize: 18 }}
                  />
                </a>
                <a
                  href={'https://az-az.facebook.com/JoberTech/'}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  <FontAwesomeIcon icon={faFacebook} style={{ fontSize: 18 }} />
                </a>
                <a
                  href={'https://www.youtube.com/watch?v=0jC2LzB7vfY'}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  <FontAwesomeIcon icon={faYoutube} style={{ fontSize: 18 }} />
                </a>
              </Box>
              {/* <Typography
                variant={'subtitle1'}
                color={lightTheme.palette.primary.main}
              >
                {t('header.selectCountry')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  columnGap: '10px',
                }}
              >
                <a
                  href="#"
                  onClick={() => handlePaysChange('ch')}
                  style={{
                    padding: '5px',
                    lineHeight: 0,
                    border:
                      sessionStorage.getItem('codePays') === 'ch'
                        ? '1px solid #808bf8'
                        : '',
                  }}
                >
                  <img src="https://flagsapi.com/CH/flat/32.png" />
                </a>
                <a
                  href="#"
                  onClick={() => handlePaysChange('fr')}
                  style={{
                    padding: '5px',
                    lineHeight: 0,
                    border:
                      sessionStorage.getItem('codePays') === 'fr'
                        ? '1px solid #808bf8'
                        : '',
                  }}
                >
                  <img src="https://flagsapi.com/FR/flat/32.png" />
                </a>
              </Box> */}
            </Box>
            <Box
              maxWidth={'360px'}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                rowGap: '50px',
                width: { xs: '100%', sm: '350px' },

                gridRowStart: 1,
                gridRowEnd: 2,
                gridColumnStart: { xs: 1, sm: 3, lg: 5 },
                gridColumnEnd: { xs: 3, sm: 4, lg: 6 },
              }}
            >
              <Box
                sx={{
                  display: { xs: 'flex', lg: 'none' },
                  justifyContent: 'center',
                }}
              >
                <img src={logo} alt={'Jobertech'} width={205}  />
              </Box>
              {/*{i18n.language === "fr" ? (
                <>
                  {secondFooters
                    .filter(secondFooter => secondFooter.lang === "fr")
                    .map((secondFooter, index) => (
                      <Typography variant={"body1"} color={"#333333"} key={index}>
                        <Markup content={secondFooter.detail} />
                      </Typography>
                    ))
                  }
                </>
              ):(
                <>
                  {secondFooters
                    .filter(secondFooter => secondFooter.lang === "en")
                    .map((secondFooter, index) => (
                      <Typography variant={"body1"} color={"#333333"} key={index}>
                        <Markup content={secondFooter.detail} />
                      </Typography>
                    ))
                  }
                </>
              )}*/}

              <BorderButton
                type={'rainbow'}
                textColor={'rainbow'}
                displayIconLeft={'none'}
                displayIconRight={'none'}
                handleClick={() => navigate('/contact')}
              >
                {t('button.rdv')}
              </BorderButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'baseline',
                columnGap: '10px',
                position: 'absolute',
                bottom: '-52.5px',
                left: 0,
                right: 0,
              }}
            >
              {/* <a
                href={'https://hcub3.fr/'}
                target={'_blank'}
                rel={'noreferrer'}
              > */}
              <img src={hcubeLogo} alt={'Hcube'} width={'30px'} />
              {/* </a> */}
              <Typography>
                <FontAwesomeIcon icon={faCopyright} style={{ fontSize: 16 }} />
                &nbsp;-&nbsp;{date}
              </Typography>
            </Box>
          </Container>
        </Box>
      </footer>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}
