import * as React from 'react'
import {
  TableCell,
  tableCellClasses,
  styled,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Paper,
  IconButton,
  CircularProgress,
  Box,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/pro-regular-svg-icons'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: `1px solid ${lightTheme.palette.primary.b2}`,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: lightTheme.palette.primary.b6,
    color: lightTheme.palette.text.primary,
    fontFamily: 'Century Gothic Bold',
    fontSize: 10,
    lineHeight: '140%',
    letterSpacing: '0.2px',
    textTransform: 'uppercase',
    padding: '20px 5px',
    borderBottom: 'none',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '20px 5px',
  },

  [theme.breakpoints.up('sm')]: {
    [`&.${tableCellClasses.head}`]: {
      fontSize: 12,
    },
  },

  [theme.breakpoints.up('lg')]: {
    [`&.${tableCellClasses.head}`]: {
      fontSize: 14,
    },
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: `1px solid ${lightTheme.palette.primary.b2}`,
  '&:nth-of-type(odd)': {
    backgroundColor: '#FFFFFF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const BlockColor = styled(TableCell)(({ theme }) => ({
  width: 20,
  height: 60,
  backgroundColor: lightTheme.palette.primary.b2,
  padding: 0,
  borderBottom: `1px solid ${lightTheme.palette.primary.b2}`,
}))

const ListAdmin = ({ handleClick, loading, items, setId }) => {
  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '40vh',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none',
          borderRadius: 0,
        }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell align="left">Nom</StyledTableCell>
              <StyledTableCell align="left">Prénom</StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{ display: { xs: 'none', sm: 'table-cell' } }}
              >
                Rôle
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{ display: { xs: 'none', sm: 'table-cell' } }}
              >
                Mail
              </StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
              <StyledTableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => {
              return (
                <StyledTableRow key={index}>
                  <BlockColor />
                  <StyledTableCell sx={{ textTransform: 'capitalize' }}>
                    {item.nom}
                  </StyledTableCell>
                  <StyledTableCell sx={{ textTransform: 'capitalize' }}>
                    {item.prenom}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                  >
                    {item.role === 'ROLE_ADMIN'
                      ? 'Administrateur'
                      : item.role === 'ROLE_SUPER_ADMIN'
                      ? 'Administrateur maître'
                      : item.role === 'ROLE_MINI_ADMIN'
                      ? 'Administrateur collaborateur'
                      : null}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                  >
                    {item.email}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton
                      sx={{
                        border: `1px solid ${lightTheme.palette.primary.b2}`,
                        borderRadius: '3px',
                        backgroundColor: '#FFFFFF',
                      }}
                      onClick={() => {
                        setId(item.id)
                        /*handleClick(`${item.nom} ${item.prenom}`);*/
                        handleClick(item.id)
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{
                          fontSize: 15,
                          color: lightTheme.palette.primary.b2,
                        }}
                      />
                    </IconButton>
                  </StyledTableCell>
                  <BlockColor />
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/*<AppPagination setProducts={(p) => setProducts(p)} />*/}
    </>
  )
}

export default ListAdmin
