import React from 'react'
import OffresSection from './components/OffresSection'

export default function CompanyOffers() {
 /*  const { t } = useTranslation() */

 /*  const data = [
    {
      id: 1,
      title: `${t('stickyNav.companyService')}`,
      list: [
        {
          text: `${t('stickyNav.recruitment')}`,
        },
        {
          text: `${t('stickyNav.searchOptimization')}`,
        },
      ],
      button: `${t('button.discuss')}`,
    },
  ] */

  return (
    <div>
      <main style={{ margin: '80px 0' }}>
        <OffresSection />
      </main>
    </div>
  )
}
