import React from "react";
import {Box, styled, Typography} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";

const CustomButton = styled(Box)(({theme, borderRadius}) => ({
    backgroundColor: lightTheme.palette.primary.b4,
    padding: "5px",
    borderRadius: borderRadius,
    width: 165,
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",


    [theme.breakpoints.up("sm")]: {
        fontSize: 10,
        height: 40
    },
    [theme.breakpoints.up("lg")]: {
        width: 200,
        height: 50,
        padding: "10px 20px",
        columnGap: "15px"
    },
}));

const TextButton = styled(Typography)(({theme}) => ({
    fontFamily: "Century Gothic",
    fontSize: 8,
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: lightTheme.palette.text.secondary,

    [theme.breakpoints.up("sm")]: {
        fontSize: 10,
    },
}));

const IconBox = styled(Box)(({theme}) => ({
    color: "#FFFFFF",
    fontSize: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: 22,
    height: 20,
}));

export default function ButtonPopup({icon, children, borderRadius}) {
    return (
        <CustomButton borderRadius={borderRadius}>
            <IconBox>
                {icon}
            </IconBox>
            <TextButton>
                {children}
            </TextButton>
        </CustomButton>
    )
}