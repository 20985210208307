import { createSlice } from "@reduxjs/toolkit";

export const eventSlice = createSlice({
  name: "event",
  initialState: { idEvent: "" },
  reducers: {
    setIdService: (state, action) => {
      state.idEvent = action.payload;
    },
  },
});

export default eventSlice.reducer;
export const { setIdService } = eventSlice.actions;
