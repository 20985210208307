import Auth from './routes/auth'
import Candidate from './routes/candidate'
import Company from './routes/company'
import Admin from './routes/admin'
import User from './routes/user'
import Offres from './routes/offres'
import Postes from './routes/postes'
import ForgotPassword from './routes/forgotPassword'
import SiteVitrine from './routes/siteVitrine'
import FinalisationInscription from './routes/finalisationInscription'
import Calendar from './routes/calendrier'
import Fonctions from './routes/fonctions'
import Documents from './routes/documents'
import OffresUtilisateurs from './routes/offresUtilisateurs'
import besoinEntreprise from './routes/besoinEntreprise'
import countriesCities from './routes/countriesCities'
import Rate from './routes/rates'
import Favoris from './routes/favoris'
import AteliersUtilisateurs from './routes/ateliersUtilisateurs'

const Api = {
  auth: new Auth(),
  candidate: new Candidate(),
  company: new Company(),
  admin: new Admin(),
  user: new User(),
  offer: new Offres(),
  offerUtilisateur: new OffresUtilisateurs(),
  ateliersUtilisateurs: new AteliersUtilisateurs(),
  poste: new Postes(),
  forgotPassword: new ForgotPassword(),
  siteVitrine: new SiteVitrine(),
  finalisationInscription: new FinalisationInscription(),
  calendar: new Calendar(),
  fonction: new Fonctions(),
  document: new Documents(),
  besoinEntreprise: new besoinEntreprise(),
  countriesCities: new countriesCities(),
  rate: new Rate(),
  favoris: new Favoris(),
}

export default Api
