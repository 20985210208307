import React from 'react'
import { Box, styled } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { SidebarData } from './SidebarData'
import Submenu from './Submenu'
import logo from '../../assets/images/logos/logoAdmin.svg'
import { DarkButton } from '../buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGear,
  faArrowRightFromBracket,
} from '@fortawesome/pro-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { userLogout } from '../../store/slices/userAuthReducer'
import { useDispatch } from 'react-redux'

const SidebarNav = styled('nav')(({ theme }) => ({
  background: lightTheme.palette.admin.adminMenu,
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  overflow: 'auto',
  position: 'fixed',
  padding: '35px 25px',
  left: 0,

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  [theme.breakpoints.up('lg')]: {
    maxWidth: 260,
    width: 260,
    height: '100vh',
    flexGrow: 1,
    zIndex: 2,
  },

  [theme.breakpoints.up('xl')]: {
    maxWidth: 300,
    width: 300,
  },
}))

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('lg')]: {
    display: 'block',
  },
}))

const SidebarWrap = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '100px',
}))

const Sidebar = () => {
  let navigate = useNavigate()
  const dispatch = useDispatch()

  const logout = () => {
    dispatch(userLogout())
    navigate('/')
  }

  return (
    <SidebarNav>
      <LogoContainer>
        <img src={logo} alt={'Jobertech'} />
      </LogoContainer>
      <SidebarWrap>
        {SidebarData.map((item, index) => {
          return <Submenu item={item} key={index} />
        })}
      </SidebarWrap>
      <Box
        sx={{
          width: { xs: '115%', sm: '105%' },
          height: 85,
          display: { xs: 'flex', lg: 'none' },
          justifyContent: { xs: 'center', sm: 'flex-start' },
          alignItems: 'center',
          columnGap: { xs: '15px', sm: '20px' },
          margin: '0 -25px -35px -25px',
          padding: '20px 25px',
          borderTop: `3px solid ${lightTheme.palette.admin.adminSearch}`,
        }}
      >
        <DarkButton
          icon={<FontAwesomeIcon icon={faGear} style={{ fontSize: 15 }} />}
          handleClick={() => navigate('/admin')}
        >
          Paramètres
        </DarkButton>
        <DarkButton
          icon={
            <FontAwesomeIcon
              icon={faArrowRightFromBracket}
              style={{ fontSize: 15 }}
            />
          }
          handleClick={logout}
        >
          Déconnexion
        </DarkButton>
      </Box>
    </SidebarNav>
  )
}

export default Sidebar
