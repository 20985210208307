import { styled } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";

const CustomLabelButton = styled('div')(({theme, border, width, spanHeight}) => ({
  position: "relative",
  width: width,
  height: spanHeight,
  background: "#FFFFFF",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Century Gothic",
  fontSize: 14,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.text.primary,
  textTransform: "uppercase",
  border: border,

  input: {
    position: "absolute",
    opacity: 0,
    width: "100%",
    height: "100%",
    margin: 0,
    cursor: "pointer"
  },

  span: {
    width: "100%",
    height: spanHeight,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

  },


  "&:hover": {
    background: lightTheme.palette.primary.b4,
    color: "#FFFFFF"
  },

  "&:active": {
    background: lightTheme.palette.primary.main,
    color: "#FFFFFF"
  },

  "input:checked + span": {
    background: lightTheme.palette.primary.main,
    color: "#FFFFFF"
  },

}));

const ButtonRadioInput = ({children, value, handleChange, isChecked, border, width, spanHeight = 55, register}) => {


  return (
    <CustomLabelButton border={border} width={width} spanHeight={spanHeight}>
      <input
        type={"radio"}
        name={"radio"}
        value={value}
        onClick={() => handleChange(value)}
        checked={isChecked}
        {...register}
      />
      <span height={spanHeight}>{children}</span>
    </CustomLabelButton>
  )
}

export default ButtonRadioInput;