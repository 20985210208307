import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  Grid,
  styled,
} from '@mui/material'
import UserProfilHeading from '../../components/headings/UserProfilHeading'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import '../../styles/components/_layouts.scss'
import { TextInputAdmin } from '../../components/inputs'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { phoneRegExp } from '../../config'
import { useForm } from 'react-hook-form'
import { BorderButtonPurple, CustomButton } from '../../components/buttons'
import React, { useEffect, useState } from 'react'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { EditPasswordModal } from '../../components/modals'
import { useSelector } from 'react-redux'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../styles/theme/lightTheme'

const InputContainer = styled(FormControl)(({ theme }) => ({
  rowGap: '5px',
}))

const schema = yup.object({
  prenom: yup.string().required('Merci de renseigner ce champ.'),
  nom: yup.string().required('Merci de renseigner ce champ.'),
  email: yup
    .string()
    .email('Merci de rentrer un email valide.')
    .required('Merci de renseigner ce champ.'),
  telephone: yup
    .string()
    .required('Merci de renseigner ce champ.')
    .matches(phoneRegExp, 'Merci de rentrer un numéro de téléphone valide.')
    .nullable(),
  adresse: yup.string().required('Merci de renseigner ce champ.'),
  ville: yup.string().required('Merci de renseigner ce champ.'),
  codePostal: yup.number().required('Merci de renseigner ce champ.'),
  pays: yup.string().required('Merci de renseigner ce champ.'),
  /*raisonSocial: yup.string().required("Merci de renseigner ce champ."),
  fonction: yup.string().required("Merci de renseigner ce champ."),*/
  /*besoin: yup.string().required("Merci de renseigner ce champ."),*/
})

const UserProfile = () => {
  const [loading, setLoading] = useState(true)
  const [editing, setEditing] = useState(false)
  /* const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [err, setErr] = useState(false); */
  const [snapShot, setSnapShot] = useState(null)
  const [editMode, setEditMode] = useState(false)
  const [role, setRole] = useState('')
  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    clearErrors,
    reset,
  } = useForm({ resolver: yupResolver(schema), mode: 'onChange' })
  const { data } = useSelector((state) => state.userAuth.user)
  //let navigate = useNavigate();

  /* useEffect(() => {
    //console.log({ errors });
  }, [errors]); */

  useEffect(() => {
    fetchData()
  }, [data.id, reset, unregister])

  const fetchData = async () => {
    try {
      let result = await Api.user.detail(data.id)

      const handling = handlingErrors(result)
      if (handling.status === 401) {
        setLoading(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        setRole(res.role)
        reset(res)
        setLoading(false)
      } else {
        setLoading(false)
        /* setMsg(handling.message);
        setErr(true);
        setOpen(true); */
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  useEffect(() => {
    if (editing) {
      setEditMode(false)
    }
  }, [editing])

  const onSubmit = async (values) => {
    const {
      admin,
      candidat,
      date,
      entreprise,
      lang,
      offre,
      parent,
      password,
      role,
      fonctions,
      status,
      ...rest
    } = values
    setEditing(true)
    try {
      const result = await Api.user.edit(data.id, rest)
      const handling = handlingErrors(result)
      if (handling.status === 401) {
        setEditing(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
      if (handling.status === 0) {
        setEditing(false)
        toast.error(
          "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
        window.scrollTo(0, 0)
      }
      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        setEditing(false)
        toast.success('Le profil à été modifier avec succès !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setEditing(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const [openModal, setOpenModal] = React.useState(false)
  const handleOpen = () => setOpenModal(true)
  const handleClose = () => setOpenModal(false)

  const onSuccessChangePassword = () => {
    toast.success('Le mot de passe a été modifié avec succès ! !', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      icon: ({ theme, type }) => (
        <FontAwesomeIcon
          icon={faCircleCheck}
          fontSize={25}
          color={lightTheme.palette.success.main}
        />
      ),
    })
    window.scrollTo(0, 0)
  }

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <>
      <Container
        maxWidth={'lg'}
        sx={{
          padding: '20px 0',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '20px',
        }}
      >
        <UserProfilHeading>Vos informations professionnelles</UserProfilHeading>
        <Box
          className={'BoxContainer'}
          sx={{
            margin: 0,
            width: '100%',
            padding: '40px 70px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          <form>
            <Grid container spacing={2} className={'gridContainerUserProfil'}>
              <Grid item xs={12} sm={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Prénom</FormLabel>
                  <TextInputAdmin
                    placeholder={'Prénom'}
                    width={'100%'}
                    register={{ ...register('prenom') }}
                    resetValue={() => {
                      setValue('prenom', snapShot['prenom'])
                      clearErrors('prenom')
                    }}
                    editMode={editMode}
                  />
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Nom</FormLabel>
                  <TextInputAdmin
                    placeholder={'Prénom'}
                    width={'100%'}
                    register={{ ...register('nom') }}
                    resetValue={() => {
                      setValue('nom', snapShot['nom'])
                      clearErrors('nom')
                    }}
                    editMode={editMode}
                  />
                </InputContainer>
              </Grid>
              {role === 'ROLE_ENTREPRISE' && (
                <>
                  <Grid item xs={12} sm={6}>
                    <InputContainer fullWidth>
                      <FormLabel className={'labelForm'}>
                        Nom de l'entreprise
                      </FormLabel>
                      <TextInputAdmin
                        placeholder={"Nom de l'entreprise"}
                        width={'100%'}
                        register={{ ...register('entreprise.raisonSocial') }}
                        resetValue={() => {
                          setValue(
                            'entreprise.raisonSocial',
                            snapShot['raisonSocial']
                          )
                          clearErrors('entreprise.raisonSocial')
                        }}
                        editMode={editMode}
                      />
                    </InputContainer>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InputContainer fullWidth>
                      <FormLabel className={'labelForm'}>Fonction</FormLabel>
                      <TextInputAdmin
                        placeholder={'Fonction'}
                        width={'100%'}
                        register={{ ...register('entreprise.fonctions') }}
                        resetValue={() => {
                          setValue('fonctions', snapShot['fonctions'])
                          clearErrors('fonctions')
                        }}
                        editMode={editMode}
                      />
                    </InputContainer>
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Mail</FormLabel>
                  <TextInputAdmin
                    placeholder={'Prénom'}
                    width={'100%'}
                    register={{ ...register('email') }}
                    resetValue={() => {
                      setValue('email', snapShot['email'])
                      clearErrors('email')
                    }}
                    editMode={editMode}
                  />
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Numéro de téléphone
                  </FormLabel>
                  <TextInputAdmin
                    placeholder={'Prénom'}
                    width={'100%'}
                    register={{ ...register('telephone') }}
                    resetValue={() => {
                      setValue('telephone', snapShot['telephone'])
                      clearErrors('telephone')
                    }}
                    editMode={editMode}
                  />
                </InputContainer>
              </Grid>
              <Grid item xs={12}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Adresse de l'entreprise
                  </FormLabel>
                  <TextInputAdmin
                    placeholder={'Prénom'}
                    width={'100%'}
                    register={{ ...register('adresse') }}
                    resetValue={() => {
                      setValue('adresse', snapShot['adresse'])
                      clearErrors('adresse')
                    }}
                    editMode={editMode}
                  />
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Ville</FormLabel>
                  <TextInputAdmin
                    placeholder={'Prénom'}
                    width={'100%'}
                    register={{ ...register('ville') }}
                    resetValue={() => {
                      setValue('ville', snapShot['ville'])
                      clearErrors('ville')
                    }}
                    editMode={editMode}
                  />
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Code postal</FormLabel>
                  <TextInputAdmin
                    placeholder={'Prénom'}
                    width={'100%'}
                    register={{ ...register('codePostal') }}
                    resetValue={() => {
                      setValue('codePostal', snapShot['codePostal'])
                      clearErrors('codePostal')
                    }}
                    editMode={editMode}
                  />
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Pays</FormLabel>
                  <TextInputAdmin
                    placeholder={'Prénom'}
                    width={'100%'}
                    register={{ ...register('pays') }}
                    resetValue={() => {
                      setValue('pays', snapShot['pays'])
                      clearErrors('pays')
                    }}
                    editMode={editMode}
                  />
                </InputContainer>
              </Grid>
            </Grid>
          </form>

          <Box className={'wrapperButtons'}>
            <BorderButtonPurple
              width={{ xs: '100%', sm: 240, lg: 300 }}
              handleClick={handleOpen}
            >
              Modifier mon mot de passe
            </BorderButtonPurple>
            <Box className={'blockButtons'}>
              {(!editMode || editing) && (
                <CustomButton
                  handleClick={() => setEditMode(true)}
                  width={{ xs: '100%', sm: 160, lg: 200 }}
                  displayIcon={'none'}
                >
                  Modifier
                </CustomButton>
              )}

              {editing ? (
                <CircularProgress />
              ) : editMode ? (
                <>
                  <BorderButtonPurple
                    width={{ xs: '50%', sm: 160, lg: 200 }}
                    handleClick={(e) => {
                      e.preventDefault()
                      clearErrors()
                      reset(snapShot)
                      setEditMode(false)
                    }}
                  >
                    Annuler
                  </BorderButtonPurple>
                  <CustomButton
                    displayIcon={'none'}
                    width={{ xs: '50%', sm: 160, lg: 200 }}
                    handleClick={handleSubmit(onSubmit)}
                  >
                    Mettre à jour
                  </CustomButton>
                </>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Container>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />

      <EditPasswordModal
        open={openModal}
        onClose={handleClose}
        id={data.id}
        onSuccess={onSuccessChangePassword}
      />
    </>
  )
}

export default UserProfile
