import React, { useEffect, useState } from 'react'
import { Box, Container, Skeleton, Typography } from '@mui/material'
import { SectionsHeading } from '../../../../../components/headings'
import { SliderImages } from '../../../../../components/images'
import lightTheme from '../../../../../styles/theme/lightTheme'
import { useTranslation } from 'react-i18next'
import '../../../../../styles/components/_skeleton.scss'
import { Markup } from 'interweave'
import { CustomButton } from '../../../../../components/buttons'
import { useNavigate } from 'react-router-dom'

export default function RecruitmentSection(data) {
  const { t } = useTranslation()
  const [recruitments, setRecruitments] = useState([])

  const navigate = useNavigate()
  useEffect(() => {
    setRecruitments(data.recrutement)
  }, [data.recrutement])

  return (
    <Box
      component={'section'}
      width={'100%'}
      pt={'100px'}
      pb={'170px'}
      id={'sec1'}
    >
      <>
        {recruitments.map((recruitment, index) => (
          <Container
            maxWidth={'lg'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              rowGap: '50px',
            }}
            key={index}
          >
            {!recruitment.titre ? (
              <Skeleton
                variant={'text'}
                animation={'wave'}
                className={'skeleton-title'}
              />
            ) : (
              <SectionsHeading
                type={'animation'}
                display={'flex'}
                justifyContent={'center'}
                width={{ xs: 265, lg: 350 }}
              >
                {recruitment.titre}
              </SectionsHeading>
            )}

            {!recruitment.detail ? (
              <Box width={'100%'}>
                <Skeleton
                  variant={'text'}
                  animation={'wave'}
                  className={'skeleton-text'}
                />
                <Skeleton
                  variant={'text'}
                  animation={'wave'}
                  className={'skeleton-text'}
                />
                <Skeleton
                  variant={'text'}
                  animation={'wave'}
                  className={'skeleton-text-50'}
                />
              </Box>
            ) : (
              <Typography
                variant={'body2'}
                sx={{
                  maxWidth: { xs: '100%', sm: 900 },
                  color: lightTheme.palette.text.primary,
                }}
              >
                <Markup content={recruitment.detail} />
              </Typography>
            )}

            <SliderImages items={recruitments} />
            <CustomButton
              handleClick={() => {
                navigate('/auth/inscription/entreprise')
              }}
              displayIcon={'none'}
            >
              {t(`button.findCandidate`)}
            </CustomButton>
          </Container>
        ))}
      </>
    </Box>
  )
}
