import React from "react";
import { Box, styled } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";

const Button = styled(Box)(({theme}) => ({
  width: "max-content",
  height: 45,
  backgroundColor: lightTheme.palette.admin.adminSearch,
  color: lightTheme.palette.text.secondary,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  columnGap: "10px",
  fontFamily: "Century Gothic Bold",
  fontSize: 10,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  textTransform: "uppercase",
  padding: "10px 15px",

  [theme.breakpoints.up("sm")]: {
    fontSize: 12
  },

  [theme.breakpoints.up("lg")]: {
    fontSize: 14
  },
}));

const Icon = styled(Box)(({theme}) => ({
  display: "block",
}));

const DarkButton = ({children, icon, handleClick}) => {
  return (
    <Button onClick={handleClick}>
      <span>{children}</span>
      <Icon>{icon}</Icon>
    </Button>
  )
}

export default DarkButton;