import React from "react";
import {Box, styled, Typography} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/pro-solid-svg-icons";


const Button = styled(Box)(({ theme, type, height, width }) => ({
    width: width,
    height: 45,
    marginLeft: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "10px",
    position: "relative",
    boxSizing: "border-box",
    border: "1px solid transparent",
    borderRadius: "3px",
    backgroundClip: "padding-box",
    background:
        type === "rainbow"
            ? "#FFFFFF"
            : type === "basic"
            ? "#FFFFFF"
            : "#FFFFFF",
    cursor: "pointer",
    padding: "0 10px",

    "&:before": {
        content: "''",
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: "-1",
        borderRadius: "inherit",
        background:
            type === "rainbow"
                ? lightTheme.palette.gradients.gradientAll
                : type === "basic"
                ? lightTheme.palette.primary.b2
                : lightTheme.palette.primary.b2,
        margin: "-2px",
    },

    "&:hover": {
        "&:before": {
            margin: "-3px",
        }
    },
    "&:active": {
        "&:before": {
            margin: "-2px",
            transition: "all .1s ease-in-out"
        }
    },

    [theme.breakpoints.up("sm")]: {
        height: 41
    },

    [theme.breakpoints.up("lg")]: {
        height: 45
    },

}));

const Span = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "textRainbow",
    name: "Span",
})(({ theme, textcolor}) => ({
    fontFamily: "Century Gothic Bold",
    fontSize: 10,
    lineHeight: "140%",
    letterSpacing: "0.2px",
    textAlign: "center",
    textTransform: "uppercase",
    background: textcolor === "rainbow"
                ? lightTheme.palette.gradients.gradientAll
                : textcolor === "basic"
                ? lightTheme.palette.primary.b2
                : lightTheme.palette.primary.b2,
    backgroundClip: "text",
    textFillColor: "transparent",

    [theme.breakpoints.up("sm")]: {
        fontSize: 12
    },

    [theme.breakpoints.up("lg")]: {
        fontSize: 14
    },
}));

const IconLeft = styled(Box, {
    shouldForwardProp: (prop) => prop !== "displayIconLeft",
    name: "IconLeft",
})(({theme, displayIconLeft}) => ({
    display: displayIconLeft === "block"
                ? "block"
                : displayIconLeft === "none"
                ? "none"
                : "",
    "& .arrowLeft": {
        color: lightTheme.palette.primary.b2
    }
}));

const IconRight = styled(Box, {
    shouldForwardProp: (prop) => prop !== "displayIconRight",
    name: "IconRight",
})(({theme, displayIconRight}) => ({
    display: displayIconRight === "block"
                ? "block"
                : displayIconRight === "none"
                ? "none"
                : "",
    padding: 0,
    "& .arrowRight": {
        color: lightTheme.palette.primary.b2
    },

    /*[theme.breakpoints.up("lg")]: {
        padding: "0 10px",
    },*/
}));

export default function BorderButton({children, textColor, type , height, width, displayIconRight, displayIconLeft, handleClick, icon}) {
    return (
        <Button type={type} height={height} width={width} onClick={handleClick}>
            <IconLeft display={displayIconLeft}>
                <FontAwesomeIcon icon={faArrowLeft}  className="arrowLeft" />
            </IconLeft>
            <Span textcolor={textColor}>
                {children}
            </Span>
            <IconRight display={displayIconRight}>
                {icon}
                {/*<FontAwesomeIcon icon={faArrowRight} className="arrowRight" />*/}
            </IconRight>
        </Button>
    )
}