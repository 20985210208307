import React from "react";
import { Box, Skeleton, styled } from "@mui/material";
import ScrollContainer from "react-indiana-drag-scroll";
import lightTheme from "../../styles/theme/lightTheme";
import { keyframes } from "@emotion/react";

const CustomBox =styled(Box)(({theme}) => ({
    display: "flex",
    columnGap: "15px",
    maxWidth: "100%",

    [theme.breakpoints.up("sm")]: {
        columnGap: "20px"
    },
    [theme.breakpoints.up("lg")]: {
        columnGap: "30px",
    },
}));

const Block = styled(Box)(({theme,type}) => ({
    width: 25,
    height: 80,
    backgroundColor: type === "animation"
      ? lightTheme.palette.error.main
      : type === "basic"
        ? "#FFFFFF"
        : lightTheme.palette.error.main,
    borderRadius: "10px",
    /*animation: type === "animation"
      ? `${colorEffect} 15s linear infinite`
      : type === "basic"
        ? "none"
        : "none",*/
    animation: `${colorEffect} 15s linear infinite`,

    [theme.breakpoints.up("sm")]: {
        width: 45,
        height: 105,
    },
    [theme.breakpoints.up("md")]: {
        borderRadius: "20px",
    },
    [theme.breakpoints.up("lg")]: {
        width: 60,
        height: 150
    },
}));

const colorEffect = keyframes`
    0% { background-color: #F76E11;}
    25% { background-color: #96CEB4; }
    50% { background-color: #808BF8; }
    75% { background-color: #96CEB4; }
    100% { background-color: #F76E11;}
`

const ImageContainer = styled(Box)(({theme}) => ({
    width: 80,
    height: 80,
    borderRadius: "10px",
    overflow: "hidden",
    position: "relative",

    img: {
      position: "absolute",
      width: "100%",
      height: "100%",
      objectFit: "cover"
    },

    [theme.breakpoints.up("sm")]: {
        width: 100,
        height: 100,
    },
    [theme.breakpoints.up("lg")]: {
        width: 150,
        height: 150
    },
}));

const ScrollWrap = styled(Box)(({theme}) => ({
    display: "flex",
    columnGap: "15px",
    width: "200%",

    [theme.breakpoints.up("sm")]: {
        columnGap: "20px",
        width: "100%"
    },
    [theme.breakpoints.up("lg")]: {
        columnGap: "30px"
    },
}));

const WrapperImage = ({items}) => {
    return (
      <>
          {items ? (
            <>
                <ScrollWrap>
                    {!items.photos[0] ? (
                      <>
                          <Skeleton variant={"rounded"} animation={"wave"} className={"skeleton-slider-image"} />
                      </>
                    ) : (
                      <ImageContainer>
                          <img src={items.photos[0].lien} alt={"Jobertech"}/>
                      </ImageContainer>
                      )}

                    {!items.photos[1] ? (
                      <>
                          <Skeleton variant={"rounded"} animation={"wave"} className={"skeleton-slider-image"} />
                      </>
                    ) : (
                      <ImageContainer>
                          <img src={items.photos[1].lien} alt={"Jobertech"}/>
                      </ImageContainer>
                    )}

                    {!items.photos[2] ? (
                      <>
                          <Skeleton variant={"rounded"} animation={"wave"} className={"skeleton-slider-image"} />
                      </>
                    ) : (
                      <ImageContainer>
                          <img src={items.photos[2].lien} alt={"Jobertech"}/>
                      </ImageContainer>
                    )}

                    {!items.photos[3] ? (
                      <>
                          <Skeleton variant={"rounded"} animation={"wave"} className={"skeleton-slider-image"} />
                      </>
                    ) : (
                      <ImageContainer>
                          <img src={items.photos[3].lien} alt={"Jobertech"}/>
                      </ImageContainer>
                    )}

                    {!items.photos[4] ? (
                      <>
                          <Skeleton variant={"rounded"} animation={"wave"} className={"skeleton-slider-image"} />
                      </>
                    ) : (
                      <ImageContainer>
                          <img src={items.photos[4].lien} alt={"Jobertech"}/>
                      </ImageContainer>
                    )}
                </ScrollWrap>
            </>
          ) : (
            <></>
          )}
      </>
    )
}

export default function SliderImages( props, {type}) {
    return (
      <>
          {props.items && props.items.map((item, index) => (
            <CustomBox>
                <Block type={type}/>
                <ScrollContainer key={index}>
                    <WrapperImage items={item}/>
                </ScrollContainer>
                <Block type={type}/>
            </CustomBox>
          ))}
      </>
    )
}