import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { Box, styled } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { ROLE_CANDIDAT, ROLE_ENTREPRISE } from '../../data'

const SidebarLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  color: lightTheme.palette.grey.light,
  justifyContent: 'space-between',
  alignItems: 'center',
  listStyle: 'none',
  height: 60,
  textDecoration: 'none',
  fontSize: 14,
  fontFamily: 'Century Gothic Bold',
  lineHeight: '140%',
  letterSpacing: '0.2px',
  textTransform: 'uppercase',
}))

const Title = styled(Box)({
  display: 'flex',
  alignItems: 'center',
})

const Icon = styled(Box)({
  width: 25,
  height: 25,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const SidebarLabel = styled('span')(({ theme }) => ({
  marginLeft: '12px',
}))

const DropdownLink = styled(NavLink)({
  height: 60,
  paddingLeft: '10px',
  display: 'flex',
  alignItems: 'center',
  columnGap: '15px',
  textDecoration: 'none',
  color: lightTheme.palette.grey.main,
  fontFamily: 'Century Gothic',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',

  '&:hover': {
    color: lightTheme.palette.grey.light,
    cursor: 'pointer',
  },

  '&.active': {
    color: lightTheme.palette.grey.light,
  },
})

const DropdownLabel = styled('span')({})

const Submenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false)
  const [countCandidat, setCountCandidat] = useState(0)
  const [countEntreprise, setCountEntreprise] = useState(0)
  const [countDemande, setCountDemande] = useState(0)

  const getUsers = async (role, status) => {
    try {
      const result = await Api.user.listWithPaginationAndFilter({
        role: role,
        status: status,
      })

      const res = handlingErrors(result)

      if (res.ok) {
        role === ROLE_CANDIDAT
          ? setCountCandidat(res.data['hydra:totalItems'])
          : role === ROLE_ENTREPRISE
          ? setCountEntreprise(res.data['hydra:totalItems'])
          : setCountDemande(res.data['hydra:totalItems'])
        //(role === ROLE_CANDIDAT)
        /* const sort = res.data['hydra:member'].sort((a, b) =>
          a.date < b.date ? 1 : -1
        ) */
      }
    } catch (error) {
      console.debug(error)
    }
  }

  useEffect(() => {
    if (item.counter && item.counter === 'client') {
      getUsers(ROLE_CANDIDAT, 'active')
      getUsers(ROLE_ENTREPRISE, 'active')
    }
    if (item.counter && item.counter === 'prospect') {
      getUsers(ROLE_CANDIDAT, 'classic')
      getUsers(ROLE_ENTREPRISE, 'classic')
      getUsers([ROLE_ENTREPRISE, ROLE_CANDIDAT], 'contact')
    }
  }, [item])

  const showSubnav = () => setSubnav(!subnav)

  return (
    <>
      <SidebarLink
        /*to={item.path}*/ to={'#'}
        onClick={item.subNav && showSubnav}
      >
        <Title>
          <Icon>{item.icon}</Icon>
          <SidebarLabel>{item.title}</SidebarLabel>
        </Title>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink to={item.path} key={index}>
              {item.icon}
              <DropdownLabel>
                {item.title}{' '}
                {item.counter &&
                  item.counter === 'candidat' &&
                  '(' + countCandidat + ')'}
                {item.counter &&
                  item.counter === 'entreprise' &&
                  '(' + countEntreprise + ')'}
                {item.counter &&
                  item.counter === 'demande' &&
                  '(' + countDemande + ')'}
              </DropdownLabel>
            </DropdownLink>
          )
        })}
    </>
  )
}

export default Submenu
