import React, { useEffect, useState } from 'react'
import TextWithMasonryImage from '../../../../../components/TextWithMasonryImage'
import { Markup } from 'interweave'

export default function SearchJobSection(data) {
  const [searchSections, setSearchSections] = useState([])

  useEffect(() => {
    setSearchSections(data.rechercheJob)
  }, [data.rechercheJob])

  return (
    <>
      {searchSections.map((searchSection, index) => (
        <TextWithMasonryImage
          key={index}
          title={searchSection.titre}
          sousTitre={searchSection.sousTitre}
          text1={<Markup content={searchSection.detail} />}
          itemProps={searchSections}
        />
      ))}
    </>
  )
}
