import { Box, styled, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { StepsCandidat } from './Steps'
import { useParams } from 'react-router-dom'

const ContentBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '50px',

  [theme.breakpoints.up('lg')]: {
    width: '50%',
  },
}))

const InscriptionCandidat = () => {
  const [token, setToken] = useState('')

  const params = useParams()

  useEffect(() => {
    setToken(params.token)
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        justifyContent: { xs: 'center', lg: 'space-between' },
        marginTop: '50px',
        rowGap: { xs: '10px', lg: '80px' },
      }}
    >
      <ContentBox>
        <Typography variant={'h1'} textAlign={{ xs: 'center', lg: 'left' }}>
          Finalisons votre inscription sur la plateforme Jobertech
        </Typography>
        {/* <ContentBody>
          <Typography variant={'h2'}>Loremp ipsum</Typography>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo
            enim orci elit tristique. Ultricies mauris pretium metus, risus
            tortor. A enim nullam volutpat, praesent ligula mattis posuere
            adipiscing ante.
          </Typography>
          <Typography>
            Quam vulputate fusce egestas vestibulum dictum velit. Dolor nullam
            justo felis egestas sit. Et amet, a vitae sed iaculis posuere lacus.
            Bibendum pellentesque mi adipiscing dui amet dui, vel, amet.
          </Typography>
        </ContentBody>*/}
      </ContentBox>
      <Box width={{ xs: '100%', lg: '40%' }}>
        {token && (
          /*<Steps token={token} type={"candidat"}/>*/
          <StepsCandidat token={token} />
        )}
      </Box>
    </Box>
  )
}

export default InscriptionCandidat
