import React from "react";
import {Box, styled} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";

const BannerRight = styled(Box)(({theme}) => ({
    height: 380,
    width: "100%",
    backgroundColor: lightTheme.palette.primary.b6,
    position: "absolute",
    right: 0,
    top: 480,

    [theme.breakpoints.up("sm")]: {
        width: "60%",
        height: 420,
        top: 200,
        borderRadius: "125px 0px 0px 125px",
    },
    [theme.breakpoints.up("lg")]: {
        width: "50%",
        height: 550,
        top: 220
    },
}));

export default function BannerBackgroundRight() {
    return (
        <BannerRight/>
    )
}