import { Box } from '@mui/material'
import { AdminHeading } from '../../../components/headings'
import lightTheme from '../../../styles/theme/lightTheme'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Api from '../../../services/api.service'
import { handlingErrors } from '../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import ListTableRdv from '../../../components/tables/ListTableRdv'
import { EditEvent } from '../../../components/modals'
import AppPagination from '../../../components/paginations'

const ListeRendezVous = () => {
  const [loading, setLoading] = useState(false)
  /* const [searchQuery, setSearchQuery] = useState('') */
  const [rdvs, setRdvs] = useState([])
  const [id, setId] = useState(null)
  const [openCard, setOpenCard] = useState('')
  const [event, setEvent] = useState({})
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  /*   const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearchQuery(value)
  }, 800) */

  const handleCloseEvent = () => {
    setOpenCard(false)
  }

  const handlePageChange = (event, page) => {
    setPage(page)
    getRdv(page)
    sessionStorage.setItem('pageCompany', page)
  }
  useEffect(() => {
    getRdv(page)
  }, [])

  const getRdv = async (page) => {
    setLoading(true)

    /* let payload = {
      search: searchQuery,
    }
    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    ) */

    try {
      const result = await Api.calendar.listWithFilter({
        type: ['rdv_decouverte', 'rdv_accompagnement'],
        page: page,
        /*  ...payload, */
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setRdvs(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])

        //const sort = res.data.sort(( a, b) => ( a.date < b.date ? 1 : -1));
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoading(false)
  }

  const handleClickToProfil = (rdv) => {
    setOpenCard(true)
    setEvent(rdv)
  }

  const userData = useSelector((state) => state.userAuth.user)
  if (
    userData.data.role !== 'ROLE_SUPER_ADMIN' &&
    userData.data.role !== 'ROLE_ADMIN' &&
    userData.data.role !== 'ROLE_MINI_ADMIN'
  )
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '40vh',
        }}
      >
        Désolé, vous n'êtes pas autorisé à accéder à cette page
      </Box>
    )

  return (
    <>
      <div style={{ width: '100%' }}>
        <AdminHeading displayIconReturn={'none'}>
          Demandes de rendez-vous découverte
        </AdminHeading>
        <Box
          className={'BoxContainer'}
          sx={{
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          {/* <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <SearchInputBasic onChange={handleSearchChange} />
          </Box> */}

          <ListTableRdv
            type={'candidat'}
            items={rdvs}
            setId={setId}
            loading={loading}
            handleClick={handleClickToProfil}
          />
          <AppPagination
            handlePageChange={handlePageChange}
            count={count}
            page={page}
          />
        </Box>
      </div>

      {openCard && (
        <EditEvent
          open={openCard}
          onClose={handleCloseEvent}
          id={id}
          //role={role}
          event={event}
          onSave={handleCloseEvent}
          showParams={true}
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default ListeRendezVous
