import ApiRequest from "../request/ApiRequest";

export default class Company {
  constructor() {
    this.ApiRequest = new ApiRequest();
  }

  async add() {
    try {
      const res = await this.ApiRequest.post("utilisateurs");
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async list() {
    try {
      const res = await this.ApiRequest.get("entreprises");
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listProspect() {
    try {
      const res = await this.ApiRequest.get("entreprises.json?profile.status=contact");
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listProspectOffre(id) {
    try {
      const res = await this.ApiRequest.get(`entreprises.json?profile.offre=/offres.json/${id}`);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`entreprises/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`entreprises/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`entreprises/${id}`);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listByIdOffre(id) {
    try {
      const res = await this.ApiRequest.get(`entreprises.json?offres.id=${id}`);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get("entreprises.json", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async alertEmail(type) {
    try {
      const res = await this.ApiRequest.get(`offre/entreprise/${type}`);
      return res;
    } catch (error) {
      return error.response;
    }
  }

}