import { Box, Container, styled } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import { Outlet, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logos/logo.svg"

const LayoutContainer = styled(Box)(({theme}) => ({
  height: "100vh",
  overflow: "hidden",
  position: "relative",
  backgroundColor: "rgba(227, 230, 255, .5)"
}));

const LogoContainer = styled(Box)(({theme}) => ({
  cursor: "pointer",
  width: 180,
  height: 50,
  padding: "0 25px",
  position: "relative",

  img: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },

  [lightTheme.breakpoints.up("lg")]: {
    padding: 0,
    width: 230,
  },

}));

const QuadrantCircleTop = styled(Box)(({theme}) => ({
  width: 150,
  height: 150,
  backgroundColor: lightTheme.palette.primary.b4,
  borderRadius: "0 0 0 100%",
  position: "absolute",
  right: 0,
  top: "-50px",

  [lightTheme.breakpoints.up("md")]: {
    width: 250,
    height: 250,
  },
}));

const QuadrantCircleBottom = styled(Box)(({theme}) => ({
  width: 150,
  height: 150,
  backgroundColor: lightTheme.palette.primary.b4,
  borderRadius: "0 100% 0 0",
  position: "absolute",
  left: 0,
  bottom: "-50px",

  [lightTheme.breakpoints.up("md")]: {
    width: 250,
    height: 250,
  },
}));

const ErrorLayout = () => {
  const navigate = useNavigate();

  return (
    <LayoutContainer>
      <QuadrantCircleTop/>
      <Container
        maxWidth={"lg"}
        sx={{
          padding: "80px 0",
          display: "flex",
          height: "100%",
          flexDirection: "column"
        }}
      >
        <LogoContainer onClick={() => navigate("/")}>
          <img src={logo} alt={"Jobertech"} width={231} />
        </LogoContainer>
        <Outlet/>
      </Container>
      <QuadrantCircleBottom/>
    </LayoutContainer>
  )
}

export default ErrorLayout;