import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import lightTheme from "../../styles/theme/lightTheme";

const Banner = styled(Box)(({theme}) => ({
  height: 55,
  width: "100%",
  borderRadius: "0px 0px 40px 40px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
  backgroundColor: "#FFFFFF",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  zIndex: 1,

  [theme.breakpoints.up("sm")]: {
    height: 80,
  },
  [theme.breakpoints.up("lg")]: {
    height: 70,
    borderRadius: 0,
    width: "calc(100% - 260px)",
  },
  [theme.breakpoints.up("xl")]: {
    width: "calc(100% - 300px)",
    borderRadius: 0,
  },
}));

const Title = styled(Typography)(({theme}) => ({
  textAlign: "center",
  textTransform: "uppercase",

  [theme.breakpoints.up("sm")]: {
    fontSize: 16,

  },
  [theme.breakpoints.up("lg")]: {
    fontSize: 20
  },
}));

const IconReturn = styled(Box)(({theme}) => ({
  position: "absolute",
  left: 25,
  cursor: "pointer",

  "& .icon": {
    fontSize: 25,
    color: lightTheme.palette.primary.b4,

    "&:hover": {
      color: lightTheme.palette.primary.main
    },

    "&:active": {
      color: lightTheme.palette.primary.b4
    }
  }
}))

const AdminHeading = ({children, displayIconReturn, handleClickIcon}) => {
  return (
    <Banner>
      <IconReturn display={displayIconReturn} onClick={handleClickIcon}>
        <FontAwesomeIcon icon={faArrowLeft} className={"icon"}/>
      </IconReturn>
      <Title variant={"h1"}>
        {children}
      </Title>
    </Banner>
  )
}

export default AdminHeading;