import React, { useEffect, useState } from 'react'
import SearchJobSection from './components/SearchJobSection'
import OptimizationSection from './components/OptimizationSection'
import TransitionSection from './components/TransitionSection'
import OffersSection from './components/OffersSection'
import StickyNavbar from '../../../../components/navbar/StickyNavbar'
import { useTranslation } from 'react-i18next'
import ConsultantSection from './components/ConsultantSection'
import { CircularProgress } from '@mui/material'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'

export default function Candidate() {
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [candidateData, setCandidateData] = useState([])

  const data = [
    {
      title: `${t('stickyNav.candidatService')}`,
      list: [
        {
          text: `${t('stickyNav.jobPath')}`,
        },
        {
          text: `${t('stickyNav.professionalTransition')}`,
        },
      ],
      button: `${t('button.discover')}`,
    },
  ]

  useEffect(() => {
    getHomeData(i18n.language)
  }, [i18n.language])

  const getHomeData = async (lang) => {
    setLoading(true)

    try {
      const result = await Api.siteVitrine.listWithFilter({
        lang: lang,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }

      if (res.ok) {
        setCandidateData(res.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (loading) {
    return (
      <div>
        <StickyNavbar items={data} width={400} />
        <main style={{ marginTop: '80px' }}>
          <CircularProgress />
        </main>
      </div>
    )
  }

  return (
    <div>
      <StickyNavbar items={data} width={400} />
      <main style={{ marginTop: '-80px' }}>
        <SearchJobSection
          rechercheJob={candidateData.filter(
            (data) => data.section === 'recherche-job'
          )}
        />
        <OptimizationSection
          optimisationParcours={candidateData.filter(
            (data) => data.section === 'optimisation-parcours'
          )}
        />
        <TransitionSection
          transition={candidateData.filter(
            (data) => data.section === 'transition'
          )}
        />
        <OffersSection />
        <ConsultantSection />
      </main>
    </div>
  )
}
