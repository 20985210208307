import ApiRequest from "../request/ApiRequest";

export default class Admin {
  constructor() {
    this.ApiRequest = new ApiRequest();
  }

  async add() {
    try {
      const res = await this.ApiRequest.post("utilisateurs");
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async list() {
    try {
      const res = await this.ApiRequest.get("utilisateurs?role=ROLE_ADMIN");
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`utilisateurs/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`utilisateurs/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async detailsDisponibilites(id) {
    try {
      const res = await this.ApiRequest.get(`admins/${id}.json`);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async editDisponibilites(id, data) {
    try {
      const res = await this.ApiRequest.put(`admins/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

}