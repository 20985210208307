import React, { useState } from 'react'
import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
} from '@mui/material'
import { TextInputClassic } from '../inputs'
import { BorderButtonPurple, CustomButton } from '../buttons'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_errors.scss'
import { useTranslation } from 'react-i18next'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 512,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '50px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 620,
    padding: '30px',
  },
}))

const InputContainer = styled(FormControl)(({ theme }) => ({
  rowGap: '5px',
}))

const EditPasswordModal = ({ open, onClose, id, onSuccess }) => {
  const { t } = useTranslation()

  const schema = yup
    .object({
      oldPassword: yup.string().required(t('errorMessage.oldPassword')),
      newPassword: yup.string().required(t('errorMessage.newPassword')),
      confirmNewPassword: yup
        .string()
        .required(t('errorMessage.confirmNewPassword'))
        .oneOf([yup.ref('newPassword')], t('errorMessage.passwordNotMatch')),
    })
    .required()
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: 'onChange' })

  const [loading, setLoading] = useState(false)
 /*  const [err, setErr] = useState(false)
  const [msg, setMsg] = useState('') */

  const onSubmit = async (data) => {
    setLoading(true)
    let result = await Api.auth.checkPassword({
      password: data.oldPassword,
      newPassword: data.newPassword,
    })

    const res = handlingErrors(result)
    if (!res.ok) {
      /* setMsg(res.message)
      setErr(true) */
      setLoading(false)
      window.scrollTo(0, 0)
    } else {
      if (!res.data) {
        setError(
          'oldPassword',
          { message: t('errorMessage.incorrectOldPassword') },
          { shouldFocus: true }
        )
      } else {
        const result = await Api.user.edit(id, { password: data.newPassword })
        const res = handlingErrors(result)
        if (!res.ok) {
         /*  setMsg(res.message)
          setErr(true) */
          window.scrollTo(0, 0)
        } else {
          reset()
          onClose()
          onSuccess()
        }
        // EDIT PASSWORD SEND TO API USER UPDATE
      }
      setLoading(false)
    }
    // reset();
    // onClose();
  }

  return (
    <Modal open={open}>
      <ModalContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '30px',
          }}
        >
          <Typography
            variant={'subtitle2'}
            textTransform={'uppercase'}
            textAlign={'center'}
          >
            {t('box.title.editPassword')}
          </Typography>
          <Box
            sx={{
              backgroundColor: lightTheme.palette.primary.b6,
              padding: '20px 15px',
            }}
          >
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputContainer fullWidth>
                    <FormLabel>{t('box.oldPassword')}</FormLabel>
                    <TextInputClassic
                      placeholder={t('box.oldPasswordText')}
                      type={'password'}
                      register={{ ...register('oldPassword') }}
                    />
                    {errors.oldPassword && (
                      <Typography className={'error-message'}>
                        {errors.oldPassword.message}
                      </Typography>
                    )}
                  </InputContainer>
                </Grid>
                <Grid item xs={12}>
                  <InputContainer fullWidth>
                    <FormLabel>{t('box.newPassword')}</FormLabel>
                    <TextInputClassic
                      placeholder={t('box.newPasswordText')}
                      type={'password'}
                      register={{ ...register('newPassword') }}
                    />
                    {errors.newPassword && (
                      <Typography className={'error-message'}>
                        {errors.newPassword.message}
                      </Typography>
                    )}
                  </InputContainer>
                </Grid>
                <Grid item xs={12}>
                  <InputContainer fullWidth>
                    <FormLabel>{t('box.confirmNewPassword')}</FormLabel>
                    <TextInputClassic
                      placeholder={t('box.confirmNewPasswordText')}
                      type={'password'}
                      register={{ ...register('confirmNewPassword') }}
                    />
                    {errors.confirmNewPassword && (
                      <Typography className={'error-message'}>
                        {errors.confirmNewPassword.message}
                      </Typography>
                    )}
                  </InputContainer>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>

        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.cancel')}
          </BorderButtonPurple>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              /*handleClick={(e) => {
              /!*e.preventDefault();*!/
              handleSubmit(onSubmit);
            }}*/
              handleClick={handleSubmit(onSubmit)}
            >
              {t('button.save')}
            </CustomButton>
          )}
        </Box>
      </ModalContainer>
    </Modal>
  )
}

export default EditPasswordModal
