import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Grid,
  Modal,
  styled,
  Typography,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { userLogout } from '../../store/slices/userAuthReducer'
import TextareaInputClassic from '../inputs/TextareaInputClassic'
import { ROLE_ENTREPRISE } from '../../data'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const proSchema = yup.object({
  commentaire: yup.string().required('Merci de renseigner ce champ.'),
})

const parSchema = yup.object({
  commentaire: yup.string().required('Merci de renseigner ce champ.'),
})

const EditCommentaire = ({ open, onClose, onSave, id, role }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
 /*  const [commentaire, setCommentaire] = useState('')
  const [snapShot, setSnapShot] = useState(null) */
  const {
    register,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(role === ROLE_ENTREPRISE ? proSchema : parSchema),
  })

  useEffect(() => {
    fetchData()
  }, [id, dispatch, reset])

  const fetchData = async () => {
    try {
      let result = await Api.user.detail(id)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        console.log('erreur')
      }

      if (handling.ok) {
        const res = handling.data
        /* setSnapShot(res)
        setCommentaire(res.commentaire) */
        reset(res)
      }
    } catch (error) {
      console.log(error)
    }
  }


  const onSubmit = async (data) => {
    setLoading(true)

    //return

    const {
      admin,
      candidat,
      userOffres,
      date,
      entreprise,
      lang,
      offre,
      parent,
      password,
      status,
      ...rest
    } = data
    
    try {
      const result = await Api.user.edit(id, { commentaire: data.commentaire })
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        dispatch(userLogout())
        return
      }

      if (handling.ok) {
        //const res = handling.data
        setLoading(false)
        onSave()
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log('erreur submit !')
    }
  }

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          Modifier le commentaire
        </Typography>
        <BoxContainer>
          <form>
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
              >
                <TextareaInputClassic
                  placeholder={'Commentaires Jobertech'}
                  width={'100%'}
                  register={{ ...register('commentaire') }}
                />
              </Grid>
            </Grid>
          </form>
        </BoxContainer>
        {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            Annuler
          </BorderButtonPurple>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              handleClick={handleSubmit(onSubmit)}
            >
              Enregistrer
            </CustomButton>
          )}
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default EditCommentaire
