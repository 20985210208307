import axios from 'axios'

export default class countriesCities {
  async getCitiesByCountry(countryName) {
    try {
      const res = await axios.post(
        'https://countriesnow.space/api/v0.1/countries/cities',
        {
          country: countryName,
        },
        {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json',
            accept: '*/*',
          },
        }
      )
      return res
    } catch (error) {
      return error.response
    }
  }
  async getStatesByCountry(countryName) {
    try {
      const res = await axios.post(
        'https://countriesnow.space/api/v0.1/countries/states',
        {
          country: countryName,
        },
        {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            'Content-Type': 'application/json',
            accept: '*/*',
          },
        }
      )
      return res
    } catch (error) {
      return error.response
    }
  }
}
