import React, { useEffect, useState } from 'react'
import { Box, Skeleton, styled } from '@mui/material'
import logo from '../../assets/images/logos/logo-green.svg'
import '../../styles/components/_skeleton.scss'

const BigImage = styled(Box)(({ theme }) => ({
  width: 205,
  height: 205,
  borderRadius: '100%',
  overflow: 'hidden',
  position: 'relative',

  img: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  [theme.breakpoints.up('sm')]: {
    width: 225,
    height: 225,
  },
  [theme.breakpoints.up('lg')]: {
    width: 300,
    height: 300,
  },
}))

const SmallImage = styled(Box)(({ theme }) => ({
  width: 68,
  height: 68,
  borderRadius: '100%',
  overflow: 'hidden',
  position: 'relative',
  border: '2px solid #FFFFFF',

  img: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  [theme.breakpoints.up('sm')]: {
    width: 75,
    height: 75,
  },
  [theme.breakpoints.up('lg')]: {
    width: 100,
    height: 100,
  },
}))

const Logo = styled(Box)(({ theme }) => ({
  width: 32,
  height: 32,
  position: 'absolute',
  zIndex: 1,
  top: '-15px',
  right: '-15px',

  img: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  [theme.breakpoints.up('sm')]: {
    width: 35,
    height: 35,
  },
  [theme.breakpoints.up('lg')]: {
    width: 47,
    height: 47,
  },
}))

const ImageContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 68,
  height: 68,

  [theme.breakpoints.up('sm')]: {
    width: 75,
    height: 75,
  },
  [theme.breakpoints.up('lg')]: {
    width: 100,
    height: 100,
  },
}))

export default function FlowerImage(data) {
  const [resulstImages, setResultsImages] = useState([])

  useEffect(() => {
    setResultsImages(data.recrutementResultat)
  }, [data.recrutementResultat])

  return (
    <>
      {resulstImages.map((resulstImage, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            columnGap: { xs: 0, sm: '14px', lg: '18px' },
          }}
        >
          {!resulstImage.photos[3] ? (
            <Skeleton
              variant={'text'}
              animation={'wave'}
              className={'skeleton-circle-image'}
            />
          ) : (
            <BigImage>
              <img src={resulstImage.photos[3].lien} alt={'Jobertech'} />
            </BigImage>
          )}

          <Box
            sx={{
              width: { xs: 95, sm: 115, lg: 165 },
              height: { xs: 285, lg: 392 },
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              rowGap: { xs: '20px', sm: '25px', lg: '30px' },
            }}
          >
            <ImageContainer alignSelf={'start'}>
              {!resulstImage.photos[0] ? (
                <Skeleton
                  variant={'text'}
                  animation={'wave'}
                  className={'skeleton-small-circle'}
                />
              ) : (
                <SmallImage>
                  <img src={resulstImage.photos[0].lien} alt={'Jobertech'} />
                </SmallImage>
              )}

              <Logo>
                <img src={logo} alt={'Jobertech'} />
              </Logo>
            </ImageContainer>

            <ImageContainer alignSelf={'end'}>
              {!resulstImage.photos[2] ? (
                <Skeleton
                  variant={'text'}
                  animation={'wave'}
                  className={'skeleton-small-circle'}
                />
              ) : (
                <SmallImage>
                  <img src={resulstImage.photos[2].lien} alt={'Jobertech'} />
                </SmallImage>
              )}
              <Logo>
                <img src={logo} alt={'Jobertech'} />
              </Logo>
            </ImageContainer>

            <ImageContainer alignSelf={'start'}>
              {!resulstImage.photos[1] ? (
                <Skeleton
                  variant={'text'}
                  animation={'wave'}
                  className={'skeleton-small-circle'}
                />
              ) : (
                <SmallImage>
                  <img src={resulstImage.photos[1].lien} alt={'Jobertech'} />
                </SmallImage>
              )}
              <Logo>
                <img src={logo} alt={'Jobertech'} />
              </Logo>
            </ImageContainer>
          </Box>
        </Box>
      ))}
    </>
  )
}
