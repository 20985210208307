import React, {useState} from "react";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/pro-regular-svg-icons";
import {faChevronRight} from "@fortawesome/pro-regular-svg-icons";
import lightTheme from "../../styles/theme/lightTheme";
import img from "../../assets/images/article-4.jpg";
import img2 from "../../assets/images/article-2.jpg";
import img3 from "../../assets/images/article-6.jpg";
import img4 from "../../assets/images/article-1.jpg";
import img5 from "../../assets/images/article-3.jpg";
import {styled} from "@mui/material";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import eventbriteIcon from "../../assets/images/icons/eventbrite-black.png";
import meetupIcon from "../../assets/images/icons/meetup-black.png";
import {BadgeButton, CustomButton} from "../buttons";

/*const steps = [
    {
        label: 'Select campaign settings',
        description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
    },
    {
        label: 'Create an ad group',
        description:
            'An ad group contains one or more ads which target a shared set of keywords.',
    },
    {
        label: 'Create an ad',
        description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
    },
];*/

const data = [
    {
        id: 1,
        picture: img,
        title: "Lorem Ipsum 1",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi velit vulputate et, sagittis scelerisque eget enim sapien. In diam, orci aliquet augue. Dictum egestas morbi dolor ut eu cursus. Porttitor feugiat non purus, mollis sed nisi nulla. Dictum egestas morbi dolor ut eu cursus. Porttitor feugiat non purus, mollis sed nisi nulla.",        date: "16/05/2023",
        time: "18h00",
        location: "online",
        price: "45chf"
    },
    {
        id: 2,
        picture: img2,
        title: "Lorem Ipsum 2",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi velit vulputate et, sagittis scelerisque eget enim sapien. In diam, orci aliquet augue. Dictum egestas morbi dolor ut eu cursus. Porttitor feugiat non purus, mollis sed nisi nulla. Dictum egestas morbi dolor ut eu cursus. Porttitor feugiat non purus, mollis sed nisi nulla.",
        date: "19/06/2023",
        time: "17h00",
        location: "online",
        price: "45chf"
    },
    {
        id: 3,
        picture: img3,
        title: "Lorem Ipsum 3",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi velit vulputate et, sagittis scelerisque eget enim sapien. In diam, orci aliquet augue. Dictum egestas morbi dolor ut eu cursus. Porttitor feugiat non purus, mollis sed nisi nulla. Dictum egestas morbi dolor ut eu cursus. Porttitor feugiat non purus, mollis sed nisi nulla.",
        date: "16/05/2023",
        time: "18h30",
        location: "online",
        price: "45chf"
    },
    {
        id: 4,
        picture: img4,
        title: "Lorem Ipsum 4",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi velit vulputate et, sagittis scelerisque eget enim sapien. In diam, orci aliquet augue. Dictum egestas morbi dolor ut eu cursus. Porttitor feugiat non purus, mollis sed nisi nulla. Dictum egestas morbi dolor ut eu cursus. Porttitor feugiat non purus, mollis sed nisi nulla.",        date: "15/07/2023",
        time: "19h00",
        location: "online",
        price: "45chf"
    },
    {
        id: 5,
        picture: img5,
        title: "Lorem Ipsum 5",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi velit vulputate et, sagittis scelerisque eget enim sapien. In diam, orci aliquet augue. Dictum egestas morbi dolor ut eu cursus. Porttitor feugiat non purus, mollis sed nisi nulla. Dictum egestas morbi dolor ut eu cursus. Porttitor feugiat non purus, mollis sed nisi nulla.",
        date: "24/01/2023",
        time: "20h00",
        location: "online",
        price: "45chf"
    },

];

const TitleBanner = styled(Typography)(({theme}) => ({
    textTransform: "uppercase",
    color: "#FFFFFF"
}));

const ImageContainer = styled(Box)(({theme}) => ({
    width: 145,
    height: 145,
    borderRadius: "30px",
    position: "relative",
    overflow: "hidden",

    [theme.breakpoints.up("sm")]: {
        width: 320,
        height: 320,
    },
    [theme.breakpoints.up("lg")]: {
        width: 430,
        height: 430,
    },
}));

const Title = styled(Typography)(({theme}) => ({
    textTransform: "uppercase",
    color: lightTheme.palette.grey.dark
}));

const SocialContainer = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "10px"

}));

const Icon = styled(Box)(({theme}) => ({
    width: 15,
    height: 15,
    position: "relative"
}));

const ContentBox = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 250px)",
    height: 145,
    justifyContent: "space-between",

    [theme.breakpoints.up("sm")]: {
        width: "calc(100% - 390px)",
        height: 320,
    },
    [theme.breakpoints.up("lg")]: {
        height: 430,
        width: "calc(100% - 500px)",
    },
}));


export default function WorkshopCard() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = data.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <Box >
            <Paper
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 100,
                    pl: 2,
                    backgroundColor: lightTheme.palette.primary.b2
                }}
            >
                <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                    disableRipple
                >
                    {theme.direction === 'rtl' ? (
                        <FontAwesomeIcon icon={faChevronRight} color={"#FFFFFF"} fontSize={"25px"}/>
                    ) : (
                        <FontAwesomeIcon icon={faChevronLeft} color={"#FFFFFF"} fontSize={"25px"} />
                    )}
                </Button>
                <TitleBanner variant={"h2"}>Nos ateliers à venir</TitleBanner>
                <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                    disableRipple
                >
                    {theme.direction === 'rtl' ? (
                        <FontAwesomeIcon icon={faChevronLeft} color={"#FFFFFF"} fontSize={"25px"} />
                    ) : (
                        <FontAwesomeIcon icon={faChevronRight} color={"#FFFFFF"} fontSize={"25px"} />
                    )}
                </Button>
            </Paper>
            <Box
                sx={{
                    height: 550,
                    backgroundColor: "#FFFFFF",
                    width: '100%',
                    padding: {xs: "20px", sm: "25px", lg: "60px 70px"},

                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        columnGap: "70px"
                    }}
                >
                    <ImageContainer>
                        <img src={data[activeStep].picture} alt={"Jobertech"}/>
                    </ImageContainer>
                    <ContentBox>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <Title variant={"h4"}>{data[activeStep].title}</Title>
                            <SocialContainer>
                                <a href={"https://fr.linkedin.com/"} target={"_blank"} rel={"noreferrer"}>
                                    <FontAwesomeIcon icon={faLinkedinIn} style={{fontSize: 18}}/>
                                </a>
                                <a href={"https://www.eventbrite.fr/"} target={"_blank"} rel={"noreferrer"}>
                                    <Icon>
                                        <img src={eventbriteIcon} alt={"Jobertech - Eventbrite"}/>
                                    </Icon>
                                </a>
                                <a href={"https://www.meetup.com/fr-FR/"} target={"_blank"} rel={"noreferrer"}>
                                    <Icon>
                                        <img src={meetupIcon} alt={"Jobertech - Meetup"}/>
                                    </Icon>
                                </a>
                            </SocialContainer>
                        </Box>

                        <Box>
                            <BadgeButton items={data}>16/05/2025</BadgeButton>
                        </Box>
                        <Box
                            sx={{

                            }}
                        >
                            <Typography>
                                {data[activeStep].description}
                            </Typography>
                        </Box>
                        <Box alignSelf={"flex-end"}>
                            <CustomButton dispayIcon={"none"} width={200}>En savoir plus</CustomButton>
                        </Box>

                    </ContentBox>
                </Box>
            </Box>
        </Box>
    )
}