import React from "react";
import { Button } from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSliders} from "@fortawesome/pro-regular-svg-icons";


const FilterButton = ({handleClick}) => {
  return (
    <Button
      variant={"outlined"}
      endIcon={<FontAwesomeIcon icon={faSliders} />}
      onClick={handleClick}
    >
      Filtre(s)
    </Button>
  )
}

export default FilterButton;