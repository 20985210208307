import React, { useState } from 'react'
import {
  Container,
  styled,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material'
import AuthCard from '../../components/cards/AuthCard'
import lightTheme from '../../styles/theme/lightTheme'
import { EmailInput } from '../../components/inputs'
import { CustomButton } from '../../components/buttons'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'

const FormBox = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '25px',
  width: '100%',
})

const ForgotPassword = () => {
  const { t } = useTranslation()
  const schema = yup.object({
    email: yup
      .string()
      .email(t('errorMessage.emailValid'))
      .required(t('errorMessage.required')),
  })
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })
  const navigate = useNavigate()

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const obj = {
        ...data,
      }

      const res = await Api.forgotPassword.demandePassword(obj)
      const handling = handlingErrors(res)

      if (handling.status === 401) {
        return
      }
      if (handling.ok) {
        toast.success(t('successMessage.requestSend'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })

        setLoading(false)
        setTimeout(() => {
          navigate('/')
        }, 3000)
        /*dispatch(userLogin({ email: data.email, password: data.password }));*/
      } else {
        if (handling.status === 500) {
          toast.error(t('successMessage.emailNotFound'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          })
        } else {
          toast.error(t('successMessage.somethingWrong'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          })
        }
        setLoading(false)
      }
    } catch (e) {
      toast.error(t('successMessage.emailNotFound'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
      setLoading(false)
    }
  }

  return (
    <>
      <Container
        maxWidth={'lg'}
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AuthCard title={t('login.forgotYourPassword')}>
          <FormBox>
            <Typography
              variant={'body2'}
              sx={{
                color: lightTheme.palette.text.primary,
              }}
            >
              {t('login.forgotPasswordText')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
                a: {
                  fontFamily: 'Century Gothic',
                  fontSize: { xs: 8, md: 10, lg: 12 },
                  lineHeight: '140%',
                  letterSpacing: '0.2px',
                  color: lightTheme.palette.primary.main,
                  alignSelf: 'end',
                },
              }}
            >
              <EmailInput
                placeholder={t('login.addressMail')}
                width={'100%'}
                register={{ ...register('email') }}
              />
              {errors.email && (
                <Typography className={'error-message'}>
                  {errors.email.message}
                </Typography>
              )}
              <Link to={'../se-connecter'}>{t('login.rememberPassword')}</Link>
            </Box>
            <Box>
              {loading ? (
                <CircularProgress />
              ) : (
                <CustomButton
                  displayIcon={'none'}
                  handleClick={handleSubmit(onSubmit)}
                >
                  {t('login.sendResetLink')}
                </CustomButton>
              )}
            </Box>
          </FormBox>
        </AuthCard>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default ForgotPassword
