import React from 'react'
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/pro-regular-svg-icons'
import moment from 'moment-timezone'

const HeaderTab = styled(TableHead)(({ theme }) => ({
  height: 60,
  backgroundColor: lightTheme.palette.primary.b6,
}))

const TitleTab = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,
  textTransform: 'uppercase',

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
}))

const BodyTab = styled(TableCell)(({ theme, type }) => ({
  borderBottom:
    type === 'entreprise'
      ? `1px solid ${lightTheme.palette.secondary.main}`
      : type === 'candidat'
      ? `1px solid ${lightTheme.palette.warning.dark}`
      : `1px solid ${lightTheme.palette.secondary.main}`,
}))

const BlockTabCircle = styled(TableCell)(({ theme }) => ({
  width: 25,
  height: 61,
  backgroundColor: lightTheme.palette.secondary.main,
  padding: 0,
  margin: 0,
  borderBottom: `1px solid ${lightTheme.palette.secondary.main}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '5px',

  '.firstCircle, .secondCircle, .thirdCircle, .fourthCircle, .fiveCircle': {
    width: 5,
    height: 5,
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
  },
}))

const BlockTabSquare = styled(TableCell)(({ theme }) => ({
  width: 25,
  height: 61,
  backgroundColor: lightTheme.palette.warning.dark,
  padding: 0,
  margin: 0,
  borderBottom: `1px solid ${lightTheme.palette.warning.dark}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '5px',

  '.firstSquare, .secondSquare, .thirdSquare, .fourthSquare, .fiveSquare': {
    width: 5,
    height: 5,
    backgroundColor: '#FFFFFF',
  },
}))

const BlockTab = styled(TableCell)(({ theme, type }) => ({
  width: 15,
  height: 60,
  padding: 0,
  backgroundColor:
    type === 'entreprise'
      ? lightTheme.palette.secondary.main
      : type === 'candidat'
      ? lightTheme.palette.warning.dark
      : lightTheme.palette.secondary.main,
  borderBottom:
    type === 'entreprise'
      ? `1px solid ${lightTheme.palette.secondary.main}`
      : type === 'candidat'
      ? `1px solid ${lightTheme.palette.warning.dark}`
      : `1px solid ${lightTheme.palette.secondary.main}`,
}))

const ButtonIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.primary.b2}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const EmptyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic',
  fontSize: 14,
  lineHeight: '140%',
  color: lightTheme.palette.grey.main,
  textAlign: 'center',
  fontStyle: 'italic',
  padding: '15px 0',
  background: 'rgba(0, 0, 0, 0.04)',
}))

const ListTableRdv = ({
  type,
  items,
  setId,
  loading,
  handleClick,
  showDeleteButton = true,
}) => {
  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none',
          borderRadius: 0,
        }}
      >
        <Table>
          <HeaderTab>
            <TableRow>
              <TableCell width={25} sx={{ padding: 0 }} />
              <TitleTab>Nom</TitleTab>
              <TitleTab>Prénom</TitleTab>
              <TitleTab sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                Mail
              </TitleTab>
              <TitleTab sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                Date
              </TitleTab>

              <TableCell />
              <TableCell width={15} sx={{ padding: 0 }} />
            </TableRow>
          </HeaderTab>
          <TableBody>
            {items?.length > 0 && (
              <>
                {items.map((item, index) => (
                  <TableRow key={index}>
                    {item.entreprise ? (
                      <BlockTabCircle>
                        <div className={'firstCircle'} />
                        <div className={'secondCircle'} />
                        <div className={'thirdCircle'} />
                        <div className={'fourthCircle'} />
                        <div className={'fiveCircle'} />
                      </BlockTabCircle>
                    ) : (
                      <BlockTabSquare>
                        <div className={'firstSquare'} />
                        <div className={'secondSquare'} />
                        <div className={'thirdSquare'} />
                        <div className={'fourthSquare'} />
                        <div className={'fiveSquare'} />
                      </BlockTabSquare>
                    )}
                    <BodyTab type={item.entreprise ? 'entreprise' : 'candidat'}>
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '1',
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {item.candidat?.profile?.nom}
                        {item.entreprise?.profile?.nom}
                      </Typography>
                    </BodyTab>
                    <BodyTab type={item.entreprise ? 'entreprise' : 'candidat'}>
                      {item.candidat?.profile.prenom}
                      {item.entreprise?.profile.prenom}
                    </BodyTab>
                    <BodyTab
                      type={item.entreprise ? 'entreprise' : 'candidat'}
                      sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                    >
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '1',
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {item.candidat?.profile?.email}
                        {item.entreprise?.profile?.email}
                      </Typography>
                    </BodyTab>
                    <BodyTab type={item.entreprise ? 'entreprise' : 'candidat'}>
                      {moment(item.dateDebut)
                        .tz('Europe/Paris')
                        .format('DD/MM/YYYY') +
                        ' ' +
                        moment(item.dateDebut)
                          .tz('Europe/Paris')
                          .format('HH:mm')}
                    </BodyTab>

                    <BodyTab
                      type={item.entreprise ? 'entreprise' : 'candidat'}
                      sx={{
                        padding: '10px',
                        width: showDeleteButton ? '130px' : '30px',
                      }}
                    >
                      <ButtonIcon
                        onClick={() => {
                          setId(
                            item.entreprise
                              ? item.entreprise.profile.id
                              : item.candidat.profile.id
                          )
                          handleClick(item)
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                          color={lightTheme.palette.primary.b2}
                          fontSize={16}
                        />
                      </ButtonIcon>
                    </BodyTab>
                    <BlockTab
                      type={item.entreprise ? 'entreprise' : 'candidat'}
                    />
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
        {!items?.length > 0 && <EmptyText>pas de rendez-vous ici...</EmptyText>}
      </TableContainer>

      {/*<AppPagination setProducts={(p) => setProducts(p)}/>*/}
    </>
  )
}

export default ListTableRdv
