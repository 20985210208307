import React, { useEffect, useState } from 'react'
import { AdminHeading } from '../../../../components/headings'
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  styled,
  Typography,
} from '@mui/material'
import '../../../../styles/components/_layouts.scss'
import '../../../../styles/components/_grids.scss'
import '../../../../styles/components/_box.scss'
import '../../../../styles/components/_inputs.scss'
import {
  ButtonInputUpload,
  TextInputClassic,
} from '../../../../components/inputs'
import TextareaInputClassic from '../../../../components/inputs/TextareaInputClassic'
import {
  BorderButtonPurple,
  CustomButton,
} from '../../../../components/buttons'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../../styles/theme/lightTheme'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { SOFTSKILLS, VALEURS, countries } from '../../../../data'
import { fileSize } from '../../../../config'

const InputContainer = styled(FormControl)(({ theme }) => ({
  rowGap: '5px',
}))

const schema = yup.object({
  prenom: yup
    .string('Ce champ doit être une chaîne de caractères.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'),
  nom: yup
    .string('Ce champ doit être une chaîne de caractères.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'),
  email: yup
    .string('Ce champ doit être une chaîne de caractères.')
    .email('Merci de rentrer un email valide.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'),
  telephone: yup
    .string('Ce champ doit être une chaîne de caractères.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'),
  /*.matches(phoneRegExp, "Merci de rentrer un numéro de téléphone valide.")*/
  /* adresse: yup
    .string('Ce champ doit être une chaîne de caractères.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'), */
  codePostal: yup
    .number()
    .typeError('Veuillez respecter le format.')
    .required('Ce champ est obligatoire. Veuillez le renseigner.'),
  nationalite: yup
    .string()
    .required('Ce champ est obligatoire. Veuillez le renseigner.'),
  dateNaissance: yup.date('Ce champ doit être une date valide.'),
  job: yup.string('Ce champ doit être une chaîne de caractères.'),
  cv: yup.mixed(),
})

const AddCandidate = () => {
  let navigate = useNavigate()
  const [loadingAddAdmin, setLoadingAddAdmin] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorsJob, setErrorsJob] = useState(false)
  /* const [errorsStatusMarital, setErrorsStatusMarital] = useState(false) */
  const [phoneValue, setPhoneValue] = useState()
  const [file, setFile] = useState('')
  const [fileError, setFileError] = useState(false)
  /* const [statusMarital, setStatusMarital] = useState('') */
  const [dateValue, setDateValue] = useState(dayjs(new Date()))
  const [postes, setPostes] = useState([])
  const [posteSelect, setPosteSelect] = useState('')
  const [open, setOpen] = React.useState(false)
  let typingTimer //timer identifier
  let doneTypingInterval = 800 //time in ms (5 seconds)
 /*  const [competences, setCompetences] = useState([])
  const [competencesAnnexes, setCompetencesAnnexes] = useState([]) */
  const [softskils, setSoftskils] = useState([])
  const [valeurs, setValeurs] = useState([])
  const [selectedCountry, setSelectedCountry] = useState({
    label: 'Switzerland',
  })
  const [cities, setCities] = useState([])
  const [states, setStates] = useState([])
  const [selectedCity, setSelectedCity] = useState('Genève')
  const [selectedState, setSelectedState] = useState()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  useEffect(() => {
    handleCountryChange(selectedCountry)
  }, ['selectedCountry'])

  const handleCountryChange = async (country) => {
    setSelectedCountry(country)

    try {
      const result = await Api.countriesCities.getCitiesByCountry(country.label)
      const res = handlingErrors(result)

      const result2 = await Api.countriesCities.getStatesByCountry(
        country.label
      )
      const res2 = handlingErrors(result2)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setCities(res.data.data)
        setSelectedCity(res.data.data[0])
      }

      if (res2.ok) {
        setStates(res2.data.data.states)
        setSelectedState(res2.data.data.states[0])
      }
    } catch (error) {
      console.debug(error)
    }
  }

  useEffect(() => {
    getPostes()
  }, [])

  const getPostes = async (searchQuery) => {
    let payload = {
      search: searchQuery,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoading(true)

    try {
      const result = await Api.poste.listWithFilter({
        status: true,
        ...payload,
      })

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setPostes(res.data)
        const sort = res.data.sort((a, b) => (a.date < b.date ? 1 : -1))
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoading(false)
  }

  /* const handleStatusMaritalSelect = (event) => {
    const inputValue = event.target.value
    setStatusMarital(inputValue)
  } */

  const addCandidate = async (data) => {
    //console.log(data);return
    setLoadingAddAdmin(true)
    try {
      const result = await Api.candidate.addPlusCv(data)
      const res = handlingErrors(result)
      if (res.ok) {
        toast.success('La création du candidat à été réalisé avec succès !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })

        setTimeout(() => {
          navigate('/admin/gestion-prospect/liste-prospects-candidats')
        }, 1000)
      }

      if (res.status === 422) {
        toast.error("L'email est déjà utilisé pour un autre compte !", {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (res.status === 401) {
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoadingAddAdmin(false)
  }

  const handleFileChange = (event) => {
    if (!event.target.files[0]) return
    if (fileSize(event.target.files[0].size) > 2) {
      setFile('')
      setValue('files', [])
      setFileError(true)
    } else {
      setFile(event.target.files[0])
      setValue('files', [event.target.files[0]])
      setFileError(false)
    }
  }

  const onSubmit = (data) => {
    setErrorsJob(false)
    //setErrorsStatusMarital(false)
    if (!posteSelect) setErrorsJob(true)
    //if (!statusMarital) setErrorsStatusMarital(true)
    if (!posteSelect) return

    const formData = new FormData()
    formData.append('prenom', data.prenom)
    formData.append('nom', data.nom)
    formData.append('password', data.email)
    formData.append('email', data.email)
    formData.append('telephone', data.telephone)
    formData.append('adresse', data.adresse)
    formData.append('ville', selectedCity)
    formData.append('etat', selectedState.name)
    formData.append('codePostal', data.codePostal)
    formData.append('pays', selectedCountry.label)
    formData.append('nationalite', data.nationalite)
    //formData.append('statusMarital', statusMarital)
    formData.append('commentaire', data.commentaire)
    formData.append('dateNaissance', dateValue.format('DD/MM/YYYY'))
    formData.append('cv', file ? file : null)
    formData.append('profile.mobilite', 50)
    formData.append('profile.poste', posteSelect.id)
    /* formData.append('profile.competences', JSON.stringify(competences))
    formData.append('profile.competencesAnnexes', JSON.stringify(competencesAnnexes)) */
    formData.append('profile.softskils', JSON.stringify(softskils))
    formData.append('profile.valeurs', JSON.stringify(valeurs))

    /*const payload = {
      ...data,
      roles: ["ROLE_CANDIDAT"],
      password: "password",
      status: "classic",
      cv: form,
      mobilite: 50,
    };*/
    addCandidate(formData)
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <AdminHeading
          handleClickIcon={() =>
            navigate('/admin/gestion-prospect/liste-prospects-candidats')
          }
        >
          Ajouter un candidat
        </AdminHeading>
        <Box
          className={'BoxContainer'}
          sx={{
            padding: '50px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          <form>
            <Grid container spacing={2} className={'gridContainer'}>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Prénom</FormLabel>
                  <TextInputClassic
                    placeholder={'Prénom'}
                    width={'100%'}
                    register={{ ...register('prenom') }}
                  />
                  {errors.prenom && (
                    <Typography className={'error-message'}>
                      {errors.prenom.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Nom</FormLabel>
                  <TextInputClassic
                    placeholder={'Nom'}
                    width={'100%'}
                    register={{ ...register('nom') }}
                  />
                  {errors.nom && (
                    <Typography className={'error-message'}>
                      {errors.nom.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Mail</FormLabel>
                  <TextInputClassic
                    placeholder={'Mail'}
                    width={'100%'}
                    type={'email'}
                    register={{ ...register('email') }}
                  />
                  {errors.email && (
                    <Typography className={'error-message'}>
                      {errors.email.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Numéro de téléphone
                  </FormLabel>
                  {/*<TextInputClassic
                    placeholder={"Numéro de téléphone"}
                    width={"100%"}
                    register={{...register("telephone")}}
                  />*/}

                  <Controller
                    name="phone-input"
                    control={control}
                    rules={{
                      validate: (value) => isValidPhoneNumber(value),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        international
                        value={phoneValue}
                        onChange={setPhoneValue}
                        defaultCountry="CH"
                        id="phone-input"
                        {...register('telephone')}
                        containerStyle={{
                          border: '10px solid black',
                          background: 'pink',
                        }}
                        inputStyle={{
                          background: 'lightblue',
                        }}
                      />
                    )}
                  />

                  {errors.telephone && (
                    <Typography className={'error-message'}>
                      {errors.telephone.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Année de naissance
                  </FormLabel>
                  <Box class="fc-right-custom">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={'fr'}
                    >
                      <DatePicker
                        openTo="year"
                        views={['year']}
                        onChange={setDateValue}
                        value={dateValue}
                        inputFormat={'YYYY'}
                        register={{ ...register('dateNaissance') }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%',
                              height: 45,
                              backgroundColor: lightTheme.palette.primary.b2,
                              borderRadius: '5px',

                              '& .css-1laqsz7-MuiInputAdornment-root, & .MuiInputAdornment-root': {
                                height: '100%',
                                width: '50px',
                                margin: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',

                                '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root, & .root-MuiIconButton-root':
                                  {
                                    margin: 0,
                                    color: '#FFFFFF',
                                  },
                              },
                            }}
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '5px 0 0 5px',
                                border: '1px solid #9E9E9E',
                                padding: '10px 15px',
                                fontFamily: 'Century Gothic',
                                fontSize: 14,
                                lineHeight: '140%',
                                letterSpacing: '0.2px',
                                color: lightTheme.palette.text.primary,
                                backgroundColor: '#FFFFFF',
                              }}
                              register={{ ...register('dateNaissance') }}
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                    {errors.dateNaissance && (
                      <Typography className={'error-message'}>
                        {errors.dateNaissance.message}
                      </Typography>
                    )}
                  </Box>
                </InputContainer>
              </Grid>
              {/* <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Statut marital</FormLabel>
                  <Autocomplete
                    selectOnFocus
                    disableClearable
                    onChange={(event, newValue) => {
                      setStatusMarital(newValue.id)
                    }}
                    id="status-marital"
                    options={statusMaritalList}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  {errorsStatusMarital && (
                    <Typography className={'error-message'}>
                      {
                        'Ce champ est obligatoire. Veuillez sélectionner un status marital.'
                      }
                    </Typography>
                  )}
                </InputContainer>
              </Grid> */}

              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Poste</FormLabel>
                  <Autocomplete
                    id="asynchronous-postes"
                    sx={{ width: '100%', height: '45px' }}
                    open={open}
                    onChange={(event, newValue) => {
                      setPosteSelect(newValue)
                    }}
                    onKeyDown={(event, newInputValue) => {
                      clearTimeout(typingTimer)
                      if (event.target.value) {
                        typingTimer = setTimeout(function () {
                          getPostes(event.target.value)
                        }, doneTypingInterval)
                      }
                    }}
                    onOpen={() => {
                      setOpen(true)
                    }}
                    onClose={() => {
                      setOpen(false)
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.titre === value.titre
                    }
                    getOptionLabel={(option) => option.titre}
                    options={postes}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        id="text-asynchronous-postes"
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  {errorsJob && (
                    <Typography className={'error-message'}>
                      {
                        'Ce champ est obligatoire. Veuillez sélectionner un poste.'
                      }
                    </Typography>
                  )}
                </InputContainer>
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Adresse</FormLabel>
                  <TextInputClassic
                    placeholder={'Adresse'}
                    width={'100%'}
                    register={{ ...register('adresse') }}
                  />
                  {errors.adresse && (
                    <Typography className={'error-message'}>
                      {errors.adresse.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Pays</FormLabel>
                  <Autocomplete
                    disableClearable
                    sx={{
                      width: '100%',
                      backgroundColor: '#fff',
                    }}
                    value={selectedCountry ? selectedCountry : {}}
                    onChange={(event, newValue) => {
                      handleCountryChange(newValue)
                    }}
                    id="country-select"
                    options={countries}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </InputContainer>
              </Grid>

              <Grid item xs={12} sm={6} lg={3}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Ville</FormLabel>
                  <Autocomplete
                    sx={{
                      width: '100%',
                      backgroundColor: '#fff',
                    }}
                    freeSolo={true}
                    value={selectedCity ? selectedCity : null}
                    onChange={(event, newValue) => {
                      setSelectedCity(newValue)
                    }}
                    id="country-select"
                    options={cities}
                    autoHighlight
                    //getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        onChange={(event) => {
                          setSelectedCity(event.target.value)
                        }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>État/Canton</FormLabel>
                  <Autocomplete
                    sx={{
                      width: '100%',
                      backgroundColor: '#fff',
                    }}
                    value={selectedState ? selectedState : null}
                    onChange={(event, newValue) => {
                      setSelectedState(newValue)
                    }}
                    freeSolo={true}
                    id="state-select"
                    options={states}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        onChange={(event) => {
                          setSelectedState({ name: event.target.value })
                        }}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Code postal</FormLabel>
                  <TextInputClassic
                    placeholder={'Code postal'}
                    width={'100%'}
                    register={{ ...register('codePostal') }}
                  />
                  {errors.codePostal && (
                    <Typography className={'error-message'}>
                      {errors.codePostal.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Nationalité</FormLabel>
                  <TextInputClassic
                    placeholder={'Nationalité'}
                    width={'100%'}
                    register={{ ...register('nationalite') }}
                  />
                  {errors.nationalite && (
                    <Typography className={'error-message'}>
                      {errors.nationalite.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              {/* <Grid item xs={12} sm={12} lg={12}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Compétence(s) technique(s)
                  </FormLabel>
                  <Autocomplete
                    sx={{ width: '100%' }}
                    multiple
                    options={competences.map((option) => option)}
                    onChange={(event, newValue) => {
                      if (newValue.length > 6) newValue.slice(0, 6)
                      setCompetences(newValue.slice(0, 6))
                    }}
                    freeSolo
                    renderTags={(competences, getTagProps) =>
                      competences.slice(0, 6).map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Ajouter les compétences techniques"
                      />
                    )}
                  />
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Compétence(s) technique(s) annexe(s)
                  </FormLabel>
                  <Autocomplete
                    sx={{ width: '100%' }}
                    multiple
                    options={competencesAnnexes.map((option) => option)}
                    onChange={(event, newValue) => {
                      setCompetencesAnnexes(newValue)
                    }}
                    freeSolo
                    renderTags={(competencesAnnexes, getTagProps) =>
                      competencesAnnexes.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Ajouter les compétences techniques annexes"
                      />
                    )}
                  />
                </InputContainer>
              </Grid> */}
              <Grid item xs={12} sm={12} lg={12}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Softskils</FormLabel>
                  <Autocomplete
                    sx={{ width: '100%' }}
                    multiple
                    options={[...SOFTSKILLS['fr'], ...SOFTSKILLS['en']].sort()}
                    onChange={(event, newValue) => {
                      setSoftskils(newValue)
                    }}
                    renderTags={(softskils, getTagProps) =>
                      softskils.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Ajouter les softskils"
                      />
                    )}
                  />
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Valeurs</FormLabel>
                  <Autocomplete
                    sx={{ width: '100%' }}
                    multiple
                    options={[...VALEURS['fr'], ...VALEURS['en']]}
                    onChange={(event, newValue) => {
                      setValeurs(newValue)
                    }}
                    renderTags={(valeurs, getTagProps) =>
                      valeurs.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Ajouter les valeurs"
                      />
                    )}
                  />
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Commentaires Jobertech
                  </FormLabel>
                  <TextareaInputClassic
                    placeholder={'Commentaires Jobertech'}
                    register={{ ...register('commentaire') }}
                    type={'border'}
                  />
                  {errors.commentaire && (
                    <Typography className={'error-message'}>
                      {errors.commentaire.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <ButtonInputUpload onChange={handleFileChange} />

                  {fileError ? (
                    <p>
                      <Typography className={'error-message'}>
                        Le fichier ne doit pas dépasser 2 mega
                      </Typography>
                    </p>
                  ) : (
                    <p>{file.name}</p>
                  )}
                </InputContainer>
              </Grid>
            </Grid>
          </form>

          <Box
            className={'wrapperButtons'}
            sx={{
              justifyContent: 'flex-end',
            }}
          >
            <Box className={'blockButtons'}>
              {loadingAddAdmin ? (
                <CircularProgress />
              ) : (
                <>
                  <BorderButtonPurple
                    width={{ xs: '50%', sm: 160, lg: 200 }}
                    handleClick={() =>
                      navigate(
                        '/admin/gestion-prospect/liste-prospects-candidats'
                      )
                    }
                  >
                    Annuler
                  </BorderButtonPurple>
                  <CustomButton
                    displayIcon={'none'}
                    width={{ xs: '50%', sm: 160, lg: 200 }}
                    handleClick={handleSubmit(onSubmit)}
                  >
                    Ajouter
                  </CustomButton>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default AddCandidate
