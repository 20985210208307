import { Box, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { StepsEntreprise } from "./Steps";
import { useParams } from "react-router-dom";

const ContentBox = styled(Box)(({theme}) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  rowGap: "50px",

  [theme.breakpoints.up("lg")]: {
    width: "50%"
  },
}));


const InscriptionEntreprise = () => {
  /* const [id, setId] = useState(null); */
  const [token, setToken] = useState("");

  /*useEffect(() => {
    setToken(route.params?.token);
  }, [route.params?.token]);*/

  /*useEffect(() => {
    fetchId();
  }, []);*/

  const params = useParams();

  useEffect(() => {
    setToken(params.token)
  }, []);



  /*const fetchId = async () => {
    try {
      const result = await Api.user.detail(id);
      console.log(result);

      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur")
      }

      if(res.ok) {
        console.log("ca marche id")
      }

    } catch (error) {
      console.log(error)
    }
  }*/

  /*const postInscription = async () => {
    try {
      const result = await Api.user.edit(id, {

      });

      console.log(result);

      const res = handlingErrors(result);

      if(res.status === 401) {
        console.log("erreur post")
      }

      if(res.ok) {
        console.log("ca marche post");
      }
    } catch (error) {
      console.log(error)
    }
  }*/


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {xs: "column", lg: "row"},
        justifyContent: {xs: "center", lg: "space-between"},
        marginTop: { xs: "10px", lg: "50px" },
        rowGap: { xs: '10px', lg: '80px' },
      }}
    >
      <ContentBox>
        <Typography variant={"h1"} textAlign={{xs: "center", lg: "left"}}>
          Finalisons votre inscription sur la plateforme Jobertech
        </Typography>
{/*         <ContentBody>
          <Typography variant={"h2"}>
            Loremp ipsum
          </Typography>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo enim orci elit tristique. Ultricies mauris pretium metus, risus tortor. A enim nullam volutpat, praesent ligula mattis posuere adipiscing ante.
          </Typography>
          <Typography>
            Quam vulputate fusce egestas vestibulum dictum velit. Dolor nullam justo felis egestas sit. Et amet, a vitae sed iaculis posuere lacus. Bibendum pellentesque mi adipiscing dui amet dui, vel, amet.
          </Typography>
        </ContentBody> */}
      </ContentBox>
      <Box width={{ xs: "100%", lg: "40%" }}>
        {token && (
          /*<Steps token={token} type={"entreprise"} />*/
          <StepsEntreprise token={token} />
        )}
      </Box>
    </Box>
  )
}

export default InscriptionEntreprise;