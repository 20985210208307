import React from "react";
import {Box, styled} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";

const CustomBox = styled(Box)(({theme}) => ({
    width: "100%",
    height: "100%",
    background: lightTheme.palette.gradients.gradientGreen,
    borderRadius: "0px 0px 50px 50px",
    padding: "50px 0 50px 0",

    [theme.breakpoints.up("sm")]: {
        padding: "60px 0 60px 0"
    },

    [theme.breakpoints.up("lg")]: {
        borderRadius: "0px 0px 125px 125px",
        padding: "100px 0 100px 0"
    },
}))

export default function SectionLayoutBottom({children}) {
    return (
        <CustomBox component={"section"}>
            {children}
        </CustomBox>
    )
}