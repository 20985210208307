import { MenuItem, Select, styled } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import React from 'react'

const CustomSelect = styled(Select)(({ theme, type, width }) => ({
  width: width ? width : '100%',
  height: 45,
  borderWidth: type === 'border' ? '1px' : 0,
  borderRadius: '5px',
  backgroundColor: '#FFFFFF',
  color: lightTheme.palette.text.primary,
  fontFamily: 'Century Gothic',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  cursor: 'pointer',
  position: 'relative',
  borderStyle: 'solid',
  borderColor: type === 'border' ? lightTheme.palette.grey.main : 'transparent',

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '& .MuiSelect-select': {
    '&.MuiSelect-outlined': {
      '&.MuiInputBase-input': {
        '&.MuiOutlinedInput-input': {
          paddingRight: '10px',
        },
      },
    },
  },
}))
const SelectInput = ({
  items,
  value,
  handleChange,
  name,
  type,
  register,
  width,
  defaultValue,
  ...props
}) => {
  return (
    <CustomSelect
      type={type}
      value={value}
      onChange={handleChange}
      width={width}
      IconComponent={() => (
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{
            position: 'absolute',
            right: '10px',
            color: lightTheme.palette.grey.main,
          }}
        />
      )}
      name={name}
      {...register}
      defaulValue={defaultValue}
    >
      {items && items.map((item, index) => (
        <MenuItem selected={item.text === defaultValue || item.value === defaultValue} key={item.id} value={item.value}>
              {item.text}
            </MenuItem>
      ))}
    </CustomSelect>
  )
}

export default SelectInput
