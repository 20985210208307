import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  styled,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { Markup } from 'interweave'
import lightTheme from '../../../../styles/theme/lightTheme'
import intervenantsIcon from '../../../../assets/icons/intervenants.svg'
import logoFooter from '../../../../assets/images/logos/logo-article.svg'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import 'moment/locale/fr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faLinkedin,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons'
import { faLink } from '@fortawesome/pro-solid-svg-icons'

import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share'
import copy from 'clipboard-copy'

const GridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
}))
const ColorBoxFooter = styled(Box)(({ theme, type }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  width: '71%',
  height: '71%',
  top: '20%',
  left: '10%',
  zIndex: '-1',
  borderRadius: '50%',
}))
const ImageBoxFooter = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: 100,
  height: 100,
  top: '-133px',
  left: '50%',
  transform: 'translate(-50px , 70px)',
  zIndex: 1,

  [theme.breakpoints.up('sm')]: {
    height: 100,
    width: 100,
    transform: 'translate(-50px , 70px)',
  },
  [theme.breakpoints.up('lg')]: {
    height: 150,
    width: 150,
    transform: 'translate(-75px , 75px)',
  },
}))

export default function AtelierDetail() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [atelier, setAtelier] = useState([])
  const [loading, setLoading] = useState(false)
  moment.locale(i18n.language)
  const params = useParams()

  useEffect(() => {
    if (params.atelierId) {
      getAtelier(params.atelierId)
    }
  }, [params.atelierId])

  const getAtelier = async (atelierId) => {
    setLoading(true)

    try {
      const result = await Api.siteVitrine.getAtelier(atelierId)

      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }

      if (res.ok) {
        setAtelier(res.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCopyClick = async () => {
    try {
      await copy(window.location.href)
      alert(t('successMessage.copy'))
    } catch (err) {
      console.log('Failed to copy text: ', err)
    }
  }

  if (loading)
    return (
      <main style={{ marginTop: '80px' }}>
        <CircularProgress />
      </main>
    )

  return (
    <main>
      <Container
        maxWidth={'100%'}
        sx={{
          backgroundColor: '#96A0FF',
          position: 'relative',
          display: 'flex',
          marginBottom: '50px',
          minHeight: '80px',
          borderRadius: { lg: '0px 0px 30px 30px' },
          boxShadow: '0px 4px 10px 0px #0000001A',
          margin: '0',
          zIndex: 1,
        }}
      >
        <Container
          maxWidth={'lg'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Grid container>
            <GridItem item xs={12}>
              <Typography
                component={'h3'}
                sx={{
                  margin: { xs: '15px', sm: 0 },
                  textAlign: 'center',
                  verticalAlign: 'center',
                  fontSize: { xs: 12, sm: 16, lg: 24 },
                  fontFamily: 'Century Gothic Bold',
                  color: '#FFF',
                  lineHeight: { xs: '20px', sm: '80px', lg: '80px' },
                }}
              >
                {/* {atelier.id} --  */}
                {atelier.titre}
              </Typography>
            </GridItem>
          </Grid>
        </Container>
      </Container>
      {/* top atelier */}
      <Container
        maxWidth={'lg'}
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          /* width: { xs: '100vw' }, */
          backgroundColor: '#E3E6FF',
          padding: '0 !important',
        }}
      >
        <Grid container>
          <GridItem item xs={12} sm={2.5} lg={2}></GridItem>
          <GridItem item xs={12} sm={7} lg={8}>
            <img
              src={atelier.couverture}
              alt={atelier.titre}
              className={'image'}
            />
          </GridItem>
        </Grid>
        <GridItem item xs={12} sm={2.5} lg={2}></GridItem>
      </Container>

      {/* detail atelier */}
      <Container
        maxWidth={'lg'}
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '50px',
          /* width: { xs: '100vw' }, */
          backgroundColor: '#A4ADFF',
          minHeight: '80px',
          borderRadius: '0px 0px 30px 30px',
          padding: '20px 40px',
        }}
      >
        <Grid container alignItems="center" justifyContent="center">
          <GridItem item alignItems={'center'} order={1}>
            <Typography
              sx={{
                width: 'fit-content',
                borderRadius: '100px',
                background: '#fff',
                zIndex: '1',
                padding: '10px 20px',
                fontSize: {
                  xs: '10px !important',
                  sm: '12px !important',
                  lg: '14px !important',
                },
                fontFamily: 'Century Gothic',
                color: '#000',
                float: 'left',
                margin: '10px',
                textAlign: 'right',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              <Typography
                sx={{
                  width: 'fit-content',
                  zIndex: '1',
                  fontWeight: '400',
                  fontFamily: 'Century Gothic Bold',
                  color: '#A4ADFF',
                  float: 'left',
                  marginRight: '10px',
                  textTransform: 'uppercase',
                }}
              >
                {t('profile.date')}
              </Typography>
              {moment(atelier.date).tz('Europe/Paris').format('DD/MM/YYYY')}
            </Typography>
          </GridItem>
          <GridItem item alignItems={'center'} order={2}>
            <Typography
              sx={{
                width: 'fit-content',
                borderRadius: '100px',
                background: '#fff',
                zIndex: '1',
                padding: '10px 20px',
                fontSize: {
                  xs: '10px !important',
                  sm: '12px !important',
                  lg: '14px !important',
                },
                fontFamily: 'Century Gothic',
                color: '#000',
                float: 'left',

                margin: '10px',
                textAlign: 'right',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              <Typography
                sx={{
                  width: 'fit-content',
                  zIndex: '1',
                  fontWeight: '400',
                  fontFamily: 'Century Gothic Bold',
                  color: '#A4ADFF',
                  float: 'left',
                  marginRight: '10px',
                  textTransform: 'uppercase',
                }}
              >
                {t('profile.hour')}
              </Typography>
              {moment(atelier.date).tz('Europe/Paris').format('HH:mm')}
            </Typography>
          </GridItem>
          <GridItem item alignItems={'center'} order={7}>
            <Typography
              sx={{
                width: 'fit-content',
                borderRadius: '100px',
                background: '#fff',
                zIndex: '1',
                padding: '10px 20px',
                fontSize: {
                  xs: '10px !important',
                  sm: '12px !important',
                  lg: '14px !important',
                },
                fontFamily: 'Century Gothic',
                color: '#000',
                float: 'left',

                margin: '10px',
                textAlign: 'right',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              <Typography
                sx={{
                  width: 'fit-content',
                  zIndex: '1',
                  fontWeight: '400',
                  fontFamily: 'Century Gothic Bold',
                  color: '#A4ADFF',
                  float: 'left',
                  marginRight: '10px',
                  textTransform: 'uppercase',
                }}
              >
                {t('profile.price')}
              </Typography>
              {(
                atelier.prix *
                JSON.parse(sessionStorage.getItem('currency')).currentRate
              ).toFixed(2)}{' '}
              {JSON.parse(sessionStorage.getItem('currency')).devise}
            </Typography>
          </GridItem>
          <GridItem item alignItems={'center'} order={5}>
            <Typography
              sx={{
                width: 'fit-content',
                borderRadius: '100px',
                background: '#fff',
                zIndex: '1',
                padding: '10px 20px',
                fontSize: {
                  xs: '10px !important',
                  sm: '12px !important',
                  lg: '14px !important',
                },
                fontFamily: 'Century Gothic',
                color: '#000',
                float: 'left',

                margin: '10px',
                textAlign: 'right',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              <Typography
                sx={{
                  width: 'fit-content',
                  zIndex: '1',
                  fontWeight: '400',
                  fontFamily: 'Century Gothic Bold',
                  color: '#A4ADFF',
                  float: 'left',
                  marginRight: '10px',
                  textTransform: 'uppercase',
                }}
              >
                {t('profile.place')}
              </Typography>
              {atelier.lieu}
            </Typography>
          </GridItem>
          <GridItem item alignItems={'center'} order={6}>
            <Typography
              sx={{
                width: 'fit-content',
                borderRadius: '100px',
                background: '#fff',
                zIndex: '1',
                padding: '10px 20px',
                fontSize: {
                  xs: '10px !important',
                  sm: '12px !important',
                  lg: '14px !important',
                },
                fontFamily: 'Century Gothic',
                color: '#000',
                float: 'left',

                margin: '10px',
                textAlign: 'right',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              <Typography
                sx={{
                  width: 'fit-content',
                  zIndex: '1',
                  fontWeight: '400',
                  fontFamily: 'Century Gothic Bold',
                  color: '#A4ADFF',
                  float: 'left',
                  marginRight: '10px',
                  textTransform: 'uppercase',
                }}
              >
                {t('profile.language')}
              </Typography>
              {atelier.langues && Object.values(atelier.langues).join(' / ')}
            </Typography>
          </GridItem>
        </Grid>
      </Container>

      {/* details */}
      <Box
        width={'100%'}
        component={'section'}
        sx={{
          padding: '0 0 130px 0',
        }}
      >
        <Box width={'100%'} position={'relative'}>
          <Container
            maxWidth={'lg'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid container>
              <GridItem item xs={12} sm={12} lg={12}>
                <Typography
                  component={'h1'}
                  sx={{
                    textAlign: 'center',
                    fontSize: { sm: 16, lg: 24 },
                    fontFamily: 'Century Gothic Bold',
                    color: lightTheme.palette.text.primary,
                    lineHeight: '140%',
                  }}
                >
                  {/* {atelier.id} --  */}
                  {atelier.sousTitre}
                </Typography>

                <Typography
                  sx={{
                    textAlign: 'justify',
                    fontWeight: 'bold',
                    fontSize: { sm: 12, lg: 14 },
                    marginTop: 3,
                  }}
                >
                  <Markup content={atelier.apropos} />
                </Typography>

                {/* Block Partisipants */}
                {atelier?.intervenants?.length > 0 && (
                  <Container
                    maxWidth={'lg'}
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '50px',
                      marginTop: '50px',
                      /* width: { xs: '100vw' }, */
                      backgroundColor: '#E3E6FF',
                      minHeight: '80px',
                      borderRadius: '85px',
                      padding: {
                        xs: '0 50px',
                        sm: '0 50px',
                        md: '0 !important',
                        lg: '0 !important',
                      },
                    }}
                  >
                    <Grid container>
                      <GridItem
                        item
                        xs={6}
                        sm={0}
                        md={2}
                        lg={3}
                        alignItems={'center'}
                        order={1}
                        sx={{
                          width: '170px !important',
                          height: '170px !important',
                          maxWidth: '170px !important',
                          backgroundColor: '#A4ADFF',
                          borderRadius: '85px',
                          padding: '40px 0',
                          marginRight: '50px',
                          display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'flex',
                            lg: 'flex',
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              xs: '10px !important',
                              sm: '12px !important',
                              lg: '14px !important',
                            },
                            fontFamily: 'Century Gothic',
                            color: '#fff',
                            marginBottom: {
                              xs: '20px',
                              sm: '20px',
                              md: '20px',
                              lg: 0,
                            },
                            textAlign: 'right',
                          }}
                        >
                          <Typography
                            sx={{
                              width: 'fit-content',
                              zIndex: '1',
                              fontWeight: '400',
                              fontFamily: 'Century Gothic Bold',
                              color: '#fff',
                              textAlign: 'center',
                              textTransform: 'uppercase',
                            }}
                          >
                            {t('list.presenceOf')} :<br></br>
                            <br></br>
                            <img
                              alt="intervenantsIcon"
                              src={intervenantsIcon}
                              width={'45px'}
                            />
                          </Typography>
                        </Typography>
                      </GridItem>
                      {atelier?.intervenants?.map((intervenant, index) => (
                        <GridItem
                          item
                          xs={12}
                          sm={12 / atelier?.intervenants?.length}
                          md={9 / atelier?.intervenants?.length}
                          lg={9 / atelier?.intervenants?.length}
                          alignItems={'center'}
                          order={2}
                          sx={{
                            padding: {
                              xs: '5px 0',
                              sm: '25px 0',
                              md: '25px 0',
                              lg: '25px 0',
                            },
                          }}
                        >
                          <Grid
                            container
                            wrap="nowrap"
                            spacing={2}
                            sx={{ marginTop: 0 }}
                          >
                            <Grid item>
                              <img
                                alt={intervenant.nom + ' ' + intervenant.prenom}
                                src={intervenant.photo}
                                width={'75px'}
                                sx={{
                                  border: '2px solid #FFFFFF',
                                  borderRadius: '50%',
                                }}
                              />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                              <Typography
                                noWrap
                                sx={{
                                  zIndex: '1',
                                  padding: '10px 0',
                                  fontSize: {
                                    xs: '12px !important',
                                    sm: '12px !important',
                                    lg: '14px !important',
                                  },
                                  fontFamily: 'Century Gothic bold',
                                  textTransform: 'uppercase',
                                  color: '#000',
                                  float: 'left',
                                  width: '100%',
                                }}
                              >
                                {intervenant.nom} {intervenant.prenom}
                              </Typography>
                              <Typography
                                noWrap
                                sx={{
                                  zIndex: '1',
                                  fontSize: {
                                    xs: '10px !important',
                                    sm: '10px !important',
                                    lg: '12px !important',
                                  },
                                  fontFamily: 'Century Gothic',
                                  color: '#000',
                                  float: 'left',
                                  width: '100%',
                                }}
                              >
                                {intervenant.fonction}
                              </Typography>
                            </Grid>
                          </Grid>
                          {/* <Typography
                          sx={{
                            width: 'fit-content',
                            zIndex: '1',
                            padding: '10px 20px',
                            fontSize: {
                              xs: '10px !important',
                              sm: '12px !important',
                              lg: '14px !important',
                            },
                            fontFamily: 'Century Gothic',
                            color: '#000',
                            float: 'left',
                            
                            marginBottom: {
                              xs: '20px',
                              sm: '20px',
                              md: '20px',
                              lg: 0,
                            },
                          }}
                        >
                          <img
                            src={intervenant.photo}
                            width={'75px'}
                            sx={{
                              border: '2px solid #FFFFFF',
                              borderRadius: '50%',
                            }}
                          />
                          {intervenant.nom} {intervenant.prenom}
                        </Typography> */}
                        </GridItem>
                      ))}
                    </Grid>
                  </Container>
                )}

                <Typography>
                  <Markup content={atelier.description} />
                </Typography>
              </GridItem>
            </Grid>
          </Container>

          <Container
            maxWidth={'lg'}
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '50px',
              marginBottom: '50px',
              /* width: { xs: '100vw' }, */
              backgroundColor: '#A4ADFF',
              minHeight: '80px',
              borderRadius: '100px',
              padding: '0 40px !important',
            }}
          >
            <Grid container>
              <GridItem item xs={12} sm={12} md={8} lg={8}>
                <Typography
                  component={'h3'}
                  sx={{
                    margin: { xs: '15px', sm: 0 },
                    textAlign: {
                      xs: 'center',
                      sm: 'center',
                      md: 'left',
                      lg: 'left',
                    },
                    fontSize: { xs: 12, sm: 12, lg: 14 },
                    fontFamily: 'Century Gothic Bold',
                    color: '#FFF',
                    lineHeight: { xs: '20px', sm: '80px', lg: '80px' },
                    textTransform: 'uppercase',
                  }}
                >
                  {t('footer.workshopFooter1')}
                </Typography>
              </GridItem>
              <GridItem item xs={12} sm={12} md={4} lg={4}>
                <Typography
                  component={'p'}
                  sx={{
                    textAlign: {
                      xs: 'center',
                      sm: 'center',
                      md: 'right',
                      lg: 'right',
                    },
                    fontSize: { xs: 10, sm: 12, lg: 14 },
                    fontFamily: 'Century Gothic Bold',
                    color: '#FFF',
                    lineHeight: '80px',
                  }}
                >
                  <Button
                    size="small"
                    onClick={() => {
                      navigate('/atelier/inscription/' + atelier.id)
                    }}
                    sx={{
                      width: 'fitContent',
                      fontWeight: 'bold',
                      color: '#FFF',
                      border: '1px solid #FFF',
                      padding: '10px 60px',
                      fontSize: { xs: 10, sm: 12, lg: 14 },
                      fontFamily: 'Century Gothic Bold',
                      backgroundColor: '#96A0FF',
                    }}
                  >
                    {t('button.atelierRegister')}
                  </Button>
                </Typography>
              </GridItem>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Box
        width={'100%'}
        minHeight={'150px'}
        padding={'30px 0'}
        backgroundColor={'#EEEEEE'}
        position={'relative'}
      >
        <ImageBoxFooter>
          <img alt='Jobertech' src={logoFooter} width={'100%'} />

          <ColorBoxFooter type={atelier.type} />
        </ImageBoxFooter>
        <Box width={'100%'} component={'section'} position={'relative'}>
          <Container
            maxWidth={'lg'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid container>
              <GridItem
                item
                xs={12}
                sm={6}
                lg={6}
                textTransform={'uppercase'}
                sx={{
                  fontFamily: 'Century Gothic Bold',
                  margin: '20px 0',
                  textAlign: { xs: 'center', lg: 'left' },
                }}
              >
                {t('footer.workshopFooter2')} <br></br>
                {t('footer.workshopFooter3')}
              </GridItem>
              <GridItem
                item
                xs={12}
                sm={6}
                lg={6}
                textAlign={'center'}
                sx={{
                  margin: '20px 0',
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent={{ xs: 'center', lg: 'flex-end' }}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={2}>
                    <FontAwesomeIcon
                      onClick={handleCopyClick}
                      icon={faLink}
                      style={{
                        fontSize: 30,
                        color: '#808BF8',
                        cursor: 'pointer',
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <LinkedinShareButton
                      url={window.location.href}
                      title={atelier.titre}
                      summary={atelier.descriptif}
                    >
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        style={{
                          fontSize: 40,
                          color: '#2A66BC',
                          cursor: 'pointer',
                        }}
                      />
                    </LinkedinShareButton>
                  </Grid>
                  <Grid item xs={2}>
                    <FacebookShareButton
                      url={window.location.href}
                      quote={atelier.titre}
                    >
                      <FontAwesomeIcon
                        icon={faFacebook}
                        style={{
                          fontSize: 40,
                          color: '#3b5998',
                          cursor: 'pointer',
                        }}
                      />
                    </FacebookShareButton>
                  </Grid>
                  <Grid item xs={2}>
                    <WhatsappShareButton
                      url={window.location.href}
                      title={atelier.titre}
                    >
                      <FontAwesomeIcon
                        icon={faWhatsapp}
                        style={{
                          fontSize: 40,
                          color: '#25D366',
                          cursor: 'pointer',
                        }}
                      />
                    </WhatsappShareButton>
                  </Grid>
                </Grid>
                {/*  <Typography>
                  <FontAwesomeIcon icon={faLinkedin} style={{ fontSize: 40 }} />
                </Typography>
                <Typography>
                  <FontAwesomeIcon icon={faFacebook} style={{ fontSize: 40 }} />
                </Typography>
                <Typography>
                  <FontAwesomeIcon
                    icon={faInstagram}
                    style={{ fontSize: 40 }}
                  />
                </Typography> */}
              </GridItem>
            </Grid>
          </Container>
        </Box>
      </Box>
    </main>
  )
}
