import { CircularProgress, styled, Typography } from '@mui/material'
import UserMasonryLayout from '../../../components/layouts/UserMasonryLayout'
import { useSelector } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import lightTheme from '../../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
  faHome,
  faCircleCheck,
  faMapMarker,
  faEnvelope,
  faPhone,
  faPen,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import Api from '../../../services/api.service'
import { handlingErrors } from '../../../utils'
import { GedTable, OffreTable, BiBlockTable } from '../../../components/tables'
import moment from 'moment-timezone'
import {
  ChoiceOfferModal,
  EditDocuments,
  EditEvent,
  EditInfos,
  EditPasswordModal,
  PaymentModal,
  EditBesoinEntreprise,
} from '../../../components/modals'
import EditBesoin from '../../../components/modals/EditBesoin'
import { Slide, toast, ToastContainer } from 'react-toastify'
import EditList from '../../../components/modals/EditList'
import DeleteGeneralModal from '../../../components/modals/DeleteGeneralModal'
import {
  disponibilitesList2,
  disponibleSousList2,
  ROLE_CANDIDAT,
  ROLE_ENTREPRISE,
  SOFTSKILLS,
  VALEURS,
} from '../../../data'
import EditResume from '../../../components/modals/EditResume'
import UserMasonryLayoutCompany from '../../../components/layouts/UserMasonryLayoutCompany'
import BesoinTable from '../../../components/tables/BesoinTable'
import DetailBesoinEntreprise from '../../../components/modals/DetailBesoinEntreprise'
import GedTabs from '../../../components/tables/GedTabs'
import { CustomButton } from '../../../components/buttons'
import AddEvent from '../../../components/modals/AddEvent'
import { useTranslation } from 'react-i18next'
import AtelierTable from '../../../components/tables/AtelierTable'
import OffreEntrepriseTable from '../../../components/tables/OffreEntrepriseTable'
import { faCalendar } from '@fortawesome/pro-solid-svg-icons'
import EditDisponibilite from '../../../components/modals/EditDisponibilite'

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} disableShrink />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="lightTheme.palette.text.primary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

const ProfilContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '45px',
  justifyContent: 'space-between',
}))

const BannerProfil = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 40,
  backgroundColor: lightTheme.palette.primary.b6,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
}))

const WrapperBanner = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))

const BannerProfilContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '10px',
}))

const TitleSection = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,
  textTransform: 'uppercase',
}))

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const WrapperProfilText = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  rowGap: '5px',
}))

const IconContainer = styled(Box)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    borderWidth: '2px',
  },

  '&:active': {
    borderWidth: '1px',
  },
}))

const UserDashboard = () => {
  const { t, i18n } = useTranslation()
  let navigate = useNavigate()
  const userData = useSelector((state) => state.userAuth.user)
  if (!userData) {
    navigate('/')
  }
  const [open, setOpen] = useState(false)
  const [openCalendar, setOpenCalendar] = useState(false)
  const [openDoc, setOpenDoc] = useState(false)
  const [openBesoin, setOpenBesoin] = useState(false)
  const [openDetailBesoin, setOpenDetailBesoin] = useState(false)
  const [openResume, setOpenResume] = useState(false)
  const [openValeurs, setOpenValeurs] = useState(false)
  const [openCompetences, setOpenCompetences] = useState(false)
  const [openCompetencesAnnexes, setOpenCompetencesAnnexes] = useState(false)
  const [openSoftskils, setOpenSoftskils] = useState(false)
  /* const [role, setRole] = useState(userData?.data.role) */
  const [type, setType] = useState(null)
  const [typeOffre, setTypeOffre] = useState('offer')
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState([])
  const [besoins, setBesoins] = useState([])
  const [besoin, setBesoin] = useState([])
  const [resume, setResume] = useState([])
  const [isFile, setIsFile] = useState([])
  const [dateRdv, setDateRdv] = useState([])
  const [dateRdvPasse, setDateRdvPasse] = useState([])
  const [dateRdvRelation, setDateRdvRelation] = useState([])
  const [dateRdvPasseRelation, setDateRdvPasseRelation] = useState([])
  const [progress, setProgress] = useState(0)

  const params = useParams()

  useEffect(() => {
    if (params.eventId) {
      fetchEventData(params.eventId)
    }
  }, [params.eventId])

  const fetchEventData = async (id) => {
    try {
      let result = await Api.calendar.detail(id)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        console.log('erreur')
      }

      if (handling.ok) {
        const res = handling.data
        handleOpenEvent(res)
        /* if (res.entreprise?.fonctions?.id)
          setFonctionSelect(res.entreprise?.fonctions?.titre) */
        //if (res.candidat?.poste) setPosteSelect(res.candidat?.poste)
      }
    } catch (error) {
      console.log(error)
    }
  }

  /* const [loadingRdv, setLoadingRdv] = useState(false) */

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => {
    setOpen(false)
    //fetchData()
  }, [])
  const handleOpenCalendar = () => {
    setOpenCalendar(true)
  }
  const handleCloseCalendar = useCallback(() => {
    setOpenCalendar(false)
    //fetchData()
  }, [])
  const handleSave = useCallback(() => {
    fetchData()
    setOpenEvent(false)
    setOpen(false)
    setOpenDoc(false)
    setOpenBesoin(false)
    setOpenResume(false)
    setOpenSoftskils(false)
    setOpenCompetences(false)
    setOpenCompetencesAnnexes(false)
    setOpenValeurs(false)
    setOpenPayementOffre(false)
    setOpenAddEvent(false)
  }, [])

  const handleSaveDisponibilite = useCallback(() => {
    toast.success(t('successMessage.disponibiliteChanged'), {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      icon: ({ theme, type }) => (
        <FontAwesomeIcon
          icon={faCircleCheck}
          fontSize={25}
          color={lightTheme.palette.success.main}
        />
      ),
    })
    setOpenCalendar(false)
    setTimeout(function () {
      fetchData()
    }, 1000)
  }, [])

  const handleOpenDoc = useCallback(() => setOpenDoc(true), [])
  const handleCloseDoc = useCallback(() => {
    setOpenDoc(false)
    //fetchData()
  }, [])
  const handleOpenBesoin = useCallback(() => {
    setOpenBesoin(true)
    setBesoin('')
  }, [])
  const handleCloseBesoin = useCallback(() => {
    setOpenBesoin(false)
  }, [])
  const handleCloseDetailBesoin = useCallback(() => {
    setOpenDetailBesoin(false)
  }, [])
  const handleOpenResume = useCallback(() => setOpenResume(true), [])
  const handleCloseResume = useCallback(() => {
    setOpenResume(false)
    //fetchData()
  }, [])
  const handleOpenCompetences = useCallback(() => setOpenCompetences(true), [])
  const handleCloseCompetences = useCallback(() => {
    setOpenCompetences(false)
    //fetchData()
  }, [])
  const handleOpenCompetencesAnnexes = useCallback(
    () => setOpenCompetencesAnnexes(true),
    []
  )
  const handleCloseCompetencesAnnexes = useCallback(() => {
    setOpenCompetencesAnnexes(false)
    //fetchData()
  }, [])
  const handleOpenValeurs = useCallback(() => setOpenValeurs(true), [])
  const handleCloseValeurs = useCallback(() => {
    setOpenValeurs(false)
    //fetchData()
  }, [])
  const handleOpenSoftskils = useCallback(() => setOpenSoftskils(true), [])
  const handleCloseSoftskils = useCallback(() => {
    setOpenSoftskils(false)
    //fetchData()
  }, [])

  const handleOpenAddEvent = useCallback(() => setOpenAddEvent(true), [])
  const handleOpenOffer = useCallback(() => setOpenOffre(true), [])
  const handleCloseOffer = useCallback(() => {
    setOpenOffre(false)
    //fetchData()
  }, [])
  const handlePayementOffre = useCallback((offre) => {
    setTypeOffre(
      userData?.data.role === ROLE_CANDIDAT ? 'offer' : 'offerEntreprise'
    )
    setOffreToPay(offre)
    setOpenPayementOffre(true)
  }, [])
  const handlePayementAtelier = useCallback((atelier) => {
    setTypeOffre('atelier')
    setOffreToPay(atelier)
    setOpenPayementOffre(true)
  }, [])

  const handleClosePayementOffre = useCallback(() => {
    setOpenPayementOffre(false)
    //fetchData()
  }, [])
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openDeleteBesoinModal, setOpenDeleteBesoinModal] = useState(false)
  const handleCloseDeleteModal = useCallback(
    () => setOpenDeleteModal(false),
    []
  )
  const handleConfirmDeleteModal = () => removeFile()
  const handleCloseDeleteBesoinModal = () => setOpenDeleteBesoinModal(false)
  const handleConfirmDeleteBesoinModal = () => removeBesoin()
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [fileDelete, setFileDelete] = useState()
  const [besoinDelete, setBesoinDelete] = useState()
  const [loadingBesoin, setLoadingBesoin] = useState(true)

  const [openPayementOffre, setOpenPayementOffre] = useState(false)
  const [offreToPay, setOffreToPay] = useState()
  const [openOffre, setOpenOffre] = useState(false)
  const [offres, setOffres] = useState([])
  const [offreSelect, setOffreSelect] = useState('')
  const [offresSelect, setOffresSelect] = useState([])
  /* const [loadingOffer, setLoadingOffer] = useState(true) */
  const [loadingButton, setLoadingButton] = useState(false)
  const { handleSubmit } = useForm({ mode: 'onBlur' })
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)

  /* const [dateRelation, setDateRelation] = useState([]) */
  const [openEvent, setOpenEvent] = useState(false)
  const [openAddEvent, setOpenAddEvent] = useState(false)
  const [event, setEvent] = useState({})

  const handleOpenEvent = useCallback((event) => {
    setEvent(event)
    setOpenEvent(true)
  }, [])
  const handleCloseEvent = useCallback(() => {
    setOpenEvent(false)
    //fetchData()
  }, [])

  const handleCloseAddEvent = useCallback(() => {
    setOpenAddEvent(false)
    //fetchData()
  }, [])

  const [openPasswordModal, setOpenPasswordModal] = useState(false)
  const handleOpenPassword = () => setOpenPasswordModal(true)
  const handleClosePassword = () => setOpenPasswordModal(false)

  const onSuccessChangePassword = () => {
    toast.success(t('successMessage.passwordChanged'), {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      icon: ({ theme, type }) => (
        <FontAwesomeIcon
          icon={faCircleCheck}
          fontSize={25}
          color={lightTheme.palette.success.main}
        />
      ),
    })
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    getOffers()
    fetchData()
    if (sessionStorage.getItem('offerSelected')) {
      setOffreSelect(JSON.parse(sessionStorage.getItem('offerSelected')))
      if (userData?.data.role === ROLE_CANDIDAT && offreSelect)
        addOffer(offreSelect)
    }
  }, [userData?.data.role])

  const handleSaveBesoin = () => {
    setOpenBesoin(false)
    getBesoinsCompany(user.entreprise?.id, page)
  }

  const getBesoinsCompany = async (idCompany, page) => {
    setLoadingBesoin(true)
    try {
      let result = await Api.besoinEntreprise.listWithFilter({
        entreprise: idCompany,
        page: page,
      })

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoadingBesoin(false)
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling
        setLoadingBesoin(false)
        setBesoins(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
      } else {
        setLoadingBesoin(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoadingBesoin(false)
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
      window.scrollTo(0, 0)
    }
  }
  const getOffers = async () => {
    try {
      let result = await Api.offer.listWithFilter({
        categorie:
          userData?.data.role === ROLE_ENTREPRISE ? 'entreprise' : 'candidat',
      })

      setOffres(result)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        //setLoadingOffer(false)
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        //setLoadingOffer(false)
        setOffres(res)
      } else {
        //setLoadingOffer(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      //setLoadingOffer(false)
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
      window.scrollTo(0, 0)
    }
  }

  const handleChangeSelect = (evt) => {
    const inputValue = evt.target.value
    setOffreSelect(inputValue)
  }

  const addOffer = async (offer) => {
    if (sessionStorage.getItem('offerSelected')) {
      let offerUser = {
        offre: `/api/offres/${offer.id}`,
        prix: offer.prix,
        utilisateur: `/api/utilisateurs/${userData?.data.id}`,
      }
      if (userData?.data.role !== ROLE_CANDIDAT) {
        offerUser = {
          offre: `/api/offres_entreprises/${offer.id}`,
          prix: parseInt(
            offer[`prix${sessionStorage.getItem('periodeSelected')}`]
          ),
          prixPromo: parseInt(
            offer[`prix${sessionStorage.getItem('periodeSelected')}`]
          ),
          periode: parseInt(sessionStorage.getItem('periodeSelected')),
          utilisateur: `/api/utilisateurs/${userData?.data.id}`,
        }
      }

      try {
        const result = await Api.offerUtilisateur.add(
          offerUser,
          userData?.data.role
        )

        const handling = handlingErrors(result)

        if (handling.ok) {
          const res = handling.data
          //setSnapShot(res);
          handlePayementOffre(res)
          fetchData()
          sessionStorage.removeItem('offerSelected')
          sessionStorage.removeItem('periodeSelected')
          toast.success(
            t('successMessage.offerAdded') +
              '<br>' +
              t('successMessage.proceedPayment'),
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              icon: ({ theme, type }) => (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  fontSize={25}
                  color={lightTheme.palette.success.main}
                />
              ),
            }
          )
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const onSubmit = async (data) => {
    setLoadingButton(true)

    offresSelect.push({
      offre: `/api/offres/${offreSelect.id}`,
      prix: offreSelect.prix,
    })
    setOffresSelect(offresSelect)

    try {
      const result = await Api.user.edit(userData?.data.id, {
        userOffres: userData?.data.role === ROLE_CANDIDAT ? offresSelect : [],
        entrepriseOffres:
          userData?.data.role !== ROLE_CANDIDAT ? offresSelect : [],
      })

      const handling = handlingErrors(result)

      if (handling.ok) {
        const res = handling.data
        //setSnapShot(res);
        setOpenOffre(false)
        setLoadingButton(false)
        handlePayementOffre(
          userData?.data.role === ROLE_CANDIDAT
            ? res.userOffres.slice(-1)[0]
            : res.entrepriseOffres.slice(-1)[0]
        )
        fetchData()
        toast.success(t('successMessage.offerAdded'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
      }
    } catch (error) {
      setLoadingButton(false)
    }
  }

  const handleRemove = (idFile) => {
    setOpenDeleteModal(true)
    setFileDelete(idFile)
  }
  const handleEditBesoin = (besoin) => {
    setOpenBesoin(true)
    setBesoin(besoin)
  }
  const handleDetailBesoin = (besoin) => {
    setOpenDetailBesoin(true)
    setBesoin(besoin)
  }
  const handleRemoveBesoin = (idBesoin) => {
    setOpenDeleteBesoinModal(true)
    setBesoinDelete(idBesoin)
  }
  const handlePageChange = (event, page) => {
    setPage(page)
    getBesoinsCompany(user.entreprise?.id, page)
  }
  const removeBesoin = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.besoinEntreprise.delete(besoinDelete)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const indexBesoin = besoins.findIndex((bs) => bs.id === besoinDelete)

        if (indexBesoin > -1) {
          besoins.splice(indexBesoin, 1)
        }
        setBesoins(besoins)
        setOpenDeleteBesoinModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoadingDelete(false)
  }
  const removeFile = async () => {
    setLoadingDelete(true)
    try {
      let result = await Api.document.delete(fileDelete)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (handling.ok) {
        const indexFile = isFile.findIndex((file) => file.id === fileDelete)

        if (indexFile > -1) {
          isFile.splice(indexFile, 1)
        }
        setIsFile(isFile)
        setOpenDeleteModal(false)
      } else {
        window.scrollTo(0, 0)
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoadingDelete(false)
  }

  const fetchData = async () => {
    setLoading(true)

    try {
      let result = await Api.user.detail(userData?.data.id)

      //setRole(result.data.role)

      if (userData?.data.role === ROLE_ENTREPRISE) {
        setType('entreprise')
      } else if (userData?.data.role === ROLE_CANDIDAT) {
        setType('candidat')
      } else {
        setType(null)
      }

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        console.log('erreur')
      }

      if (handling.ok) {
        const res = handling.data
        setUser(res)
        if (userData?.data.role === ROLE_ENTREPRISE) {
          getBesoinsCompany(res.entreprise?.id, page)
        }

        const currentDate = new Date()
        setDateRdv(
          userData?.data.role === ROLE_CANDIDAT
            ? res.candidat?.calendriers
                .filter((item) => currentDate < new Date(item.dateDebut))
                .sort((a, b) => a.id - b.id)
            : res.entreprise?.calendriers
                .filter((item) => currentDate < new Date(item.dateDebut))
                .sort((a, b) => a.id - b.id)
        )
        setDateRdvPasse(
          userData?.data.role === ROLE_CANDIDAT
            ? res.candidat?.calendriers
                .filter((item) => new Date(item.dateDebut) < currentDate)
                .sort((a, b) => a.id - b.id)
            : res.entreprise?.calendriers
                .filter((item) => new Date(item.dateDebut) < currentDate)
                .sort((a, b) => a.id - b.id)
        )

        if (userData?.data.role === ROLE_CANDIDAT) {
          setDateRdvRelation(
            res.candidat?.calendriers
              .filter(
                (item) =>
                  currentDate < new Date(item.dateDebut) &&
                  item.type === 'rdv_entreprise'
              )
              .sort((a, b) => a.id - b.id)
          )
          setDateRdvPasseRelation(
            res.candidat?.calendriers
              .filter(
                (item) =>
                  new Date(item.dateDebut) < currentDate &&
                  item.type === 'rdv_entreprise'
              )
              .sort((a, b) => a.id - b.id)
          )
        }

        let userOffres = []
        if (userData?.data.role === ROLE_CANDIDAT && res.userOffres) {
          res.userOffres.map((userOffre, i) => {
            userOffres.push({
              id: userOffre.id,
              offre: `/api/offres/${userOffre.offre.id}`,
              prix: userOffre.prix,
            })
          })
        }
        if (userData?.data.role !== ROLE_CANDIDAT && res.entrepriseOffres) {
          res.entrepriseOffres.map((userOffre, i) => {
            userOffres.push({
              id: userOffre.id,
              offre: `/api/offres_entreprises/${userOffre.offre.id}`,
              prix: userOffre.prix,
            })
          })
        }
        setOffresSelect(userOffres)
        let docs = []
        //if (res.candidat?.cv) docs.push({ type: 'cv', nom: res.candidat?.cv })
        if (res.documents) {
          const documents = Object.values(res.documents)
          documents.forEach((doc) => {
            docs.push(doc)
          })
        }

        setIsFile(docs)
        if (userData?.data.role === ROLE_CANDIDAT)
          setBesoin(res.candidat?.besoin)

        setResume(
          userData?.data.role === ROLE_CANDIDAT ? res.candidat?.resume : ''
        )

        let totalProfil = 0
        if (userData?.data.role === ROLE_CANDIDAT) {
          if (res.candidat?.besoin) totalProfil = totalProfil + 10
          if (res.candidat?.resume) totalProfil = totalProfil + 10
          if (res.userLangues.length > 0) totalProfil = totalProfil + 10
          if (res.candidat?.competences.length > 0)
            totalProfil = totalProfil + 10
          if (res.candidat?.softskils.length > 0) totalProfil = totalProfil + 10
          if (res.candidat?.valeurs.length > 0) totalProfil = totalProfil + 10
          if (
            Object.keys(res.documents)
              .map((key) => res.documents[key])
              .find((item) => item.type === 'Cv')
          )
            totalProfil = totalProfil + 40

          setProgress(totalProfil)
        }

        setLoading(false)

        if (!userData?.data.autorise) {
          toast.error(t('successMessage.notAuthorized'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          })
          navigate(
            userData?.data.role === ROLE_CANDIDAT
              ? '/candidats'
              : '/entreprises/offres'
          )
        }
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  if (!type && loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )

  if (type === 'entreprise')
    return (
      <>
        <UserMasonryLayoutCompany
          user={userData}
          type={type}
          handleOpenDoc={handleOpenDoc}
          handleOpenBesoin={handleOpenBesoin}
          handleOpenResume={handleOpenResume}
          handleOpenValeurs={handleOpenValeurs}
          handleOpenCompetences={handleOpenCompetences}
          handleOpenCompetencesAnnexes={handleOpenCompetencesAnnexes}
          handleOpenSoftskils={handleOpenSoftskils}
          handleOpenOffer={handleOpenOffer}
          profil={
            <ProfilContainer>
              <TitleContainer>
                <TitleSection sx={{ width: '100%' }}>
                  {t('profile.title')}
                </TitleSection>
                <IconContainer sx={{ marginRight: '5px' }} onClick={handleOpen}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
                <IconContainer onClick={handleOpenCalendar}>
                  <FontAwesomeIcon
                    icon={faCalendar}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </TitleContainer>
              <WrapperProfilText>
                <Typography
                  sx={{
                    fontFamily: 'Century Gothic Bold',
                    fontSize: { xs: 20 },
                    lineHeight: '120%',
                    color: lightTheme.palette.text.primary,
                    textAlign: 'center',
                    display: { xs: 'none', lg: 'block' },
                    textTransform: 'uppercase',
                  }}
                >
                  {user.entreprise?.raisonSocial}
                </Typography>
                <Typography
                  variant={'subtitle2'}
                  textAlign={'center'}
                  textTransform={'capitalize'}
                >
                  {user.prenom} {user.nom}
                </Typography>
                <Typography
                  variant={'body2'}
                  color={lightTheme.palette.grey.grey}
                  textAlign={'center'}
                >
                  {user.entreprise?.fonctions?.titre}
                  {user.candidat?.poste?.titre}
                </Typography>
              </WrapperProfilText>
              <BannerProfilContainer>
                <WrapperBanner>
                  {/* {user.langues?.length > 0 && (
                <BannerProfil>
                  <FontAwesomeIcon icon={faComment} />
                  <Typography>{user.langues}</Typography>
                </BannerProfil>
              )} */}
                  <BannerProfil>
                    <FontAwesomeIcon icon={faMapMarker} />

                    <Typography
                      textAlign={'right'}
                      title={user.adresse}
                      style={{
                        width: '90%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        lineHeight: '16px',
                      }}
                    >
                      {user.adresse}
                    </Typography>
                  </BannerProfil>
                  <BannerProfil>
                    <FontAwesomeIcon icon={faHome} />
                    <Typography>
                      {user.codePostal} / {user.ville} / {user.pays}
                    </Typography>
                  </BannerProfil>
                  <BannerProfil>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <Typography
                      textAlign={'right'}
                      title={user.email}
                      style={{
                        width: '90%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {user.email}
                    </Typography>
                  </BannerProfil>
                  <BannerProfil>
                    <FontAwesomeIcon icon={faPhone} />
                    <Typography>{user.telephone}</Typography>
                  </BannerProfil>

                  {false ? (
                    <CircularProgress />
                  ) : (
                    <CustomButton
                      displayIcon={'none'}
                      handleClick={() => setOpenAddEvent(true)}
                    >
                      {t('profile.appointmentRequest')}
                    </CustomButton>
                  )}
                </WrapperBanner>
                {/*<BorderButtonPurple>Voir le résumé</BorderButtonPurple>*/}
              </BannerProfilContainer>
            </ProfilContainer>
          }
          besoinClient={
            besoins && (
              <BesoinTable
                besoins={besoins}
                loading={loadingBesoin}
                handleRemove={handleRemoveBesoin}
                handleEdit={handleEditBesoin}
                handleDetailBesoin={handleDetailBesoin}
                handlePageChange={handlePageChange}
                count={count}
                page={page}
                role={userData?.data.role}
              />
            )
          }
          resumeClient={
            <Typography variant={'body2'} color={lightTheme.palette.grey.grey}>
              <div style={{ whiteSpace: 'pre-line' }}>{resume}</div>
            </Typography>
          }
          offre={
            user.entrepriseOffres && (
              <OffreEntrepriseTable
                offres={user.entrepriseOffres}
                handlePayementOffre={handlePayementOffre}
              />
            )
          }
          atelier={
            user.userAteliers && (
              <AtelierTable
                ateliers={user.userAteliers}
                handlePayementAtelier={handlePayementAtelier}
              />
            )
          }
          ged={
            isFile ? (
              <GedTable
                files={isFile}
                dateFile={moment(user.date)
                  .tz('Europe/Paris')
                  .format('DD/MM/YYYY HH:mm')}
                handleRemove={handleRemove}
              />
            ) : (
              <GedTable />
            )
          }
          rendezVous={
            <BiBlockTable
              rdvAVenir={dateRdv}
              rdvPasse={dateRdvPasse}
              heureAVenir={''}
              handleOpenEvent={handleOpenEvent}
            />
          }
          relation={<BiBlockTable rdvAVenir={[]} heureAVenir={''} />}
          displayValeurSection={'display'}
          valeurs={user.entreprise?.valeurs}
          loading={loading}
        />
        {open && (
          <EditInfos
            open={open}
            onClose={handleClose}
            onSave={handleSave}
            handleOpenPassword={handleOpenPassword}
            id={userData?.data.id}
            role={userData?.data.role}
            type={userData?.data.role}
          />
        )}
        {/* {openBesoin && (
          <EditBesoin
            open={openBesoin}
            onClose={handleCloseBesoin}
            onSave={handleSave}
            id={userData?.data.id}
            besoin={besoin}
          />
        )} */}
        {openResume && (
          <EditResume
            open={openResume}
            onClose={handleCloseResume}
            onSave={handleSave}
            id={userData?.data.id}
          />
        )}
        {openDoc && (
          <EditDocuments
            open={openDoc}
            onClose={handleCloseDoc}
            onSave={handleSave}
            id={userData?.data.id}
            isAdmin={false}
            role={userData?.data.role}
          />
        )}
        {openPasswordModal && (
          <EditPasswordModal
            open={openPasswordModal}
            onClose={handleClosePassword}
            id={userData?.data.id}
            onSuccess={onSuccessChangePassword}
          />
        )}
        {openBesoin && (
          <EditBesoinEntreprise
            open={openBesoin}
            onClose={handleCloseBesoin}
            onSave={handleSaveBesoin}
            id={user.entreprise?.id}
            besoin={besoin}
            role={userData?.data.role}
          />
        )}
        {openDetailBesoin && (
          <DetailBesoinEntreprise
            open={openDetailBesoin}
            onClose={handleCloseDetailBesoin}
            besoin={besoin}
            role={userData?.data.role}
          />
        )}
        {openOffre && (
          <ChoiceOfferModal
            open={openOffre}
            onClose={handleCloseOffer}
            items={offres}
            handleClick={handleSubmit(onSubmit)}
            value={offreSelect}
            handleChange={handleChangeSelect}
            loading={loadingButton}
          />
        )}
        {openPayementOffre && (
          <PaymentModal
            open={openPayementOffre}
            onClose={handleClosePayementOffre}
            userId={userData?.data.id}
            userOffre={offreToPay}
            typeOffre={typeOffre}
            currency={'eur'}
            role={userData?.data.role}
            onSuccess={handleSave}
          />
        )}
        {openDeleteModal && (
          <DeleteGeneralModal
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            onConfirm={handleConfirmDeleteModal}
            loading={loadingDelete}
          />
        )}
        {openDeleteBesoinModal && (
          <DeleteGeneralModal
            open={openDeleteBesoinModal}
            onClose={handleCloseDeleteBesoinModal}
            onConfirm={handleConfirmDeleteBesoinModal}
            loading={loadingDelete}
          />
        )}
        {openEvent && (
          <EditEvent
            open={openEvent}
            onClose={handleCloseEvent}
            id={userData?.data.id}
            role={userData?.data.role}
            event={event}
            poste={event.candidat?.poste?.titre}
            onSave={handleSave}
            showParams={false}
          />
        )}
        {openValeurs && (
          <EditList
            open={openValeurs}
            onClose={handleCloseValeurs}
            onSave={handleSave}
            id={user.entreprise?.id}
            type={'valeurs'}
            values={user.entreprise?.valeurs}
            /* datas={VALEURS[i18n.language]} */
            datas={[...VALEURS['fr'], ...VALEURS['en']]}
            role={'entreprise'}
          />
        )}
        {openAddEvent && (
          <AddEvent
            open={openAddEvent}
            onClose={handleCloseAddEvent}
            id={user.entreprise?.id}
            role={userData?.data.role}
            onSave={handleSave}
          />
        )}
        {openCalendar && (
          <EditDisponibilite
            open={openCalendar}
            onClose={handleCloseCalendar}
            onSave={handleSaveDisponibilite}
            user={user}
          />
        )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          closeButton={false}
          transition={Slide}
          className={'toast'}
        />
      </>
    )
  if (type === 'candidat')
    return (
      <>
        <UserMasonryLayout
          user={userData}
          userLangues={user.userLangues}
          type={type}
          handleOpenDoc={handleOpenDoc}
          handleOpenBesoin={handleOpenBesoin}
          handleOpenResume={handleOpenResume}
          handleOpenValeurs={handleOpenValeurs}
          handleOpenCompetences={handleOpenCompetences}
          handleOpenCompetencesAnnexes={handleOpenCompetencesAnnexes}
          handleOpenSoftskils={handleOpenSoftskils}
          handleOpenOffer={handleOpenOffer}
          handleOpenAddEvent={handleOpenAddEvent}
          profil={
            <ProfilContainer>
              <TitleContainer>
                <TitleSection
                  sx={{
                    color:
                      user.userLangues.length === 0
                        ? lightTheme.palette.error.main
                        : 'initial',
                  }}
                >
                  {t('profile.title')}
                  {user.userLangues.length === 0 && '*'}
                </TitleSection>
                <IconContainer onClick={handleOpen}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </TitleContainer>
              <WrapperProfilText>
                <Typography
                  sx={{
                    fontFamily: 'Century Gothic Bold',
                    fontSize: { xs: 20 },
                    lineHeight: '120%',
                    color: lightTheme.palette.text.primary,
                    textAlign: 'center',
                    display: { xs: 'none', lg: 'block' },
                    textTransform: 'uppercase',
                  }}
                >
                  <CircularProgressWithLabel
                    value={progress}
                    color={progress > 50 ? 'success' : 'warning'}
                  />
                </Typography>
                <Typography
                  variant={'subtitle2'}
                  textAlign={'center'}
                  textTransform={'capitalize'}
                >
                  {user.prenom} {user.nom}
                </Typography>
                <Typography
                  variant={'body2'}
                  color={lightTheme.palette.grey.grey}
                  textAlign={'center'}
                >
                  {user.entreprise?.fonctions?.titre}
                  {user.candidat?.poste?.titre}
                </Typography>

                {user.candidat?.typeDisponibilite && (
                  <Typography
                    variant={'body2'}
                    color={lightTheme.palette.grey.grey}
                    textAlign={'center'}
                  >
                    {
                      disponibilitesList2.find(
                        (dispo) => dispo.id === user.candidat?.typeDisponibilite
                      ).titre[i18n.language]
                    }

                    {user.candidat?.typeDisponibilite === 2 &&
                      user.candidat?.disponibleSous &&
                      disponibleSousList2.find(
                        (dispoS) => dispoS.id === user.candidat?.disponibleSous
                      ).titre[i18n.language]}

                    {user.candidat?.typeDisponibilite === 4 &&
                      moment(user.candidat?.disponible)
                        .tz('Europe/Paris')
                        .format('DD/MM/YYYY')}
                  </Typography>
                )}
              </WrapperProfilText>
              <BannerProfilContainer>
                <WrapperBanner>
                  {/* {user.langues?.length > 0 && (
                  <BannerProfil>
                    <FontAwesomeIcon icon={faComment} />
                    <Typography>{user.langues}</Typography>
                  </BannerProfil>
                )} */}
                  <BannerProfil>
                    <FontAwesomeIcon icon={faMapMarker} />

                    <Typography
                      textAlign={'right'}
                      title={user.adresse}
                      style={{
                        width: '90%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        lineHeight: '16px',
                      }}
                    >
                      {user.adresse}
                    </Typography>
                  </BannerProfil>
                  <BannerProfil>
                    <FontAwesomeIcon icon={faHome} />
                    <Typography>
                      {user.codePostal} / {user.ville} / {user.pays}
                    </Typography>
                  </BannerProfil>
                  <BannerProfil>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <Typography
                      textAlign={'right'}
                      title={user.email}
                      style={{
                        width: '90%',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {user.email}
                    </Typography>
                  </BannerProfil>
                  <BannerProfil>
                    <FontAwesomeIcon icon={faPhone} />
                    <Typography>{user.telephone}</Typography>
                  </BannerProfil>
                  {/*   {user.dateNaissance && (
                  <BannerProfil>
                    <FontAwesomeIcon icon={faCakeCandles} />
                    <Typography>{user.dateNaissance}</Typography>
                  </BannerProfil>
                )} */}

                  {/* {user.statusMarital && (
                  <BannerProfil>
                    <FontAwesomeIcon icon={faRingsWedding} />
                    <Typography>{user.statusMarital}</Typography>
                  </BannerProfil>
                )} */}

                  {false ? (
                    <CircularProgress />
                  ) : (
                    <CustomButton
                      displayIcon={'none'}
                      handleClick={() => setOpenAddEvent(true)}
                    >
                      {t('profile.appointmentRequest')}
                    </CustomButton>
                  )}
                </WrapperBanner>
                {/*<BorderButtonPurple>Voir le résumé</BorderButtonPurple>*/}
              </BannerProfilContainer>
            </ProfilContainer>
          }
          besoin={besoin}
          besoinClient={
            <Typography variant={'body2'} color={lightTheme.palette.grey.grey}>
              <div style={{ whiteSpace: 'pre-line' }}>{besoin}</div>
            </Typography>
          }
          resume={resume}
          resumeClient={
            <Typography variant={'body2'} color={lightTheme.palette.grey.grey}>
              <div style={{ whiteSpace: 'pre-line' }}>{resume}</div>
            </Typography>
          }
          offre={
            user.userOffres && (
              <OffreTable
                offres={user.userOffres}
                handlePayementOffre={handlePayementOffre}
              />
            )
          }
          atelier={
            user.userAteliers && (
              <AtelierTable
                ateliers={user.userAteliers}
                handlePayementAtelier={handlePayementAtelier}
              />
            )
          }
          files={isFile}
          ged={
            isFile ? (
              <GedTabs
                files={isFile}
                dateFile={moment(user.date)
                  .tz('Europe/Paris')
                  .format('DD/MM/YYYY HH:mm')}
                handleRemove={handleRemove}
              />
            ) : (
              <GedTable />
            )
          }
          rendezVous={
            <BiBlockTable
              rdvAVenir={dateRdv}
              rdvPasse={dateRdvPasse}
              heureAVenir={''}
              handleOpenEvent={handleOpenEvent}
            />
          }
          relation={
            <BiBlockTable
              rdvAVenir={dateRdvRelation}
              rdvPasse={dateRdvPasseRelation}
              heureAVenir={''}
              handleOpenEvent={handleOpenEvent}
            />}
          /*  displayValeurSection={type === 'entreprise' ? 'bloc' : 'none'}
        valeurs={
          <ValeurContainer>
            {role === ROLE_ENTREPRISE && (
              <Typography>{user.entreprise.valeurs}</Typography>
            )}
          </ValeurContainer>
        } */

          displayCompetencesSection={
            userData?.data.role === ROLE_CANDIDAT ? 'display' : 'none'
          }
          competences={user.candidat?.competences}
          competencesAnnexes={user.candidat?.competencesAnnexes}
          displaySoftskilSection={
            userData?.data.role === ROLE_CANDIDAT ? 'display' : 'none'
          }
          softskils={user.candidat?.softskils}
          displayValeurSection={
            userData?.data.role === ROLE_CANDIDAT ? 'display' : 'none'
          }
          valeurs={user.candidat?.valeurs}
          loading={loading}
        />
        {open && (
          <EditInfos
            open={open}
            onClose={handleClose}
            onSave={handleSave}
            handleOpenPassword={handleOpenPassword}
            id={userData?.data.id}
            role={userData?.data.role}
            type={userData?.data.role}
          />
        )}
        {openBesoin && (
          <EditBesoin
            open={openBesoin}
            onClose={handleCloseBesoin}
            onSave={handleSave}
            id={userData?.data.id}
          />
        )}
        {openResume && (
          <EditResume
            open={openResume}
            onClose={handleCloseResume}
            onSave={handleSave}
            id={userData?.data.id}
          />
        )}
        {openDoc && (
          <EditDocuments
            open={openDoc}
            onClose={handleCloseDoc}
            onSave={handleSave}
            id={userData?.data.id}
            isAdmin={false}
            role={userData?.data.role}
          />
        )}
        {openPasswordModal && (
          <EditPasswordModal
            open={openPasswordModal}
            onClose={handleClosePassword}
            id={userData?.data.id}
            onSuccess={onSuccessChangePassword}
          />
        )}
        {userData?.data.role === ROLE_CANDIDAT && openCompetences && (
          <EditList
            open={openCompetences}
            onClose={handleCloseCompetences}
            onSave={handleSave}
            id={user.candidat?.id}
            type={'competences'}
            values={user.candidat?.competences}
            datas={[]}
          />
        )}
        {userData?.data.role === ROLE_CANDIDAT && openCompetencesAnnexes && (
          <EditList
            open={openCompetencesAnnexes}
            onClose={handleCloseCompetencesAnnexes}
            onSave={handleSave}
            id={user.candidat?.id}
            type={'competencesAnnexes'}
            values={user.candidat?.competencesAnnexes}
            datas={[]}
          />
        )}
        {userData?.data.role === ROLE_CANDIDAT && openSoftskils && (
          <EditList
            open={openSoftskils}
            onClose={handleCloseSoftskils}
            onSave={handleSave}
            id={user.candidat?.id}
            type={'softskils'}
            values={user.candidat?.softskils}
            datas={[...SOFTSKILLS['fr'], ...SOFTSKILLS['en']].sort()}
          />
        )}
        {userData?.data.role === ROLE_CANDIDAT && openValeurs && (
          <EditList
            open={openValeurs}
            onClose={handleCloseValeurs}
            onSave={handleSave}
            id={user.candidat?.id}
            type={'valeurs'}
            values={user.candidat?.valeurs}
            datas={VALEURS[i18n.language]}
          />
        )}

        {openOffre && (
          <ChoiceOfferModal
            open={openOffre}
            onClose={handleCloseOffer}
            items={offres}
            handleClick={handleSubmit(onSubmit)}
            value={offreSelect}
            handleChange={handleChangeSelect}
            loading={loadingButton}
          />
        )}

        {openPayementOffre && (
          <PaymentModal
            open={openPayementOffre}
            onClose={handleClosePayementOffre}
            userId={userData?.data.id}
            userOffre={offreToPay}
            typeOffre={typeOffre}
            currency={'eur'}
            role={userData?.data.role}
            onSuccess={handleSave}
          />
        )}

        {openDeleteModal && (
          <DeleteGeneralModal
            open={openDeleteModal}
            onClose={handleCloseDeleteModal}
            onConfirm={handleConfirmDeleteModal}
            loading={loadingDelete}
          />
        )}

        {openEvent && (
          <EditEvent
            open={openEvent}
            onClose={handleCloseEvent}
            id={userData?.data.id}
            role={userData?.data.role}
            poste={user.candidat?.poste?.titre}
            event={event}
            onSave={handleSave}
            showParams={false}
          />
        )}

        {openAddEvent && (
          <AddEvent
            open={openAddEvent}
            onClose={handleCloseAddEvent}
            id={user.candidat?.id}
            role={userData?.data.role}
            onSave={handleSave}
          />
        )}

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          closeButton={false}
          transition={Slide}
          className={'toast'}
        />
      </>
    )
}

export default UserDashboard
