import React from "react";
import { Routes, Route } from "react-router-dom";
import { ContactLayout } from "../components/layouts";
import { Contact } from "../pages/Public";

const ContactRouter = () => {
  return (
    <Routes>
      <Route element={<ContactLayout/>}>
        <Route index element={<Contact/>}/>
      </Route>
    </Routes>
  )
}

export default ContactRouter;