import React from "react";
import {Box, styled} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";

const CustomBox = styled(Box)(({theme}) => ({
    width: "100%",
    height: "100%",
    backgroundColor: lightTheme.palette.primary.b6,
    borderRadius: "50px 50px 0px 0px",
    padding: "50px 0 50px 0",

    [theme.breakpoints.up("lg")]: {
        borderRadius: "125px 125px 0px 0px"
    },
}))

export default function SectionLayout({children, id}) {
    return (
        <CustomBox component={"section"} id={id}>
            {children}
        </CustomBox>
    )
}