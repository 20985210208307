import { AppNavbar } from "../navbar";
import { Outlet } from "react-router-dom";
import "../../styles/components/_layouts.scss";

const UserLayout = () => {
  return (
    <div className={"UserLayout"}>
      <AppNavbar/>
      <main className={"MainUser"}>
        <Outlet/>
      </main>
    </div>
  )
}

export default UserLayout;