import React from "react";
import {Box, Button, styled, Typography} from "@mui/material";
import logoArticle from "../../assets/images/logos/tinyLogo-article.svg";
import logoVideo from "../../assets/images/logos/tinyLogo-video.svg";
import logoPodcast from "../../assets/images/logos/tinyLogo-podcast.svg";
import lightTheme from "../../styles/theme/lightTheme";
/*import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/pro-regular-svg-icons";
import {faClock} from "@fortawesome/pro-regular-svg-icons";
import {faLocationDot} from "@fortawesome/pro-regular-svg-icons";*/

const CardContainer = styled(Box)(({theme}) => ({
    width: 228,
    minHeight: 340,
    display: "flex",
    flexDirection: "column",
    rowGap: "25px",
    position: "relative",

    [theme.breakpoints.up("sm")]: {
        width: 305,
        height: 340,
    },
    [theme.breakpoints.up("lg")]: {
        width: 370,
        height: 440,
    },
}));

const ImageContainer = styled(Box)(({theme}) => ({
    width: 228,
    height: 130,
    borderRadius: "45px 0",
    overflow: "hidden",
    position: "relative",

    img: {
      position: "absolute",
      width: "100%"
    },

    [theme.breakpoints.up("sm")]: {
        width: 305,
        height: 174,
    },
    [theme.breakpoints.up("lg")]: {
        width: 370,
        height: 200,
    },
}));

const CustomBox = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",

    [theme.breakpoints.up("sm")]: {
        rowGap: "15px",
    },
    [theme.breakpoints.up("lg")]: {
        rowGap: "20px",
    },
}));

const TextButton = styled(Button)({
    fontFamily: "Century Gothic Bold",
    textTransform: "uppercase",
    fontSize: 14,
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: lightTheme.palette.primary.main,
    maxWidth: "max-content",
    alignSelf: "end"
});

const Thumbnail = styled(Box)({
    height: 27,
    borderRadius: "50px",
    backgroundColor: "#FFFFFF",
    position: "absolute",
    top: "10px",
    right: "10px",
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 10px"
});

const ThumbnailImage = styled(Box)({
    width: 15,
    height: 15,
});

const LineBottomOrange = styled(Box)(({theme}) => ({
    width: "100%",
    height: 2,
    backgroundColor: lightTheme.palette.error.main,
    position: "absolute",
    bottom: 0
}));

const LineBottomPurple = styled(Box)(({theme}) => ({
    width: "100%",
    height: 2,
    backgroundColor: lightTheme.palette.secondary.main,
    position: "absolute",
    bottom: 0
}));

const LineBottomGreen = styled(Box)(({theme}) => ({
    width: "100%",
    height: 2,
    backgroundColor: lightTheme.palette.success.main,
    position: "absolute",
    bottom: 0
}));

const DescriptionCard = styled(Typography)(({theme}) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '4',
    WebkitBoxOrient: 'vertical',
}));

export default function EventCard(props) {
    return (
        <>
            {props.items && props.items.map((item, index) => (
                <CardContainer key={index}>
                    <ImageContainer>
                        <img src={item.picture} alt={"Jobertech"}/>
                        <Thumbnail>
                            <ThumbnailImage >
                                {(item.tag === "article") && <img src={logoArticle} alt={"Jobertech"} />}
                                {(item.tag === "video") && <img src={logoVideo} alt={"Jobertech"} />}
                                {(item.tag === "podcast") && <img src={logoPodcast} alt={"Jobertech"} />}
                            </ThumbnailImage>
                            <Typography variant={"caption"} color={lightTheme.palette.grey.dark} textTransform={"capitalize"}>{item.tag}</Typography>
                        </Thumbnail>
                    </ImageContainer>
                    <CustomBox>
                        {/*<EventContainer>
                            <EventBox>
                                <FontAwesomeIcon icon={faCalendar} className="icon"/>
                                <Typography variant={"caption"} color={"#FFFFFF"}>16/05/2023</Typography>
                            </EventBox>
                            <EventBox>
                                <FontAwesomeIcon icon={faClock} className="icon"/>
                                <Typography variant={"caption"} color={"#FFFFFF"}>18H00</Typography>
                            </EventBox>
                            <EventBox>
                                <FontAwesomeIcon icon={faLocationDot} className="icon"/>
                                <Typography variant={"caption"} color={"#FFFFFF"}>Online</Typography>
                            </EventBox>
                        </EventContainer>*/}
                        <Typography variant={"h4"} color={lightTheme.palette.grey.dark}>{item.title}</Typography>
                        <DescriptionCard variant={"body1"} color={lightTheme.palette.grey.dark}>
                            {item.description}
                        </DescriptionCard>
                        <TextButton variant={"text"}>En savoir plus</TextButton>
                    </CustomBox>
                    {(item.tag === "article") && <LineBottomOrange />}
                    {(item.tag === "video") && <LineBottomPurple />}
                    {(item.tag === "podcast") && <LineBottomGreen />}
                </CardContainer>
            ))}
        </>
    )
}