import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { BorderButton } from '../../../components/buttons'
import { useNavigate } from 'react-router-dom'

const NoAccess = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '50vh',
        padding: '0 5vh',
      }}
    >
      <Typography
        className={'error-message'}
        variant="h1"
        style={{ fontSize: 60, fontStyle: 'italic' }}
      >
        403
      </Typography>
      <Typography
        variant="h6"
        className={'error-message'}
        style={{ fontSize: 16 }}
      >
        {t('errorMessage.notAuthorized')}
      </Typography>
      <br></br>
      <BorderButton
        type={'rainbow'}
        textColor={'rainbow'}
        displayIconLeft={'none'}
        displayIconRight={'none'}
        handleClick={() => navigate('/entreprises/offres')}
      >
        {t('button.viewOurOffers')}
      </BorderButton>
    </Box>
  )
}

export default NoAccess
