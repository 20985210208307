import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  Skeleton,
  Typography,
  styled,
} from '@mui/material'
import { SectionsHeading } from '../../../../components/headings'
import { useTranslation } from 'react-i18next'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import AppPagination from '../../../../components/paginations'
import { Markup } from 'interweave'
import lightTheme from '../../../../styles/theme/lightTheme'
import { debounce, truncate } from '../../../../config'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/fr'
import { useNavigate } from 'react-router-dom'
import {
  SearchInputBasic,
  SelectInputObject,
} from '../../../../components/inputs'
import moment from 'moment-timezone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendar,
  faClock,
  faMapMarkerAlt,
} from '@fortawesome/pro-regular-svg-icons'

const GridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
}))
const ImageContainer = styled(Box)(({ theme }) => ({
  height: 200,
  borderRadius: '25px 0 25px 0',
  position: 'relative',
  overflow: 'hidden',

  '& .image': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  [theme.breakpoints.up('sm')]: {
    height: 180,
  },

  [theme.breakpoints.up('lg')]: {},
}))

const InputContainer = styled(FormControl)(({ theme }) => ({
  rowGap: '5px',
}))

export default function Ateliers() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [ateliers, setAteliers] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [dateValue, setDateValue] = useState(null)
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState([
    {
      id: 0,
      titre: t('filter.sortDateAsc'),
      filtre: 'date',
      type: 'asc',
    },
    {
      id: 1,
      titre: t('filter.sortDateDesc'),
      filtre: 'date',
      type: 'desc',
    },
    { id: 2, titre: t('filter.sortTitle'), filtre: 'titre', type: 'asc' },
  ])

  const [orderSelect, setOrderSelect] = useState(order[0])

  useEffect(() => {
    // Observer les changements de langue
    const languageChangeHandler = () => {
      setOrder([
        {
          id: 0,
          titre: t('filter.sortDateAsc'),
          filtre: 'date',
          type: 'asc',
        },
        {
          id: 1,
          titre: t('filter.sortDateDesc'),
          filtre: 'date',
          type: 'desc',
        },
        { id: 2, titre: t('filter.sortTitle'), filtre: 'titre', type: 'asc' },
      ])

      /* setTimeout(() => {
        setFilterSelect(filter[0])
        setOrderSelect(order[0])
        alert('ccc')
      }, 2200) */
    }

    i18n.on('languageChanged', languageChangeHandler)
    return () => {
      i18n.off('languageChanged', languageChangeHandler)
    }
  }, [i18n])

  useEffect(() => {
    setOrderSelect(order.find((obj2) => obj2.id === orderSelect.id))
  }, [order])

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearch(value)
  }, 800)

  const handleOrderSelect = (event) => {
    const inputValue = event.target.value
    setOrderSelect(inputValue)
  }

  useEffect(() => {
    setPage(1)
    getAtelier(1)
  }, [i18n.language, search, orderSelect, dateValue])

  const handlePageChange = (event, page) => {
    setPage(page)
    getAtelier(page)
  }

  const getAtelier = async (page) => {
    let payload = {
      search: search,
      lang: i18n.language,
      /* type: filterSelect.filtre, */
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )

    if (dateValue)
      payload.date = {
        after: dateValue.format('YYYY-MM') + '-01T00:00:00',
        before:
          dateValue.format('YYYY-MM') +
          '-' +
          new Date(
            dateValue.format('YYYY'),
            dateValue.format('MM'),
            0
          ).getDate() +
          'T23:59:59',
      }

    setLoading(true)

    try {
      const result = await Api.siteVitrine.getAteliers(
        page,
        payload,
        orderSelect.filtre,
        orderSelect.type
      )

      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }

      if (res.ok) {
        setAteliers(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <main>
      <Box
        width={'100%'}
        component={'section'}
        sx={{
          padding: { xs: '110px 0 130px 0', md: '100px 0 ', lg: '80px 0' },
        }}
      >
        <Box width={'100%'} position={'relative'}>
          <Container
            maxWidth={'lg'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '40px',
            }}
          >
            <SectionsHeading type={'animation'}>
              {t('home.ourWorkshop')}
            </SectionsHeading>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item lg={3} md={4} xs={12}>
                <InputContainer fullWidth>
                  <Box class="fc-right-custom">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18n.language}
                    >
                      <DatePicker
                        openTo="month"
                        views={['month', 'year']}
                        onChange={setDateValue}
                        value={dateValue}
                        inputFormat={'MM/YYYY'}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '100%',
                              height: 45,
                              backgroundColor: lightTheme.palette.primary.b2,
                              borderRadius: '5px',

                              '& .css-1laqsz7-MuiInputAdornment-root, & .MuiInputAdornment-root': {
                                height: '100%',
                                width: '50px',
                                margin: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',

                                '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root, & .root-MuiIconButton-root':
                                  {
                                    margin: 0,
                                    color: '#FFFFFF',
                                  },
                              },
                            }}
                          >
                            <input
                              ref={inputRef}
                              {...inputProps}
                              placeholder={t('filter.dateMonth')}
                              style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '5px 0 0 5px',
                                border: '1px solid #9E9E9E',
                                padding: '10px 15px',
                                fontFamily: 'Century Gothic',
                                fontSize: 14,
                                lineHeight: '140%',
                                letterSpacing: '0.2px',
                                color: lightTheme.palette.text.primary,
                                backgroundColor: '#FFFFFF',
                              }}
                            />
                            {InputProps?.endAdornment}
                          </Box>
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </InputContainer>
              </Grid>
              <Grid item lg={3} md={4} xs={12}>
                <SelectInputObject
                  items={order}
                  value={orderSelect}
                  handleChange={handleOrderSelect}
                />
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  display: { xs: 'none', md: 'none', lg: 'initial' },
                }}
              ></Grid>
              <Grid item lg={4} md={4} xs={12}>
                <SearchInputBasic
                  width={'100%'}
                  onChange={handleSearchChange}
                />
              </Grid>
            </Grid>

            {loading ? (
              <Grid container>
                <GridItem item lg={12} alignItems={'center'}>
                  <CircularProgress />
                </GridItem>
              </Grid>
            ) : (
              <Grid container spacing={5}>
                {ateliers.map((item, index) => (
                  <GridItem item xs={12} sm={6} lg={4} key={index}>
                    {!item.miniature ? (
                      <></>
                    ) : (
                      <ImageContainer>
                        {/* <Typography
                          sx={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                            background: '#FFFFFF',
                            zIndex: '1',
                            padding: '5px 10px',
                            fontSize: '12px !important',
                            textTransform: 'capitalize',
                          }}
                        >
                          <img
                            style={{
                              marginRight: '5px',
                              marginBottom: '-2px',
                            }}
                            src={
                              item.type == 'article'
                                ? logoAtelier
                                : item.type == 'video'
                                ? logoVideo
                                : logoPodcast
                            }
                            alt={item.type}
                          />
                          {item.type}
                        </Typography> */}
                        <img
                          src={item.miniature}
                          alt={`Jobertech - ${item.titre}`}
                          className={'image'}
                          style={{ objectFit: 'contain' }}
                        />
                      </ImageContainer>
                    )}
                    <Box width={'100%'} display={'block'}>
                      <Typography
                        sx={{
                          width: 'fit-content',
                          borderRadius: '5px',
                          background: '#808BF8',
                          zIndex: '1',
                          padding: '5px 10px',
                          fontSize: '12px !important',
                          color: '#fff',
                          float: 'left',
                          marginRight: '10px',
                          marginTop: 1,
                        }}
                      >
                        <FontAwesomeIcon icon={faCalendar} />{' '}
                        {moment(item.date)
                          .tz('Europe/Paris')
                          .format('DD/MM/YYYY')}
                      </Typography>
                      <Typography
                        sx={{
                          width: 'fit-content',
                          borderRadius: '5px',
                          background: '#808BF8',
                          zIndex: '1',
                          padding: '5px 10px',
                          fontSize: '12px !important',
                          color: '#fff',
                          float: 'left',
                          marginRight: '10px',
                          marginTop: 1,
                        }}
                      >
                        <FontAwesomeIcon icon={faClock} />{' '}
                        {moment(item.date).tz('Europe/Paris').format('HH:mm')}
                      </Typography>
                      <Typography
                        sx={{
                          width: 'fit-content',
                          borderRadius: '5px',
                          background: '#808BF8',
                          zIndex: '1',
                          padding: '5px 10px',
                          fontSize: '12px !important',
                          color: '#fff',
                          float: 'left',
                          marginRight: '10px',
                          marginTop: 1,
                        }}
                      >
                        <FontAwesomeIcon icon={faMapMarkerAlt} /> {item.lieu}
                      </Typography>
                    </Box>

                    {!item.titre ? (
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        height={14}
                      />
                    ) : (
                      <Typography
                        sx={{
                          textAlign: 'left',
                          textTransform: 'uppercase',
                          fontFamily: 'Century Gothic Bold',
                          fontSize: { sm: 12, lg: 14 },
                          color: lightTheme.palette.text.primary,
                          lineHeight: '140%',
                          letterSpacing: '0.2px',
                        }}
                      >
                        {/* {item.id} --  */}
                        {item.titre}
                      </Typography>
                    )}

                    {!item.apropos ? (
                      <Box width={'100%'} display={'block'}>
                        <Skeleton
                          variant={'text'}
                          animation={'wave'}
                          height={14}
                        />
                        <Skeleton
                          variant={'text'}
                          animation={'wave'}
                          width={'50%'}
                          height={14}
                        />
                      </Box>
                    ) : (
                      <Typography
                        sx={{
                          textAlign: 'justify',
                          fontSize: { sm: 10, lg: 12 },
                        }}
                      >
                        <Markup content={truncate(item.apropos)} />
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        textAlign: 'right',
                      }}
                    >
                      <Button
                        size="small"
                        onClick={() => {
                          navigate('/atelier/' + item.id)
                        }}
                        sx={{
                          width: 'fitContent',
                          fontWeight: 'bold',
                        }}
                      >
                        {t('button.more')}
                      </Button>
                    </Typography>
                    {/* <hr
                      style={{
                        width: '100%',
                        border: '1px solid #F76E11',
                        borderColor:
                          item.type == 'article'
                            ? '#F76E11'
                            : item.type == 'video'
                            ? '#BF4FCE'
                            : '#96CEB4',
                      }}
                    /> */}
                  </GridItem>
                ))}
                <Grid item xs={0} sm={12} sx={{ width: '100%' }}>
                  <AppPagination
                    handlePageChange={handlePageChange}
                    count={count}
                    page={page}
                    pageSize={6}
                  />
                </Grid>
              </Grid>
            )}
          </Container>
        </Box>
      </Box>
    </main>
  )
}
