import React from "react";
import {Box, styled} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";

const CustomCircle = styled(Box)(({theme, top, bottom, left, right, display}) => ({
  width: 200,
  height: 200,
  borderRadius: "0 100% 0 0",
  background: lightTheme.palette.gradients.gradientGreen,
  transform: "rotate(-180deg)",
  position: "absolute",
  top: top,
  left: left,
  right: right,
  bottom: bottom,
  display: display,
  zIndex: -1,

  [theme.breakpoints.up("sm")]: {
    width: 200,
    height: 200,
  },

  [theme.breakpoints.up("lg")]: {

  },
}));

export default function QuadrantCircleLayout({top, left, right, bottom, display}) {
  return (
    <CustomCircle top={top} left={left} right={right} bottom={bottom} display={display}/>
  )
}