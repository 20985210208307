import React, { useEffect, useState } from 'react'
import AuthCard from '../../components/cards/AuthCard'
import { Box, CircularProgress, Container, styled, Typography } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import {  PasswordInputBasic } from '../../components/inputs'
import { useNavigate, useParams } from 'react-router-dom'
import { CustomButton } from '../../components/buttons'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import * as yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import { passwordRegExp } from '../../config'

const FormBox = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '25px',
  width: '100%',
})

const schema = yup.object({
  password: yup
    .string()
    .matches(
      passwordRegExp,
      'Votre mot de passe doit comporter au moins 6 caractères et doit contenir au moins une lettre, un chiffre et un caractère spécial (@$!%*?&#_=<>).'
    )
    .required('Merci de créer votre mot de passe.'),
  confirmPassword: yup
    .string()
    .required('Merci de ressaisir votre mot de passe.')
    .oneOf([yup.ref('password')], 'Le mot de passe ne correspond pas.'),
})

const ResetPassword = () => {
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })
  const navigate = useNavigate();

  const params = useParams()
  useEffect(() => {
    setToken(params.token)
  }, [])
  const onSubmit = async (data) => {
    setLoading(true)

    const obj = {
      ...data,
    }

    const res = await Api.forgotPassword.changePassword(token, obj)
    const handling = handlingErrors(res)

    if (handling.status === 401) {
      return
    }
    if (handling.ok) {
      toast.success('Votre mot de passe a bien été enregistrer!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleCheck}
            fontSize={25}
            color={lightTheme.palette.success.main}
          />
        ),
      })

      setLoading(false)
      setTimeout(() => {
        navigate('/auth/se-connecter')
      }, 1000)
      /*dispatch(userLogin({ email: data.email, password: data.password }));*/
    } else {
      if (handling.status === 422) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      } else {
        toast.error(
          "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
      setLoading(false)
    }
  }

  return (
    <>
      <Container
        maxWidth={'lg'}
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AuthCard title={'Réinitialisation du mot de passe'}>
          <FormBox>
            <Typography
              variant={'body2'}
              sx={{
                color: lightTheme.palette.text.primary,
              }}
            >
              Vous avez demandé à réinitialiser votre mot de passe, veuillez
              choisir votre nouveau mot de passe.
            </Typography>
            <PasswordInputBasic
              placeholder={'Nouveau mot de passe'}
              register={{ ...register('password') }}
            />

            {errors.password && (
              <Typography className={'error-message'}>
                {errors.password.message}
              </Typography>
            )}
            <PasswordInputBasic
              placeholder={'Confirmer nouveau mot de passe'}
              register={{ ...register('confirmPassword') }}
            />

            {errors.confirmPassword && (
              <Typography className={'error-message'}>
                {errors.confirmPassword.message}
              </Typography>
            )}

            <Box mt={'100px'}>
              {loading ? (
                <CircularProgress />
              ) : (
                <CustomButton
                  dispayIcon={'none'}
                  handleClick={handleSubmit(onSubmit)}
                >
                  Valider mon nouveau mot de passe
                </CustomButton>
              )}
            </Box>
          </FormBox>
        </AuthCard>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default ResetPassword
