import ApiRequest from '../request/ApiRequest'

export default class AteliersUtilisateurs {
  constructor() {
    this.ApiRequest = new ApiRequest()
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post('atelier_utilisateurs', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async get(data) {
    try {
      const res = await this.ApiRequest.get('atelier_utilisateurs', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`atelier_utilisateurs/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }
}
