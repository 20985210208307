import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import moment from 'moment/moment'
import lightTheme from '../../styles/theme/lightTheme'
import * as React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faCreditCard,
  faHourglassEnd,
} from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const TablePanelBox = styled(TabPanel)(({ theme }) => ({
  height: 280,
  backgroundColor: 'rgb(227, 230, 255, 0.5)',
  '& .MuiBox-root': {
    padding: 0,
  },
}))

const HeaderTab = styled(TableHead)(({ theme }) => ({
  height: 50,
  backgroundColor: lightTheme.palette.primary.b6,
}))

const TitleHead = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

const BodyTable = styled(TableBody)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: '2px solid rgb(227, 230, 255, 0.5)',
  fontFamily: 'Century Gothic',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

const OffreEntrepriseTable = ({ offres, handlePayementOffre }) => {
  const { t, i18n } = useTranslation()
  return (
    <TablePanelBox>
      {offres && (
        <Table>
          <HeaderTab>
            <TableRow>
              <TitleHead>{t('profile.date')}</TitleHead>
              <TitleHead>{t('profile.dateExpire')}</TitleHead>
              <TitleHead>{t('header.offer')}</TitleHead>
              <TitleHead>{t('profile.price')}</TitleHead>
              <TitleHead>{t('profile.status')}</TitleHead>
            </TableRow>
          </HeaderTab>
          {offres &&
            offres.map((offre, i) => (
              <BodyTable key={i}>
                <TableRow>
                  <StyledTableCell>
                    {moment(offre.date)
                      .tz('Europe/Paris')
                      .format('DD/MM/YYYY HH:mm')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {/* {moment(offre.dateFin)
                      .tz('Europe/Paris')
                      .isBefore(moment().tz('Europe/Paris')) &&
                      'Offre expirée le '} */}
                    {moment(offre.dateFin)
                      .tz('Europe/Paris')
                      .format('DD/MM/YYYY')}
                  </StyledTableCell>
                  <StyledTableCell>
                    {i18n.language === 'fr'
                      ? offre.offre.titre
                      : offre.offre.titreEn}
                  </StyledTableCell>
                  <StyledTableCell>
                    {(
                      offre.prixPromo *
                      JSON.parse(sessionStorage.getItem('currency')).currentRate
                    ).toFixed(2)}{' '}
                    {sessionStorage.getItem('currency')
                      ? JSON.parse(sessionStorage.getItem('currency')).devise
                      : 'CHF'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {offre.status === 'payer' &&
                    moment(offre.dateFin)
                      .tz('Europe/Paris')
                      .isBefore(moment().tz('Europe/Paris')) ? (
                      <FontAwesomeIcon
                        title={'Offre expirée'}
                        icon={faHourglassEnd}
                        fontSize={20}
                        color={lightTheme.palette.primary.main}
                      />
                    ) : offre.status === 'payer' ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        fontSize={20}
                        color={lightTheme.palette.success.main}
                      />
                    ) : (
                      <Box
                        sx={{
                          cursor: 'pointer',
                        }}
                        onClick={(e) => {
                          e.preventDefault()
                          handlePayementOffre(offre)
                        }}
                      >
                        <FontAwesomeIcon icon={faCreditCard} fontSize={20} />
                      </Box>
                    )}
                  </StyledTableCell>
                </TableRow>
              </BodyTable>
            ))}
        </Table>
      )}
    </TablePanelBox>
  )
}

export default OffreEntrepriseTable
