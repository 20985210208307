import React, { useEffect, useState } from 'react'
import { AdminHeading } from '../../../components/headings'
import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  styled,
} from '@mui/material'
import '../../../styles/components/_layouts.scss'
import '../../../styles/components/_grids.scss'
import '../../../styles/components/_box.scss'
import '../../../styles/components/_inputs.scss'
import { TextInputAdmin } from '../../../components/inputs'
import { BorderButtonPurple, CustomButton } from '../../../components/buttons'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import Api from '../../../services/api.service'
import { handlingErrors } from '../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../styles/theme/lightTheme'
import { useDispatch } from 'react-redux'
import { phoneRegExp } from '../../../config'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons'
import {
  STATUS_BLOCKED,
  STATUS_ACTIVE,
} from './config/status'

const InputContainer = styled(FormControl)(({ theme }) => ({
  rowGap: '5px',
}))

const schema = yup
  .object({
    prenom: yup.string().required('Merci de renseigner ce champ.'),
    nom: yup.string().required('Merci de renseigner ce champ'),
    email: yup
      .string()
      .email('Merci de rentrer un email valide.')
      .required('Merci de renseigner ce champ.'),
    telephone: yup
      .string()
      .required('Merci de renseigner ce champ.')
      .matches(phoneRegExp, 'Merci de rentrer un numéro de téléphone valide.')
      .nullable(),
    adresse: yup.string().required('Merci de renseigner ce champ.'),
    ville: yup.string().required('Merci de renseigner ce champ.'),
    codePostal: yup.number().required('Merci de renseigner ce champ.'),
    pays: yup.string().required('Merci de renseigner ce champ.'),
  })
  .required()

const ProfilAdmin = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [blockLoading, setBlockLoading] = useState(false)
  //const [removeLoading, setRemoveLoading] = useState(false)
  const [editing, setEditing] = useState(false)
 /*  const [openMenu, setOpenMenu] = useState(false) */
  const [snapShot, setSnapShot] = useState(null)
  const [editMode, setEditMode] = useState(false)
  //const [userType, setUserType] = useState(null)
 /*  const [isSuperAdmin, setIsSuperAdmin] = useState(false) */
  const [statusBlock, setStatusBlock] = useState(null)
  //const [role, setRole] = useState(null)
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
  } = useForm({ resolver: yupResolver(schema), mode: 'onChange' })

  const params = useParams()

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = await Api.user.detail(params.adminId)

        setStatusBlock(result.data.status)

        const handling = handlingErrors(result)
        if (handling.status === 401) {
          toast.error('Votre session à expiré, merci de vous reconnecter.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          })
          window.scrollTo(0, 0)
        }
        if (handling.ok) {
          const res = handling.data
         /*  const userRole = [
            'ROLE_SUPER_ADMIN',
            'ROLE_MINI_ADMIN',
            'ROLE_ADMIN',
          ].every((x) => res.roles.includes(x))
            ? 'ROLE_ADMIN'
            : res.roles.some((x) => x === 'ROLE_ADMIN')
            ? 'ROLE_ADMIN'
            : 'ROLE_CLIENT' */
          /* if (userRole === 'ROLE_CLIENT') {
            setUserType(res.type)
          } */

         /* if (res.roles.includes('ROLE_SUPER_ADMIN')) {
            setIsSuperAdmin(true)
          }  */

          //setRole(userRole)
          setSnapShot(res)
          reset(res)
          setLoading(false)
        } else {
          setLoading(false)
          /*setMsg(handling.message);
          setErr(true);
          setOpen(true);*/
          window.scrollTo(0, 0)
        }
      } catch (error) {
        setLoading(false)
        toast.error(
          "Quelque chose s'est mal passé, merci de réessayer plus tard !",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
        window.scrollTo(0, 0)
      }
    }

    /*if (id) fetchData();*/

    fetchData()
  }, [params.aminId, dispatch, reset, statusBlock])

  useEffect(() => {
    if (editing) {
      setEditMode(false)
    }
  }, [editing])

  /*const onSubmit = async (data) => {
    setEditing(true);
    let roles;
    if (isSuperAdmin && !snapShot.roles.includes("ROLE_SUPER_ADMIN")) {
      roles = [...snapShot.roles, "ROLE_SUPER_ADMIN"];
    }
    if (!isSuperAdmin && snapShot.roles.includes("ROLE_SUPER_ADMIN")) {
      roles = [...snapShot.roles.filter((x) => x !== "ROLE_SUPER_ADMIN")];
    }
    try {
      const result = await Api.user.edit(
        data.id,
        roles ? { ...data, roles } : data
      );
      const handling = handlingErrors(result);
      if (handling.status === 401) {
        setEditing(false);
        toast.error("Votre session à expiré, merci de vous reconnecter.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
        });
        window.scrollTo(0, 0);
        return;
      }
      if (handling.status === 0) {
        setEditing(false);
        toast.error("Quelque chose s'est mal passé, merci de réessayer plus tard !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
        });
        window.scrollTo(0, 0);
      }
      if (handling.ok) {
        const res = handling.data;
        setSnapShot(res);
        if (role === "ROLE_CLIENT") {
          setUserType(res.type);
        }
        setEditing(false);
        toast.success("Le profil à été modifié avec succès !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleCheck} fontSize={25} color={lightTheme.palette.success.main}/>
        });
        window.scrollTo(0, 0);
      }
    } catch (error) {
      setEditing(false);
      toast.error("Quelque chose s'est mal passé, merci de réessayer plus tard !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
      });
      window.scrollTo(0, 0);
    }
  };*/

  const onSubmit = async (values) => {
    const {
      admin,
      candidat,
      date,
      entreprise,
      lang,
      offre,
      parent,
      password,
      role,
      status,
      ...rest
    } = values
    setEditing(true)
    try {
      const result = await Api.user.edit(values.id, rest)
      const handling = handlingErrors(result)
      if (handling.status === 401) {
        setEditing(false)
        toast.error('Votre session à expiré ! Merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
      if (handling.status === 0) {
        setEditing(false)
        toast.error(
          "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
        window.scrollTo(0, 0)
      }
      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        setEditing(false)
        toast.success('Le profil à été modifié avec succès !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setEditing(false)
      toast.error(
        "Quelque chose s'est mal passé ! Merci de réessayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  const handleDelete = async () => {
    setLoading(true)
    try {
      let result = await Api.user.delete(params.adminId)

      const handling = handlingErrors(result)
      if (handling.status === 401) {
        // dispatch(userLogout());
        setLoading(false)
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
      if (handling.ok) {
        /*handleBack();*/

        toast.success('Le profil à été supprimé avec succès !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      } else {
        setLoading(false)
        /*setMsg(handling.message);
        setErr(true);
        setOpen(true);*/
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        "Quelque chose s'est mal passé, merci de réessayer plus tard !",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

 /*  const handleToggle = () => {
    setIsSuperAdmin(!isSuperAdmin)
  } */

  const handleBlock = async () => {
    setBlockLoading(true)
    try {
      let result = await Api.user.edit(params.adminId, {
        ...snapShot,
        status:
          snapShot.status === STATUS_BLOCKED ? STATUS_ACTIVE : STATUS_BLOCKED,
      })

      const handling = handlingErrors(result)
      if (handling.status === 401) {
        // dispatch(userLogout());
        setLoading(false)
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
      if (handling.ok) {
        setSnapShot(handling.data)
        setBlockLoading(false)
        //setOpenMenu(false)
        /*setMsg(
          `le profil a été ${
            handling.data.status === STATUS_ACTIVE ? `debloqué` : `bloqué`
          } avec succès`
        );
        setOpen(true);*/
        window.scrollTo(0, 0)
      } else {
        setLoading(false)
        /*setMsg(handling.message);
        setErr(true);
        setOpen(true);*/
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(
        "Quelque chose s'est mal passé, merci de réessayer plus tard !",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
      window.scrollTo(0, 0)
    }
  }

  /* const handleRemove = async () => {
    setRemoveLoading(true)
    try {
      let result = await Api.user.edit(params.adminId, {
        ...snapShot,
        status: STATUS_NOT_ACTIVE,
      })

      const handling = handlingErrors(result)
      if (handling.status === 401) {
        // dispatch(userLogout());
        setLoading(false)
        /*setMsg("Votre session a expiré merci de vous reconnecter.");
        setErr(true);
        setOpen(true);
        window.scrollTo(0, 0)
        return
      }
      if (handling.ok) {
        setRemoveLoading(false)
        setOpenMenu(false)
        /*setMsg(`le profil a été supprimé avec succès`);
        setOpen(true);
        window.scrollTo(0, 0)
      } else {
        setLoading(false)
        /*setMsg(handling.message);
        setErr(true);
        setOpen(true);
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      /*setMsg("Quelque chose c'est mal passé. Merci d'essayer plus tard");
      setErr(true);
      setOpen(true);
      window.scrollTo(0, 0)
    }
  } */

  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <>
      {/*<div style={{width: "100%"}}>*/}
      {!editMode || editing ? (
        <AdminHeading handleClickIcon={() => navigate(-1)}>
          Profil administrateur
        </AdminHeading>
      ) : (
        <AdminHeading>Modifier profil</AdminHeading>
      )}

      <Box
        className={'BoxContainer'}
        sx={{
          padding: '50px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: { xs: '30px', sm: '55px', lg: '70px' },
        }}
      >
        <form>
          <Grid container spacing={2} className={'gridContainer'}>
            <Grid item xs={12} sm={6} lg={6}>
              <InputContainer fullWidth>
                <FormLabel className={'labelForm'}>Prénom</FormLabel>
                <TextInputAdmin
                  placeholder={'Prénom'}
                  width={'100%'}
                  register={{ ...register('prenom') }}
                  resetValue={() => {
                    setValue('prenom', snapShot['prenom'])
                    clearErrors('prenom')
                  }}
                  editMode={editMode}
                />
              </InputContainer>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <InputContainer fullWidth>
                <FormLabel className={'labelForm'}>Nom</FormLabel>
                <TextInputAdmin
                  placeholder={'Nom'}
                  width={'100%'}
                  register={{ ...register('nom') }}
                  resetValue={() => {
                    setValue('nom', snapShot['nom'])
                    clearErrors('nom')
                  }}
                  editMode={editMode}
                />
              </InputContainer>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <InputContainer fullWidth>
                <FormLabel className={'labelForm'}>Mail</FormLabel>
                <TextInputAdmin
                  placeholder={'Mail'}
                  width={'100%'}
                  type={'email'}
                  register={{ ...register('email') }}
                  resetValue={() => {
                    setValue('email', snapShot['email'])
                    clearErrors('email')
                  }}
                  editMode={editMode}
                />
              </InputContainer>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <InputContainer fullWidth>
                <FormLabel className={'labelForm'}>
                  Numéro de téléphone
                </FormLabel>
                <TextInputAdmin
                  placeholder={'Numéro de téléphone'}
                  width={'100%'}
                  register={{ ...register('telephone') }}
                  resetValue={() => {
                    setValue('telephone', snapShot['telephone'])
                    clearErrors('telephone')
                  }}
                  editMode={editMode}
                />
              </InputContainer>
            </Grid>
            <Grid item xs={12} sm={6} lg={12}>
              <InputContainer fullWidth>
                <FormLabel className={'labelForm'}>
                  Adresse de l'entreprise
                </FormLabel>
                <TextInputAdmin
                  placeholder={"Adresse de l'entreprise"}
                  width={'100%'}
                  register={{ ...register('adresse') }}
                  resetValue={() => {
                    setValue('adresse', snapShot['adresse'])
                    clearErrors('adresse')
                  }}
                  editMode={editMode}
                />
              </InputContainer>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputContainer fullWidth>
                <FormLabel className={'labelForm'}>Ville</FormLabel>
                <TextInputAdmin
                  placeholder={'Ville'}
                  width={'100%'}
                  register={{ ...register('ville') }}
                  resetValue={() => {
                    setValue('ville', snapShot['ville'])
                    clearErrors('ville')
                  }}
                  editMode={editMode}
                />
              </InputContainer>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputContainer fullWidth>
                <FormLabel className={'labelForm'}>Code postal</FormLabel>
                <TextInputAdmin
                  placeholder={'Code postal'}
                  width={'100%'}
                  register={{ ...register('codePostal') }}
                  resetValue={() => {
                    setValue('codePostal', snapShot['codePostal'])
                    clearErrors('codePostal')
                  }}
                  editMode={editMode}
                />
              </InputContainer>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputContainer fullWidth>
                <FormLabel className={'labelForm'}>Pays</FormLabel>
                <TextInputAdmin
                  placeholder={'Pays'}
                  width={'100%'}
                  register={{ ...register('pays') }}
                  resetValue={() => {
                    setValue('pays', snapShot['pays'])
                    clearErrors('pays')
                  }}
                  editMode={editMode}
                />
              </InputContainer>
            </Grid>
          </Grid>
        </form>

        <Box className={'wrapperButtons'}>
          {!editMode ? (
            <BorderButtonPurple
              width={{ xs: '100%', sm: 240, lg: 300 }}
              loading={blockLoading}
              handleClick={handleBlock}
            >
              {/*{statusBlock && (
                <>*/}
              {snapShot.status === STATUS_ACTIVE
                ? `Bloquer cet administrateur`
                : `Débloquer cet administrateur`}
              {/*</>
              )}*/}
            </BorderButtonPurple>
          ) : (
            <BorderButtonPurple
              width={{ xs: '100%', sm: 240, lg: 300 }}
              handleClick={handleDelete}
            >
              Supprimer l'administrateur
            </BorderButtonPurple>
          )}

          <Box className={'blockButtons'}>
            {(!editMode || editing) && (
              <CustomButton
                displayIcon={'none'}
                width={{ xs: '50%', sm: 160, lg: 200 }}
                handleClick={() => setEditMode(true)}
              >
                Modifier
              </CustomButton>
            )}

            {editing ? (
              <CircularProgress />
            ) : editMode ? (
              <>
                <BorderButtonPurple
                  width={{ xs: '50%', sm: 160, lg: 200 }}
                  handleClick={(e) => {
                    e.preventDefault()
                    clearErrors()
                    reset(snapShot)
                    setEditMode(false)
                  }}
                >
                  Annuler
                </BorderButtonPurple>
                <CustomButton
                  displayIcon={'none'}
                  width={{ xs: '50%', sm: 160, lg: 200 }}
                  handleClick={handleSubmit(onSubmit)}
                >
                  Mettre à jour
                </CustomButton>
              </>
            ) : null}
          </Box>
        </Box>
      </Box>
      {/*</div>*/}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default ProfilAdmin
