import React from "react";
import { Routes, Route } from "react-router-dom";
import { AuthLayout } from "../components/layouts";
import InscriptionAtelier from "../pages/Public/InscriptionAtelier";

const InscriptionAtelierRouter = () => {
  return (
    <Routes>
      <Route element={<AuthLayout/>}>
        <Route index element={<InscriptionAtelier/>}/>
      </Route>
    </Routes>
  )
}

export default InscriptionAtelierRouter;