/*
let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: todayStr
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T12:00:00'
  }
]

export function createEventId() {
  return String(eventGuid++)
}*/

export const ViewsOptions = [
  { value: "dayGridMonth", label: "Par mois" },
  { value: "timeGridWeek", label: "Par semaine" },
  { value: "timeGridDay", label: "Par jour"},
];

export const hours = [
  { label: "8h00 - 8h30", value: "08:00:00" },
  { label: "8h30 - 9h00", value: "08:30:00" },
  { label: "9h00 - 9h30", value: "09:00:00" },
  { label: "9h30 - 10h00", value: "09:30:00" },
  { label: "10h00 - 10h30", value: "10:00:00" },
  { label: "10h30 - 11h00", value: "10:30:00" },
  { label: "11h00 - 11h30", value: "11:00:00" },
  { label: "11h30 - 12h00", value: "11:30:00" },
  { label: "12h00 - 12h30", value: "12:00:00" },
  { label: "12h30 - 13h00", value: "12:30:00" },
  { label: "13h00 - 13h30", value: "13:00:00" },
  { label: "13h30 - 14h00", value: "13:30:00" },
  { label: "14h00 - 14h30", value: "14:00:00" },
  { label: "14h30 - 15h00", value: "14:30:00" },
  { label: "15h00 - 15h30", value: "15:00:00" },
  { label: "15h30 - 16h00", value: "15:30:00" },
  { label: "16h00 - 16h30", value: "16:00:00" },
  { label: "16h30 - 17h00", value: "16:30:00" },
  { label: "17h00 - 17h30", value: "17:00:00" },
  { label: "17h30 - 18h00", value: "17:30:00" },
  { label: "18h00 - 18h30", value: "18:00:00" },
];
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function getDayName(date = new Date(), locale = "fr-FR") {
  return new Date(date).toLocaleDateString(locale, { weekday: "long" });
}
export default capitalizeFirstLetter;
