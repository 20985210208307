import { Routes, Route, Navigate } from 'react-router-dom'
import { UserLayout } from '../components/layouts'
import UserDashboard from '../pages/Dashboard/User'
import UserProfile from '../pages/Profile/UserProfile'
import MatchingCandidate from '../pages/Dashboard/Candidate/MatchingCandidate'
import Candidate from '../pages/Dashboard/Candidate/Candidate'
import Meeting from '../pages/Dashboard/Meeting'
import FavoriteCandidats from '../pages/Dashboard/Candidate/FavoriteCandidats'
import Besoins from '../pages/Dashboard/Company/Besoins'
import { useSelector } from 'react-redux'
import { ROLE_CANDIDAT, ROLE_ENTREPRISE } from '../data'

const UserRouter = () => {
  const currentUser = useSelector((state) => state.userAuth.user)

  const isCandidat = () => {
    return currentUser?.data?.role === ROLE_CANDIDAT
  }
  const isEntreprise = () => {
    return currentUser?.data?.role === ROLE_ENTREPRISE
  }
  
  return (
    <Routes>
      <Route
        element={
          isEntreprise() || isCandidat() ? (
            <UserLayout />
          ) : (
            <Navigate replace to={'/'} />
          )
        }
      >
        <Route index element={<UserDashboard />} />
        <Route path={'mon-profil'} element={<UserProfile />} />
        <Route
          path={'/candidat/matching/:besoinId'}
          element={
            isEntreprise() ? (
              <MatchingCandidate />
            ) : (
              <Navigate replace to={'/'} />
            )
          }
        />
        <Route
          path={'/candidat/favoris'}
          element={
            isEntreprise() ? (
              <FavoriteCandidats />
            ) : (
              <Navigate replace to={'/'} />
            )
          }
        />
        <Route path={'/event/:eventId'} element={<UserDashboard />} />
        <Route
          path={'/candidats'}
          element={
            isEntreprise() ? <Candidate /> : <Navigate replace to={'/'} />
          }
        />
        <Route
          path={'/annonces'}
          element={isCandidat() ? <Besoins /> : <Navigate replace to={'/'} />}
        />
        <Route path={'/meeting/:meetingId'} element={<Meeting />} />
      </Route>
    </Routes>
  )
}

export default UserRouter
