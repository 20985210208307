import React, { useEffect, useState } from 'react'
import { Box, Grid, styled } from '@mui/material'
import { AdminHeading } from '../../../../components/headings'
import { SearchInputBasic } from '../../../../components/inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FiltersModal } from '../../../../components/modals'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { toast } from 'react-toastify'
import lightTheme from '../../../../styles/theme/lightTheme'
import { useNavigate } from 'react-router-dom'
import AppPagination from '../../../../components/paginations'
import { debounce } from '../../../../config'
import ListTableAtelier from '../../../../components/tables/ListTableAtelier'

const WrapperButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const AteliersList = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [ateliers, setAteliers] = useState([])
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState(null)
  const [search, setSearch] = useState('')
  const handleClose = () => setOpen(false)
  const [page, setPage] = useState(
    sessionStorage.getItem('pageCandidat')
      ? parseInt(sessionStorage.getItem('pageCandidat'))
      : 1
  )
  const [count, setCount] = useState(0)

  const handleSearchChange = debounce((event) => {
    const { value } = event.target
    setSearch(value)
  }, 800)

  useEffect(() => {
    getAteliers(page)
  }, [id, search])

  const handlePageChange = (event, page) => {
    setPage(page)
    getAteliers(page)
    sessionStorage.setItem('pageCandidat', page)
  }

  const getAteliers = async (page) => {
    setLoading(true)
    let payload = {
      search: search,
    }

    try {
      const result = await Api.siteVitrine.getAteliers(
        page,
        payload
        /* orderSelect.filtre,
        orderSelect.type */
      )

      const res = handlingErrors(result)

      if (res.status === 401) {
        toast.error('Votre session à expiré, merci de vous reconnecter.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setAteliers(res.data['hydra:member'])
        setCount(res.data['hydra:totalItems'])
        /* const sort = res.data['hydra:member'].sort((a, b) =>
          a.date < b.date ? 1 : -1
        ) */
      }

      if (res.status === 0) {
        toast.error(
          "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          }
        )
      }
    } catch (error) {
      toast.error(
        "Quelque chose s'est mal passé ! Merci d'essayer plus tard.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        }
      )
    }
    setLoading(false)
  }

  const handleClickToProfil = (userId) => {
    navigate(`/admin/gestion-contenus/atelier/${userId}`)
  }

  /*if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );*/

  return (
    <>
      <div style={{ width: '100%' }}>
        {/*{!openCard ? (
          <>*/}
        <AdminHeading displayIconReturn={'none'}>
          LISTES DES Évènements
        </AdminHeading>

        <Box
          className={'BoxContainer'}
          sx={{
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          {/*<Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <SearchInputBasic
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                  }}
                />
              </Box>*/}

          <WrapperButton sx={{ columnGap: '15px' }}>
            <Grid item>
              <SearchInputBasic onChange={handleSearchChange} />
            </Grid>
            {/* <Grid item>
              <SelectInputObject
                items={filter}
                value={filterSelect}
                handleChange={handleFilterSelect}
              />
            </Grid> */}
            {/* <BlockButton>
              <BorderButtonPurple
                width={{ xs: 45, sm: 230, lg: 265 }}
                displayText={{ xs: 'none', sm: 'block' }}
                icon={<FontAwesomeIcon icon={faCirclePlus} fontSize={15} />}
                handleClick={() =>
                  navigate('/admin/gestion-client/ajouter-client-candidat')
                }
              >
                Ajouter un candidat
              </BorderButtonPurple>
            </BlockButton> */}
          </WrapperButton>
          <ListTableAtelier
            type={'atelier'}
            items={ateliers}
            setId={setId}
            loading={loading}
            handleClick={handleClickToProfil}
          />
          <AppPagination
            handlePageChange={handlePageChange}
            count={count}
            page={page}
          />
        </Box>
        {/*</>
        ) : (
          <AtelierProfil id={id} />
        )}*/}
      </div>

      <FiltersModal open={open} onClose={handleClose} />
    </>
  )
}

export default AteliersList
