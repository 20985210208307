export const days = [
  {
    text: 'Lundi',
  },
  {
    text: 'Mardi',
  },
  {
    text: 'Mercredi',
  },
  {
    text: 'Jeudi',
  },
  {
    text: 'Vendredi',
  },
]

export const horaires = {
  'fr': [
    {
      id: 0,
      text: 'indisponible',
      value: '',
    },
    {
      id: 1,
      text: '08:00',
      value: '08:00:00',
    },
    {
      id: 2,
      text: '08:30',
      value: '08:30:00',
    },
    {
      id: 3,
      text: '09:00',
      value: '09:00:00',
    },
    {
      id: 4,
      text: '09:30',
      value: '09:30:00',
    },
    {
      id: 5,
      text: '10:00',
      value: '10:00:00',
    },
    {
      id: 6,
      text: '10:30',
      value: '10:30:00',
    },
    {
      id: 7,
      text: '11:00',
      value: '11:00:00',
    },
    {
      id: 8,
      text: '11:30',
      value: '11:30:00',
    },
    {
      id: 9,
      text: '12:00',
      value: '12:00:00',
    },
    {
      id: 10,
      text: '12:30',
      value: '12:30:00',
    },
    {
      id: 11,
      text: '13:00',
      value: '13:00:00',
    },
    {
      id: 12,
      text: '13:30',
      value: '13:30:00',
    },
    {
      id: 13,
      text: '14:00',
      value: '14:00:00',
    },
    {
      id: 14,
      text: '14:30',
      value: '14:30:00',
    },
    {
      id: 15,
      text: '15:00',
      value: '15:00:00',
    },
    {
      id: 16,
      text: '15:30',
      value: '15:30:00',
    },
    {
      id: 17,
      text: '16:00',
      value: '16:00:00',
    },
    {
      id: 18,
      text: '16:30',
      value: '16:30:00',
    },
    {
      id: 19,
      text: '17:00',
      value: '17:00:00',
    },
    {
      id: 20,
      text: '17:30',
      value: '17:30:00',
    },
    {
      id: 21,
      text: '18:00',
      value: '18:00:00',
    },
    {
      id: 22,
      text: '18:30',
      value: '18:30:00',
    },
    {
      id: 23,
      text: '19:00',
      value: '19:00:00',
    },
    {
      id: 24,
      text: '19:30',
      value: '19:30:00',
    },
    {
      id: 25,
      text: '20:00',
      value: '20:00:00',
    },
    {
      id: 26,
      text: '20:30',
      value: '20:30:00',
    },
    {
      id: 27,
      text: '21:00',
      value: '21:00:00',
    },
  ],
  'en': [
    {
      id: 0,
      text: 'unavailable',
      value: '',
    },
    {
      id: 1,
      text: '08:00',
      value: '08:00:00',
    },
    {
      id: 2,
      text: '08:30',
      value: '08:30:00',
    },
    {
      id: 3,
      text: '09:00',
      value: '09:00:00',
    },
    {
      id: 4,
      text: '09:30',
      value: '09:30:00',
    },
    {
      id: 5,
      text: '10:00',
      value: '10:00:00',
    },
    {
      id: 6,
      text: '10:30',
      value: '10:30:00',
    },
    {
      id: 7,
      text: '11:00',
      value: '11:00:00',
    },
    {
      id: 8,
      text: '11:30',
      value: '11:30:00',
    },
    {
      id: 9,
      text: '12:00',
      value: '12:00:00',
    },
    {
      id: 10,
      text: '12:30',
      value: '12:30:00',
    },
    {
      id: 11,
      text: '13:00',
      value: '13:00:00',
    },
    {
      id: 12,
      text: '13:30',
      value: '13:30:00',
    },
    {
      id: 13,
      text: '14:00',
      value: '14:00:00',
    },
    {
      id: 14,
      text: '14:30',
      value: '14:30:00',
    },
    {
      id: 15,
      text: '15:00',
      value: '15:00:00',
    },
    {
      id: 16,
      text: '15:30',
      value: '15:30:00',
    },
    {
      id: 17,
      text: '16:00',
      value: '16:00:00',
    },
    {
      id: 18,
      text: '16:30',
      value: '16:30:00',
    },
    {
      id: 19,
      text: '17:00',
      value: '17:00:00',
    },
    {
      id: 20,
      text: '17:30',
      value: '17:30:00',
    },
    {
      id: 21,
      text: '18:00',
      value: '18:00:00',
    },
    {
      id: 22,
      text: '18:30',
      value: '18:30:00',
    },
    {
      id: 23,
      text: '19:00',
      value: '19:00:00',
    },
    {
      id: 24,
      text: '19:30',
      value: '19:30:00',
    },
    {
      id: 25,
      text: '20:00',
      value: '20:00:00',
    },
    {
      id: 26,
      text: '20:30',
      value: '20:30:00',
    },
    {
      id: 27,
      text: '21:00',
      value: '21:00:00',
    },
  ],
}

export const duree = [
  {
    id: 1,
    text: '0H10',
    value: 10,
  },
  {
    id: 2,
    text: '0h15',
    value: 15,
  },
  {
    id: 3,
    text: '0H20',
    value: 20,
  },
  {
    id: 4,
    text: '0H25',
    value: 25,
  },
  {
    id: 5,
    text: '0H30',
    value: 30,
  },
  {
    id: 6,
    text: '0H35',
    value: 35,
  },
  {
    id: 7,
    text: '0H40',
    value: 40,
  },
  {
    id: 8,
    text: '0H45',
    value: 45,
  },
  {
    id: 9,
    text: '0H50',
    value: 50,
  },
  {
    id: 10,
    text: '0H55',
    value: 55,
  },
  {
    id: 11,
    text: '1H00',
    value: 60,
  },
  {
    id: 12,
    text: '1H05',
    value: 65,
  },
  {
    id: 13,
    text: '1H10',
    value: 70,
  },
  {
    id: 14,
    text: '1H15',
    value: 75,
  },
  {
    id: 15,
    text: '1H20',
    value: 80,
  },
  {
    id: 16,
    text: '1H25',
    value: 85,
  },
  {
    id: 17,
    text: '1H30',
    value: 90,
  },
]

export const dureeEntre = [
  {
    id: 1,
    text: '0H05',
    value: 5,
  },
  {
    id: 2,
    text: '0H10',
    value: 10,
  },
  {
    id: 3,
    text: '0H15',
    value: 15,
  },
  {
    id: 4,
    text: '0H20',
    value: 20,
  },
  {
    id: 5,
    text: '0H25',
    value: 25,
  },
  {
    id: 6,
    text: '0H30',
    value: 30,
  },
  {
    id: 7,
    text: '0H35',
    value: 35,
  },
  {
    id: 8,
    text: '0H40',
    value: 40,
  },
  {
    id: 9,
    text: '0H45',
    value: 45,
  },
  {
    id: 10,
    text: '0H50',
    value: 50,
  },
  {
    id: 11,
    text: '0H55',
    value: 55,
  },
  {
    id: 12,
    text: '1H00',
    value: 60,
  },
]
