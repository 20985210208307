import React from 'react'
import { Box, styled } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'

const BannerLeft = styled(Box)(({ theme }) => ({
  height: 380,
  width: '120%',
  backgroundColor: lightTheme.palette.primary.b6,
  position: 'absolute',
  left: '-10%',
  top: 360,
  zIndex: -1,

  [theme.breakpoints.up('sx')]: {
    height: 405,
    width: '120%',
    top: 320,
    left: '-10%',
  },
  [theme.breakpoints.up('sm')]: {
    borderRadius: '0 125px 125px 0px',
    height: 420,
    width: '100%',
    top: 70,
    left: '-50%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '100%',
    height: 550,
    top: 108,
    left: '-50%',
  },
}))

export default function BannerBackgroundRight() {
  return <BannerLeft />
}
