import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Container, Typography } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons'
import { SectionsHeading } from '../../../../components/headings'

export default function Faq() {
  const { t, i18n } = useTranslation()

  return (
    <main>
      <Box
        width={'100%'}
        component={'section'}
        sx={{
          padding: { xs: '110px 0 130px 0', md: '100px 0 ', lg: '80px 0' },
        }}
      >
        <Box width={'100%'} position={'relative'}>
          <Container
            maxWidth={'lg'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '40px',
            }}
          >
            <SectionsHeading type={'animation'}>
              {t('faq.titre')}
            </SectionsHeading>
            {i18n.language === 'fr' ? (
              <div>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Qu'est-ce que JoberTech ?
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant={'p'}>
                      JoberTech accompagne les candidats de l’IT à trouver le
                      job idéal grâce à sa plateforme de matching avec des
                      entreprises qui recrutent dans l’IT avec une vision
                      adaptée aux attentes actuelles.
                    </Typography>
                    <br></br>
                    <br></br>
                    <Typography variant={'p'}>
                      JoberTech a une interface simple qui permet aux candidats
                      de l’informatique de trouver un emploi fixe que vous soyez
                      en poste ou non. C’est une plateforme sans cv et sans
                      photo avec des profils candidats anonymes pour éviter la
                      discrimination et les biais cognitifs. Chez JoberTech,
                      nous avons à cœur de donner une chance à tous nos
                      candidats d’avoir un premier contact.
                    </Typography>
                    <br></br>
                    <br></br>
                    <Typography variant={'p'}>
                      Les entreprises contactent les candidats, ces derniers ont
                      le choix d’accepter ou refuser selon l’entreprise et son
                      attractivité.
                    </Typography>
                    <br></br>
                    <br></br>
                    <Typography variant={'p'}>
                      L’entreprise aura l’opportunité de mettre en avant son
                      attractivité à l’écrit ou par vidéo pour attirer les
                      talents.
                    </Typography>
                    <br></br>
                    <br></br>
                    <Typography variant={'p'}>
                      Les interactions sont simplifiées grâce à des informations
                      objectives qui permettront aux deux parties de se parler
                      la première fois par visioconférence directement sur
                      Jobertech.
                    </Typography>
                    <br></br>
                    <br></br>
                    <Typography variant={'p'}>
                      Les candidats créent un compte et ont des propositions de
                      matchs. Ils peuvent aussi émettre un intérêt auprès des
                      opportunités en ligne.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Quelles compétences sont recherchées sur JoberTech ?
                  </AccordionSummary>
                  <AccordionDetails>
                    La plateforme JoberTech est ouvert à tous les talents de
                    l’IT y compris les profils métiers et de C-Level.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    Je suis un candidat résident à l’étranger, puis-je postuler
                    chez JoberTech ?
                  </AccordionSummary>
                  <AccordionDetails>
                    Si vous souhaitez venir vivre, travailler en Suisse et que
                    vous êtes citoyen européen, vous pouvez postuler chez
                    JoberTech.
                    <br></br>
                    <br></br>
                    Nous allons bientôt ouvrir à la France.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Est que l’inscription sur la plateforme JoberTech est-elle
                    gratuite ?
                  </AccordionSummary>
                  <AccordionDetails>Oui</AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Est-ce que je serai accompagné lorsque j’aurai un
                    entretien ? 
                  </AccordionSummary>
                  <AccordionDetails>
                    Tous les candidats sont préparés par nos Talent Developer
                    avant un entretien avec un recruteur gratuitement.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Est-ce que je peux m’inscrire gratuitement sans prendre un
                    pack de coaching ?
                  </AccordionSummary>
                  <AccordionDetails>
                    Oui et si besoin je pourrais demander conseils à nos Talent
                    Developer par la suite.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Est-ce que je serai accompagné lorsque j’aurai un
                    entretien ?
                  </AccordionSummary>
                  <AccordionDetails>
                    Tous les candidats sont préparés par nos Talent Developer
                    avant un entretien avec un recruteur gratuitement.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Si je me rends compte que j’ai besoin de plus qu’un job
                    coaching que se passe-t-il ?
                  </AccordionSummary>
                  <AccordionDetails>
                    JoberTech vous propose un panel de services de développement
                    personnel pour avancer dans le bon sens, s’aligner
                    personnellement et professionnellement. Cette approche
                    permet aux candidats de débloquer des situations et
                    d’accéder au job idéal lié à ses aspirations.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Je suis en poste et je ne souhaite pas que mon employeur le
                    sache.
                  </AccordionSummary>
                  <AccordionDetails>
                    Nous avons une démarche confidentielle et hermétique entre
                    les candidats et les entreprises. De plus, les profils
                    inscrits ne sont pas visibles par les entreprises jusqu’au
                    moment où vous aurez accepté la demande de rendez-vous par
                    visioconférence. Vous saurez quelle entreprise vous contact
                    puisque vous aurez sa présentation.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Je me suis inscrit sur la plateforme mais je ne suis plus en
                    recherche.
                  </AccordionSummary>
                  <AccordionDetails>
                    Il vous suffit de cocher que vous n’êtes plus disponible
                    dans votre espace.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Comment faire pour supprimer mon compte ?
                  </AccordionSummary>
                  <AccordionDetails>
                    Si vous souhaitez supprimer votre compte, il vous suffit de
                    nous en faire part à l’adresse suivante :
                    <a href="mailto:contact@jobertech.com">
                      contact@jobertech.com
                    </a>
                    .
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Je n’ai pas trouvé l’intitulé de poste dans le menu
                    déroulant ?
                  </AccordionSummary>
                  <AccordionDetails>
                    Si vous ne trouvez pas l’intitulé de poste dans le menu
                    déroulant, il vous suffit de nous en faire part à l’adresse
                    suivante:
                    <a href="mailto:contact@jobertech.com">
                      contact@jobertech.com
                    </a>
                    .
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Comment ajouter des compétences afin qu’elles puissent
                    apparaître sur mon profil ?
                  </AccordionSummary>
                  <AccordionDetails>
                    Pour ajouter des compétences, il faut saisir la ou les
                    compétences et ensuite cliquer sur la touche Entrée (Enter
                    ou return).
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    En quoi consiste l’accès au réseau JoberTech dans le pack
                    candidat ?
                  </AccordionSummary>
                  <AccordionDetails>
                    Il consiste à avoir des conseils personnalisés concernant
                    mon profil candidats de la part du marché (managers
                    IT/expert IT).
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    En tant qu’entreprise, j’aimerai recruter mais je n’attire
                    pas les bons candidats malgré tous mes efforts ?
                  </AccordionSummary>
                  <AccordionDetails>
                    Nous avons une offre de services entreprises qui pourra vous
                    permettre de travailler sur les sujets de branding, culture
                    entreprise, sur vos valeurs, votre attractivité et la
                    gestion du changement. Nous avons un panel d’experts pour
                    les grandes entreprises ou les PME.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    J’ai posté une annonce pour recruter sur d’autres
                    plateformes mais cela ne donne rien.
                  </AccordionSummary>
                  <AccordionDetails>
                    Sur la plateforme JoberTech, vous avez la possibilité de
                    prendre un abonnement sans engagement pour trouver votre
                    futur collaborateur et poster une annonce en ligne qui sera
                    vue et proposée aux candidats pertinents. Vous aurez la
                    possibilité d’attirer des talents de manière ciblée sur
                    votre zone géographique et dans le domaine IT.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Quels types d’entreprises sont représentées sur JoberTech ?
                  </AccordionSummary>
                  <AccordionDetails>
                    Nous avons des clients de toute taille et de différents
                    domaines d’activités. L’objectif est de trouver
                    l’environnement et le poste idéal à tous nos candidats.
                    Chaque candidat et chaque entreprise peut trouver chaussure
                    à son pied.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Comment fonctionne JoberTech lorsque je suis une entreprise
                    ?
                  </AccordionSummary>
                  <AccordionDetails>
                    Je peux prendre un rendez-vous pour avoir des informations
                    directement via le site. <br></br>
                    <br></br>
                    Ou alors je peux prendre l’abonnement sans engagement qui me
                    correspond le plus afin de bénéficier des outils et
                    fonctionnalités en place sur la plateforme.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Quelle est la différence de JoberTech par rapport à d’autres
                    approches ?
                  </AccordionSummary>
                  <AccordionDetails>
                    Nous sommes experts de l'IT et parlons le même langage.
                    JoberTech est une plateforme qui matche les besoins des
                    entreprises et des candidats sur la base de plusieurs
                    critères et non uniquement que sur la base des compétences
                    techniques. Les matchs se font sur la base des compétences
                    techniques, des softskills, des valeurs et de l’état
                    d’esprit grâce à JoberMind.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Est-ce que tout est automatisé sur la plateforme JoberTech ?
                  </AccordionSummary>
                  <AccordionDetails>
                    Chaque interlocuteur a un premier contact avec une personne
                    de l’équipe JoberTech.
                    <br></br>
                    <br></br>
                    Certains processus sont automatisés pour vous laisser plus
                    d’autonomie et de liberté.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    Qu’est-ce que je paye lorsque j’ai trouvé mon candidat ?
                  </AccordionSummary>
                  <AccordionDetails>
                    Je paye au succès selon l’option choisie et l’autonomie
                    souhaitée sur la base de 2 options.
                    <br></br>
                    <br></br>
                    JoberTech souhaite rendre accessible à toutes les
                    entreprises le recrutement informatique qualitatif et
                    durable.
                  </AccordionDetails>
                </Accordion>
              </div>
            ) : (
              <div>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    What is JoberTech?
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant={'p'}>
                      JoberTech helps IT candidates to find the ideal job thanks
                      to its matching platform with companies recruiting in the
                      IT sector with a vision adapted to today's expectations.
                    </Typography>
                    <br></br>
                    <br></br>
                    <Typography variant={'p'}>
                      JoberTech has a simple interface that allows IT candidates
                      to find a permanent job whether they are currently
                      employed or not. It is a CV and photo free platform with
                      anonymous candidate profiles to avoid discrimination and
                      cognitive bias. At JoberTech, we're committed to giving
                      all our candidates a chance to make a first contact.
                    </Typography>
                    <br></br>
                    <br></br>
                    <Typography variant={'p'}>
                      Companies contact candidates who have the option of
                      acceptance or rejection depending on the company and its
                      attractiveness.
                    </Typography>
                    <br></br>
                    <br></br>
                    <Typography variant={'p'}>
                      The company will have the opportunity to highlight its
                      attractiveness in writing or by video in order to attract
                      talent.
                    </Typography>
                    <br></br>
                    <br></br>
                    <Typography variant={'p'}>
                      Interactions are simplified thanks to objective
                      information that allows both parties to talk for the first
                      time via videoconference directly on Jobertech.
                    </Typography>
                    <br></br>
                    <br></br>
                    <Typography variant={'p'}>
                      Candidates create an account and receive match proposals.
                      They can also register their interest in online
                      opportunities.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    What skills are we looking for on JoberTech?
                  </AccordionSummary>
                  <AccordionDetails>
                    The JoberTech platform is open to all IT talent, including
                    business and C-Level profiles.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    I am a candidate living abroad. Can I apply to JoberTech?
                  </AccordionSummary>
                  <AccordionDetails>
                    If you want to live and work in Switzerland and you are a
                    European citizen, you can apply to JoberTech.
                    <br></br>
                    <br></br>
                    We will soon be opening to France.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Is registration on the JoberTech platform free?
                  </AccordionSummary>
                  <AccordionDetails>Yes</AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Will I be accompanied when I have an interview? 
                  </AccordionSummary>
                  <AccordionDetails>
                    All candidates are prepared by our Talent Developers before
                    an interview with a recruiter, free of charge.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Can I register for free without taking a coaching package?
                  </AccordionSummary>
                  <AccordionDetails>
                    Yes, and if necessary, I can ask our Talent Developers for
                    advice afterwards.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Yes, and if necessary, I can ask our Talent Developers for
                    advice afterwards.
                  </AccordionSummary>
                  <AccordionDetails>
                    JoberTech offers you a range of personal development
                    services to help you move in the right direction and align
                    yourself personally and professionally. This approach
                    enables candidates to unblock situations and find the ideal
                    job that matches their aspirations.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    I am in a job and I don't want my employer to be informed.
                  </AccordionSummary>
                  <AccordionDetails>
                    Our approach is confidential and hermetic between candidates
                    and companies. What's more, registered profiles are not
                    visible to companies until you accept the request for a
                    videoconference meeting. You will know which company is
                    contacting you because you'll get their presentation.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    I have registered on the platform but I am no longer
                    searching.
                  </AccordionSummary>
                  <AccordionDetails></AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Simply check that you are no longer available in your space.
                  </AccordionSummary>
                  <AccordionDetails></AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    How do I delete my account?
                  </AccordionSummary>
                  <AccordionDetails>
                    If you wish to delete your account, simply let us know at
                    the following address:
                    <a href="mailto:contact@jobertech.com">
                      contact@jobertech.com
                    </a>
                    .
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    I can't find the job title in the drop-down menu?
                  </AccordionSummary>
                  <AccordionDetails>
                    If you can't find your job title in the drop-down menu,
                    please let us know at
                    <a href="mailto:contact@jobertech.com">
                      contact@jobertech.com
                    </a>
                    .
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    How do I add technical skills to appear on my profile?
                  </AccordionSummary>
                  <AccordionDetails>
                    To add skills, type in the skill(s) and then press Enter or
                    return.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    In the Candidate Package, what does access to the JoberTech
                    network consist of?
                  </AccordionSummary>
                  <AccordionDetails>
                    It consists of personalised advice on my candidate profile
                    from the market (IT managers/IT experts).
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    As a company, I would like to recruit, but in spite of my
                    best efforts, I am not able to attract the right candidates?
                  </AccordionSummary>
                  <AccordionDetails>
                    We have a range of corporate services that can help you work
                    on branding, corporate culture, your values, your
                    attractiveness and change management. We have a panel of
                    experts for both large companies and SMEs.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    I have posted a recruitment advert on other platforms, but
                    it is come to nothing.
                  </AccordionSummary>
                  <AccordionDetails>
                    On the JoberTech platform, you can take out a no-obligation
                    subscription to find your future employee and place an
                    online advertisement that will be seen and offered to
                    relevant candidates. You'll be able to attract talent in
                    your geographical area and in the IT sector.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    What types of companies are represented on JoberTech?
                  </AccordionSummary>
                  <AccordionDetails>
                    We have clients of all sizes and from a wide range of
                    business sectors. Our aim is to find the ideal environment
                    and position for all our candidates. Every candidate and
                    every company can find what they are looking for.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    How does JoberTech work if I am a company?
                  </AccordionSummary>
                  <AccordionDetails>
                    I can make an appointment to receive information directly
                    from the website.
                    <br></br>
                    <br></br>
                    Or I can sign up for a no-obligation subscription that suits
                    me best to benefit from the tools and features available on
                    the platform.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    What makes JoberTech different from other approaches?
                  </AccordionSummary>
                  <AccordionDetails>
                    We are IT experts and we speak the same language. JoberTech
                    is a platform that matches the needs of companies and
                    candidates on the basis of several criteria, not just
                    technical skills. Matches are made on the basis of technical
                    skills, softskills, values and state of mind thanks to
                    JoberMind.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Is everything automated on the JoberTech platform?
                  </AccordionSummary>
                  <AccordionDetails>
                    No. Everyone who contacts us has an initial contact with a
                    member of the JoberTech team.
                    <br></br>
                    <br></br>
                    Some processes are automated to give you more autonomy and
                    freedom.
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    sx={{ fontWeight: 'bold' }}
                    expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    What do I pay once I have found my candidate?
                  </AccordionSummary>
                  <AccordionDetails>
                    I pay for success according to the option chosen and the
                    degree of autonomy required, based on 2 options.
                    <br></br>
                    <br></br>
                    JoberTech wants to make it accessible to all.{' '}
                  </AccordionDetails>
                </Accordion>
              </div>
            )}
          </Container>
        </Box>
      </Box>
    </main>
  )
}
