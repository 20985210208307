import React, { useEffect, useState } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import Api from '../../../../../services/api.service'
import { handlingErrors } from '../../../../../utils'
import { CustomButton } from '../../../../../components/buttons'
import { useNavigate } from 'react-router-dom'
import { Markup } from 'interweave'
import { useSelector } from 'react-redux'

export default function OffresSection() {
  const { t, i18n } = useTranslation()
  const [offers, setOffers] = useState([])
  const [loading, setLoading] = useState(true)
  const [periode, setPeriode] = useState('3')
  const navigate = useNavigate()
  const user = useSelector((state) => state.userAuth.user)
  if (sessionStorage.getItem('offerSelected'))
    sessionStorage.removeItem('offerSelected')
  if (sessionStorage.getItem('periodeSelected'))
    sessionStorage.removeItem('periodeSelected')

  useEffect(() => {
    getOffer(i18n.language)
  }, [i18n.language])

  const getOffer = async (lang) => {
    setLoading(true)

    try {
      const result = await Api.siteVitrine.getOffersEntreprise({
        visible: true,
        codePays: localStorage.getItem('codePays'),
      })
      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }

      if (res.ok) {
        /*  let offres = [{ id: 0, titre: t('button.shooseOffer') }].concat(
          res.data
        ) */
        setOffers(res.data)
        setLoading(false)
        /* setOffreSelect(offres[0]) */
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (event, nvPeriode) => {
    if (nvPeriode) setPeriode(nvPeriode)
  }

  if (loading) {
    return (
      <Container maxWidth={'lg'}>
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      </Container>
    )
  }

  return (
    <Container maxWidth={'lg'}>
      <Grid container mt={2} mb={2} justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h3" align="center" p={2}>
            {t('button.period')}
          </Typography>
        </Grid>
        <ToggleButtonGroup
          color="primary"
          value={periode}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value="3">
            <b>3 {t('box.mounth')}</b>
          </ToggleButton>
          <ToggleButton value="6">
            <b>6 {t('box.mounth')}</b>
          </ToggleButton>
          <ToggleButton value="12">
            <b>12 {t('box.mounth')}</b>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid justifyContent={'center'} container spacing={2}>
        {offers.map((offre, index) => (
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Card
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <CardContent style={{ flex: 1 }}>
                <Grid item xs={12}>
                  <Typography
                    variant="h3"
                    align="center"
                    gutterBottom
                    textTransform={'bold'}
                    sx={{
                      backgroundColor: offre.couleur,
                      borderBottom: '1px solid #eee',
                    }}
                    p={2}
                  >
                    {i18n.language === 'fr' ? offre.titre : offre.titreEn}
                  </Typography>
                  <Typography variant="h5" align="center" gutterBottom>
                    {offre.status || offre.offreEssai
                      ? (
                          offre[`prix${periode}`] *
                          JSON.parse(sessionStorage.getItem('currency'))
                            .currentRate
                        ).toFixed(2)
                      : t('box.onDemand')}{' '}
                    {!offre.status && !offre.offreEssai
                      ? ''
                      : sessionStorage.getItem('currency')
                      ? JSON.parse(sessionStorage.getItem('currency')).devise
                      : 'CHF'}
                  </Typography>
                  <Typography variant="subtitle1" align="center" gutterBottom>
                    {offre.status
                      ? `${periode} ${t('box.mounth')}`
                      : offre.offreEssai
                      ? `15 ${t('box.days')}`
                      : ''}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {offre.permissions
                    .sort((a, b) => a.id - b.id)
                    .map((permission, index) => (
                      <>
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={true}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': 1 }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={1}
                              primary={
                                i18n.language === 'fr'
                                  ? permission.nom
                                  : permission.nomEn
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      </>
                    ))}
                </Grid>
              </CardContent>
              <CardActions style={{ marginTop: 'auto' }}>
                <Grid justifyContent={'center'} container spacing={2}>
                  <Grid item xs={12}>
                    <Markup
                      content={
                        i18n.language === 'fr'
                          ? offre.description
                          : offre.descriptionEn
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {offre.status || offre.id === 1 ? (
                      <CustomButton
                        handleClick={() => {
                          if (offre.id !== 1) {
                            sessionStorage.setItem(
                              'offerSelected',
                              JSON.stringify(offre)
                            )
                            sessionStorage.setItem('periodeSelected', periode)
                            user
                              ? navigate('/entreprises/offre/paiement')
                              : navigate('/auth/inscription/entreprise')
                          } else {
                            navigate('/auth/inscription/entreprise')
                          }
                        }}
                        displayIcon={'none'}
                      >
                        {t('button.startNow')}
                      </CustomButton>
                    ) : (
                      <CustomButton
                        handleClick={() => {
                          navigate('/auth/inscription/entreprise')
                        }}
                        displayIcon={'none'}
                      >
                        {t('button.contactUs')}
                      </CustomButton>
                    )}
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}
