import React from "react";
import { FormControl, styled } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import PropTypes from "prop-types";


const CustomInput = styled('input')(({theme, width}) => ({
  height: 45,
  width: "100%",
  backgroundColor: "#FFFFF",
  borderRadius: "5px",
  border: `1px solid ${lightTheme.palette.grey.main}`,
  fontFamily: "Century Gothic",
  fontSize: 14,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.text.primary,
  padding: "10px 15px",

  "&::placeholder": {
    fontFamily: "Century Gothic",
    fontSize: 14,
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: lightTheme.palette.text.primary,
  },

  "&:active, &:focus": {
    outline: "none"
  },

  [lightTheme.breakpoints.up("md")]: {
    fontSize: 12,
  },

  [lightTheme.breakpoints.up("lg")]: {
    fontSize: 14,
  },

}));

const TextInputClassic = ({placeholder, width, register, type, onChange, onKeyDown, value}) => {

  return (
    <FormControl>
      <CustomInput
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        width={width}
        type={type ? type : "text"}
        autoComplete={"off"}
        required
        value={value}
        {...register}
      />
    </FormControl>
  )
}

export default TextInputClassic;

TextInputClassic.propTypes = {
  placeholder: PropTypes.string,
  register: PropTypes.func.isRequired,
};
