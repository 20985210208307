import React, { useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Grid,
  Modal,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { useDispatch } from 'react-redux'
import { userLogout } from '../../store/slices/userAuthReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClose,
  faSquarePlus,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { niveauCompetencesList } from '../../data'
import { TextInputClassic } from '../inputs'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const EditList = ({
  open,
  onClose,
  onSave,
  values,
  datas,
  id,
  role = 'candidat',
  type,
}) => {
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [dataValue, setDataValue] = useState(values)
  const [title, setTitle] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    switch (type) {
      case 'competences':
        setTitle(t('filter.competences') + ' (max 6)')
        break
      case 'competencesAnnexes':
        setTitle(t('filter.competencesAnnexes'))
        break
      case 'valeurs':
        setTitle(t('filter.values'))
        break
      case 'softskils':
        setTitle(t('filter.softskils'))
        break
      default:
        break
    }
    if (
      type !== 'competences' &&
      type !== 'competencesAnnexes' &&
      dataValue.length < 6
    ) {
      setError(true)
    } else {
      setError(false)
    }
  }, [type])
  const onSubmit = async () => {
    /* console.log(competences)
    return */
    setError(false)
    if (
      type !== 'competences' &&
      type !== 'competencesAnnexes' &&
      dataValue.length < 6
    ) {
      setError(true)
      return
    }
    setLoading(true)
    try {
      const result = await Api.candidate.edit(id, {
        [type]:
          type === 'competences' || type === 'competencesAnnexes'
            ? competences
            : dataValue,
      })
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        dispatch(userLogout())
        return
      }

      if (handling.ok) {
        //const res = handling.data
        setLoading(false)
        onSave()
      } else {
        console.log('erreur')
        setLoading(false)
      }
    } catch (error) {
      console.log('erreur submit !')
    }
  }

  const onSubmitCompany = async () => {
    setLoading(true)
    try {
      const result = await Api.company.edit(id, { [type]: dataValue })
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        dispatch(userLogout())
        return
      }

      if (handling.ok) {
        //const res = handling.data
        setLoading(false)
        onSave()
      } else {
        console.log('erreur')
        setLoading(false)
      }
    } catch (error) {
      console.log('erreur submit !')
    }
  }

  const [competences, setCompetences] = useState(
    type === 'competences' || type === 'competencesAnnexes' ? values : []
  )
  const [refresh, setRefresh] = useState(1)
  const handleAddCompetence = () => {
    competences.push({ competence: '', niveau: niveauCompetencesList[0].id })
    setCompetences(competences)
    setRefresh(Math.random())
  }

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {t('box.title.editList')} {title}
          <Box
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
          </Box>
        </Typography>
        <BoxContainer>
          {type === 'competences' || type === 'competencesAnnexes' ? (
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                maxHeight: 450,
                overflow: 'auto',
                flexDirection: {
                  xs: 'row',
                },
              }}
            >
              {competences.map((cmpt, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      padding: 1,
                    }}
                  >
                    <TextInputClassic
                      type={'text'}
                      onChange={(event) => {
                        competences[index].competence = event.target.value
                        setCompetences(competences)
                        setRefresh(Math.random())
                      }}
                      value={competences[index].competence}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    sx={{
                      padding: 1,
                    }}
                  >
                    <Autocomplete
                      disableClearable
                      value={niveauCompetencesList[cmpt.niveau - 1]}
                      sx={{ width: '100%', height: '45px' }}
                      onChange={(event, newValue) => {
                        competences[index].niveau = newValue.id
                        setCompetences(competences)
                        setRefresh(Math.random())
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.titre === value.titre
                      }
                      getOptionLabel={(option) => option.titre[i18n.language]}
                      options={niveauCompetencesList}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ backgroundColor: '#fff' }}
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={1}
                    sx={{
                      padding: 1,
                      alignContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      competences.splice(index, 1)
                      setCompetences(competences)
                      setRefresh(Math.random())
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      fontSize={20}
                      style={{ marginTop: '10px' }}
                    />
                  </Grid>
                </Grid>
              ))}

              {competences.length < 6 && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    width={'100%'}
                    border={'1px dotted #666'}
                    padding={'10px !important'}
                    borderRadius={1}
                    sx={{ cursor: 'pointer' }}
                    onClick={handleAddCompetence}
                  >
                    <FontAwesomeIcon
                      icon={faSquarePlus}
                      fontSize={20}
                      color={'#666'}
                      style={{ margin: '0 5px -2px 0' }}
                    />
                    {t('box.addOtherCompetence')}
                  </Box>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
              >
                {type === 'competences' ? (
                  <Autocomplete
                    sx={{ width: '100%', backgroundColor: '#fff' }}
                    multiple
                    defaultValue={dataValue}
                    options={datas}
                    onChange={(event, newValue) => {
                      setDataValue(newValue.slice(0, 6))
                    }}
                    freeSolo={
                      type === 'competences' || type === 'competencesAnnexes'
                        ? true
                        : false
                    }
                    renderTags={(dataValue, getTagProps) =>
                      dataValue
                        .slice(0, 6)
                        .map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('filter.competencesTextCandidat')}
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    sx={{ width: '100%', backgroundColor: '#fff' }}
                    multiple
                    defaultValue={dataValue}
                    options={datas}
                    onChange={(event, newValue) => {
                      if (
                        type !== 'competencesAnnexes' &&
                        newValue.length < 6
                      ) {
                        setError(true)
                      } else {
                        setError(false)
                      }
                      setDataValue(newValue)
                    }}
                    freeSolo={
                      type === 'competences' || type === 'competencesAnnexes'
                        ? true
                        : false
                    }
                    renderTags={(dataValue, getTagProps) =>
                      dataValue.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={
                          type === 'competences' ||
                          type === 'competencesAnnexes'
                            ? t('filter.competencesTextCandidat')
                            : t('box.addthe') + title
                        }
                      />
                    )}
                  />
                )}
                {error && (
                  <Typography className={'error-message'}>
                    {type === 'competencesAnnexes'
                      ? t('errorMessage.listCompAnn')
                      : type === 'softskils'
                      ? t('errorMessage.listCompSoftskills')
                      : t('errorMessage.list')}
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
        </BoxContainer>
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.cancel')}
          </BorderButtonPurple>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              handleClick={role === 'entreprise' ? onSubmitCompany : onSubmit}
            >
              {t('button.save')}
            </CustomButton>
          )}
        </Box>
      </ModalContainer>
    </Modal>
  )
}

export default EditList
