import React from "react";
import {Box, styled} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";

const CustomCircle = styled(Box)(({theme, top, bottom, left, right, display}) => ({
    width: 200,
    height: 200,
    borderRadius: "100%",
    background: lightTheme.palette.gradients.gradientGreen,
    transform: "rotate(-180deg)",
    position: "fixed",
    top: top,
    left: left,
    right: right,
    bottom: bottom,
    display: display,

    [theme.breakpoints.up("sm")]: {
        width: 365,
        height: 365,
    },

    [theme.breakpoints.up("lg")]: {

    },
}));

export default function CircleLayout({top, left, right, bottom, display}) {
    return (
        <CustomCircle top={top} left={left} right={right} bottom={bottom} display={display}/>
    )
}