import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
  styled,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { Markup } from 'interweave'
import lightTheme from '../../../../styles/theme/lightTheme'
import { extractYouTubeVideoId } from '../../../../config'
import logoArticle from '../../../../assets/images/logos/logo-sign-white.svg'
import logoFooter from '../../../../assets/images/logos/logo-article.svg'
import { useParams } from 'react-router-dom'
import { HeaderCard } from '../../../../components/cards'
import moment from 'moment-timezone'
import 'moment/locale/fr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faLinkedin,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons'
import { faLink } from '@fortawesome/pro-solid-svg-icons'

import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share'
import copy from 'clipboard-copy'

const GridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',
}))

const ColorBox = styled(Box)(({ theme, type }) => ({
  position: 'absolute',
  backgroundColor:
    type === 'article' ? '#F76E11' : type === 'video' ? '#BF4FCE' : '#96CEB4',
  width: '71%',
  height: '71%',
  top: '20%',
  left: '10%',
  zIndex: '-1',
  borderRadius: '50%',
}))
const ImageBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: 60,
  height: 60,
  bottom: 0,
  left: '50%',
  transform: 'translate(-30px , 30px)',
  zIndex: 1,

  [theme.breakpoints.up('sm')]: {
    height: 60,
    width: 60,
    transform: 'translate(-30px , 30px)',
  },
  [theme.breakpoints.up('lg')]: {
    height: 100,
    width: 100,
    transform: 'translate(-50px , 50px)',
  },
}))
const ColorBoxFooter = styled(Box)(({ theme, type }) => ({
  position: 'absolute',
  backgroundColor: '#FFF',
  width: '71%',
  height: '71%',
  top: '20%',
  left: '10%',
  zIndex: '-1',
  borderRadius: '50%',
}))
const ImageBoxFooter = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: 100,
  height: 100,
  top: '-133px',
  left: '50%',
  transform: 'translate(-50px , 70px)',
  zIndex: 1,

  [theme.breakpoints.up('sm')]: {
    height: 100,
    width: 100,
    transform: 'translate(-50px , 70px)',
  },
  [theme.breakpoints.up('lg')]: {
    height: 150,
    width: 150,
    transform: 'translate(-75px , 75px)',
  },
}))

export default function ArticleDetail() {
  const { t, i18n } = useTranslation()
  const [article, setArticle] = useState([])
  const [loading, setLoading] = useState(false)
  moment.locale(i18n.language)
  const params = useParams()

  useEffect(() => {
    if (params.articleId) {
      getArticle(params.articleId)
    }
  }, [params.articleId])

  const getArticle = async (articleId) => {
    setLoading(true)

    try {
      const result = await Api.siteVitrine.getArticle(articleId)

      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }

      if (res.ok) {
        setArticle(res.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCopyClick = async () => {
    try {
      await copy(window.location.href)
      alert(t('successMessage.copy'))
    } catch (err) {
      console.log('Failed to copy text: ', err)
    }
  }

  if (loading)
    return (
      <main style={{ marginTop: '80px' }}>
        <CircularProgress />
      </main>
    )

  return (
    <main>
      <Container
        maxWidth={'lg'}
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '50px',
          width: { xs: '100vw' },
        }}
      >
        <HeaderCard
          key={1}
          title={''}
          alt={article.titre}
          img={article.banniere}
          isArticle={true}
        />
        <ImageBox>
          <img src={logoArticle} alt={article.type} />
          <ColorBox type={article.type} />
        </ImageBox>
      </Container>
      <Box
        width={'100%'}
        component={'section'}
        sx={{
          padding: '0 0 130px 0',
        }}
      >
        <Box width={'100%'} position={'relative'}>
          <Container
            maxWidth={'lg'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid container>
              <GridItem item xs={12} sm={12} lg={12}>
                <Typography
                  component={'h1'}
                  sx={{
                    textAlign: 'center',
                    fontSize: { sm: 16, lg: 24 },
                    fontFamily: 'Century Gothic Bold',
                    color: lightTheme.palette.text.primary,
                    lineHeight: '140%',
                  }}
                >
                  {/* {article.id} --  */}
                  {article.titre}
                </Typography>
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontFamily: 'Century Gothic',
                    fontSize: { sm: 10, lg: 12 },
                    color: lightTheme.palette.text.primary,
                    lineHeight: '100%',
                    textTransform: 'capitalize',
                  }}
                >
                  {/* {article.id} --  */}
                  {article.type} -{' '}
                  {moment(article.datePublication)
                    .tz('Europe/Paris')
                    .format('DD MMMM YYYY')}
                </Typography>

                <Typography
                  sx={{
                    textAlign: 'justify',
                    fontWeight: 'bold',
                    fontSize: { sm: 12, lg: 14 },
                    marginTop: 3,
                  }}
                >
                  <Markup content={article.descriptif} />
                </Typography>
                <hr
                  style={{
                    width: '100%',
                    border: '1px solid #F76E11',
                    borderColor:
                      article.type === 'article'
                        ? '#F76E11'
                        : article.type === 'video'
                        ? '#BF4FCE'
                        : '#96CEB4',
                  }}
                />

                <Typography>
                  <Markup content={article.description} />
                  {article.type === 'video' && article.lien && (
                    <Box
                      width={'100%'}
                      sx={{
                        height: { xs: '260px', sm: '450px', lg: '600px' },
                      }}
                    >
                      <iframe
                        width="100%"
                        height="100%"
                        src={
                          'https://www.youtube.com/embed/' +
                          extractYouTubeVideoId(article.lien)
                        }
                        title={article.titre}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </Box>
                  )}
                </Typography>
              </GridItem>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Box
        width={'100%'}
        minHeight={'150px'}
        padding={'30px 0'}
        backgroundColor={'#EEEEEE'}
        position={'relative'}
      >
        <ImageBoxFooter>
          <img alt="Jobertech" src={logoFooter} width={'100%'} />

          <ColorBoxFooter type={article.type} />
        </ImageBoxFooter>
        <Box width={'100%'} component={'section'} position={'relative'}>
          <Container
            maxWidth={'lg'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Grid container>
              <GridItem
                item
                xs={12}
                sm={6}
                lg={6}
                textTransform={'uppercase'}
                sx={{
                  fontFamily: 'Century Gothic Bold',
                  margin: '20px 0',
                  textAlign: { xs: 'center', lg: 'left' },
                }}
              >
                {t('footer.articleFooter1')} <br></br>
                {t('footer.articleFooter2')}
              </GridItem>
              <GridItem
                item
                xs={12}
                sm={6}
                lg={6}
                textAlign={'center'}
                sx={{
                  margin: '20px 0',
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent={{ xs: 'center', lg: 'flex-end' }}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={2}>
                    <FontAwesomeIcon
                      onClick={handleCopyClick}
                      icon={faLink}
                      style={{
                        fontSize: 30,
                        color: '#808BF8',
                        cursor: 'pointer',
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <LinkedinShareButton
                      url={window.location.href}
                      title={article.titre}
                      summary={article.descriptif}
                    >
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        style={{
                          fontSize: 40,
                          color: '#2A66BC',
                          cursor: 'pointer',
                        }}
                      />
                    </LinkedinShareButton>
                  </Grid>
                  <Grid item xs={2}>
                    <FacebookShareButton
                      url={window.location.href}
                      quote={article.titre}
                    >
                      <FontAwesomeIcon
                        icon={faFacebook}
                        style={{
                          fontSize: 40,
                          color: '#3b5998',
                          cursor: 'pointer',
                        }}
                      />
                    </FacebookShareButton>
                  </Grid>
                  <Grid item xs={2}>
                    <WhatsappShareButton
                      url={window.location.href}
                      title={article.titre}
                    >
                      <FontAwesomeIcon
                        icon={faWhatsapp}
                        style={{
                          fontSize: 40,
                          color: '#25D366',
                          cursor: 'pointer',
                        }}
                      />
                    </WhatsappShareButton>
                  </Grid>
                </Grid>
                {/*  <Typography>
                  <FontAwesomeIcon icon={faLinkedin} style={{ fontSize: 40 }} />
                </Typography>
                <Typography>
                  <FontAwesomeIcon icon={faFacebook} style={{ fontSize: 40 }} />
                </Typography>
                <Typography>
                  <FontAwesomeIcon
                    icon={faInstagram}
                    style={{ fontSize: 40 }}
                  />
                </Typography> */}
              </GridItem>
            </Grid>
          </Container>
        </Box>
      </Box>
    </main>
  )
}
