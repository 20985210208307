import { styled } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'

const CustomSelect = styled('select')(({ theme, width }) => ({
  width: width ? width : '100%',
  height: 45,
  borderRadius: '5px',
  background: '#FFFFFF',
  fontFamily: 'Century Gothic',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,
  borderColor: lightTheme.palette.grey.main,
  cursor: 'pointer',

  option: {},
}))

const SelectBasic = ({
  items,
  register,
  width,
  handleChange,
  dataDay,
  dataTime,
  defaultValue,
  ...props
}) => {
  return (
    <CustomSelect data-time={dataTime} data-day={dataDay} onChange={handleChange} {...register} width={width}>
      {items.map((item) => (
        <option
          selected={item.text === defaultValue || item.value === defaultValue}
          key={item.id}
          value={item.value}
        >
          {item.text}
        </option>
      ))}
    </CustomSelect>
  )
}

export default SelectBasic
