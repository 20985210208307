import ApiRequest from "../request/ApiRequest";

export default class Documents {
  constructor() {
    this.ApiRequest = new ApiRequest();
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post("documents/ajout", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async list() {
    try {
      const res = await this.ApiRequest.get("documents");
      return res;
    } catch (error) {
      return error.response;
    }
  }


  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`documents/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`documents/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`documents/${id}`);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get("documents.json", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

}