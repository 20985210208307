import React from "react";
import { Box, Skeleton, styled } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import "../../styles/components/_skeleton.scss"

const BadgeBox = styled(Box)(({theme}) =>({
    backgroundColor: lightTheme.palette.primary.b4,
    borderRadius: "30px 0",
    width: 155,
    height: 50,
    cursor: "initial",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "10px",
    padding: "10px 20px",

    [theme.breakpoints.up("sm")]: {
        width: 221,
        height: 70,
        columnGap: "20px",
    },

    [theme.breakpoints.up("lg")]: {
        width: "calc(50% - 15px)",
    },
}));

const Icon = styled(Box)(({theme}) => ({
    color: "#FFFFFF",
    fontSize: 15,

    [theme.breakpoints.up("lg")]: {
        fontSize: 20,
    },
}));

const Text = styled(Box)(({theme}) => ({
    width: 100,
    fontFamily: "Century Gothic",
    fontSize: 8,
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#FFFFFF",

    [theme.breakpoints.up("sm")]: {
        width: 150,
        fontSize: 10
    },

    [theme.breakpoints.up("lg")]: {
        width: 213,
        fontSize: 12
    },
}));

export default function BadgeRoundedButton ({children, icon}) {
    return (
        <BadgeBox>
            <Icon>
                {icon}
            </Icon>

            {children?.length > 0 ? (
              <Text>
                  {children}
              </Text>
            ) : (
              <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"} />
              )}

        </BadgeBox>
    )
}