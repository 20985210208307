import React, { useState } from 'react'
import {
  styled,
  Box,
  Typography,
  IconButton,
  AppBar,
  useMediaQuery,
  Slide,
  Container,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { SearchInput } from '../inputs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/pro-solid-svg-icons'
import { faRightToBracket } from '@fortawesome/pro-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/images/logos/logo-admin.svg'
import { useTheme } from '@emotion/react'
import Sidebar from '../Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { userLogout } from '../../store/slices/userAuthReducer'

const Nav = styled(AppBar)(({ theme }) => ({
  background: lightTheme.palette.admin.adminMenu,
  height: 110,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  position: 'fixed',
  right: 0,
  /*padding: "40px 70px",*/
  zIndex: 2,
  borderRadius: '0px 0px 40px 40px',

  [theme.breakpoints.up('md')]: {
    height: 130,
  },

  [theme.breakpoints.up('lg')]: {
    width: 'calc(100% - 260px)',
    height: 80,
    /*padding: "15px 30px",*/
    borderRadius: 0,
  },
}))

const NavContainer = styled(Container)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const LogContainer = styled(Box)(({ theme }) => ({
  width: 270,
  height: 80,
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: lightTheme.palette.admin.adminSearch,
  /*marginRight: "150px",*/
  padding: '25px 55px',
  columnGap: '20px',

  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    padding: '25px 45px',
  },
}))

const Logo = styled(Box)(({ theme }) => ({
  display: 'block',

  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}))

/*const BurgerMenu = styled(Box, {
  shouldForwardProp: (prop) => prop !== "checked",
  name: "MenuContainer",
})(({ theme, checked }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  transition: "all .8 ease-in-out",
  width: 35,
  height: 25,
  cursor: "pointer",

  ["& .checkbox"]: {
    display: "none",
  },

  ["& .hamburger-lines"]: {
    display: "flex",
    flexDirection: "column",

    justifyContent: "flex-end",
    alignItems: "center",
    transition: "all 500ms ease-in",

    position: "relative",
    width: 35,
    height: 25,

    ["& .line"]: {
      display: "block",
      height: 4,
      background: "#FFFFFF",
      borderRadius: "5px",

      position: "absolute",
      transition: "all 700ms ease-in-out",
    },

    ["& .line1"]: {
      width: "50%",
      top: 0,
      right: 0
    },
    ["& .line2"]: {
      width: "100%",
      top: "11px",
    },
    ["& .line3"]: {
      width: "50%",
      bottom: 0,
      left: 0
    },
  },

  ["& input[type='checkbox']:checked ~ .hamburger-lines"]: {
    justifyContent: "space-between",
  },
  ["& input[type='checkbox']:checked ~ .hamburger-lines .line"]: {
    backgroundColor: "#FFFFFF",
  },

  ["& input[type='checkbox']:checked ~ .hamburger-lines .line1"]: {
    transform: "rotate(135deg)",
    top: "11px",
    width: "100%",
    transition: "all 700ms ease-in-out"
  },
  ["& input[type='checkbox']:checked ~ .hamburger-lines .line2"]: {
    transform: "rotate(45deg)",
    top: "11px",
    width: "100%",
    transition: "all 700ms ease-in-out"
  },
  ["& input[type='checkbox']:checked ~ .hamburger-lines .line3"]: {
    width: 0
  },

  [theme.breakpoints.up("lg")]: {
    display: "none"
  }
}));*/

const BurgerMenu = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'checked',
  name: 'MenuContainer',
})(({ theme, checked }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  transition: 'all .8 ease-in-out',
  width: 35,
  height: 25,
  cursor: 'pointer',

  '& .checkbox': {
    display: 'none',
  },

  '& .hamburger-lines': {
    display: 'flex',
    flexDirection: 'column',

    justifyContent: 'flex-end',
    alignItems: 'center',
    transition: 'all 500ms ease-in',

    position: 'relative',
    width: 35,
    height: 25,

    '& .line': {
      display: 'block',
      height: 4,
      background: '#FFFFFF',
      borderRadius: '5px',

      position: 'absolute',
      transition: 'all 700ms ease-in-out',
    },

    '& .line1': {
      width: '50%',
      top: 0,
      right: 0,
    },
    '& .line2': {
      width: '100%',
      top: '11px',
    },
    '& .line3': {
      width: '50%',
      bottom: 0,
      left: 0,
    },
  },

  "& input[type='checkbox']:checked ~ .hamburger-lines": {
    justifyContent: 'space-between',
  },
  "& input[type='checkbox']:checked ~ .hamburger-lines .line": {
    backgroundColor: '#FFFFFF',
  },

  "& input[type='checkbox']:checked ~ .hamburger-lines .line1": {
    /*transform: "rotate(135deg)",*/
    /*top: "11px",*/
    width: '15%',
    transition: 'all 700ms ease-in-out',
  },
  "& input[type='checkbox']:checked ~ .hamburger-lines .line2": {
    /*transform: "rotate(45deg)",
    top: "11px",*/
    width: '15%',
    right: 0,
    transition: 'all 700ms ease-in-out',
  },
  "& input[type='checkbox']:checked ~ .hamburger-lines .line3": {
    /*width: 0*/
    width: '15%',
    right: 0,
    left: 'auto',
    transition: 'all 700ms ease-in-out',
  },

  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}))

const AdminNavbar = () => {
  const user = useSelector((state) => state.userAuth.user)
  let navigate = useNavigate()
  const theme = useTheme()
  const [checked, setChecked] = useState(false)
  const isLg = useMediaQuery(theme.breakpoints.up('lg'))
  const dispatch = useDispatch()

  const logout = () => {
    dispatch(userLogout())
    navigate('/')
  }

  if (!user) {
    navigate('/')
    return
  }

  return (
    <>
      <Nav>
        <NavContainer maxWidth={'xl'}>
          <Logo>
            <img src={logo} alt={'Jobertech'} />
          </Logo>
          <SearchInput display={{ xs: 'none', md: 'flex' }} />

          <BurgerMenu onClick={() => setChecked(!checked)} checked={checked}>
            <input
              className="checkbox"
              type="checkbox"
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <div className="hamburger-lines">
              <span className="line line1" />
              <span className="line line2" />
              <span className="line line3" />
            </div>
          </BurgerMenu>

          <LogContainer>
            <Typography
              sx={{
                fontFamily: 'Century Gothic',
                fontSize: 14,
                lineHeight: '140%',
                letterSpacing: '0.2px',
                color: lightTheme.palette.text.secondary,
                textTransform: 'capitalize',
              }}
            >
              {user.data.prenom} {user.data.nom}
            </Typography>
            <IconButton
              type={'button'}
              aria-label={'settings'}
              sx={{ padding: 0 }}
              onClick={() => navigate('/admin')}
            >
              <FontAwesomeIcon
                icon={faGear}
                style={{ color: '#FFFFFF', fontSize: '20px' }}
              />
            </IconButton>
            <IconButton
              type={'button'}
              aria-label={'logout'}
              sx={{ padding: 0 }}
              onClick={logout}
            >
              <FontAwesomeIcon
                icon={faRightToBracket}
                style={{ color: '#FFFFFF', fontSize: '20px' }}
              />
            </IconButton>
          </LogContainer>
        </NavContainer>
      </Nav>
      {!isLg && (
        <Slide direction={'down'} in={checked} timeout={700}>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', lg: 'flex' },
              flexDirection: 'column',
              alignItems: 'center',
              position: 'absolute',
              top: { xs: '70px', md: '90px' },
              left: 0,
              width: '100%',
              height: 410,
              /*height: { xs: 600, md: 580 },*/
              /*backgroundColor: "#FFFFFF",*/
              zIndex: 1,
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
            }}
          >
            <Sidebar />
          </Box>
        </Slide>
      )}
    </>
  )
}

export default AdminNavbar
