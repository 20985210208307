import { Box, styled } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";

const ReturnBox = styled(Box)(({theme}) => ({
  width: 50,
  height: 50,
  borderRadius: "100%",
  backgroundColor: "rgba(181, 188, 255, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",

  "& .icon": {
    fontSize: 20,
    /*color: lightTheme.palette.primary.main*/
    color: "#FFFFFF"
  },

  "&:hover": {
    backgroundColor: "rgba(181, 188, 255, 1)"
  },

  "&:active": {
    backgroundColor: "rgba(181, 188, 255, 0.5)",
  }
}))


const ReturnButton = () => {
  return (
    <ReturnBox>
      <FontAwesomeIcon icon={faArrowLeft} className={"icon"}/>
    </ReturnBox>
  )
}

export default ReturnButton;