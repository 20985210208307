import React, { useEffect } from 'react'
import {
  Container,
  styled,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material'
import { EmailInput } from '../../components/inputs'
import PasswordInput from '../../components/inputs/PasswordInput'
import { CustomButton } from '../../components/buttons'
import lightTheme from '../../styles/theme/lightTheme'
import AuthCard from '../../components/cards/AuthCard'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Slide, toast, ToastContainer } from 'react-toastify'
import '../../styles/components/_toasts.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { userLogin, userLogout } from '../../store/slices/userAuthReducer'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  ROLE_CANDIDAT,
  ROLE_ENTREPRISE,
  acces_depose_annonce,
} from '../../data'
import { hasPermission } from '../../config'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'

const FormBox = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '25px',
  width: '100%',
})

export default function Login({ atelier, onChangeUser, setLogin }) {
  const { t } = useTranslation()

  const schema = yup.object({
    email: yup
      .string()
      .email(t('errorMessage.emailValid'))
      .required(t('errorMessage.required')),
    password: yup.string().required(t('errorMessage.required')),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })
  const dispatch = useDispatch()
  const { isFetching, user, error } = useSelector((state) => state.userAuth)

  const navigate = useNavigate()

  useEffect(() => {
    if (error) {
      if (error.code === 401) {
        toast.error(t('successMessage.loginIncorrect'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      } else {
        toast.error(t('successMessage.somethingWrong'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }
    }
  }, [user, error])

  const handleUser = (user) => {
    onChangeUser(user)
  }

  const handleKeyPress = (e) => {
    console.log(e.key)
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)()
    }
  }

  const alertEntreprise = async () => {
    try {
      const result = await Api.company.alertEmail('type3')
      const res = handlingErrors(result)
      console.debug(res)
    } catch (error) {}
  }

  const onSubmit = (data) => {
    dispatch(userLogin(data))
      .then((res) => {
        const dataRole = res.payload.data.role
        const dataStatus = res.payload.data.status
        /* if (atelier?.id) {
          sessionStorage.setItem('atelierSelected', JSON.stringify(atelier))
        } */

        if (dataStatus === 'blocke' || dataStatus === 'desactive') {
          dispatch(userLogout())
          toast.error(
            dataStatus === 'blocke'
              ? t('successMessage.compteDesactive')
              : t('successMessage.compteBloque'),
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              icon: ({ theme, type }) => (
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  fontSize={25}
                  color={lightTheme.palette.error.main}
                />
              ),
            }
          )
          return
        }

        if (
          dataRole === 'ROLE_SUPER_ADMIN' ||
          dataRole === 'ROLE_MINI_ADMIN' ||
          dataRole === 'ROLE_ADMIN'
        ) {
          return navigate('/admin')
        } else if (dataRole === ROLE_ENTREPRISE || dataRole === ROLE_CANDIDAT) {
          if (
            dataRole === ROLE_ENTREPRISE &&
            !hasPermission(res.payload.data, acces_depose_annonce)
          ) {
            alertEntreprise()
          }

          if (atelier?.id) {
            handleUser(res.payload.data)
          } else {
            return navigate('/user')
          }
        } else {
          return null
        }
      })
      .catch((error) => console.log(error))
  }

  return (
    <>
      <Container
        maxWidth={'lg'}
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AuthCard
          title={t('login.loginTitle')}
          showImg={atelier?.id ? false : true}
        >
          <FormBox onSubmit={handleSubmit(onSubmit)}>
            <Typography
              variant={'body2'}
              sx={{
                color: lightTheme.palette.text.primary,
              }}
            >
              {t('login.loginText')}
            </Typography>
            <Box>
              <EmailInput
                placeholder={t('login.addressMail')}
                width={'100%'}
                register={{ ...register('email') }}
                handleKeyPress={handleKeyPress}
              />
              {errors.email && (
                <Typography
                  variant={'caption'}
                  color={lightTheme.palette.error.nope}
                >
                  {errors.email.message}
                </Typography>
              )}
            </Box>

            <Box>
              <PasswordInput
                width={'100%'}
                register={{ ...register('password') }}
                handleKeyPress={handleKeyPress}
              />
              {errors.password && (
                <Typography
                  variant={'caption'}
                  color={lightTheme.palette.error.nope}
                >
                  {errors.password.message}
                </Typography>
              )}
            </Box>

            <Box>
              {isFetching ? (
                <Box
                  width={'100%'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <CustomButton
                  displayIcon={'none'}
                  handleClick={handleSubmit(onSubmit)}
                >
                  {t('button.connection')}
                </CustomButton>
              )}
            </Box>
            <Box
              onClick={() => {
                if (atelier?.id) {
                  setLogin(false)
                } else {
                  navigate('/auth/inscription')
                }
              }}
              sx={{
                color: lightTheme.palette.primary.main,
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              {t('login.registrationText')}
            </Box>
          </FormBox>
        </AuthCard>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}
