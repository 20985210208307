import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/Auth/Login";
import { AuthLayout } from "../components/layouts";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ResetPassword from "../pages/Auth/ResetPassword";
import Register from "../pages/Auth/Register";

const AuthRouter = () => {
  return(
    <Routes>
      <Route element={<AuthLayout/>}>
        <Route path={"se-connecter"} element={<Login/>} />
        <Route path={"mot-de-passe-oublie"} element={<ForgotPassword/>} />
        <Route path={"reinitialisation-mot-de-passe/:token"} element={<ResetPassword/>} />
        <Route path={"inscription"} element={<Register/>} />
        <Route path={"inscription/entreprise"} element={<Register typeRegister={'entreprise'}/>} />
        <Route path={"inscription/candidat"} element={<Register typeRegister={'candidat'}/>} />
      </Route>
    </Routes>
  )
}

export default AuthRouter;