import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import { SelectInputBasic, TextInput } from '../inputs'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as yup from 'yup'
import { phoneRegExp } from '../../config'
import { useDispatch } from 'react-redux'
import { userLogout } from '../../store/slices/userAuthReducer'
import TextareaInputClassic from '../inputs/TextareaInputClassic'
import { useTranslation } from 'react-i18next'
import { ROLE_ENTREPRISE } from '../../data'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const parSchema = yup.object({
  //besoin: yup.string().required('Merci de renseigner ce champ.'),
})

const EditBesoin = ({ open, onClose, onSave, openDelete, id, role }) => {
  const { t } = useTranslation()

  const proSchema = yup.object({
    prenom: yup.string().required(t('errorMessage.required')),
    nom: yup.string().required(t('errorMessage.required')),
    email: yup
      .string()
      .email(t('errorMessage.emailValid'))
      .required(t('errorMessage.required')),
    telephone: yup
      .string()
      .required(t('errorMessage.required'))
      .matches(phoneRegExp, t('errorMessage.phone'))
      .nullable(),
    raisonSocial: yup.string().required(t('errorMessage.required')),
    /*langues: yup.string().required("Merci de renseigner ce champ."),
    dateNaissance: yup.string().required("Merci de renseigner ce champ."),
    statusMarital: yup.string().required("Merci de renseigner ce champ."),*/
  })

  const [loadingInfos, setLoadingInfos] = useState(true)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  //const [fonctions, setFonctions] = useState([])
  const [fonctionSelect, setFonctionSelect] = useState('')
  const [besoin, setBesoin] = useState('')
  const [snapShot, setSnapShot] = useState(null)
  const {
    register,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(role === ROLE_ENTREPRISE ? proSchema : parSchema),
  })

  useEffect(() => {
    fetchData()
  }, [id, dispatch, reset])

  const fetchData = async () => {
    setLoadingInfos(true)
    try {
      let result = await Api.user.detail(id)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        console.log('erreur')
      }

      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        setBesoin(res.candidat.besoin)
        reset(res)
        setLoadingInfos(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleFonctionSelect = (event) => {
    const inputValue = event.target.value
    setFonctionSelect(inputValue)
  }

  const onSubmit = async (data) => {
    setLoading(true)

    //return

    const {
      admin,
      candidat,
      userOffres,
      date,
      entreprise,
      lang,
      offre,
      parent,
      password,
      status,
      ...rest
    } = data
    try {
      const result = await Api.candidate.edit(candidat.id, {
        besoin: candidat.besoin,
      })
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        dispatch(userLogout())
        return
      }

      if (handling.ok) {
        //const res = handling.data
        setLoading(false)
        onSave()
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log('erreur submit !')
    }
  }

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {role === ROLE_ENTREPRISE ? (
            <>{t('box.title.editJob')}</>
          ) : (
            <>{t('box.title.searchCandidate')}</>
          )}
        </Typography>
        <BoxContainer>
          {loadingInfos ? (
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <form>
              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                }}
              >
                {role === ROLE_ENTREPRISE && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '5px',
                      }}
                    >
                      <FormLabel className={'labelForm'}>
                        {t('box.companyName')}
                      </FormLabel>
                      <TextInput
                        placeholder={t('box.companyName')}
                        type={'text'}
                        register={{ ...register('entreprise.raisonSocial') }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '5px',
                      }}
                    >
                      <FormLabel className={'labelForm'}>
                        {t('contact.function')}
                      </FormLabel>
                      <SelectInputBasic
                        items={[]}
                        value={fonctionSelect}
                        handleChange={handleFonctionSelect}
                      />
                    </Grid>
                  </>
                )}

                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                  }}
                >
                  <TextareaInputClassic
                    placeholder={t('contact.need')}
                    width={'100%'}
                    register={{ ...register('candidat.besoin') }}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </BoxContainer>
        {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.cancel')}
          </BorderButtonPurple>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              handleClick={handleSubmit(onSubmit)}
            >
              {t('button.save')}
            </CustomButton>
          )}
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default EditBesoin
