import React from "react";
import { Radio, FormControlLabel, styled} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";


const BpIcon = styled('span')(({ theme }) => ({
  position: "relative",
  borderRadius: '50%',
  width: 20,
  height: 20,
  backgroundColor: "#FFFFFF",
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    /*backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',*/
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#FFFFFF',
  '&:before': {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: 'block',
    width: 12,
    height: 12,
    background: lightTheme.palette.primary.main,
    borderRadius: "50%",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#FFFFFF',
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const RadioInput = ({label, value}) => {
  return (
    <FormControlLabel value={value} control={<BpRadio />} label={label} />
  )
}

export default RadioInput;
