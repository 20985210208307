import ApiRequest from "../request/ApiRequest";

export default class besoinEntreprise {
  constructor() {
    this.ApiRequest = new ApiRequest();
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post("besoin_entreprises", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`besoin_entreprises/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`besoin_entreprises/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }
  async list() {
    try {
      const res = await this.ApiRequest.get("besoin_entreprises");
      return res;
    } catch (error) {
      return error.response;
    }
  }
  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`besoin_entreprises/${id}`);
      return res;
    } catch (error) {
      return error.response;
    }
  }
  async matchingCandidatesByOffre(id) {
    try {
      const res = await this.ApiRequest.get(`matcher/candidat/${id}`);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get("besoin_entreprises.jsonld", data, "jsonld");
      return res;
    } catch (error) {
      return error.response;
    }
  }
}
