import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import lightTheme from "../../styles/theme/lightTheme";

const Banner = styled(Box)(({theme}) => ({
  width: "100%",
  height: 70,
  backgroundColor: lightTheme.palette.primary.b3,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

}));

const Title = styled(Typography)(({theme}) => ({
  textAlign: "center",
  textTransform: "uppercase",
  color: lightTheme.palette.text.secondary,

  [theme.breakpoints.up("sm")]: {
    fontSize: 16,

  },
  [theme.breakpoints.up("lg")]: {
    fontSize: 20
  },
}));

const IconReturn = styled(Box)(({theme}) => ({
  position: "absolute",
  left: 25,
  cursor: "pointer",

  "& .icon": {
    fontSize: 25,
    color: lightTheme.palette.primary.b4,

    "&:hover": {
      color: lightTheme.palette.primary.main
    },

    "&:active": {
      color: lightTheme.palette.primary.b4
    }
  }
}))

const UserProfilHeading = ({children, displayIconReturn, handleClickIcon}) => {
  return (
    <Banner>
      <IconReturn display={displayIconReturn} onClick={handleClickIcon}>
        <FontAwesomeIcon icon={faArrowLeft} className={"icon"}/>
      </IconReturn>
      <Title variant={"h1"}>
        {children}
      </Title>
    </Banner>
  )
}

export default UserProfilHeading;