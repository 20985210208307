import React from "react";
import {styled, Box} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";

const InputBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    rowGap: "5px",
    width: "100%"
});

const Label = styled('label')(({theme, display}) => ({
    fontFamily: "Century Gothic Bold",
    fontSize: "10px",
    lineHeight: "140%",
    color: lightTheme.palette.text.primary,
    letterSpacing: "0.2px",
    display: display,

    [lightTheme.breakpoints.up("md")]: {
        fontSize: 12,
    },

    [lightTheme.breakpoints.up("lg")]: {
        fontSize: 14,
    },
}));

const CustomInput = styled("input", {
    shouldForwardProp: (prop) => prop !== "EmailInput",
})(({ theme, width}) => ({
    height: 45,
    width: width,
    backgroundColor: "#FFFFF",
    borderRadius: "5px",
    border: "none",
    fontFamily: "Century Gothic",
    fontSize: 10,
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: lightTheme.palette.grey.main,
    padding: "10px 15px",

    "&::placeholder": {
        fontFamily: "Century Gothic",
        fontSize: 10,
        lineHeight: "140%",
        letterSpacing: "0.2px",
        color: lightTheme.palette.grey.main,
    },

    "&:active, &:focus": {
        outline: "none"
    },

    [lightTheme.breakpoints.up("lg")]: {
        fontSize: 12,

        "&::placeholder": {
            fontSize: 12,
        },
    },

    [lightTheme.breakpoints.up("lg")]: {
        width: width,
        fontSize: 14,

        "&::placeholder": {
            fontSize: 14,
        },
    },

}));

export default function EmailInput({register, placeholder, width, display, value, onChange, id, handleKeyPress}) {
    return (
      <InputBox>
          <Label display={display}>{placeholder}</Label>
          <CustomInput
            placeholder={placeholder}
            type={"email"}
            width={width}
            onChange={onChange}
            autoComplete={"off"}
            name={"email"}
            value={value}
            onKeyPress={handleKeyPress}
            id={id}
            required
            {...register}
          />
      </InputBox>

    )
}