import React from "react";
import { FormControl, styled } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import PropTypes from "prop-types";


const CustomInput = styled('input')(({theme, width}) => ({
  height: 45,
  width: "100%",
  backgroundColor: "#FFFFF",
  borderRadius: "5px",
  border: `1px solid ${lightTheme.palette.grey.main}`,
  fontFamily: "Century Gothic",
  fontSize: 10,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.text.primary,
  padding: "10px 15px",
  textTransform: "capitalize",

  "&::placeholder": {
    fontFamily: "Century Gothic",
    fontSize: 14,
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: lightTheme.palette.text.primary,
  },

  "&:active, &:focus": {
    outline: "none"
  },

  [lightTheme.breakpoints.up("md")]: {
    fontSize: 12,
  },

  [lightTheme.breakpoints.up("lg")]: {
    fontSize: 14,
  },

}));

const TextInputAdmin = ({placeholder, width, register, type, editMode, ...props}) => {
  const sharedProps =
    editMode === true
      ? { ...props, readOnly: false }
      : { ...props, readOnly: true };

  return (
    <FormControl>
      <CustomInput
        placeholder={placeholder}
        width={width}
        type={type ? type : "text"}
        autoComplete={"off"}
        required
        {...register}
        {...sharedProps}
      />
    </FormControl>
  )
}

export default TextInputAdmin;

TextInputAdmin.propTypes = {
  editMode: PropTypes.bool.isRequired,
  resetValue: PropTypes.func.isRequired,
  disabled: PropTypes.node,
  placeholder: PropTypes.string,
  register: PropTypes.func.isRequired,
  type: PropTypes.string,
  date: PropTypes.string,
};
