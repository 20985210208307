import React from "react";
import {Button, styled, Typography} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/pro-regular-svg-icons";
import {faClock} from "@fortawesome/pro-regular-svg-icons";
import {faLocationDot} from "@fortawesome/pro-regular-svg-icons";
import {faTag} from "@fortawesome/pro-regular-svg-icons";



const CustomButton = styled(Button)(({theme, width}) => ({
    height: 25,
    backgroundColor: lightTheme.palette.primary.b3,
    borderRadius: "10px",
    width: width,
    fontFamily: "Century Gothic Bold",
    fontSize : 14,
    lineHeight: "140%",
    color: "#FFFFFF",
    cursor: "initial",

    ":hover": {
        backgroundColor: lightTheme.palette.primary.b3
    },

    [theme.breakpoints.up("lg")]: {
        height: 45,
        width: 130
    },
}));

export default function BadgeButton(props) {
    return (
        <>
            {props.items && props.items.map((item, index) => (
                <CustomButton key={index}>
                    {(item.tag === "calendar") && <FontAwesomeIcon icon={faCalendar}  />}
                    {(item.tag === "time") && <FontAwesomeIcon icon={faClock}  />}
                    {(item.tag === "location") && <FontAwesomeIcon icon={faLocationDot}  />}
                    {(item.tag === "tag") && <FontAwesomeIcon icon={faTag}  />}
                    <Typography>{item.tag}</Typography>
                </CustomButton>
            ))}
        </>

    )
}