import React from "react";
import { Outlet } from "react-router-dom";
import AppNavBar from "../navbar/AppNavBar";
import Footer from "../footer";

const PublicLayout = () => {
  return (
    <div className={"Layout"}>
      <AppNavBar/>
      <Outlet/>
      <Footer/>
    </div>
  )
}

export default PublicLayout;