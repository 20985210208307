import React, { useEffect, useState } from 'react'
import {
  FormControl,
  FormLabel,
  Grid,
  styled,
  Box,
  Typography,
  CircularProgress,
  TextField,
  Autocomplete,
} from '@mui/material'
import lightTheme from '../../../../styles/theme/lightTheme'
import RegisterCard from '../../../../components/cards/RegisterCard'
import {
  RadioInputBasic,
  TextInput,
} from '../../../../components/inputs'
import { CustomButton, BorderButton } from '../../../../components/buttons'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Api from '../../../../services/api.service'
import { handlingErrors } from '../../../../utils'
import { userLogout } from '../../../../store/slices/userAuthReducer'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faCircleExclamation,
  faFaceLaugh,
} from '@fortawesome/pro-regular-svg-icons'
import { useDispatch } from 'react-redux'
import '../../../../styles/components/_errors.scss'
import '../../../../styles/components/_grids.scss'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { DEFAULT_DUREE, DEFAULT_DUREE_ENTRE, ROLE_CANDIDAT, ROLE_ENTREPRISE, SLOT_DEFAULT_VALUE, countries } from '../../../../data'
import StripeAppTest from '../../../../components/Stripe/StripeAppTest'
import { Container } from '@mui/system'

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  rowGap: '5px',
}))

const CustomFormLabel = styled(FormLabel)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [lightTheme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
  [lightTheme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
}))

const Steps = ({
  atelierUser,
  atelier,
  user,
  step,
  setLogin,
  setAtelierUser,
}) => {
  const { t, i18n } = useTranslation()
  let navigate = useNavigate()
  const [currentStep, setCurrentStep] = useState(step)
  const [type, setType] = useState('entreprise')
  const [loading, setLoading] = useState(false)
  const [loadingPoste, setLoadingPoste] = useState(false)
  const [loadingFonction, setLoadingFonction] = useState(false)
  //const [err, setErr] = useState('')
  const dispatch = useDispatch()
  const [postes, setPostes] = useState([])
  const [posteSelect, setPosteSelect] = useState('')
  const [fonctions, setFonctions] = useState([])
  const [fonctionSelect, setFonctionSelect] = useState('')
  const [id, setId] = useState(user?.id)
  const [openCard, setOpenCard] = useState(false)
  const [phoneValue, setPhoneValue] = useState()
  const [selectedCountry, setSelectedCountry] = useState({
    label: 'Switzerland',
  })
  const [cities, setCities] = useState([])
  const [defaultCities, setDefaultCities] = useState([])
  const [selectedCity, setSelectedCity] = useState('Genève')
  const [states, setStates] = useState([])
  const [selectedState, setSelectedState] = useState()
  const [defaultState, setDefaultState] = useState([])
  const [open, setOpen] = React.useState(false)
  let typingTimer //timer identifier
  let doneTypingInterval = 800 //time in ms (5 seconds)

  const [codePromo, setCodePromo] = useState('')
/*   const [loadingCodePromo, setLoadingCodePromo] = useState(false) */
  const [errorCodePromo, setErrorCodePromo] = useState(false)

  const title = atelier.titre

  const proSchema = yup.object({
    raisonSocial: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.companyName')),
    /*fonction: yup
      .string(t("errorMessage.string"))
      .required(t("errorMessage.function")),*/
    prenom: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.firstname')),
    nom: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.lastname')),
    email: yup
      .string(t('errorMessage.string'))
      .email(t('errorMessage.emailValid'))
      .required(t('errorMessage.email')),
    telephone: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.number')),
    /*.matches(phoneRegExp, "Merci de rentrer un numéro de téléphone valide."),*/
    adresse: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.address')),
    /* ville: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.city')), */
    codePostal: yup
      .number()
      .typeError(t('errorMessage.zipCodeValid'))
      .required(t('errorMessage.zipCode')),
    /* pays: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.country')), */
    /*offre: yup
      .string()
      .required(),*/
    /* besoin: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.needs')),
    acceptTermes: yup
      .boolean()
      .test('acceptTermes', t('errorMessage.terms'), (val) => {
        return val
      }), */
    /*dateRdv: yup
      .string()
      .test("dateRdv", "You must be 18 years or older", function (value) {
        return moment().diff(moment(value, "YYYY-MM-DD"), "years") <= 1;
      })
      .required(),*/
    /*  slot: yup
      .string()
      .test('slot', t('errorMessage.selectHour'), (val) => {
        return val
      })
      .nullable(), */
  })

  const parSchema = yup.object({
    prenom: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.firstname')),
    nom: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.lastname')),
    email: yup
      .string(t('errorMessage.string'))
      .email(t('errorMessage.emailValid'))
      .required(t('errorMessage.email')),
    telephone: yup.string().required(t('errorMessage.number')),
    /*.matches(phoneRegExp, "Merci de rentrer un numéro de téléphone valide."),*/
    adresse: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.address')),
    /* ville: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.city')), */
    codePostal: yup
      .number()
      .typeError(t('errorMessage.typeNumber'))
      .required(t('errorMessage.zipCode')),
    /* pays: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.country')), */
    /*offre: yup
      .string()
      .required(),*/
    /*  besoin: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.needs')),
    acceptTermes: yup
      .boolean()
      .test('acceptTermes', t('errorMessage.terms'), (val) => {
        return val
      }), */
    /*dateRdv: yup
      .string()
      .test("dateRdv", "You must be 18 years or older", function (value) {
        return moment().diff(moment(value, "YYYY-MM-DD"), "years") <= 1;
      })
      .required(),*/
    /* slot: yup.string().test('slot', t('errorMessage.selectHour'), (val) => {
      return val
    }), */
  })

  const defaultValue = {
    acceptTermes: false,
  }

  const {
    register,
    handleSubmit,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(type === 'entreprise' ? proSchema : parSchema),
    mode: 'onChange',
    defaultValue,
  })

  useEffect(() => {
    handleCountryChange(selectedCountry)
  }, [selectedCountry])

  const handleCountryChange = async (country) => {
    setSelectedCountry(country)

    try {
      const result = await Api.countriesCities.getCitiesByCountry(country.label)
      const res = handlingErrors(result)

      const result2 = await Api.countriesCities.getStatesByCountry(
        country.label
      )
      const res2 = handlingErrors(result2)

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }

      if (res.ok) {
        setCities(res.data.data)
        setDefaultCities(res.data.data)
        setSelectedCity(res.data.data[0])
      }

      if (res2.ok) {
        setStates(res2.data.data.states)
        setDefaultState(res2.data.data.states)
        setSelectedState(res2.data.data.states[0])
      }
    } catch (error) {
      console.debug(error)
    }
  }

  function filteredCitiesOptions(inputValue) {
    const inputLowerCase = inputValue.toLowerCase()
    setCities(
      defaultCities.filter((option) => option.toLowerCase().startsWith(inputLowerCase))
    )
  }

  function filteredStatesOptions(inputValue) {
    const inputLowerCase = inputValue.toLowerCase()
    setStates(
      states.filter((option) => option.toLowerCase().startsWith(inputLowerCase))
    )
  }

  useEffect(() => {
    if (type === 'entreprise' && currentStep === 0) getFonctions()
  }, [currentStep, type])

  const nextStep = async () => {
    let isValidStepOne = false
    let isValidStepTwo = false
    let isValidStepTree = false
    let isValidStepFour = false
    switch (currentStep) {
      case 0:
        if (type === 'entreprise') {
          isValidStepOne = await trigger([
            'raisonSocial',
            'fonction',
            'prenom',
            'nom',
            'email',
          ])
        } else {
          isValidStepOne = await trigger([
            'prenom',
            'nom',
            'email',
            'telephone',
          ])
        }
        break

      case 1:
        if (type === 'entreprise') {
          isValidStepTwo = await trigger([
            'telephone',
            //'pays',
            'adresse',
            //'ville',
            'codePostal',
          ])
        } else {
          isValidStepTwo = await trigger([
            'adresse',
            //'ville',
            'codePostal',
            //'pays',
          ])
        }
        break

      case 2:
        isValidStepTree = true
        break

      case 3:
        isValidStepFour = true
        break

      default:
        break
    }

    if (isValidStepOne) {
      setCurrentStep(1)
    } else if (isValidStepTwo) {
      setCurrentStep(2)
    }
  }

  const handleChangeRadio = (inputValue) => {
    setType(inputValue)
  }

 /*  const handlePosteSelect = (event) => {
    const inputValue = event.target.value
    setPosteSelect(inputValue)
  } */

  /* const handleFonctionSelect = (event) => {
    const inputValue = event.target.value
    setFonctionSelect(inputValue)
  } */

  const [role, setRole] = useState(null)

  useEffect(() => {
    if (type !== 'entreprise' && currentStep === 1) getPostes()

    if (type === 'entreprise') {
      setRole(ROLE_ENTREPRISE)
    } else {
      setRole(ROLE_CANDIDAT)
    }
  }, [currentStep, type])

  const getFonctions = async () => {
    setLoadingFonction(true)
    try {
      let result = await Api.fonction.listWithFilter({
        status: true,
      })
      setFonctions(result)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoading(false)
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data

        setLoadingFonction(false)
        setFonctions(res)
      } else {
        setLoadingFonction(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoadingFonction(false)
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
      window.scrollTo(0, 0)
    }
  }

  const getPostes = async (searchQuery) => {
    let payload = {
      search: searchQuery,
    }

    payload = Object.entries(payload).reduce(
      (a, [k, v]) => (v ? ((a[k] = v), a) : a),
      {}
    )
    setLoadingPoste(true)
    try {
      let result = await Api.poste.listWithFilter({
        status: true,
        ...payload,
      })
      setPostes(result)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoadingPoste(false)
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data
        setLoadingPoste(false)
        setPostes(res)
      } else {
        setLoadingPoste(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoadingPoste(false)
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
      window.scrollTo(0, 0)
    }
  }

  const handleCodePromo = async () => {
    setLoading(true)
    setErrorCodePromo(false)
    try {
      const result = await Api.ateliersUtilisateurs.edit(atelierUser.id, {
        codePromoTexte: codePromo,
      })

      const handling = handlingErrors(result)

      if (handling.ok) {
        const res = handling.data
        setAtelierUser(res)
        setCodePromo('')
        //onSuccess()
      } else {
        setErrorCodePromo(true)
      }
    } catch (error) {
      console.log(error)
      setErrorCodePromo(true)
    }
    setLoading(false)
  }

  const onSubmit = async (data) => {
    //setErr('')
    setLoading(true)

    const atelierUser = {
      atelier: `/api/ateliers/${atelier.id}`,
      prix: atelier.prix,
    }

    //return
    const obj = {
      ...data,
      roles: [role],
      password: 'userPass',
      pays: selectedCountry.label,
      ville: selectedCity,
      userAteliers: [atelierUser],
      status: 'inscription_atelier',
      entreprise:
        type === 'entreprise'
          ? {
            /* besoin: data.besoin, */
            /*fonction: data.fonction,*/
            fonctions: `/api/fonctions/${fonctionSelect.id}`,
            raisonSocial: data.raisonSocial,
            disponibilites: SLOT_DEFAULT_VALUE,
            dureeRdv: parseInt(DEFAULT_DUREE),
            dureeEntreRdv: parseInt(DEFAULT_DUREE_ENTRE),
          }
          : null,
      candidat:
        type === 'candidat'
          ? {
            /* besoin: data.besoin, */
            //disponible: '2022-10-01T09:00:00',
            mobilite: 50,
            poste: `/api/postes/${posteSelect.id}`,
            techno: null,
          }
          : null,
    }

    const result = await Api.user.add(obj)
    const handling = handlingErrors(result)

    if (handling.status === 401) {
      dispatch(userLogout())
      return
    }
    console.log(handling)
    if (handling.ok) {
      const res = handling.data

      /* const atelierUser = {
        utilisateur: `/api/utilisateurs/${res.id}`,
        atelier: `/api/ateliers/${atelier.id}`,
        prix: atelier.prix,
      }

      const results = await Api.ateliersUtilisateurs.add(atelierUser) */

      console.log('ok')
      setId(res.id)
      setRole(res.role)
      //setOpenCard(true)
      setLogin(true)
      setLoading(false)

      console.log(t('successMessage.registerToComplete'))

      toast.error(t('successMessage.registerToComplete'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    } else {
      if (handling.status === 422) {
        toast.error(t('successMessage.emailAlreadyUsed'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      } else {
        toast.error(t('successMessage.somethingWrong'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }
      setLoading(false)
    }
  }

  return (
    <>
      <Container
        maxWidth={'lg'}
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RegisterCard>
          <form
            style={{
              display: !openCard && currentStep === 2 ? 'none' : 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              width: '100%',
            }}
          >
            <Grid container spacing={2} className={'gridContainerLogin'}>
              {!openCard && currentStep !== 4 ? (
                <>
                  {currentStep === 0 && (
                    <>
                      <Grid item xs={12} sm={6} lg={12}>
                        <CustomFormControl fullWidth>
                          <CustomFormLabel>{t('contact.iAm')}</CustomFormLabel>
                          <Box
                            sx={{
                              display: 'flex',
                              columnGap: '20px',
                              marginTop: '10px',
                            }}
                          >
                            <RadioInputBasic
                              value={'entreprise'}
                              label={t(`contact.company`)}
                              isChecked={type === 'entreprise'}
                              handleChange={handleChangeRadio}
                            />
                            <RadioInputBasic
                              value={'candidat'}
                              label={t(`contact.candidate`)}
                              isChecked={type === 'candidat'}
                              handleChange={handleChangeRadio}
                            />
                          </Box>
                        </CustomFormControl>
                      </Grid>
                      {type === 'entreprise' && (
                        <>
                          <Grid item xs={12} sm={6} lg={12}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.companyName`)}
                              </CustomFormLabel>
                              <TextInput
                                placeholder={t('contact.companyName')}
                                width={'100%'}
                                type={'text'}
                                register={{ ...register('raisonSocial') }}
                              />
                              {errors.raisonSocial && (
                                <Typography className={'error-message'}>
                                  {errors.raisonSocial.message}
                                </Typography>
                              )}
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={12}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.function`)}
                              </CustomFormLabel>
                              <Autocomplete
                                disableClearable
                                sx={{
                                  width: '100%',
                                  backgroundColor: '#fff',
                                }}
                                id="asynchronous-postes"
                                open={open}
                                onChange={(event, newValue) => {
                                  setFonctionSelect(newValue)
                                }}
                                onKeyDown={(event, newInputValue) => {
                                  clearTimeout(typingTimer)
                                  if (event.target.value) {
                                    typingTimer = setTimeout(function () {
                                      getFonctions(event.target.value)
                                    }, doneTypingInterval)
                                  }
                                }}
                                onOpen={() => {
                                  setOpen(true)
                                }}
                                onClose={() => {
                                  setOpen(false)
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.titre === value.titre
                                }
                                getOptionLabel={(option) =>
                                  i18n.language === 'fr'
                                    ? option.titre
                                    : option.titre
                                }
                                options={fonctions}
                                loading={loadingFonction}
                                renderInput={(params) => (
                                  <TextField
                                    id="text-asynchronous-fonctions"
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {loadingFonction ? (
                                            <CircularProgress
                                              color="inherit"
                                              size={20}
                                            />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                )}
                              />

                              {/* <SelectInputBasic
                              items={fonctions}
                              value={fonctionSelect}
                              handleChange={handleFonctionSelect}
                            /> */}

                              {errors.fonction && (
                                <Typography className={'error-message'}>
                                  {errors.fonction.message}
                                </Typography>
                              )}
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.firstName`)}
                              </CustomFormLabel>
                              <TextInput
                                placeholder={t('contact.firstName')}
                                width={'100%'}
                                type={'text'}
                                register={{ ...register('prenom') }}
                              />
                              {errors.prenom && (
                                <Typography className={'error-message'}>
                                  {errors.prenom.message}
                                </Typography>
                              )}
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.lastName`)}
                              </CustomFormLabel>
                              <TextInput
                                placeholder={t('contact.lastName')}
                                width={'100%'}
                                type={'text'}
                                register={{ ...register('nom') }}
                              />
                              {errors.nom && (
                                <Typography className={'error-message'}>
                                  {errors.nom.message}
                                </Typography>
                              )}
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={12}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.email`)}
                              </CustomFormLabel>
                              <TextInput
                                placeholder={t('contact.email')}
                                width={'100%'}
                                type={'email'}
                                register={{ ...register('email') }}
                              />
                              {errors.email && (
                                <Typography className={'error-message'}>
                                  {errors.email.message}
                                </Typography>
                              )}
                            </CustomFormControl>
                          </Grid>
                        </>
                      )}

                      {type === 'candidat' && (
                        <>
                          <Grid item xs={12} sm={6} lg={6}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.firstName`)}
                              </CustomFormLabel>
                              <TextInput
                                placeholder={t('contact.firstName')}
                                width={'100%'}
                                type={'text'}
                                register={{ ...register('prenom') }}
                              />
                              {errors.prenom && (
                                <Typography className={'error-message'}>
                                  {errors.prenom.message}
                                </Typography>
                              )}
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.lastName`)}
                              </CustomFormLabel>
                              <TextInput
                                placeholder={t('contact.lastName')}
                                width={'100%'}
                                type={'text'}
                                register={{ ...register('nom') }}
                              />
                              {errors.nom && (
                                <Typography className={'error-message'}>
                                  {errors.nom.message}
                                </Typography>
                              )}
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={12}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.email`)}
                              </CustomFormLabel>
                              <TextInput
                                placeholder={t('contact.email')}
                                width={'100%'}
                                type={'email'}
                                register={{ ...register('email') }}
                              />
                              {errors.email && (
                                <Typography className={'error-message'}>
                                  {errors.email.message}
                                </Typography>
                              )}
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={12}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.number`)}
                              </CustomFormLabel>
                              <Controller
                                name="phone-input"
                                control={control}
                                rules={{
                                  validate: (value) =>
                                    isValidPhoneNumber(value),
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <PhoneInput
                                    international
                                    value={phoneValue}
                                    onChange={setPhoneValue}
                                    defaultCountry="CH"
                                    id="phone-input"
                                    {...register('telephone')}
                                  />
                                )}
                              />

                              {errors.telephone && (
                                <Typography className={'error-message'}>
                                  {errors.telephone.message}
                                </Typography>
                              )}
                            </CustomFormControl>
                          </Grid>
                        </>
                      )}
                    </>
                  )}

                  {currentStep === 1 && (
                    <>
                      {type === 'entreprise' && (
                        <>
                          <Grid item xs={12} sm={6} lg={6}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.number`)}
                              </CustomFormLabel>
                              <Controller
                                name="phone-input"
                                control={control}
                                rules={{
                                  validate: (value) =>
                                    isValidPhoneNumber(value),
                                }}
                                render={({ field: { onChange, value } }) => (
                                  <PhoneInput
                                    international
                                    value={phoneValue}
                                    onChange={setPhoneValue}
                                    defaultCountry="CH"
                                    id="phone-input"
                                    {...register('telephone')}
                                  />
                                )}
                              />

                              {errors.telephone && (
                                <Typography className={'error-message'}>
                                  {errors.telephone.message}
                                </Typography>
                              )}
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.country`)}
                              </CustomFormLabel>
                              <Autocomplete
                                disableClearable
                                sx={{
                                  width: '100%',
                                  backgroundColor: '#fff',
                                }}
                                value={selectedCountry ? selectedCountry : {}}
                                onChange={(event, newValue) => {
                                  handleCountryChange(newValue)
                                }}
                                id="country-select"
                                options={countries}
                                autoHighlight
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                  >
                                    <img
                                      loading="lazy"
                                      width="20"
                                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                      alt=""
                                    />
                                    {option.label} ({option.code})
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                    }}
                                  />
                                )}
                              />
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} lg={12}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.address`)}
                              </CustomFormLabel>
                              <TextInput
                                placeholder={t('contact.address')}
                                width={'100%'}
                                type={'text'}
                                register={{ ...register('adresse') }}
                              />
                              {errors.adresse && (
                                <Typography className={'error-message'}>
                                  {errors.adresse.message}
                                </Typography>
                              )}
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.state`)}
                              </CustomFormLabel>
                              <Autocomplete
                                sx={{
                                  width: '100%',
                                  backgroundColor: '#fff',
                                }}
                                value={selectedState ? selectedState : null}
                                onChange={(event, newValue) => {
                                  setSelectedState(newValue)
                                }}
                                freeSolo={true}
                                id="state-select"
                                options={states}
                                onKeyUp={(event, newInputValue) => {
                                  clearTimeout(typingTimer)
                                  if (event.target.value) {
                                    typingTimer = setTimeout(function () {
                                      filteredStatesOptions(event.target.value)
                                    }, doneTypingInterval)
                                  } else {
                                    setStates(defaultState)
                                  }
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                  >
                                    {option.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    onChange={(event) => {
                                      setSelectedState({
                                        name: event.target.value,
                                      })
                                    }}
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                    }}
                                  />
                                )}
                              />
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.city`)}
                              </CustomFormLabel>
                              <Autocomplete
                                sx={{
                                  width: '100%',
                                  backgroundColor: '#fff',
                                }}
                                value={selectedCity ? selectedCity : null}
                                onChange={(event, newValue) => {
                                  setSelectedCity(newValue)
                                }}
                                id="citie-select"
                                options={cities}
                                onKeyUp={(event, newInputValue) => {
                                  clearTimeout(typingTimer)
                                  if (event.target.value) {
                                    typingTimer = setTimeout(function () {
                                      filteredCitiesOptions(event.target.value)
                                    }, doneTypingInterval)
                                  } else {
                                    setCities(defaultCities)
                                  }
                                }}
                                autoHighlight
                                //getOptionLabel={(option) => option.label}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                  >
                                    {option}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                    }}
                                  />
                                )}
                              />
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={12}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.zipCode`)}
                              </CustomFormLabel>
                              <TextInput
                                placeholder={t('contact.zipCode')}
                                width={'100%'}
                                type={'text'}
                                register={{ ...register('codePostal') }}
                              />
                              {errors.codePostal && (
                                <Typography className={'error-message'}>
                                  {errors.codePostal.message}
                                </Typography>
                              )}
                            </CustomFormControl>
                          </Grid>
                        </>
                      )}

                      {type === 'candidat' && (
                        <>
                          <Grid item xs={12} sm={12} lg={12}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.job`)}
                              </CustomFormLabel>
                              <Autocomplete
                                disableClearable
                                sx={{
                                  width: '100%',
                                  backgroundColor: '#fff',
                                }}
                                id="asynchronous-postes"
                                open={open}
                                onChange={(event, newValue) => {
                                  setPosteSelect(newValue)
                                }}
                                onKeyDown={(event, newInputValue) => {
                                  clearTimeout(typingTimer)
                                  if (event.target.value) {
                                    typingTimer = setTimeout(function () {
                                      getPostes(event.target.value)
                                    }, doneTypingInterval)
                                  }
                                }}
                                onOpen={() => {
                                  setOpen(true)
                                }}
                                onClose={() => {
                                  setOpen(false)
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.titre === value.titre
                                }
                                getOptionLabel={(option) =>
                                  i18n.language === 'fr'
                                    ? option.titre
                                    : option.titreEn
                                }
                                options={postes}
                                loading={loadingPoste}
                                renderInput={(params) => (
                                  <TextField
                                    id="text-asynchronous-postes"
                                    {...params}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <React.Fragment>
                                          {loadingPoste ? (
                                            <CircularProgress
                                              color="inherit"
                                              size={20}
                                            />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                        </React.Fragment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={12}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.address`)}
                              </CustomFormLabel>
                              <TextInput
                                placeholder={t('contact.address')}
                                width={'100%'}
                                type={'text'}
                                register={{ ...register('adresse') }}
                              />
                              {errors.adresse && (
                                <Typography className={'error-message'}>
                                  {errors.adresse.message}
                                </Typography>
                              )}
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.country`)}
                              </CustomFormLabel>
                              <Autocomplete
                                disableClearable
                                sx={{
                                  width: '100%',
                                  backgroundColor: '#fff',
                                }}
                                value={selectedCountry ? selectedCountry : {}}
                                onChange={(event, newValue) => {
                                  handleCountryChange(newValue)
                                }}
                                id="country-select"
                                options={countries}
                                autoHighlight
                                getOptionLabel={(option) => option.label}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                  >
                                    <img
                                      loading="lazy"
                                      width="20"
                                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                      alt=""
                                    />
                                    {option.label} ({option.code})
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                    }}
                                  />
                                )}
                              />
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.state`)}
                              </CustomFormLabel>
                              <Autocomplete
                                sx={{
                                  width: '100%',
                                  backgroundColor: '#fff',
                                }}
                                value={selectedState ? selectedState : null}
                                onChange={(event, newValue) => {
                                  setSelectedState(newValue)
                                }}
                                freeSolo={true}
                                id="state-select"
                                options={states}
                                onKeyUp={(event, newInputValue) => {
                                  clearTimeout(typingTimer)
                                  if (event.target.value) {
                                    typingTimer = setTimeout(function () {
                                      filteredStatesOptions(event.target.value)
                                    }, doneTypingInterval)
                                  } else {
                                    setStates(defaultState)
                                  }
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                  >
                                    {option.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    onChange={(event) => {
                                      setSelectedState({
                                        name: event.target.value,
                                      })
                                    }}
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                    }}
                                  />
                                )}
                              />
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.city`)}
                              </CustomFormLabel>
                              <Autocomplete
                                sx={{
                                  width: '100%',
                                  backgroundColor: '#fff',
                                }}
                                value={selectedCity ? selectedCity : null}
                                onChange={(event, newValue) => {
                                  setSelectedCity(newValue)
                                }}
                                freeSolo={true}
                                id="citie-select"
                                options={cities}
                                onKeyUp={(event, newInputValue) => {
                                  clearTimeout(typingTimer)
                                  if (event.target.value) {
                                    typingTimer = setTimeout(function () {
                                      filteredCitiesOptions(event.target.value)
                                    }, doneTypingInterval)
                                  } else {
                                    setCities(defaultCities)
                                  }
                                }}
                                autoHighlight
                                //getOptionLabel={(option) => option.label}
                                renderOption={(props, option) => (
                                  <Box
                                    component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                  >
                                    {option}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    onChange={(event) => {
                                      setSelectedCity(event.target.value)
                                    }}
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                    }}
                                  />
                                )}
                              />
                            </CustomFormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} lg={6}>
                            <CustomFormControl fullWidth>
                              <CustomFormLabel>
                                {t(`contact.zipCode`)}
                              </CustomFormLabel>
                              <TextInput
                                placeholder={t('contact.zipCode')}
                                width={'100%'}
                                type={'tel'}
                                register={{ ...register('codePostal') }}
                              />
                              {errors.codePostal && (
                                <Typography className={'error-message'}>
                                  {errors.codePostal.message}
                                </Typography>
                              )}
                            </CustomFormControl>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      rowGap: '50px',
                    }}
                  >
                    <Box
                      sx={{
                        width: 125,
                        height: 125,
                        borderRadius: '50%',
                        background: lightTheme.palette.gradients.gradientGreen,
                        position: 'relative',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faFaceLaugh}
                        style={{
                          position: 'absolute',
                          left: '50%',
                          top: '50%',
                          transform: 'translate(-50%, -50%)',
                          fontSize: 126,
                          color: '#FFFFFF',
                        }}
                      />
                    </Box>

                    <Typography
                      sx={{
                        fontFamily: 'Century Gothic Bold',
                        fontSize: { xs: 20, lg: 20 },
                        lineHeight: '120%',
                        color: lightTheme.palette.text.primary,
                        textTransform: 'uppercase',
                        textAlign: 'center',
                      }}
                    >
                      {t(`contact.thanksAtelier`)}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '20px',
                      }}
                    >
                      <Typography>{t(`contact.sendEmailAtelier`)}</Typography>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: '20px',
                justifyContent: openCard ? 'center' : 'flex-end',
              }}
            >
              {currentStep > 0 && !openCard && !user?.id && (
                <Box width={{ xs: '50%', md: 'auto', lg: '50%' }}>
                  <BorderButton
                    width={{ md: 180, lg: '100%' }}
                    type={'basic'}
                    displayIconRight={'none'}
                    handleClick={() => setCurrentStep(currentStep - 1)}
                  >
                    {t('contact.prevStep')}
                  </BorderButton>
                </Box>
              )}
              <Box
                width={{ xs: '50%', md: 'auto', lg: openCard ? '100%' : '50%' }}
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  !user?.id && (
                    <>
                      {!openCard ? (
                        <>
                          {currentStep === 0 ? (
                            <CustomButton
                              handleClick={nextStep}
                              width={{ md: 180, lg: '100%' }}
                            >
                              {t('contact.nextStep')}
                            </CustomButton>
                          ) : (
                            <CustomButton
                              handleClick={handleSubmit(onSubmit)}
                              width={{ md: 180, lg: '100%' }}
                              alignSelf={'end'}
                            >
                              {t('contact.nextStep')}
                            </CustomButton>
                          )}
                        </>
                      ) : (
                        <CustomButton
                          displayIcon={'none'}
                          width={{ md: 180, lg: '100%' }}
                          alignSelf={'end'}
                          handleClick={() => navigate('/')}
                        >
                          {t('contact.return')}
                        </CustomButton>
                      )}
                    </>
                  )
                )}
              </Box>
            </Box>
          </form>
          <Grid container spacing={2} className={'gridContainerLogin'}>
            {!openCard && currentStep === 2 && (
              <>
                <Grid item xs={12} sm={12} lg={12}>
                  <CustomFormControl fullWidth>
                    <CustomFormLabel sx={{ textAlign: 'center' }}>
                      {t('home.workshopPrice', { title })} <br></br>
                      {atelierUser?.codePromo && (
                        <>
                          {t('profile.price')} :{' '}
                          {(
                            atelierUser?.prix *
                            JSON.parse(sessionStorage.getItem('currency'))
                              .currentRate
                          ).toFixed(2)}{' '}
                          {
                            JSON.parse(sessionStorage.getItem('currency'))
                              .devise
                          }{' '}
                          <br></br>
                        </>
                      )}
                      {atelierUser?.codePromo
                        ? t('profile.firstPrice')
                        : t('profile.price')}{' '}
                      :{' '}
                      <b>
                        {(
                          atelierUser?.prixPromo *
                          JSON.parse(sessionStorage.getItem('currency'))
                            .currentRate
                        ).toFixed(2)}{' '}
                        {JSON.parse(sessionStorage.getItem('currency')).devise}
                      </b>
                    </CustomFormLabel>
                    {!atelierUser?.codePromo && (
                      <TextField
                        fullWidth
                        id="standard-name"
                        label={t('profile.haveYouCodePromo')}
                        placeholder={t('profile.enterCodePromo')}
                        variant="standard"
                        value={codePromo}
                        error={errorCodePromo && true}
                        helperText={
                          errorCodePromo && t('errorMessage.codePromo')
                        }
                        onChange={(event) => {
                          setCodePromo(event.target.value)
                          setErrorCodePromo(false)
                        }}
                        InputProps={{
                          endAdornment:
                            codePromo ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                fontSize={20}
                                style={{ cursor: 'pointer' }}
                                onClick={handleCodePromo}
                              />
                            ) : loading ? (
                              <CircularProgress size={20} />
                            ) : (
                              <></>
                            ),
                        }}
                      />
                    )}
                    <br></br>
                    <StripeAppTest
                      userId={id}
                      offreId={atelier?.id}
                      offrePrice={(
                        atelierUser?.prixPromo *
                        JSON.parse(sessionStorage.getItem('currency'))
                          .currentRate
                      ).toFixed(2)}
                      type={'atelier'}
                      onSuccess={() => setOpenCard(true)}
                      onClose={() => setCurrentStep(2)}
                    />
                  </CustomFormControl>
                </Grid>
              </>
            )}
          </Grid>
        </RegisterCard>
      </Container>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default Steps
