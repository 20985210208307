import { ROLE_CANDIDAT } from '../../data'
import ApiRequest from '../request/ApiRequest'

export default class OffresUtilisateurs {
  constructor() {
    this.ApiRequest = new ApiRequest()
  }

  async add(data, role = ROLE_CANDIDAT) {
    if (role === ROLE_CANDIDAT) {
      try {
        const res = await this.ApiRequest.post('offres_utilisateurs', data)
        return res
      } catch (error) {
        return error.response
      }
    } else {
      try {
        const res = await this.ApiRequest.post('entreprise_offres', data)
        return res
      } catch (error) {
        return error.response
      }
    }
  }

  async edit(id, data, role = ROLE_CANDIDAT) {
    if (role === ROLE_CANDIDAT) {
      try {
        const res = await this.ApiRequest.put(`offres_utilisateurs/${id}`, data)
        return res
      } catch (error) {
        return error.response
      }
    } else {
      try {
        const res = await this.ApiRequest.put(`entreprise_offres/${id}`, data)
        return res
      } catch (error) {
        return error.response
      }
    }
  }
}
