import React from "react";
import { Routes, Route } from "react-router-dom";
import { InscriptionLayout } from "../components/layouts";
import InscriptionEntreprise from "../pages/Inscription/InscriptionEntreprise";
import InscriptionCandidat from "../pages/Inscription/InscriptionCandidat";

/*import { InscriptionCandidat, InscriptionEntreprise } from "../pages/Inscription";*/


const InscriptionRouter = () => {
  return (
    <Routes>
      <Route element={<InscriptionLayout/>}>
        <Route path={":token"} element={<InscriptionCandidat />} />
        <Route path={"entreprise/:token"} element={<InscriptionEntreprise />} />
        <Route path={"candidat/:token"} element={<InscriptionCandidat />} />
      </Route>
    </Routes>
  )
}

export default InscriptionRouter;