import ApiRequest from "../request/ApiRequest";

export default class ForgotPassword {
  constructor() {
    this.ApiRequest = new ApiRequest();
  }

  async demandePassword(data) {
    const res = await this.ApiRequest.post("forgot_password/", data);
    return res;
  }

  async changePassword(token, data) {
    const res = await this.ApiRequest.post(`forgot_password/${token}`, data);
    return res;
  }

}