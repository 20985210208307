import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Grid,
  Modal,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import moment from 'moment-timezone'
import { Slide, ToastContainer, toast } from 'react-toastify'
import { faCircleCheck, faClose } from '@fortawesome/pro-solid-svg-icons'
import { FILE_URL, truncate } from '../../config'
import TextareaInputClassic from '../inputs/TextareaInputClassic'
import { useTranslation } from 'react-i18next'
import { ROLE_CANDIDAT, ROLE_ENTREPRISE } from '../../data'
import { ButtonRadioInput } from '../inputs'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import ButtonRadioInput2 from '../inputs/ButtonRadioInput2'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  minHeight: 100,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const EditEvent = ({
  open,
  onClose,
  onSave,
  poste,
  id,
  role,
  event,
  showParams = true,
}) => {
  const currentUser = useSelector((state) => state.userAuth.user)

  const { t, i18n } = useTranslation()
  const currentDateFive = new Date()
  const currentDateThirt = new Date()
  currentDateFive.setMinutes(currentDateFive.getMinutes() + 30)
  currentDateThirt.setMinutes(currentDateThirt.getMinutes() - 25)

  //console.log(new Date(event.dateDebut), currentDateThirt)

  const [loadingEvent, setLoadingEvent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [snapShot, setSnapShot] = useState(null)
  const [fonctionSelect, setFonctionSelect] = useState(poste)
  //const [posteSelect, setPosteSelect] = useState(poste)
  //const [errorTime, setErrorTime] = useState(false)
  const [dateValue, setDateValue] = useState(null)
  const [feedbackValue, setFeedbackValue] = useState('')
  const [errorFeedback, setErrorFeedback] = useState(false)
  const [seeMore, setSeeMore] = useState(false)

  const handleSeeMore = (event) => {
    setSeeMore(!seeMore)
  }

  const changeDate = (dateValue) => {
    setDateValue(dateValue.target.value)
    setCurrentDate(dateValue.target.value)
  }

  const changeFeedback = (e) => {
    if (e.target.value === '') {
      setErrorFeedback(true)
    } else {
      setErrorFeedback(false)
    }
    setFeedbackValue(e.target.value)
  }

  useEffect(() => {
    fetchData()
  }, [id])

  const fetchData = async () => {
    setLoadingEvent(true)
    try {
      let result = await Api.user.detail(id)

      const handling = handlingErrors(result)

      if (handling.status === 401) {
        console.log('erreur')
      }

      if (handling.ok) {
        const res = handling.data
        setSnapShot(res)
        setLoadingEvent(false)
        setFonctionSelect(res.entreprise?.fonctions?.titre)
        /* if (res.entreprise?.fonctions?.id)
          setFonctionSelect(res.entreprise?.fonctions?.titre) */
        //if (res.candidat?.poste) setPosteSelect(res.candidat?.poste)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const submitNewDateDecouverte = async () => {
    setLoading(true)

    try {
      const result = await Api.calendar.edit(event.id, {
        dateDebut: dateValue,
        dateFin: dateValue,
        statusAdmin: 'planifier',
      })
      const res = handlingErrors(result)

      if (res.ok) {
        toast.success(t('successMessage.appointmentUpdateSent'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        onSave()
      }

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoading(false)
  }

  const submitNewDate = async () => {
    setLoading(true)

    try {
      const result = await Api.calendar.edit(event.id, {
        dateDebut: dateValue,
        dateFin: dateValue,
        statusUser: 'planifier',
      })
      const res = handlingErrors(result)

      if (res.ok) {
        toast.success(t('successMessage.appointmentUpdateSent'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        onSave()
      }

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoading(false)
  }

  const statusEvent = async (data) => {
    setLoading(true)

    try {
      const result = await Api.calendar.edit(event.id, data)
      const res = handlingErrors(result)

      if (res.ok) {
        toast.success(t('successMessage.appointmentUpdateSent'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        onSave()
      }

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoading(false)
  }

  const feedbackEvent = async () => {
    if (feedbackValue === '') {
      setErrorFeedback(true)
      return
    }

    let data = {
      feedbackCandidat: feedbackValue,
    }
    if (role === ROLE_ENTREPRISE) {
      data = {
        feedbackEntreprise: feedbackValue,
      }
    }
    setLoading(true)
    try {
      const result = await Api.calendar.edit(event.id, data)
      const res = handlingErrors(result)

      if (res.ok) {
        toast.success(t('successMessage.feedbackSent'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        onSave()
      }

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoading(false)
  }

  const [loadingSlots, setLoadingSlots] = useState(false)
  const [setShedule, setSetShedule] = useState(false)
  const [cancelRdv, setCancelRdv] = useState(false)
  const [slots, setSlots] = useState([])
  const [times, setTimes] = useState('')

  const currentDate = new Date()
  const tomorrowDate = new Date()
  tomorrowDate.setDate(currentDate.getDate() + 1)
  const [value, setValue] = useState(dayjs(tomorrowDate))
  const [currentdate, setCurrentDate] = useState(dayjs(tomorrowDate))

  const handleChangeButtonRadio = (inputValue, dateValue) => {
    console.log(inputValue, dateValue)
    setTimes(inputValue)
    setCurrentDate(dateValue)
  }

  useEffect(() => {
    getDateDisponible()
  }, [value])

  const getDateDisponible = async () => {
    setLoadingSlots(true)
    const DateFormat = format(new Date(value), 'yyyy-MM-dd')

    try {
      let result = await Api.calendar.listDIspoEntreprisePerDate(
        DateFormat,
        currentUser.data.role === 'ROLE_SUPER_ADMIN'
          ? currentUser.data.id
          : event?.entreprise?.profile?.id
      )
      const handling = handlingErrors(result)

      if (handling.status === 401) {
        setLoadingSlots(false)
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (handling.ok) {
        const res = handling.data

        /* const DateValue = format(new Date(value), 'dd/MM/yyyy')
        console.log(res) */
        setSlots(res)
        setLoadingSlots(false)
      } else {
        setLoadingSlots(false)
        window.scrollTo(0, 0)
      }
    } catch (error) {
      setLoading(false)
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
      window.scrollTo(0, 0)
    }
  }

  const newSheduleEvent = async () => {
    setLoading(true)

    /* console.log(currentdate, dayjs(currentdate, "DD/MM/YYYY").format("YYYY-MM-DD"), times)
return; */
    const DateValue = dayjs(currentdate, "DD/MM/YYYY").format("YYYY-MM-DD")
    let data = {
      dateDebut: `${DateValue}T${times}`,
      dateFin: `${DateValue}T${times}`,
    }
    if (currentUser.data.role === 'ROLE_SUPER_ADMIN') {
      data.statusAdmin = 'replanifier'
    } else {
      data.statusUser = 'replanifier'
    }

   /*  console.log(data)
    return */

    try {
      const result = await Api.calendar.edit(event.id, data)
      const res = handlingErrors(result)

      if (res.ok) {
        toast.success(t('successMessage.appointmentUpdateSent'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        onSave()
      }

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoading(false)
  }

  const handleDownloadRdv = () => {
    const icsContent = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'PRODID:-//VotreApplication//NONSGML v1.0//EN',
      'BEGIN:VEVENT',
      'UID:' + snapShot?.email,
      'DTSTAMP:' +
        moment(event.dateDebut).tz('Europe/Paris').format('YYYYMMDD') +
        'T' +
        moment(event.dateDebut).tz('Europe/Paris').format('HHmm00') +
        'Z',
      'DTSTART:' +
        moment(event.dateDebut).tz('Europe/Paris').format('YYYYMMDD') +
        'T' +
        moment(event.dateDebut).tz('Europe/Paris').format('HHmm00'),
      'DTEND:' +
        moment(event.dateDebut).tz('Europe/Paris').format('YYYYMMDD') +
        'T' +
        moment(event.dateDebut)
          .tz('Europe/Paris')
          .add(30, 'minutes')
          .format('HHmm00'),
      'SUMMARY:Rendez-vous Jobertech',
      'DESCRIPTION:Lien : ' + event.lien,
      'LOCATION:' + event.lien,
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\n')

    const blob = new Blob([icsContent], { type: 'text/calendar' })
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.download = 'rdv' + event.id + '.ics'
    link.click()

    URL.revokeObjectURL(url)
  }

  if (event.type === 'rdv_entreprise')
    return (
      <>
        <Modal
          open={open}
          sx={{
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(51, 51, 51, 0.65)',
            },
          }}
          style={{ overflow: 'auto' }}
        >
          <ModalContainer>
            <Typography variant={'subtitle2'} textTransform={'uppercase'}>
              {t('box.title.meetingSuggestion')}
              <Box
                sx={{
                  position: 'absolute',
                  right: '10px',
                  top: '10px',
                  cursor: 'pointer',
                }}
              >
                <FontAwesomeIcon
                  icon={faClose}
                  fontSize={25}
                  onClick={onClose}
                />
              </Box>
            </Typography>
            <BoxContainer>
              {loadingEvent ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  <CircularProgress />
                </Grid>
              ) : (
                <form>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    {currentUser.data.role !== ROLE_CANDIDAT &&
                      currentUser.data.role !== ROLE_ENTREPRISE && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: '5px',
                            }}
                          >
                            <Typography>
                              <b> {t('contact.candidate')} :</b>{' '}
                              {'#Jober000' +
                                event?.candidat?.id +
                                ' ' +
                                event?.candidat?.profile?.nom +
                                ' ' +
                                event?.candidat?.profile?.prenom}
                            </Typography>
                          </Grid>
                        </>
                      )}
                    {currentUser.data.role === ROLE_ENTREPRISE && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <Typography>
                            <b> {t('contact.candidate')} :</b>{' '}
                            {'#Jober000' + event?.candidat?.id}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    {role !== ROLE_ENTREPRISE && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={role !== ROLE_ENTREPRISE ? 6 : 12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <Typography>
                            <b>{t('contact.company')} :</b>{' '}
                            {event?.entreprise?.raisonSocial}{' '}
                            {snapShot?.entreprise?.raisonSocial}
                          </Typography>
                        </Grid>
                      </>
                    )}
                    {poste && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <Typography>
                          <b>{t('filter.poste')} :</b> {poste}
                        </Typography>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '5px',
                      }}
                    >
                      <Typography>
                        <b>{t('profile.date')} :</b>{' '}
                        {moment(event.dateDebut)
                          .tz('Europe/Paris')
                          .format('DD/MM/YYYY')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '5px',
                      }}
                    >
                      <Typography>
                        <b>{t('profile.hour')} :</b>{' '}
                        {moment(event.dateDebut)
                          .tz('Europe/Paris')
                          .format('HH:mm')}
                      </Typography>
                    </Grid>
                    {role !== ROLE_ENTREPRISE &&
                      event?.entreprise?.presentation && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <Typography>
                            <b>{t('box.businessPresentation2')} :</b>{' '}
                            {seeMore
                              ? event?.entreprise?.presentation
                              : truncate(event?.entreprise?.presentation)}
                            {event?.entreprise?.presentation?.length > 150 && (
                              <a
                                href={
                                  seeMore
                                    ? '#' + t('button.seeLess')
                                    : '#' + t('button.seeMore')
                                }
                                style={{
                                  cursor: 'pointer',
                                  color: '#96A0FF',
                                  fontWeight: 'bold',
                                  textDecoration: 'underline',
                                }}
                                onClick={handleSeeMore}
                              >
                                {seeMore
                                  ? t('button.seeLess')
                                  : t('button.seeMore')}
                              </a>
                            )}
                          </Typography>
                        </Grid>
                      )}
                    {event.statusUser === 'accepter' &&
                      event.candidat?.profile?.documents.find(
                        (obj) => obj.type === 'Cv' || obj.type === 'cv'
                      ) &&
                      currentDateFive > new Date(event.dateDebut) && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <Typography>
                            <b>{t('box.candidateCv')} :</b>{' '}
                            <a
                              href={
                                FILE_URL +
                                event.candidat?.profile?.documents.find(
                                  (obj) =>
                                    obj.type === 'Cv' || obj.type === 'cv'
                                ).nom
                              }
                              target={'_blank'}
                              rel="noreferrer"
                            >
                              {
                                event.candidat?.profile?.documents.find(
                                  (obj) =>
                                    obj.type === 'Cv' || obj.type === 'cv'
                                ).nom
                              }
                            </a>
                          </Typography>
                        </Grid>
                      )}
                    {event.statusUser === 'accepter' && event.lien && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <Typography>
                            <b>{t('box.videoconference')} :</b>{' '}
                            <a
                              href={event.lien}
                              target={'_blank'}
                              rel="noreferrer"
                            >
                              {t('button.clickHere')}
                            </a>
                          </Typography>
                        </Grid>
                        {/* <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <Typography>
                            <b>{t('box.google')} :</b>{' '}
                            <a
                              href={
                                'https://www.google.com/calendar/render?action=TEMPLATE&text=Rendez-vous+Jobertech&dates=' +
                                moment(event.dateDebut)
                                  .tz('Europe/Paris')
                                  .format('YYYYMMDD') +
                                'T' +
                                moment(event.dateDebut)
                                  .tz('Europe/Paris')
                                  .format('HHmm00Z') +
                                '/' +
                                moment(event.dateDebut)
                                  .tz('Europe/Paris')
                                  .format('YYYYMMDD') +
                                'T' +
                                moment(event.dateDebut)
                                  .tz('Europe/Paris')
                                  .format('HHmm00Z') +
                                '&details=Lien+:+' +
                                event.lien
                              }
                              target={'_blank'}
                              rel="noreferrer"
                            >
                              {t('button.clickHere')}
                            </a>
                          </Typography>
                        </Grid> */}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <Typography>
                            <b>{t('box.downloadRdv')} :</b>{' '}
                            <a
                              href={'#'}
                              rel="noreferrer"
                              onClick={handleDownloadRdv}
                            >
                              {t('button.clickHere')}
                            </a>
                          </Typography>
                        </Grid>
                      </>
                    )}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '5px',
                      }}
                    >
                      <Typography>
                        <b>{t('box.appointmentStatus')} : </b>{' '}
                        {event.statusUser
                          ? t('box.' + event.statusUser)
                          : t('box.proposal')}
                      </Typography>
                    </Grid>

                    {role !== ROLE_ENTREPRISE && setShedule && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <Typography>
                            <b>{t(`contact.date`)}</b>
                          </Typography>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={'fr'}
                          >
                            <DatePicker
                              label="Custom input"
                              disablePast
                              inputFormat={'DD/MM/YYYY'}
                              value={value}
                              minDate={tomorrowDate}
                              onChange={(newValue) => {
                                setValue(newValue)
                              }}
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '45px',
                                    backgroundColor:
                                      lightTheme.palette.primary.b2,
                                    borderRadius: '5px',

                                    '& .css-1laqsz7-MuiInputAdornment-root, & .MuiInputAdornment-root':
                                      {
                                        height: '100%',
                                        width: '50px',
                                        margin: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',

                                        '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root, & .root-MuiIconButton-root':
                                          {
                                            margin: 0,
                                            color: '#FFFFFF',
                                          },
                                      },
                                  }}
                                >
                                  <input
                                    ref={inputRef}
                                    {...inputProps}
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      borderRadius: '5px 0 0 5px',
                                      border: 'none',
                                      padding: '10px 15px',
                                      fontFamily: 'Century Gothic',
                                      fontSize: 14,
                                      lineHeight: '140%',
                                      letterSpacing: '0.2px',
                                      color: lightTheme.palette.text.primary,
                                      backgroundColor: '#FFFFFF',
                                    }}
                                  />
                                  {InputProps?.endAdornment}
                                </Box>
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <Typography>
                            <b>{t(`contact.slots`)}</b>
                          </Typography>
                          {loadingSlots ? (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <CircularProgress />
                            </Grid>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                /*flexWrap: "wrap",*/
                                rowGap: '10px',

                                height: 200,
                                overflowY: 'scroll',
                                marginTop: '10px',

                                '&::-webkit-scrollbar': {
                                  width: 10,
                                },

                                '&::-webkit-scrollbar-track': {
                                  background: lightTheme.palette.primary.b5,
                                },

                                '&::-webkit-scrollbar-thumb': {
                                  background: lightTheme.palette.primary.b3,
                                },

                                '&::-webkit-scrollbar-thumb:hover': {
                                  background: lightTheme.palette.primary.b2,
                                },
                              }}
                            >
                              {Object.entries(slots).map(
                                ([date, slotTimes]) => (
                                  <>
                                    <h2>{date}</h2>
                                    {slotTimes.length > 0 ? <>
                                      {slotTimes.map((time, index) => {
                                        return (
                                          <ButtonRadioInput2
                                            value={time}
                                            date={date}
                                            handleChange={
                                              handleChangeButtonRadio
                                            }
                                            border={'1px solid #9E9E9E'}
                                            width={'80%'}
                                            spanHeight={'30px'}
                                            isChecked={
                                              times === time &&
                                              date === currentdate
                                            }
                                          >
                                            {time}
                                          </ButtonRadioInput2>
                                        )
                                      })}
                                    </> : <>--------------------</>}
                                  </>
                                )
                              )}
                            </Box>
                          )}
                        </Grid>
                      </>
                    )}

                    {role !== ROLE_CANDIDAT &&
                      event.statusUser === 'replanifier' && (
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18n.language}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: '5px',
                            }}
                          >
                            <Typography>
                              <b>{t('box.scheduleNewDate')}</b>
                            </Typography>
                            <TextField
                              type="datetime-local"
                              onChange={changeDate}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 900,
                              }}
                              defaultValue={
                                moment(event.dateDebut)
                                  .tz('Europe/Paris')
                                  .format('yyyy-MM-DD') +
                                'T' +
                                moment(event.dateDebut)
                                  .tz('Europe/Paris')
                                  .format('HH:mm')
                              }
                            />

                            {/* {errorTime && (
                              <Typography className={'error-message'}>
                                {t('errorMessage.date')}
                              </Typography>
                            )} */}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              rowGap: '5px',
                            }}
                          >
                            <Box className={'blockButtons'}>
                              {loading ? (
                                <CircularProgress />
                              ) : (
                                <>
                                  <CustomButton
                                    displayIcon={'none'}
                                    handleClick={submitNewDate}
                                  >
                                    {t('button.reschedule')}
                                  </CustomButton>
                                </>
                              )}
                            </Box>
                          </Grid>
                        </LocalizationProvider>
                      )}
                    {(role === ROLE_ENTREPRISE || role === ROLE_CANDIDAT) &&
                      event.statusUser === 'accepter' &&
                      currentDateThirt > new Date(event.dateDebut) && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          {role === ROLE_ENTREPRISE &&
                          event.feedbackEntreprise ? (
                            <Typography>
                              <b>{t('box.yourFeedback')}</b> <br></br>
                              {event.feedbackEntreprise}
                            </Typography>
                          ) : role === ROLE_CANDIDAT &&
                            event.feedbackCandidat ? (
                            <Typography>
                              <b>{t('box.yourFeedback')}</b> <br></br>
                              {event.feedbackCandidat}
                            </Typography>
                          ) : (
                            <>
                              <Typography>
                                <b>{t('box.giveYourFeedback')}</b>
                              </Typography>

                              <TextareaInputClassic
                                placeholder={'Feedback'}
                                width={'100%'}
                                onChange={changeFeedback}
                              />
                            </>
                          )}

                          {errorFeedback && (
                            <Typography className={'error-message'}>
                              {t('errorMessage.feedback')}
                            </Typography>
                          )}
                        </Grid>
                      )}
                    {role !== ROLE_ENTREPRISE &&
                      role !== ROLE_CANDIDAT &&
                      event.statusUser === 'accepter' &&
                      (event.feedbackEntreprise || event.feedbackCandidat) && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          {event.feedbackEntreprise && (
                            <Typography>
                              <b>{t('box.companyFeedback')}</b> <br></br>
                              {event.feedbackEntreprise}
                            </Typography>
                          )}

                          {event.feedbackCandidat && (
                            <Typography>
                              <b>{t('box.candidateFeedback')}</b> <br></br>
                              {event.feedbackCandidat}
                            </Typography>
                          )}
                        </Grid>
                      )}
                  </Grid>
                </form>
              )}
            </BoxContainer>
            {/*<Box className={"wrapperButtons"} width={"100%"}>*/}

            {((role === ROLE_ENTREPRISE && !event.feedbackEntreprise) ||
              (role === ROLE_CANDIDAT && !event.feedbackCandidat)) &&
              event.statusUser === 'accepter' &&
              currentDateThirt > new Date(event.dateDebut) && (
                <Box className={'blockButtons'}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <CustomButton
                      displayIcon={'none'}
                      handleClick={(e) => {
                        feedbackEvent()
                      }}
                    >
                      {t('button.giveYourFeedback')}
                    </CustomButton>
                  )}
                </Box>
              )}
            {role === ROLE_CANDIDAT &&
              event.statusUser !== 'accepter' &&
              event.statusUser !== 'refuser' &&
              event.statusUser !== 'replanifier' &&
              event.statusUser !== 'annuler' && (
                <Box className={'blockButtons'}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {!setShedule ? (
                        <>
                          <BorderButtonPurple
                            displayIcon={'none'}
                            handleClick={(e) => {
                              statusEvent({ statusUser: 'refuser' })
                            }}
                          >
                            {t('button.refuse')}
                          </BorderButtonPurple>
                          <CustomButton
                            displayIcon={'none'}
                            handleClick={(e) => {
                              statusEvent({ statusUser: 'accepter' })
                            }}
                          >
                            {t('button.accept')}
                          </CustomButton>
                        </>
                      ) : (
                        <BorderButtonPurple
                          displayIcon={'none'}
                          handleClick={(e) => {
                            setSetShedule(false)
                          }}
                        >
                          {t('button.cancel')}
                        </BorderButtonPurple>
                      )}
                      <CustomButton
                        displayIcon={'none'}
                        handleClick={(e) => {
                          //statusEvent({ statusUser: 'replanifier' })
                          setShedule ? newSheduleEvent() : setSetShedule(true)
                        }}
                      >
                        {t('button.reschedule')}
                      </CustomButton>
                    </>
                  )}
                </Box>
              )}

            {cancelRdv && (
              <Typography>
                <b>{t('button.annulerRdv')}</b>
              </Typography>
            )}
            {event.statusUser === 'accepter' && !setShedule && (
              <>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Box className={'blockButtons'}>
                    {!cancelRdv ? (
                      <CustomButton
                        displayIcon={'none'}
                        handleClick={(e) => {
                          setCancelRdv(true)
                        }}
                      >
                        {t('button.cancel')}
                      </CustomButton>
                    ) : (
                      <>
                        <BorderButtonPurple
                          displayIcon={'none'}
                          handleClick={(e) => {
                            setCancelRdv(false)
                          }}
                        >
                          {t('button.no')}
                        </BorderButtonPurple>
                        <CustomButton
                          displayIcon={'none'}
                          handleClick={(e) => {
                            statusEvent({ statusUser: 'annuler' })
                          }}
                        >
                          {t('button.yes')}
                        </CustomButton>
                      </>
                    )}
                  </Box>
                )}
              </>
            )}
            {/*</Box>*/}
          </ModalContainer>
        </Modal>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          closeButton={false}
          transition={Slide}
          className={'toast'}
        />
      </>
    )

  return (
    <>
      <Modal
        open={open}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(51, 51, 51, 0.65)',
          },
        }}
      >
        <ModalContainer>
          <Typography variant={'subtitle2'} textTransform={'uppercase'}>
            {t('profile.rdv')}
            <Box
              sx={{
                position: 'absolute',
                right: '10px',
                top: '10px',
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
            </Box>
          </Typography>
          <BoxContainer>
            {loadingEvent ? (
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: 'center',
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <form>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                  }}
                >
                  {role === ROLE_ENTREPRISE && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <Typography>
                          <b>{t('header.company')} :</b>{' '}
                          {snapShot?.entreprise?.raisonSocial}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <Typography>
                          <b>{t('contact.function')} :</b> {fonctionSelect}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  <Grid
                    item
                    xs={12}
                    sm={role !== ROLE_ENTREPRISE && poste ? 6 : 12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <Typography>
                      <b>{t('box.referent')} :</b> {snapShot?.nom}{' '}
                      {snapShot?.prenom}
                    </Typography>
                  </Grid>
                  {role !== ROLE_ENTREPRISE && poste && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '5px',
                      }}
                    >
                      <Typography>
                        <b>{t('filter.poste')} :</b> {poste}
                      </Typography>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <Typography>
                      <b>{t('profile.date')} :</b>{' '}
                      {moment(event.dateDebut)
                        .tz('Europe/Paris')
                        .format('DD/MM/YYYY')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <Typography>
                      <b>{t('profile.hour')} :</b>{' '}
                      {moment(event.dateDebut)
                        .tz('Europe/Paris')
                        .format('HH:mm')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <Typography>
                      <b>{t('box.moreInformations')} :</b>{' '}
                      {snapShot?.candidat?.besoin}{' '}
                      {snapShot?.entreprise?.besoin}
                    </Typography>
                  </Grid>

                  {event.statusAdmin === 'accepter' && event.lien && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '5px',
                      }}
                    >
                      <Typography>
                        <b>{t('box.videoconference')} :</b>{' '}
                        <a href={event.lien} target={'_blank'} rel="noreferrer">
                          {t('button.clickHere')}
                        </a>
                      </Typography>
                    </Grid>
                  )}
                  {event.statusAdmin && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '5px',
                      }}
                    >
                      <Typography>
                        <b>{t('box.appointmentStatus')} :</b>{' '}
                        {t('box.' + event.statusAdmin)}
                      </Typography>
                    </Grid>
                  )}

                  {role !== ROLE_CANDIDAT && setShedule && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <Typography>
                          <b>{t(`contact.date`)}</b>
                        </Typography>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={'fr'}
                        >
                          <DatePicker
                            label="Custom input"
                            disablePast
                            inputFormat={'DD/MM/YYYY'}
                            value={value}
                            minDate={tomorrowDate}
                            onChange={(newValue) => {
                              setValue(newValue)
                            }}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  width: '100%',
                                  height: '45px',
                                  backgroundColor:
                                    lightTheme.palette.primary.b2,
                                  borderRadius: '5px',

                                  '& .css-1laqsz7-MuiInputAdornment-root, & .MuiInputAdornment-root':
                                    {
                                      height: '100%',
                                      width: '50px',
                                      margin: 0,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',

                                      '& .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root, & .root-MuiIconButton-root':
                                        {
                                          margin: 0,
                                          color: '#FFFFFF',
                                        },
                                    },
                                }}
                              >
                                <input
                                  ref={inputRef}
                                  {...inputProps}
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '5px 0 0 5px',
                                    border: 'none',
                                    padding: '10px 15px',
                                    fontFamily: 'Century Gothic',
                                    fontSize: 14,
                                    lineHeight: '140%',
                                    letterSpacing: '0.2px',
                                    color: lightTheme.palette.text.primary,
                                    backgroundColor: '#FFFFFF',
                                  }}
                                />
                                {InputProps?.endAdornment}
                              </Box>
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '5px',
                        }}
                      >
                        <Typography>
                          <b>{t(`contact.slots`)}</b>
                        </Typography>
                        {loadingSlots ? (
                          <Grid
                            item
                            xs={12}
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <CircularProgress />
                          </Grid>
                        ) : (
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              /*flexWrap: "wrap",*/
                              rowGap: '10px',

                              height: 200,
                              overflowY: 'scroll',
                              marginTop: '10px',

                              '&::-webkit-scrollbar': {
                                width: 10,
                              },

                              '&::-webkit-scrollbar-track': {
                                background: lightTheme.palette.primary.b5,
                              },

                              '&::-webkit-scrollbar-thumb': {
                                background: lightTheme.palette.primary.b3,
                              },

                              '&::-webkit-scrollbar-thumb:hover': {
                                background: lightTheme.palette.primary.b2,
                              },
                            }}
                          >
                            {slots.map((slot) => {
                              return (
                                <ButtonRadioInput
                                  value={slot}
                                  handleChange={handleChangeButtonRadio}
                                  border={'1px solid #9E9E9E'}
                                  width={'80%'}
                                  spanHeight={'30px'}
                                  isChecked={times === slot}
                                >
                                  {slot.replace(/:/g, 'h').substr(0, 5)}
                                </ButtonRadioInput>
                              )
                            })}
                          </Box>
                        )}
                      </Grid>
                    </>
                  )}

                  {role === ROLE_CANDIDAT &&
                    event.statusAdmin === 'replanifier' && (
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={i18n.language}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <Typography>
                            <b>{t('box.scheduleNewDate')}</b>
                          </Typography>
                          <TextField
                            type="datetime-local"
                            onChange={changeDate}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 900,
                            }}
                            defaultValue={
                              moment(event.dateDebut)
                                .tz('Europe/Paris')
                                .format('yyyy-MM-DD') +
                              'T' +
                              moment(event.dateDebut)
                                .tz('Europe/Paris')
                                .format('HH:mm')
                            }
                          />

                          {/* {errorTime && (
                            <Typography className={'error-message'}>
                              {t('errorMessage.date')}
                            </Typography>
                          )} */}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: '5px',
                          }}
                        >
                          <Box className={'blockButtons'}>
                            {loading ? (
                              <CircularProgress />
                            ) : (
                              <>
                                <CustomButton
                                  displayIcon={'none'}
                                  handleClick={submitNewDateDecouverte}
                                >
                                  {t('button.reschedule')}
                                </CustomButton>
                              </>
                            )}
                          </Box>
                        </Grid>
                      </LocalizationProvider>
                    )}
                </Grid>
              </form>
            )}
          </BoxContainer>
          {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
          {showParams && (
            <Box className={'blockButtons'}>
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  {!setShedule ? (
                    <>
                      <BorderButtonPurple
                        displayIcon={'none'}
                        handleClick={(e) => {
                          statusEvent({ statusAdmin: 'refuser' })
                        }}
                      >
                        {t('button.refuse')}
                      </BorderButtonPurple>
                      <CustomButton
                        displayIcon={'none'}
                        handleClick={(e) => {
                          statusEvent({ statusAdmin: 'accepter' })
                        }}
                      >
                        {t('button.accept')}
                      </CustomButton>
                    </>
                  ) : (
                    <BorderButtonPurple
                      displayIcon={'none'}
                      handleClick={(e) => {
                        setSetShedule(false)
                      }}
                    >
                      {t('button.cancel')}
                    </BorderButtonPurple>
                  )}
                  {role !== ROLE_ENTREPRISE && role !== ROLE_CANDIDAT && (
                    /* (event.statusAdmin === 'demande' ||
                      event.statusAdmin === 'planifier') */ <CustomButton
                      displayIcon={'none'}
                      handleClick={(e) => {
                        setShedule ? newSheduleEvent() : setSetShedule(true)
                        //statusEvent({ statusAdmin: 'replanifier' })
                      }}
                    >
                      {t('button.reschedule')}
                    </CustomButton>
                  )}
                </>
              )}
            </Box>
          )}

          {/*</Box>*/}
        </ModalContainer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default EditEvent
