import React, { useEffect, useState } from 'react'
import {
  Box,
  Container,
  Skeleton,
  styled,
  Typography,
} from '@mui/material'
import {
  HeadingMethod,
  HeadingNumber,
} from '../../../../../components/headings'
import {
  BannerBackgroundLeft,
  BannerBackgroundRight,
} from '../../../../../components/layouts'
import viewsSite from '../../../../../assets/images/view.png'
import { ButtonPopup } from '../../../../../components/buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSliders } from '@fortawesome/pro-regular-svg-icons'
import { faUserGroup } from '@fortawesome/pro-regular-svg-icons'
import iconUser from '../../../../../assets/images/icons/userlove.svg'
import lightTheme from '../../../../../styles/theme/lightTheme'
import { FlowerImage } from '../../../../../components/images'
import { useTranslation } from 'react-i18next'
import { Markup } from 'interweave'
import OffresSection from './OffresSection'

const FirstSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '20px',
  paddingBottom: '30px',
  columnGap: '25px',
  flexDirection: 'column',
  rowGap: '112px',

  [theme.breakpoints.up('sm')]: {
    paddingTop: '220px',
    flexDirection: 'row',
    paddingBottom: '20px',
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: '250px',
    paddingBottom: '30px',
    columnGap: '100px',
  },
}))

const ContentBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '20px',

  [theme.breakpoints.up('sm')]: {
    width: '40%',
    rowGap: '20px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '50%',
  },
}))

const ImageBox = styled(Box)(({ theme }) => ({
  width: '100%',
  zIndex: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  rowGap: '15px',

  [theme.breakpoints.up('sm')]: {
    rowGap: '25px',
    width: '60%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '50%',
  },
}))

const ViewImage = styled(Box)(({ theme }) => ({
  width: 350,
  height: 225,
  position: 'relative',

  img: {
    position: 'absolute',
    width: '100%',
  },

  [theme.breakpoints.up('sm')]: {
    width: 385,
    height: 250,
  },
  [theme.breakpoints.up('lg')]: {
    width: 450,
    height: 295,
  },
}))

const SecondSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '125px',
  paddingBottom: '47px',
  columnGap: '25px',
  flexDirection: 'column',
  rowGap: '95px',

  [theme.breakpoints.up('sm')]: {
    paddingTop: '72px',
    flexDirection: 'row',
    columnGap: '100px',
    paddingBottom: '52px',
  },

  [theme.breakpoints.up('md')]: {
    paddingTop: '125px',
  },

  [theme.breakpoints.up('lg')]: {
    paddingTop: '205px',
    paddingBottom: '82px',
    columnGap: '100px',
  },
}))

export default function MethodsSection(data) {
  const { t } = useTranslation()
  const [networks, setNetworks] = useState([])
  const [results, setResults] = useState([])

  useEffect(() => {
    setNetworks(data.accesReseau)
  }, [data.accesReseau])

  useEffect(() => {
    setResults(data.recrutementResultat)
  }, [data.recrutementResultat])

  return (
    <Box component={'section'} width={'100%'} position={'relative'}>
      <HeadingMethod
        left={0}
        type={'left'}
        maxWidthText={{ md: '100%' }}
        sx={{
          justifyContent: 'center',
        }}
        sxTextHeading={{
          display: 'flex',
          alignItems: 'end',
          justifyContent: { sm: 'center', lg: 'flex-start' },
        }}
      >
        {t('company.methodTitle')}
        {/*Pour vous permettre de trouver le candidat idéal, vous avez le choix entre deux méthodes d’accompagnement*/}
      </HeadingMethod>
      <BannerBackgroundRight />
      <Container maxWidth={'lg'}>
        <FirstSectionContainer component={'section'}>
          <br />
          <>
            {networks.map((network, index) => (
              <ContentBox key={index}>
                {!network.titre ? (
                  <Skeleton
                    variant={'text'}
                    animation={'wave'}
                    className={'skeleton-title'}
                  />
                ) : (
                  <HeadingNumber type={'animation'} number={'1'}>
                    {network.titre}
                  </HeadingNumber>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: { xs: '15px', sm: '20px' },
                  }}
                >
                  {!network.detail ? (
                    <Box width={'100%'}>
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        className={'skeleton-text'}
                      />
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        className={'skeleton-text'}
                      />
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        className={'skeleton-text-50'}
                      />
                      <br />
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        className={'skeleton-text'}
                      />
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        className={'skeleton-text'}
                      />
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        className={'skeleton-text-50'}
                      />
                      <br />
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        className={'skeleton-text'}
                      />
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        className={'skeleton-text'}
                      />
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        className={'skeleton-text-50'}
                      />
                    </Box>
                  ) : (
                    <>
                      <Typography
                        variant={'body2'}
                        color={lightTheme.palette.text.primary}
                      >
                        <Markup content={network.detail} />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                      </Typography>
                    </>
                  )}
                </Box>
              </ContentBox>
            ))}
          </>
          <ImageBox>
            <Box>
              <ButtonPopup
                icon={<FontAwesomeIcon icon={faSliders} />}
                borderRadius={'30px 30px 30px 0px'}
              >
                {t('company.filter')}
              </ButtonPopup>
            </Box>
            <ViewImage>
              <img src={viewsSite} alt={'Jobertech'} />
            </ViewImage>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                columnGap: { xs: '20px', sm: '25px', lg: '100px' },
              }}
            >
              <ButtonPopup
                icon={<img src={iconUser} alt={'Jobertech'} />}
                borderRadius={'30px 0px 30px 30px'}
              >
                {t('company.save')}
              </ButtonPopup>
              <ButtonPopup
                icon={<FontAwesomeIcon icon={faUserGroup} />}
                borderRadius={'0px 30px 30px 30px'}
              >
                {t('company.connection')}
              </ButtonPopup>
            </Box>
          </ImageBox>
        </FirstSectionContainer>

        <OffresSection />
        <SecondSectionContainer component={'section'} position={'relative'}>
          <BannerBackgroundLeft />
          <ImageBox
            sx={{
              order: { xs: 2, sm: 1 },
            }}
          >
            <FlowerImage recrutementResultat={data.recrutementResultat} />
          </ImageBox>
          <>
            {results.map((result, index) => (
              <ContentBox
                sx={{
                  order: { xs: 1, sm: 2 },
                }}
                key={index}
              >
                {!result.titre ? (
                  <Skeleton
                    variant={'text'}
                    animation={'wave'}
                    className={'skeleton-title'}
                  />
                ) : (
                  <HeadingNumber type={'animation'} number={'2'}>
                    {result.titre}
                  </HeadingNumber>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: { xs: '15px', sm: '20px' },
                  }}
                >
                  {!result.detail ? (
                    <Box width={'100%'}>
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        className={'skeleton-text'}
                      />
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        className={'skeleton-text'}
                      />
                      <Skeleton
                        variant={'text'}
                        animation={'wave'}
                        className={'skeleton-text-50'}
                      />
                    </Box>
                  ) : (
                    <Typography
                      variant={'body2'}
                      color={lightTheme.palette.text.primary}
                    >
                      <Markup content={result.detail} />
                    </Typography>
                  )}
                </Box>
              </ContentBox>
            ))}
          </>
        </SecondSectionContainer>
      </Container>
    </Box>
  )
}
