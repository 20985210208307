import React, { useState } from "react";
import { IconButton, styled, Grid, FormLabel, Box } from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faCircleXmark, faPlus } from "@fortawesome/pro-solid-svg-icons";
import "../../styles/components/_inputs.scss";
import lightTheme from "../../styles/theme/lightTheme";

const InputContainer = styled(Box)(({theme}) => ({
  position: "relative",
  display: "flex",
  alignItems: "center"
}));

const InputTag = styled('input')(({theme}) => ({
  height: 45,
  width: "100%",
  backgroundColor: "#FFFFF",
  borderRadius: "5px",
  border: `1px solid ${lightTheme.palette.grey.main}`,
  fontFamily: "Century Gothic",
  fontSize: 14,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.text.primary,
  padding: "10px 60px 10px 15px",

  "&::placeholder": {
    fontFamily: "Century Gothic",
    fontSize: 14,
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: lightTheme.palette.text.primary,
  },

  "&:active, &:focus": {
    outline: "none"
  },

  [lightTheme.breakpoints.up("md")]: {
    fontSize: 12,
  },

  [lightTheme.breakpoints.up("lg")]: {
    fontsSize: 14,
  },
}));

const IconButtonBox = styled(IconButton)(({theme}) => ({
  backgroundColor: "#FFFFFF",
  width: 20,
  height: 20,

  "&:hover": {
    backgroundColor: "#FFFFFF"
  }
}));

const Tag = styled(Grid)(({theme}) => ({
  backgroundColor: "#FFFFFF",
  height: 45,
  padding: "15px",
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: "3px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  columnGap: "10px",
}));

const IconContainer = styled(Box)(({theme}) => ({
  position: "absolute",
  right: 0,
  width: 45,
  height: "100%",
  backgroundColor: lightTheme.palette.primary.b2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderTopRightRadius: "5px",
  borderBottomRightRadius: "5px"
}));

const SpanTag = styled("span")(({theme}) => ({
  fontFamily: "Century Gothic Bold",
  fontSize: 10,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.primary.b2,
  textTransform: "uppercase",

  [theme.breakpoints.up("sm")]: {
    fontSize: 12
  },

  [theme.breakpoints.up("lg")]: {
    fontSize: 14
  },


}))


const TagsInput = (props, {register}) => {
  const [tags, setTags] = useState([]);
  const [error, setError] = useState("");

  // Add or remove tags by using the key
  const handleTags =  event => {
    if (event.key === "Enter" && event.target.value !== "" && tags.length < props.maxTagCount) {
      setTags([...tags, event.target.value]);
      event.target.value = "";
    }
    else if (event.key === "Backspace" && tags.length && event.target.value === 0){
      const tagsCopy = [...tags];
      tagsCopy.pop();
      event.preventDefault();
      setTags(tagsCopy);
    }
    else if(tags.length < 1 && event.key === "Backspace"){
      setError("Since there is no tags you can't able to delete any tags");
    }
    else if(tags.length >= props.maxTagCount){
      setError("Vous ne pouvez plus ajouter de tag");
    }
    else if(event.target.value === "" && event.key === "Enter"){
      setError("Le tag doit comporté au minimum 1 charactère!");
    }
  };

  //Remove tags by clicking the cross sign
  const removeTags = index => {
    setTags([...tags.filter(tag => tags.indexOf(tag) !== index)]);
  };

  //To update the error after user changes something
  const handleError = () => {
    setError("");
  }

  return (
    <>
      <Grid item xs={12} sm={6} >
        <FormLabel className={"labelForm"}>Spécialité(s)</FormLabel>
        <InputContainer>
          <InputTag
            type={"text"}
            placeholder={"Spécialité(s)"}
            onKeyDown={event => handleTags(event)}
            onChange={handleError}
            {...register}
          />
          <IconContainer>
            <IconButtonBox>
              <FontAwesomeIcon icon={faPlus} fontSize={15} color={lightTheme.palette.primary.b2}/>
            </IconButtonBox>
          </IconContainer>
        </InputContainer>
        <div className="error">
          {error}
        </div>
      </Grid>

      <Grid container spacing={2} ml={0} mt={0}>
        {tags.map((tag, index) => (
          <Grid item key={index} >
            <Tag>
              <SpanTag>{tag}</SpanTag>
              <IconButton onClick={() => removeTags(index)} sx={{padding: 0}}>
                <FontAwesomeIcon icon={faCircleXmark} fontSize={15} color={lightTheme.palette.primary.b2}/>
              </IconButton>
            </Tag>
          </Grid>
        ))}
      </Grid>




    </>
  )
}

export default TagsInput;