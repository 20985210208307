import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home, Candidate, Company } from "../pages/Public";
import { ErrorLayout, PublicLayout } from "../components/layouts";
import { CGU, CGV, MentionsLegales, Politiques } from "../pages/Public/privacy-policy";
import { Error404 } from "../pages/Public/erreurs";
import Articles from "../pages/Public/customer/article";
import ArticleDetail from "../pages/Public/customer/article/detail";
import Ateliers from "../pages/Public/customer/atelier";
import AtelierDetail from "../pages/Public/customer/atelier/detail";
import CompanyOffers from "../pages/Public/customer/company/offres";
import CompanyOfferPayement from "../pages/Public/customer/company/offresPaiement";
import Faq from "../pages/Public/customer/faq";


const PublicRouter = () => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route index element={<Home/>}/>

        <Route path={"accueil"} element={<Home/>}/>
        <Route path={"candidats"} element={<Candidate/>}/>
        <Route path={"entreprises"} element={<Company/>}/>
        <Route path={"entreprises/offres"} element={<CompanyOffers/>}/>
        <Route path={"entreprises/offre/paiement"} element={<CompanyOfferPayement/>}/>
        <Route path={"candidates"} element={<Candidate/>}/>
        <Route path={"companies"} element={<Company/>}/>
        <Route path={"articles"} element={<Articles/>}/>
        <Route path={"article/:articleId"} element={<ArticleDetail/>}/>
        <Route path={"ateliers"} element={<Ateliers/>}/>
        <Route path={"atelier/:atelierId"} element={<AtelierDetail/>}/>

        <Route path={"cgu-cgv"} element={<CGU/>} />
        <Route path={"faq"} element={<Faq/>} />
        <Route path={"cgv"} element={<CGV/>} />
        <Route path={"mentions-legales"} element={<MentionsLegales/>} />
        <Route path={"politiques-de-confidentialite"} element={<Politiques/>} />
      </Route>
      <Route path={"*"} element={<ErrorLayout/>}>
        <Route path={"*"} element={<Error404/>}/>
      </Route>
    </Routes>
  )
}

export default PublicRouter;