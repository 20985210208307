import React, { useState } from 'react'
import {
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import moment from 'moment-timezone'
import { faClose } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { truncate } from '../../config'
import { useTranslation } from 'react-i18next'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { ROLE_CANDIDAT } from '../../data'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const DetailBesoinEntreprise = ({ open, onClose, besoin, role }) => {
  const { t } = useTranslation()
  const [seeMore, setSeeMore] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSeeMore = (event) => {
    setSeeMore(!seeMore)
  }

  const sendAnnonces = async () => {
    setLoading(true)

    try {
      const result = await Api.candidate.sendAnnonces(besoin.id)
      const handling = handlingErrors(result)

      if (handling.ok) {
        /* toast.success('Le test de personnalité a été envoyé avec succès !', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        }) */
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
        overflow: 'auto',
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {t('box.title.detailJob')}
          <Box
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
          </Box>
        </Typography>
        <BoxContainer>
          <Grid
            container
            spacing={2}
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('box.jobTitle')} : <b>{besoin?.poste?.titre}</b>
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('box.jobNumber')} : <b>{besoin?.nbrPoste}</b>
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('box.jobContractType')} : <b>{besoin.typeContrat}</b>
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('box.jobPositionType')} : <b>{besoin.type}</b>
              </FormLabel>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('box.jobExpectedStartDate')} :{' '}
                <b>
                  {moment(besoin.dateDebut)
                    .tz('Europe/Paris')
                    .format('DD/MM/YYYY')}
                </b>
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('box.jobPositionHours')} : <b>{besoin?.horaires}</b>
              </FormLabel>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('box.jobSalary')} :{' '}
                <b>
                  {besoin?.salaire} {besoin.devise} / {besoin.salaireSur}{' '}
                  {t('box.mounth')}
                </b>
              </FormLabel>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('box.jobLocation')} :{' '}
                <b>
                  {besoin.lieu} / {besoin?.pays}
                </b>
              </FormLabel>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('filter.languages')} :{' '}
                <b>
                  {besoin.besoinLangues
                    .map((item) => `${item.langue}`)
                    .join(', ')}
                </b>
              </FormLabel>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
            >
              <FormLabel>{t('box.jobDescription')} :</FormLabel>
              <b>
                {seeMore ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: besoin?.descriptifPoste.trim(),
                    }}
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: truncate(besoin?.descriptifPoste.trim()),
                    }}
                  />
                )}
              </b>
              {besoin?.descriptifPoste.length > 150 && (
                <a
                  href={
                    seeMore
                      ? '#' + t('button.seeLess')
                      : '#' + t('button.seeMore')
                  }
                  style={{
                    cursor: 'pointer',
                    color: '#96A0FF',
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                  }}
                  onClick={handleSeeMore}
                >
                  {seeMore ? t('button.seeLess') : t('button.seeMore')}
                </a>
              )}
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('box.jobBenefits')} : <b>{besoin?.avantages}</b>
              </FormLabel>
            </Grid> */}

            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('box.jobTelework')}{' '}
                <b>
                  {besoin?.teletravailPropose}{' '}
                  {/* {besoin?.teletravailPropose > 1 ? 'jours' : 'jour'} */}
                </b>
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('box.timeWorkType')} :{' '}
                <b>
                  {besoin?.typeTempsTravail === 'partiel'
                    ? t('box.timeWorkType2') + ' (' + besoin?.tempPartiel + '%)'
                    : t('box.timeWorkType1')}
                </b>
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('box.jobReport')} : <b>{besoin?.reportDirect}</b>
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('filter.competences')} :{' '}
                <b>{besoin.competences.join(', ')}</b>
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('filter.competencesAnnexes')} :{' '}
                <b>{besoin.competencesAnnexes.join(', ')}</b>
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('filter.softskils')} : <b>{besoin.softskils.join(', ')}</b>
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('filter.values')} : <b>{besoin.valeurs.join(', ')}</b>
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('box.jobBenefits')} :{' '}
                <b>{besoin.avantagesEntreprise.join(', ')}</b>
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('box.jobTrip')} :{' '}
                <b>{besoin.deplacement ? t('button.yes') : t('button.no')}</b>
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('box.jobPermit')} :{' '}
                <b>{besoin.permisRequis ? t('button.yes') : t('button.no')}</b>
              </FormLabel>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
              }}
            >
              <FormLabel>
                {t('box.jobVehicle')} :{' '}
                <b>
                  {besoin.vehiculeRequis ? t('button.yes') : t('button.no')}
                </b>
              </FormLabel>
            </Grid>
          </Grid>
        </BoxContainer>
        {/*<Box className={"wrapperButtons"} width={"100%"}>*/}
        <Box className={'blockButtons'}>
          {role === ROLE_CANDIDAT && (
            <CustomButton displayIcon={'none'} handleClick={sendAnnonces}>
              {loading ? <CircularProgress /> : t('button.interestedPosition')}
            </CustomButton>
          )}
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.close')}
          </BorderButtonPurple>
        </Box>
        {/*</Box>*/}
      </ModalContainer>
    </Modal>
  )
}

export default DetailBesoinEntreprise
