import React, { useEffect, useState } from 'react'
import { Box, Container, Skeleton, styled, Typography } from '@mui/material'
import { ImageBorderRadius } from '../../../../../components/images'
import { SectionsHeading } from '../../../../../components/headings'
import { BadgeRoundedButton } from '../../../../../components/buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListCheck } from '@fortawesome/pro-regular-svg-icons'
import { faUserGroup } from '@fortawesome/pro-regular-svg-icons'
import { faHeadSideBrain } from '@fortawesome/pro-regular-svg-icons'
import { faLightbulb } from '@fortawesome/pro-regular-svg-icons'
import { faImages } from '@fortawesome/pro-regular-svg-icons'
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../../../styles/theme/lightTheme'
import { Markup } from 'interweave'
import '../../../../../styles/components/_skeleton.scss'

const SectionBox = styled(Box)(({ theme }) => ({
  paddingTop: '78px',
  paddingBottom: '62px',

  [theme.breakpoints.up('sm')]: {
    paddingTop: '89px',
    paddingBottom: '67px',
  },

  [theme.breakpoints.up('lg')]: {
    paddingTop: '129px',
    paddingBottom: '80px',
  },
}))

const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: '20px',
  alignItems: 'center',
}))

const CustomBoxText = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '40px',

  [theme.breakpoints.up('sm')]: {
    width: '60%',
  },

  [theme.breakpoints.up('lg')]: {
    width: '60%',
  },
}))

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '25px',
}))

const Content = styled(Typography)(({ theme }) => ({
  color: lightTheme.palette.text.primary,
}))

const BadgesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '15px',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  [theme.breakpoints.up('lg')]: {
    gap: '30px',
    display: 'flex',
  },
}))

const BadgesContainer2 = styled(Box)(({ theme }) => ({
  display: 'none',
  gridTemplateColumns: 'repeat(3, 1fr)',
  justifyItems: 'center',
  gap: '35px',

  [theme.breakpoints.up('sm')]: {
    display: 'grid',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}))

export default function OptimizationSection(data) {
  const [optimizationSections, setOptimizationSections] = useState([])

  useEffect(() => {
    setOptimizationSections(data.optimisationParcours)
  }, [data.optimisationParcours])

  return (
    <SectionBox component={'section'} width={'100%'} id={'sec1'}>
      <>
        {optimizationSections.map((optimizationSection, index) => (
          <Container
            maxWidth={'lg'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '50px',
            }}
            key={index}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: 'space-between',
                alignItems: 'end',
              }}
            >
              {!optimizationSection.photos[0] ? (
                <Box display={{ xs: 'none', sm: 'flex' }}>
                  <Skeleton
                    variant={'rounded'}
                    animation={'wave'}
                    className={'image-border-radius'}
                  />
                </Box>
              ) : (
                <Box display={{ xs: 'none', sm: 'flex' }}>
                  <ImageBorderRadius
                    image={optimizationSection.photos[0].lien}
                  />
                </Box>
              )}

              <CustomBoxText>
                <SectionsHeading
                  type={'animation'}
                  justifyContent={{ xs: 'center', sm: 'flex-start' }}
                  width={{ xs: 315, sm: '100%' }}
                >
                  {optimizationSection.titre}
                </SectionsHeading>

                <HeaderBox>
                  {!optimizationSection.photos[0] ? (
                    <Box display={{ xs: 'flex', sm: 'none' }}>
                      <Skeleton
                        variant={'rounded'}
                        animation={'wave'}
                        className={'image-border-radius'}
                      />
                    </Box>
                  ) : (
                    <Box display={{ xs: 'flex', sm: 'none' }}>
                      <ImageBorderRadius
                        image={optimizationSection.photos[0].lien}
                      />
                    </Box>
                  )}

                  {optimizationSection.detail?.length > 0 ? (
                    <Content variant={'body2'}>
                      <Markup content={optimizationSection.detail} />
                    </Content>
                  ) : (
                    <Skeleton variant={'text'} animation={'wave'} />
                  )}
                </HeaderBox>

                <ContentBox>
                  <Content variant={'body2'}>
                    {optimizationSection.sousTitre}
                  </Content>
                  <BadgesContainer>
                    <BadgeRoundedButton
                      icon={<FontAwesomeIcon icon={faListCheck} />}
                    >
                      {optimizationSection.points[1]}
                    </BadgeRoundedButton>
                    <BadgeRoundedButton
                      icon={<FontAwesomeIcon icon={faUserGroup} />}
                    >
                      {optimizationSection.points[2]}
                    </BadgeRoundedButton>
                    <BadgeRoundedButton
                      icon={<FontAwesomeIcon icon={faHeadSideBrain} />}
                    >
                      {optimizationSection.points[3]}
                    </BadgeRoundedButton>
                    <BadgeRoundedButton
                      icon={<FontAwesomeIcon icon={faLightbulb} />}
                    >
                      {optimizationSection.points[4]}
                    </BadgeRoundedButton>
                    <BadgeRoundedButton
                      icon={<FontAwesomeIcon icon={faImages} />}
                    >
                      {optimizationSection.points[5]}
                    </BadgeRoundedButton>
                    <BadgeRoundedButton
                      icon={<FontAwesomeIcon icon={faCircleQuestion} />}
                    >
                      {optimizationSection.points[6]}
                    </BadgeRoundedButton>
                  </BadgesContainer>
                </ContentBox>
              </CustomBoxText>
            </Box>

            <BadgesContainer2>
              <BadgeRoundedButton icon={<FontAwesomeIcon icon={faListCheck} />}>
                {optimizationSection.points[1]}
              </BadgeRoundedButton>
              <BadgeRoundedButton icon={<FontAwesomeIcon icon={faUserGroup} />}>
                {optimizationSection.points[2]}
              </BadgeRoundedButton>
              <BadgeRoundedButton
                icon={<FontAwesomeIcon icon={faHeadSideBrain} />}
              >
                {optimizationSection.points[3]}
              </BadgeRoundedButton>
              <BadgeRoundedButton icon={<FontAwesomeIcon icon={faLightbulb} />}>
                {optimizationSection.points[4]}
              </BadgeRoundedButton>
              <BadgeRoundedButton icon={<FontAwesomeIcon icon={faImages} />}>
                {optimizationSection.points[5]}
              </BadgeRoundedButton>
              <BadgeRoundedButton
                icon={<FontAwesomeIcon icon={faCircleQuestion} />}
              >
                {optimizationSection.points[6]}
              </BadgeRoundedButton>
            </BadgesContainer2>
          </Container>
        ))}
      </>
    </SectionBox>
  )
}
