import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownToLine, faTrashCan } from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../styles/theme/lightTheme'
import * as React from 'react'
import PropTypes from 'prop-types'
import { FILE_URL } from '../../config'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const TablePanelBox = styled(TabPanel)(({ theme }) => ({
  backgroundColor: 'rgb(227, 230, 255, 0.5)',
  '& .MuiBox-root': {
    padding: 0,
  },
}))

const HeaderTab = styled(TableHead)(({ theme }) => ({
  height: 50,
  backgroundColor: lightTheme.palette.primary.b6,
}))

const TitleHead = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

const BodyTable = styled(TableBody)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: '2px solid rgb(227, 230, 255, 0.5)',
  fontFamily: 'Century Gothic',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))


const DeleteIcon = styled(Box)(({ theme }) => ({
  width: 20,
  height: 20,
  border: `1px solid ${lightTheme.palette.error.dark}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '2px auto',
  '&:hover': {
    borderWidth: `2px`,
  },
  '&:active': {
    borderWidth: `1px`,
  },
}))

const IconBox = styled(Box)(({ theme }) => ({
  width: 20,
  height: 20,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 auto',

  '&:hover': {
    borderWidth: '2px',
  },
  '&:active': {
    borderWidth: '1px',
  },
}))
const downloadFile = (url) => {
  const fileName = url.split('/').pop()
  const aTag = document.createElement('a')
  aTag.href = url
  aTag.setAttribute('download', fileName)
  aTag.setAttribute('target', '_blank')
  document.body.appendChild(aTag)
  aTag.click()
  aTag.remove()
}
const GedTable = ({ files, dateFile, handleRemove }) => {
  const { t } = useTranslation()
  return (
    <TablePanelBox>
      {files && (
        <Table>
          <HeaderTab>
            <TableRow>
              <TitleHead>{t('profile.date')}</TitleHead>
              <TitleHead>{t('profile.documentName')}</TitleHead>
              {/* <TitleHead>Extensions</TitleHead> */}
              {/*<TitleHead>Par</TitleHead>*/}
              <TableCell />
            </TableRow>
          </HeaderTab>

          {files &&
            files.map((file, i) => (
              <BodyTable key={i}>
                <TableRow>
                  {file.date ? (
                    <StyledTableCell>
                      {format(new Date(file.date), 'dd/MM/yyyy HH:mm')}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell>{dateFile}</StyledTableCell>
                  )}
                  <StyledTableCell>{`${file.nom
                    .split('/')
                    .pop()}`}</StyledTableCell>
                 {/*  <StyledTableCell>
                    {file.type} ({`${file.nom.split('.').pop()}`})
                  </StyledTableCell> */}
                  {/*<StyledTableCell>rlys</StyledTableCell>*/}
                  <StyledTableCell>

                    <IconBox
                      onClick={() => {
                        downloadFile(`${FILE_URL}${file.nom.split('/').pop()}`)
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faArrowDownToLine}
                        color={lightTheme.palette.primary.b2}
                      />
                    </IconBox>
                  {file.id && (
                      <DeleteIcon
                        onClick={(e) => {
                          handleRemove(file.id)
                          //e.target.parentNode.parentNode.parentNode.remove()
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          color={lightTheme.palette.error.dark}
                        />
                      </DeleteIcon>
                    )}
                  </StyledTableCell>
                </TableRow>
              </BodyTable>
            ))}
        </Table>
      )}
    </TablePanelBox>
  )
}

export default GedTable
