import React, { useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { FRONT_URL, STRIPE_URL } from '../../config'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { toast, ToastContainer, Slide } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-solid-svg-icons'
import lightTheme from '../../styles/theme/lightTheme'

export default function CheckoutFormTest({
  paymentIntentId,
  userId,
  offreId,
  offrePrice,
  type,
  periode,
  onSuccess,
  onClose,
  codePromoObject,
}) {
  const { t, i18n } = useTranslation()
  const stripe = useStripe()
  const elements = useElements()

  const [isLoading, setIsLoading] = useState(false)

  let user_id = userId //id utilisateur
  let offre_id = offreId //id offre
  let price = offrePrice // prix total a paye

  useEffect(() => {
    if (!stripe) {
      return
    }
  }, [stripe])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Url de la page de fin de paiement
        return_url: FRONT_URL,
      },
      redirect: 'if_required',
    })

    console.debug(error)

    fetch(`${STRIPE_URL}payment_insert`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        request_type: 'payment_insert',
        payment_intent_id: paymentIntentId,
        id_offre: offre_id,
        id_client: user_id,
        type: type,
        periode: periode,
        prix: parseInt(price),
        langue: i18n.language,
        code_promo: codePromoObject?.id,
        devise: sessionStorage.getItem('currency')
          ? JSON.parse(sessionStorage.getItem('currency')).devise
          : 'CHF',
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        data = JSON.parse(data)
        if (data.payment_id && data.payment_status === 'succeeded') {
          toast.success(t('successMessage.paymentOk'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleCheck}
                fontSize={25}
                color={lightTheme.palette.success.main}
              />
            ),
          })
          //setMessage(t('successMessage.paymentOk'))
          setIsLoading(false)
          setTimeout(() => {
            onSuccess()
          }, 1000)
        } else {
          toast.error(t('successMessage.paymentNok'), {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: ({ theme, type }) => (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                fontSize={25}
                color={lightTheme.palette.error.main}
              />
            ),
          })
          //setMessage(t('successMessage.paymentNok'))
          setIsLoading(false)
          setTimeout(() => {
            onClose()
          }, 1000)
        }
      })
      .catch((error) => {
        setIsLoading(false)
      })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    /* if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message)
    } else {
      setMessage('une erreur inattendue est apparue.')
    } */
  }

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" />
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <button disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">{t('button.pay')}</span>
          </button>
        )}
        {/* Afficher les messages d'erreur ou de réussite */}
        {/* {message && <div id="payment-message">{message}</div>} */}
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}
