import React from 'react'
import { Box, IconButton, InputBase, styled } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../styles/theme/lightTheme'
import { useTranslation } from 'react-i18next'

const SearchInput = styled(Box)(({ theme, width }) => ({
  width: width ? width : 320,
  height: 38,
  borderRadius: '5px',
  border: `1px solid ${lightTheme.palette.grey.main}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 15px',
  backgroundColor: '#FFFFFF',

  [theme.breakpoints.up('sm')]: {
    height: 41,
  },

  [theme.breakpoints.up('lg')]: {
    height: 45,
  },
}))

const SearchInputBasic = ({ width, onChange, value }) => {
  const { t } = useTranslation()
  return (
    <SearchInput width={width}>
      <InputBase
        placeholder={`${t('filter.searchText')}...`}
        inputProps={{ 'aria-label': `${t('filter.searchText')}...` }}
        sx={{
          flex: 1,
          color: lightTheme.palette.text.primary,
          fontFamily: 'Century Gothic',
          fontSize: 14,
          lineHeight: '140%',
          letterSpacing: '0.2px',
        }}
        onChange={onChange}
        value={value}
      />
      <IconButton disableRipple type={'button'} aria-label={'search'}>
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          style={{ color: lightTheme.palette.primary.main, fontSize: '20px' }}
        />
      </IconButton>
    </SearchInput>
  )
}

export default SearchInputBasic
