import React from "react";
import { Box, styled } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";

const Button = styled(Box)(({theme, width}) => ({
  width: width,
  height: 38,
  borderRadius: "3px",
  backgroundColor: "#FFFFFF",
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  fontFamily: "Century Gothic Bold",
  fontSize: 10,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  textTransform: "uppercase",
  color: lightTheme.palette.primary.b2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  columnGap: "10px",
  cursor: "pointer",
  padding: "10px",

  "&:hover": {
    borderWidth: "2px"
  },

  "&:active": {
    borderWidth: "1px"
  },

  [theme.breakpoints.up("sm")]: {
    height: 41,
    fontSize: 12
  },

  [theme.breakpoints.up("lg")]: {
    height: 45,
    fontSize: 14
  },
}));

const Text = styled(Box)(({theme, displayText}) => ({
  display: displayText
}))

const Icon = styled(Box)(({theme, displayIcon}) => ({
  display: displayIcon,
}));

const BorderButtonPurple = ({children, width, displayIcon, icon, displayText, handleClick}) => {
  return (
    <Button width={width} onClick={handleClick}>
      <Text display={displayText}>{children}</Text>
      <Icon display={displayIcon}>{icon}</Icon>
    </Button>
  )
}

export default BorderButtonPurple;