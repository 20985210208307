import ApiRequest from "../request/ApiRequest";

export default class Rate {
  constructor() {
    this.ApiRequest = new ApiRequest();
  }

  async list() {
    try {
      const res = await this.ApiRequest.get("rates");
      return res;
    } catch (error) {
      return error.response;
    }
  
  }


}