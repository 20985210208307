import { styled } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";

const SelectCustom = styled("select")(({theme, type}) => ({
  width: "100%",
  height: 45,
  borderWidth: type === "border"
    ? "1px"
    : 0,
  borderRadius: "5px",
  backgroundColor: "#FFFFFF",
  color: lightTheme.palette.grey.main,
  fontFamily: "Century Gothic",
  fontSize: 14,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  cursor: "pointer",
  position: "relative",
  borderStyle: "solid",
  borderColor: type === "border"
    ? lightTheme.palette.grey.main
    : "transparent",
  padding: "10px",


  '& .MuiOutlinedInput-notchedOutline': {
    border: "none",
  },

  "& .placeholder": {
    color: "#9E9E9E",
    fontFamily: "Century Gothic",
    fontSize: 14,
    lineHeight: "140%",
    letterSpacing: "0.2px",
  }
}))

const SelectInputCustom = ({placeholder, items, register}) => {
  return (
    <SelectCustom
      {...register}
    >
      <option value={""} selected disabled hidden className={"placeholder"}>{placeholder}</option>
      {items && items.map((item) => (
        <option key={item.id} value={item.id} >{item.titre}</option>
      ))}
    </SelectCustom>
  )
}

export default SelectInputCustom;