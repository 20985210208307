import ApiRequest from "../request/ApiRequest";

export default class Fonctions {
  constructor() {
    this.ApiRequest = new ApiRequest();
  }

  async add() {
    try {
      const res = await this.ApiRequest.post("fonctions");
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async list() {
    try {
      const res = await this.ApiRequest.get("fonctions");
      return res;
    } catch (error) {
      return error.response;
    }
  }


  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`fonctions/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`fonctions/${id}`, data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`fonctions/${id}`);
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get("fonctions.json", data);
      return res;
    } catch (error) {
      return error.response;
    }
  }

}