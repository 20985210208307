import ApiRequest from '../request/ApiRequest'

export default class Favoris {
  constructor() {
    this.ApiRequest = new ApiRequest()
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post('favoris', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async list() {
    try {
      const res = await this.ApiRequest.get('favoris')
      return res
    } catch (error) {
      return error.response
    }
  }

  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`favoris/${id}`, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async delete(id, data) {
    try {
      const res = await this.ApiRequest.delete(`favoris/${id}`, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`favoris/${id}`)
      return res
    } catch (error) {
      return error.response
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get('favoris.json', data)
      return res
    } catch (error) {
      return error.response
    }
  }
}
