import React from "react";
import { Outlet } from "react-router-dom";
import AdminNavbar from "../navbar/AdminNavbar";
import { Box } from "@mui/material";
import Sidebar from "../Sidebar";
import "../../styles/components/_layouts.scss";

const AdminLayout = () => {
  return (
    <div className={"AdminLayout"}>
      <Box
        sx={{
          display: {xs: "none", lg: "block"}
        }}
      >
        <AdminNavbar/>
        <Sidebar/>
      </Box>
      <Box
        sx={{
          display: {xs: "block", lg: "none"}
        }}
      >
        <AdminNavbar/>
      </Box>
      <main className={"MainAdmin"}>
        <Outlet/>
      </main>
    </div>
  )
}

export default AdminLayout;