import { configureStore } from "@reduxjs/toolkit";
import userAuthReducer from "./slices/userAuthReducer";
import userValidationReducer from "./slices/userValidationReducer";
import { eventSlice } from "./slices/eventSlice";

const store = configureStore({
  reducer: {
    userAuth: userAuthReducer,
    userFinalisation: userValidationReducer,
    event: eventSlice
  },
  devTools: true
})

export default store;