import React from "react";
import {Box, styled, Typography} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import { keyframes } from "@emotion/react";

const HeadingContainer = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "15px",

    [theme.breakpoints.up("sm")]: {
        columnGap: "20px"
    },
    [theme.breakpoints.up("lg")]: {
        columnGap: "30px",
        justifyContent: "flex-start",
    },
}));

const Number = styled(Box)(({theme,type}) => ({
    backgroundColor: type === "animation"
      ? lightTheme.palette.error.main
      : type === "basic"
        ? "#FFFFFF"
        : lightTheme.palette.error.main,
    width: 40,
    height: 30,
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    animation: type === "animation"
      ? `${colorEffect} 15s linear infinite`
      : type === "basic"
        ? "none"
        : "none",


    [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-start",
        padding: "15px 20px",
        width: 70,
        height: 50
    },
}));

const colorEffect = keyframes`
    0% { background-color: #F76E11;}
    25% { background-color: #96CEB4; }
    50% { background-color: #808BF8; }
    75% { background-color: #96CEB4; }
    100% { background-color: #F76E11;}
`

export default function HeadingNumber({number, children, type}) {
    return (
        <HeadingContainer>
            <Number type={type}>
                <Typography
                    variant={"h3"}
                    color={lightTheme.palette.text.secondary}
                    textTransform={"uppercase"}
                >
                    {number}
                </Typography>
            </Number>
            <Typography
                variant={"h3"}
                color={lightTheme.palette.text.primary}
                textTransform={"uppercase"}
                width={"fit-content"}
            >
                {children}
            </Typography>
        </HeadingContainer>
    )
}