import ApiRequest from "../request/ApiRequest";

export default class Auth {
  constructor() {
    this.ApiRequest = new ApiRequest();
  }

  async logIn(data) {
    const res = await this.ApiRequest.post("login", data);
    return res;
  }

  logOut() {
    localStorage.removeItem("user");
  }

  async checkPassword(data) {
    const res = await this.ApiRequest.post("checkpassword", data);
    return res;
  }

  restoreToken() {
    const res = JSON.parse(localStorage.getItem("user"));
    return res;
  }
}