import React from "react";
import {Box, Container, styled, Typography} from "@mui/material";
import logo from "../../assets/images/logos/headingLogo.svg";
import lightTheme from "../../styles/theme/lightTheme";

const CustomBox = styled(Box)(({theme, type, left, right, heightCustomBox}) => ({
    height: heightCustomBox ? 80 : heightCustomBox,
    width: "92%",
    backgroundColor: lightTheme.palette.primary.b3,
    borderRadius: type === "left"
        ? "0 100px 100px 0"
        : type === "right"
        ? "100px 0 0 100px"
        : "none",
    position: "absolute",
    left: left,
    right: right,
    zIndex: -1,

    [theme.breakpoints.up("sm")]: {
        height: 100
    },
}));

const ContentBox = styled(Box)(({theme, rightContent, heightContentBox}) => ({
    position: "absolute",
    width: "92%",
    height: heightContentBox ? 80 : heightContentBox,
    right: rightContent ? "auto" : rightContent,

    [theme.breakpoints.up("sm")]: {
        height: 100
    },
}));

const LogoContainer = styled(Box)(({theme, displayLogo}) => ({
    width: 60,
    height: 60,
    position: "absolute",
    top: "-35px",
    left: 0,
    right: 0,
    margin: "0 auto",
    display: displayLogo,

    img: {
        position: "absolute",
        width: "100%",
        height: "100%"
    },

    [theme.breakpoints.up("sm")]: {
        width: 100,
        height: 100,
        top: "-60px",
    },
    [theme.breakpoints.up("lg")]: {
        top: "-60px",
    },
}));

const TextHeading = styled(Typography)(({theme, textAlign, maxWidthText}) => ({
    textTransform: "uppercase",
    color: lightTheme.palette.text.secondary,
    /*maxWidth: 330,*/
    maxWidth: "100%",
    width: "100%",
    textAlign: textAlign,
    height: "100%",

    [theme.breakpoints.up("md")]: {
        maxWidth: maxWidthText,
    },
    /*[theme.breakpoints.up("lg")]: {
        maxWidth: maxWidthText
    },*/
}))

const ContainerBox = styled(Container)(({theme, marginRight, marginLeft, justifyContent}) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    /*justifyContent: "center",*/
    justifyContent: justifyContent,
    alignItems: "end",
    padding: "15px 20px",
    marginRight: marginRight,
    marginLeft: marginLeft,

    [theme.breakpoints.up("lg")]: {
        padding: "25px"
    },
}));

export default function HeadingMethod({children, type, left, right, textAlign, marginRight, marginLeft, displayLogo, justifyContent, maxWidthText, sx, rightContent, heightCustomBox, heightContentBox, sxTextHeading}) {
    return (
        <Box>
            <CustomBox type={type} left={left} right={right} height={heightCustomBox} />
            <ContentBox right={rightContent} height={heightContentBox}>
                <ContainerBox maxWidth={"lg"} /*marginRight={marginRight} marginLeft={marginLeft} justifyContent={justifyContent}*/ sx={sx}>
                    <LogoContainer display={displayLogo}>
                        <img src={logo} alt={"Jobertech"}/>
                    </LogoContainer>
                    <TextHeading variant={"subtitle2"} textAlign={textAlign} maxWidth={maxWidthText} sx={sxTextHeading}>
                        {children}
                    </TextHeading>
                </ContainerBox>
            </ContentBox>
        </Box>
    )
}