import React, { useState } from 'react'
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { FilterButton } from '../buttons'
import { FiltersModal } from '../modals'

const HeaderTab = styled(TableHead)(({ theme }) => ({
  width: '100%',
  height: 60,
  backgroundColor: lightTheme.palette.primary.b6,
}))

const BlockColor = styled(TableCell)(({ theme, type }) => ({
  width: 25,
  height: 60,
  backgroundColor:
    type === 'candidat'
      ? lightTheme.palette.warning.dark
      : type === 'entreprise'
      ? lightTheme.palette.secondary.main
      : lightTheme.palette.warning.dark,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  padding: '10px 0',
  borderBottom:
    type === 'candidat'
      ? '1px solid ' + lightTheme.palette.warning.dark
      : type === 'entreprise'
      ? '1px solid ' + lightTheme.palette.secondary.main
      : '1px solid ' + lightTheme.palette.warning.dark,
}))

const Square1 = styled('div')({
  width: 3,
  height: 3,
  backgroundColor: '#FFFFFF',
})

const Square2 = styled('div')({
  width: 3,
  height: 3,
  backgroundColor: '#FFFFFF',
})

const Square3 = styled('div')({
  width: 3,
  height: 3,
  backgroundColor: '#FFFFFF',
})

const Square4 = styled('div')({
  width: 3,
  height: 3,
  backgroundColor: '#FFFFFF',
})

const Square5 = styled('div')({
  width: 3,
  height: 3,
  backgroundColor: '#FFFFFF',
})

const SmallBlockColor = styled(TableCell)(({ theme, type }) => ({
  width: 15,
  height: 60,
  backgroundColor:
    type === 'candidat'
      ? lightTheme.palette.warning.dark
      : type === 'entreprise'
      ? lightTheme.palette.secondary.main
      : lightTheme.palette.warning.dark,
  padding: '10px 0',
  borderBottom:
    type === 'candidat'
      ? '1px solid ' + lightTheme.palette.warning.dark
      : type === 'entreprise'
      ? '1px solid ' + lightTheme.palette.secondary.main
      : '1px solid ' + lightTheme.palette.warning.dark,
}))

const BodyTd = styled(TableCell)(({ theme, type }) => ({
  borderBottom:
    type === 'candidat'
      ? '1px solid ' + lightTheme.palette.warning.dark
      : type === 'entreprise'
      ? '1px solid ' + lightTheme.palette.secondary.main
      : '1px solid ' + lightTheme.palette.warning.dark,
}))

const ListTabletests = (props) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: 'calc(100% - 50px)',
        margin: 'auto',
      }}
    >
      <FilterButton handleClick={handleOpen} />
      <FiltersModal open={open} onClose={handleClose} />
      <Table
        sx={{
          width: 'calc(100% - 60px)',
          margin: 'auto',
        }}
      >
        <HeaderTab>
          <TableRow>
            <TableCell width={25} sx={{ padding: 0 }} />
            <TableCell align={'left'}>Entreprise</TableCell>
            <TableCell align={'left'}>Nom</TableCell>
            <TableCell align={'left'}>Prénom</TableCell>
            <TableCell align={'left'}>Mail</TableCell>
            <TableCell align={'left'}>Offre</TableCell>
            <TableCell width={15} sx={{ padding: 0 }} />
          </TableRow>
        </HeaderTab>
        <TableBody>
          {props.items &&
            props.items.map((item, index) => (
              <TableRow key={index}>
                <BlockColor>
                  <Square1 />
                  <Square2 />
                  <Square3 />
                  <Square4 />
                  <Square5 />
                </BlockColor>
                <BodyTd>{item.entreprise.raisonSocial}</BodyTd>
                <BodyTd>{item.nom}</BodyTd>
                <BodyTd>{item.prenom}</BodyTd>
                <BodyTd>{item.email}</BodyTd>
                <BodyTd>{item.offre}</BodyTd>
                <SmallBlockColor />
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ListTabletests
