import styled from "@emotion/styled";
import lightTheme from "../../../styles/theme/lightTheme";
const colorBlueTwo = "#4564AD";
const colorGray = "#6A6A6A";


export const LayoutCalendar = styled("div")(({ theme }) => ({
  fontFamily: "'Mulish_400Regular'",
  position: "relative",

  ".fc-dayGridMonth-view fc-view fc-daygrid": {
    zIndex: 0
  },

  ".fc .fc-button-group": {
    position: "relative",
    display: "flex",
    verticalAlign: "middle",
    height: "45px",
    alignItems: "center",
    justifyContent: "center",
  },
  "button.fc-prev-button.fc-button.fc-button-primary": {
    padding: 0,
    height: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "15px",
    minWidth: "35px",
    borderRadius: "50%",
    color: lightTheme.palette.primary.b2,
    background: "transparent",
    border: "none",
    "&:hover": {
      color: lightTheme.palette.primary.main,
      /*background: colorBlueTwo,*/
    },
    "&:focus": {
      border: "none",
      boxShadow: "none",
    },
  },
  "button.fc-next-button.fc-button.fc-button-primary": {
    padding: 0,
    height: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "15px",
    minWidth: "35px",
    borderRadius: "50%",
    color: lightTheme.palette.primary.b2,
    background: "transparent",
    border: "none",
    "&:hover": {
      color: lightTheme.palette.primary.main,
      /*background: colorBlueTwo,*/
    },
    "&:focus": {
      border: "none",
      boxShadow: "none",
    },
  },
  "button.fc-today-button.fc-button.fc-button-primary": {
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ".fc-toolbar-title": {
    fontSize: "20px !important",
    lineHeight: "120%",
    fontFamily: "Century Gothic Bold",
    color: lightTheme.palette.text.primary,
    textTransform: "uppercase"
  },
  ".fc-toolbar-chunk": {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
  },
  ".fc-dayGridMonth-button": {
    height: "50px",
    minWidth: "90px",
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
  },
  ".fc-timeGridWeek-button": {
    height: "50px",
    minWidth: "90px",
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
  },
  ".fc-timeGridDay-button": {
    height: "50px",
    minWidth: "90px",
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
  },
  ".fc-daygrid-day-number": {
    color: colorGray,
    fontWeight: "bold",
  },
  ".fc-daygrid-day-frame.fc-scrollgrid-sync-inner": {
    height: "auto",
  },

  ".time": {
    color: "#edde3b",
  },
  ".infos": {
    color: "#04ebff",
  },
  ".fc-v-event": {
    backgroundColor: "unset !important",
  },
  ".fc .fc-timegrid-slot": {
    height: "4.5em",
  },

  ".fc-timegrid-more-link": {
    fontSize: "13px",
    fontWeight: "bold",
    textAlign: "center",
    position: "relative",
    cursor: "pointer",
    width: "100%",
    marginTop: "43px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "none !important",
    border: "none !important",
    background: "none",
    ".custom-more-link": {
      boxShadow: "0px 0px 4px 2px #78b8c5",
    },
  },
  ".fc-timegrid-event.fc-event-mirror": {
    boxShadow: "none !important",
    border: "none !important",
  },
  ".fc-timegrid-event-harness-inset .fc-timegrid-event": {
    boxShadow: "none !important",
    border: "none !important",
  },

  ".fc-timegrid-event-harness": {
    maxHeight: "4.5em",
  },
  ".fc-right-custom ": {
    position: "absolute",
    right: 0,
    /*zIndex: 9999,*/
    top: "5px",
    display: "flex",
    maxHeight: "45px",
  },
  ".fc .fc-toolbar.fc-header-toolbar": {
    marginBottom: "2.5em",
  },
  ".fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk": {
    marginTop: "10px",
  },
  ".fc-popover": {
    border: "1px solid #e5f5fa !important",
    background: "white !important",
    borderRadius: "5px",
    boxShadow: "0px 0px 5px 0px #e5f5fa !important",
  },
  ".fc-theme-standard .fc-popover-header": {
    background: "white",
  },
  ".fc-popover-title": {
    flex: 1,
    textAlign: "center",
  },
  ".fc .fc-popover-close": {
    marginRight: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "100%",
    ".fc-toolbar-title": {
      fontSize: "14px !important",
    },
    ".fc-right-custom ": {
      top: "115px",
    },
    ".fc .fc-toolbar.fc-header-toolbar": {
      marginBottom: "1.5em",
    },
    ".fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk": {
      marginTop: "25px",
    },
    ".fc-view-harness.fc-view-harness-active": {
      height: "calc(85vh - 100px) !important",
    },
  },
}));

export const DropDownContainer = styled("div")(({ theme }) => ({
  width: "130px",
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "120px",
  },
}));

export const DropDownListContainer = styled("div")(() => ({
  /*height: "auto",*/
  height: 100,
}));
export const DropDownHeader = styled("div")(() => ({
  padding: "10px",
  fontFamily: "Century Gothic",
  fontSize: "14px",
  lineHeight: "140%",
  color: lightTheme.palette.text.primary,
  background: "#ffffff",
  /*borderRadius: "8px",*/
  /*border: `1px solid ${colorBlueDark}`,*/
  /*backgroundColor: "pink",*/
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  height: "35px",
  justifyContent: "space-between",
}));
export const DropDownList = styled("ul")(() => ({
  margin: 0,
  background: "#ffffff",
  /*border: `1px solid ${colorBlueDark}`,*/
  boxSizing: "border-box",
  color: lightTheme.palette.text.primary,
  fontFamily: "Century Gothic",
  fontSize: "14px",
  lineHeight: "140%",
  /*backgroundColor: colorLightBlueTwo,*/
  /*borderRadius: "8px",*/
  padding: 0,
  paddingLeft: "10px",
  paddingRight: "5px",
}));

export const ListItem = styled("li")(() => ({
  listStyle: "none",
  cursor: "pointer",
  "&:nth-of-type(1)": {
    /*marginBottom: "10px",*/
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
export const EventBox = styled("div")(({ type }) => ({
  "&:hover": { background: lightTheme.palette.secondary.v2, color: "white" },
  display: "flex",
  position: "relative",
  alignItems: "center",
  width: "100%",
  margin: "0",
  background: lightTheme.palette.secondary.main,
  borderRadius: "4px",
  flexDirection: type ? " column" : "row",
  padding: "5px",
  height: "auto",
  color: "#FFFFFF",
  fontFamily: "Century Gothic",
  fontsize: 12,
  lineHeight: "140%",
  ".heading__services": {
    width: "100%",
    borderBottom: `1px solid ${colorBlueTwo}`,
    display: "flex",
    justifyContent: "space-between",
    ".hour": {
      fontFamily: "Century Gothic",
      fontSize: "12px !important",
      lineHeight: "140%",
      color: "#FFFFFF"
    },
    ".name": {
      fontFamily: "Century Gothic Bold",
      fontSize: 12,
      lineHeight: "140%",
      color: "#FFFFFF"
    },
    img: {
      width: "20px",
      height: "20px",
      position: "absolute",
      right: "5px",
      top: "5px",
    },
  },
  ".body__services": {
    width: "100%",
    display: "flex",
    ".desc": {
      fontFamily: "'Mulish_700Bold'",
      fontSize: "10px !important",
      whiteSpace: "normal",
      lineHeight: "13px",
      width: "120px",
      marginTop: "4px",
    },
    img: {
      width: "15px",
      height: "15px",
      position: "absolute",
      right: "5px",
      bottom: "5px",
    },
  },
  ".heading__list": {
    display: "flex",
    ".hour": {
      fontFamily: "Century Gothic",
      fontSize: "16px !important",
      lineHeight: "20px",
      marginRight: "10px",
    },
    img: {
      width: "20px",
      height: "20px",
      position: "absolute",
      right: "-8px",
      top: "13px",
    },
  },
  ".body__list": {
    flex: 1,
    width: "100%",
    display: "flex",
    ".desc": {
      fontFamily: "'Mulish_700Bold'",
      fontSize: "12px !important",
      whiteSpace: "normal",
      lineHeight: "15px",
    },
  },
}));
