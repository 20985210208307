import React, { useState } from 'react'
import { AdminHeading } from '../../../../../components/headings'
import {
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  Grid,
  styled,
  Typography,
} from '@mui/material'
import '../../../../../styles/components/_layouts.scss'
import '../../../../../styles/components/_grids.scss'
import '../../../../../styles/components/_box.scss'
import '../../../../../styles/components/_inputs.scss'
import { TextInputClassic } from '../../../../../components/inputs'
import {
  BorderButtonPurple,
  CustomButton,
} from '../../../../../components/buttons'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../../services/api.service'
import { handlingErrors } from '../../../../../utils'
import { Slide, toast, ToastContainer } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../../../../styles/theme/lightTheme'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useTranslation } from 'react-i18next'
import {
  DEFAULT_DUREE,
  DEFAULT_DUREE_ENTRE,
  ROLE_ENTREPRISE,
  SLOT_DEFAULT_VALUE,
} from '../../../../../data'

const InputContainer = styled(FormControl)(({ theme }) => ({
  rowGap: '5px',
}))

const AddProspectCompany = () => {
  let navigate = useNavigate()
  const { t } = useTranslation()
  /* const [id, setId] = useState(null); */
  /*   const [openCard, setOpenCard] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false); */
  const [loadingAddAdmin, setLoadingAddAdmin] = useState(false)
  /* const [loading, setLoading] = useState(false)
  const [companys, setCompanys] = useState([])
  const [isAdmin, setIsAdmin] = useState('') */
  const [phoneValue, setPhoneValue] = useState()

  const schema = yup.object({
    raisonSocial: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.companyName')),
    fonctions: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.function')),
    prenom: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.firstname')),
    nom: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.lastname')),
    email: yup
      .string(t('errorMessage.string'))
      .email(t('errorMessage.emailValid'))
      .required(t('errorMessage.email')),
    telephone: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.number')),
    /*.matches(phoneRegExp, "Merci de rentrer un numéro de téléphone valide.")*/
    adresse: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.address')),
    ville: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.city')),
    codePostal: yup
      .number()
      .typeError('Merci de rentrer un code postal valide.')
      .required(t('errorMessage.zipCode')),
    pays: yup
      .string(t('errorMessage.string'))
      .required(t('errorMessage.country')),
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  /*  useEffect(() => {
    getCompanys();
  }, [id]); */

  /*  const handleAdminSelect = (evt) => {
    const inputValue = evt.target.value;
    setIsAdmin(inputValue)
  };

  const getCompanys = async () => {
    //setLoading(true);

    try {
      const result = await Api.user.listWithFilter({
        roles: ROLE_ENTREPRISE,
      });
      const res = handlingErrors(result);

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
        });
        window.scrollTo(0, 0);
      }

      if (res.ok) {
        //setCompanys(res.data);
      }

      if (res.status === 0) {
        toast.error(t('successMessage.somethingWrong'), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
        });
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        icon: ({theme, type}) => <FontAwesomeIcon icon={faCircleExclamation} fontSize={25} color={lightTheme.palette.error.main}/>
      });
      window.scrollTo(0, 0);
    }

    //setLoading(false);
  }; */
  const addCompany = async (data) => {
    setLoadingAddAdmin(true)
    try {
      const result = await Api.user.add(data)
      const res = handlingErrors(result)
      if (res.ok) {
        toast.success(t('successMessage.clientAdd'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleCheck}
              fontSize={25}
              color={lightTheme.palette.success.main}
            />
          ),
        })
        //await getCompanys();

        setTimeout(() => {
          navigate('/admin')
        }, 1000)
      }

      if (res.status === 422) {
        toast.error(t('successMessage.emailAlreadyUsed'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
      }

      if (res.status === 401) {
        toast.error(t('successMessage.sessionExpired'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
        window.scrollTo(0, 0)
        return
      }

      if (res.status === 0) {
        toast.error(t('successMessage.somethingWrong'), {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          icon: ({ theme, type }) => (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              fontSize={25}
              color={lightTheme.palette.error.main}
            />
          ),
        })
      }
    } catch (error) {
      toast.error(t('successMessage.somethingWrong'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: ({ theme, type }) => (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            fontSize={25}
            color={lightTheme.palette.error.main}
          />
        ),
      })
    }
    setLoadingAddAdmin(false)
  }

  const onSubmit = (data) => {
    const payload = {
      ...data,
      roles: [ROLE_ENTREPRISE],
      password: 'password',
      status: 'classic',
      entreprise: {
        raisonSocial: data.raisonSocial,
        fonctions: data.fonction,
        disponibilites: SLOT_DEFAULT_VALUE,
        dureeRdv: parseInt(DEFAULT_DUREE),
        dureeEntreRdv: parseInt(DEFAULT_DUREE_ENTRE),
      },
    }
    addCompany(payload)
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <AdminHeading
          handleClickIcon={() =>
            navigate('/admin/gestion-prospect/liste-prospects-entreprises')
          }
        >
          Ajouter un prospect entreprise
        </AdminHeading>
        <Box
          className={'BoxContainer'}
          sx={{
            padding: '50px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: { xs: '30px', sm: '55px', lg: '70px' },
          }}
        >
          <form>
            <Grid container spacing={2} className={'gridContainer'}>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Raison Social</FormLabel>
                  <TextInputClassic
                    placeholder={'Raison Social'}
                    width={'100%'}
                    register={{ ...register('raisonSocial') }}
                  />
                  {errors.raisonSocial && (
                    <Typography className={'error-message'}>
                      {errors.raisonSocial.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Fonction</FormLabel>
                  <TextInputClassic
                    placeholder={'Fonction'}
                    width={'100%'}
                    register={{ ...register('fonctions') }}
                  />
                  {errors.fonctions && (
                    <Typography className={'error-message'}>
                      {errors.fonctions.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Prénom</FormLabel>
                  <TextInputClassic
                    placeholder={'Prénom'}
                    width={'100%'}
                    register={{ ...register('prenom') }}
                  />
                  {errors.prenom && (
                    <Typography className={'error-message'}>
                      {errors.prenom.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Nom</FormLabel>
                  <TextInputClassic
                    placeholder={'Nom'}
                    width={'100%'}
                    register={{ ...register('nom') }}
                  />
                  {errors.nom && (
                    <Typography className={'error-message'}>
                      {errors.nom.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Mail</FormLabel>
                  <TextInputClassic
                    placeholder={'Mail'}
                    width={'100%'}
                    type={'email'}
                    register={{ ...register('email') }}
                  />
                  {errors.email && (
                    <Typography className={'error-message'}>
                      {errors.email.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Numéro de téléphone
                  </FormLabel>
                  {/*<TextInputClassic
                    placeholder={"Numéro de téléphone"}
                    width={"100%"}
                    register={{...register("telephone")}}
                  />*/}

                  <Controller
                    name="phone-input"
                    control={control}
                    rules={{
                      validate: (value) => isValidPhoneNumber(value),
                    }}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        international
                        value={phoneValue}
                        onChange={setPhoneValue}
                        defaultCountry="CH"
                        id="phone-input"
                        {...register('telephone')}
                      />
                    )}
                  />

                  {errors.telephone && (
                    <Typography className={'error-message'}>
                      {errors.telephone.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={12}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>
                    Adresse de l'entreprise
                  </FormLabel>
                  <TextInputClassic
                    placeholder={"Adresse de l'entreprise"}
                    width={'100%'}
                    register={{ ...register('adresse') }}
                  />
                  {errors.adresse && (
                    <Typography className={'error-message'}>
                      {errors.adresse.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Ville</FormLabel>
                  <TextInputClassic
                    placeholder={'Ville'}
                    width={'100%'}
                    register={{ ...register('ville') }}
                  />
                  {errors.ville && (
                    <Typography className={'error-message'}>
                      {errors.ville.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Code postal</FormLabel>
                  <TextInputClassic
                    placeholder={'Code postal'}
                    width={'100%'}
                    register={{ ...register('codePostal') }}
                  />
                  {errors.codePostal && (
                    <Typography className={'error-message'}>
                      {errors.codePostal.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <InputContainer fullWidth>
                  <FormLabel className={'labelForm'}>Pays</FormLabel>
                  <TextInputClassic
                    placeholder={'Pays'}
                    width={'100%'}
                    register={{ ...register('pays') }}
                  />
                  {errors.pays && (
                    <Typography className={'error-message'}>
                      {errors.pays.message}
                    </Typography>
                  )}
                </InputContainer>
              </Grid>
            </Grid>
          </form>

          <Box
            className={'wrapperButtons'}
            sx={{
              justifyContent: 'flex-end',
            }}
          >
            <Box className={'blockButtons'}>
              {loadingAddAdmin ? (
                <CircularProgress />
              ) : (
                <>
                  <BorderButtonPurple
                    width={{ xs: '50%', sm: 160, lg: 200 }}
                    handleClick={() =>
                      navigate(
                        '/admin/gestion-prospect/liste-prospects-entreprises'
                      )
                    }
                  >
                    Annuler
                  </BorderButtonPurple>
                  <CustomButton
                    displayIcon={'none'}
                    width={{ xs: '50%', sm: 160, lg: 200 }}
                    handleClick={handleSubmit(onSubmit)}
                  >
                    Ajouter
                  </CustomButton>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        transition={Slide}
        className={'toast'}
      />
    </>
  )
}

export default AddProspectCompany
