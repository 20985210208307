import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Container,
} from '@mui/material'
import { SectionLayout } from '../../../../../components/layouts'
import { SectionsHeading } from '../../../../../components/headings'
import { OffersCard } from '../../../../../components/cards'
import { useTranslation } from 'react-i18next'
import Api from '../../../../../services/api.service'
import { handlingErrors } from '../../../../../utils'

export default function OffersSection() {
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [offers, setOffers] = useState([])

  useEffect(() => {
    getOffer(i18n.language)
  }, [i18n.language])

  const getOffer = async (lang) => {
    setLoading(true)

    try {
      const result = await Api.siteVitrine.getOffers({}, lang, 'candidat')
      const res = handlingErrors(result)

      if (res.status === 401) {
        console.log('erreur')
      }

      if (res.ok) {
        setOffers(res.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  if (loading) {
    return (
      <SectionLayout id={'sec3'}>
        <Container
          maxWidth={'lg'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '100px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Container>
      </SectionLayout>
    )
  }

  return (
    <SectionLayout id={'sec3'}>
      <Container
        maxWidth={'lg'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '100px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SectionsHeading
          type={'basic'}
          display={'flex'}
          justifyContent={'center'}
          width={{ xs: 210, sm: 240, lg: 320 }}
        >
          {t(`candidats.titleOffer`)}
        </SectionsHeading>

        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', sm: 'space-around' },
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            rowGap: '70px',
            width: '100%',
            columnGap: '30px',
          }}
        >
          <OffersCard items={offers} type={'light'} />
        </Box>
      </Container>
    </SectionLayout>
  )
}
