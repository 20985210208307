import React from "react";
import {Box, styled} from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";

const CustomSection = styled(Box)(({theme, padding}) => ({
    background: lightTheme.palette.primary.b6,
    width: "100%",
    height: "100%",
    padding: padding
}));

export default function BackgroundSection({children, padding}) {
    return (
        <CustomSection component={"section"} padding={padding}>
            {children}
        </CustomSection>
    )
}