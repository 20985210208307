import React from "react";
import { Box, Skeleton, styled, Typography } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";
import { keyframes } from "@emotion/react";

const MasonryContainer = styled(Box)(({theme}) => ({
    width: 340,
    height: 320,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "start",
    position: "relative",
    alignSelf: "center",

    [theme.breakpoints.up("sm")]: {
        width: 348,
        height: 336,
    },

    [theme.breakpoints.up("lg")]: {
        /*width: 571,*/
        width: "50%",
        height: 550,
    },
}));

const ImageBox = styled(Box)(({theme}) => ({
    width: 160,
    height: 228,
    borderRadius: "35px",
    position: "relative",
    overflow: "hidden",

    img: {
        position: "absolute",
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },

    [theme.breakpoints.up("sm")]: {
        width: 161,
        height: 228,
    },

    [theme.breakpoints.up("lg")]: {
        /*width: 270,*/
        width: "100%",
        height: 380,
        columnGap: "30px"
    },

}));

const ColorBox = styled(Box)(({theme,type}) => ({
    width: 160,
    height: 30,
    backgroundColor: type === "animation"
      ? lightTheme.palette.error.main
      : type === "basic"
        ? "#FFFFFF"
        : lightTheme.palette.error.main,
    borderRadius: "100px",
    position: "absolute",
    top: 0,
    right: 0,
    /*animation: type === "animation"
      ? `${colorEffect} 15s linear infinite`
      : type === "basic"
        ? "none"
        : "none",*/
    animation: `${colorEffect} 15s linear infinite`,

    [theme.breakpoints.up("sm")]: {
        width: 161,
    },

    [theme.breakpoints.up("lg")]: {
        /*width: 270,*/
        width: "calc(50% - 15px)",
        height: 80,
    },
}));

const colorEffect = keyframes`
    0% { background-color: #F76E11;}
    25% { background-color: #96CEB4; }
    50% { background-color: #808BF8; }
    75% { background-color: #96CEB4; }
    100% { background-color: #F76E11;}
`

const CustomBox = styled(Box)(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    rowGap: "30px",
    width: 160,

    ".skeleton-masonry-rounded": {
        width: 160,
        height: 228,
        borderRadius: "35px",
    },

    "&.box-2": {
        alignSelf: "end"
    },

    [theme.breakpoints.up("sm")]: {
        width: 161,

        ".skeleton-masonry-rounded": {
            width: 161,
            height: 228,
        },
    },

    [theme.breakpoints.up("lg")]: {
        /*width: 270,*/
        width: "calc(50% - 15px)",

        ".skeleton-masonry-rounded": {
            width: "100%",
            height: 380,
        },
    },
}));

const ImageContainer = ({items}) => {
    return (
      <>
          {items ? (
            <>
                <CustomBox>
                    {!items.photos[0] ? (
                      <>
                          <Skeleton variant={"rounded"} animation={"wave"} className={"skeleton-masonry-rounded"}/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"} />
                      </>
                    ) : (
                      <>
                          <ImageBox>
                              <img src={items.photos[0].lien} alt={"Jobertech"}/>
                          </ImageBox>

                          <Typography
                            variant={"subtitle2"}
                            textAlign={"center"}
                            textTransform={"uppercase"}

                          >
                              {items.photos[0].titre}
                          </Typography>
                      </>
                    )}
                </CustomBox>
                <CustomBox className={"box-2"}>
                    {!items.photos[1] ? (
                      <>
                          <Skeleton variant={"rounded"} animation={"wave"} className={"skeleton-masonry-rounded"}/>
                          <Skeleton variant={"text"} animation={"wave"} className={"skeleton-text"}/>
                      </>
                    ) : (
                      <>
                          <ImageBox>
                              <img src={items.photos[1].lien} alt={"Jobertech"}/>
                          </ImageBox>

                          <Typography
                            variant={"subtitle2"}
                            textAlign={"center"}
                            textTransform={"uppercase"}
                          >
                              {items.photos[1].titre}
                          </Typography>
                      </>
                      )}
                </CustomBox>
            </>
          ) : (
            <></>
            )}
      </>
    )
}

export default function MasonryImages ( props, {type}) {
    return (
      <>
          {props.items && props.items.map((item, index) => (
              <MasonryContainer key={index}>
                <ImageContainer items={item}/>
                <ColorBox type={type}/>
              </MasonryContainer>
          ))}
      </>
    )
}