import ApiRequest from '../request/ApiRequest'

export default class Calendar {
  constructor() {
    this.ApiRequest = new ApiRequest()
  }

  async add(data) {
    try {
      const res = await this.ApiRequest.post('calendriers', data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async detail(id) {
    try {
      const res = await this.ApiRequest.get(`calendriers/${id}`)
      return res
    } catch (error) {
      return error.response
    }
  }

  async edit(id, data) {
    try {
      const res = await this.ApiRequest.put(`calendriers/${id}`, data)
      return res
    } catch (error) {
      return error.response
    }
  }

  async listPerDate(data) {
    try {
      const res = await this.ApiRequest.get(`/calendrier/available/${data}`)
      return res
    } catch (error) {
      return error.response
    }
  }

  async listDIspoEntreprisePerDate(data, entrepriseId) {
    if (entrepriseId === 1) return this.listPerDate(data)
    try {
      const res = await this.ApiRequest.get(
        `/calendrier/entreprise/${entrepriseId}/available/${data}`
      )
      return res
    } catch (error) {
      return error.response
    }
  }

  async postRDV(data) {
    try {
      const res = await this.ApiRequest.post('calendriers', data)
      return res
    } catch (error) {
      return error.message
    }
  }

  async list() {
    try {
      const res = await this.ApiRequest.get('calendriers')
      return res
    } catch (error) {
      return error.response
    }
  }

  async listWithFilter(data) {
    try {
      const res = await this.ApiRequest.get(
        'calendriers.jsonld',
        data,
        'jsonld'
      )
      return res
    } catch (error) {
      return error.response
    }
  }
}
