import React from "react";
import { Box, IconButton, InputBase, styled } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faMagnifyingGlass} from "@fortawesome/pro-regular-svg-icons";
import lightTheme from "../../styles/theme/lightTheme";

const SearchBox = styled(Box)(({theme, display}) => ({
  width: 320,
  height: 45,
  backgroundColor: lightTheme.palette.admin.adminSearch,
  borderRadius: "10px",
  display: display,
  justifyContent: "center",
  alignItems: "center",
  padding: "12px 20px"
}))

const SearchInput = ({display}) => {
  return (
    <SearchBox component={'form'} display={display}>
      <InputBase
        placeholder={"Saisissez votre recherche ..."}
        inputProps={{'aria-label': 'Saisissez votre recherche ...'}}
        sx={{
          flex: 1,
          color: lightTheme.palette.text.secondary,
          fontFamily: "Century Gothic",
          fontSize: 14,
          lineHeight: "140%",
          letterSpacing: "0.2px",
        }}
      />
      <IconButton type={"button"} aria-label={"search"}>
        <FontAwesomeIcon icon={faMagnifyingGlass} style={{color: "#FFFFFF", fontSize: "20px"}}/>
      </IconButton>
    </SearchBox>
  )
}

export default SearchInput;