import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Api from '../../services/api.service'

const user = JSON.parse(localStorage.getItem('user'))

export const userLogin = createAsyncThunk(
  'userAuth/userLogin',
  async ({ email, password }, thunkAPI) => {
    try {
      const res = await Api.auth.logIn({ email, password })
      localStorage.setItem('user', JSON.stringify({ ...res.data }))
      return { ...res.data }
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return thunkAPI.rejectWithValue(err.response.data)
    }
  }
)

export const updateUser = (data) => {
  console.log(data)
  localStorage.setItem('user', data)
}

export const userLogout = createAsyncThunk('userAuth/userLogout', async () => {
  Api.auth.logOut()
})

export const userRestoreToken = createAsyncThunk(
  'userAuth/userRestoreToken',
  async () => {
    const res = Api.auth.restoreToken()
    return res
  }
)

const initialState = user
  ? { isSignedIn: true, isFetching: false, user, error: null }
  : { isSignedIn: false, isFetching: false, user: null, error: null }

export const userAuthSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    reset: (state) => {
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.isSignedIn = true
      state.isFetching = false
      state.error = null
      state.user = action.payload
    })
    builder.addCase(userLogin.rejected, (state, action) => {
      state.isSignedIn = false
      state.isFetching = false
      state.error = action.payload
    })
    builder.addCase(userLogin.pending, (state) => {
      state.isFetching = true
      state.error = null
      state.user = null
    })
    builder.addCase(userLogout.fulfilled, (state) => {
      state.isSignedIn = false
      state.user = null
    })
    builder.addCase(userRestoreToken.fulfilled, (state, action) => {
      state.isSignedIn = false
      state.user = action.payload ? action.payload : null
    })
  },
  // extraReducers: {
  //   [userLogin.fulfilled]: (state, action) => {
  //     state.isLoggedIn = true;
  //     state.user = action.payload.user;
  //   },
  //   [userLogin.rejected]: (state, action) => {
  //     state.isLoggedIn = false;
  //     state.user = null;
  //   },
  // },
})
const { actions } = userAuthSlice
export const { reset } = actions
export default userAuthSlice.reducer
