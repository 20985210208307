import React, { useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import CheckoutFormTest from './CheckoutFormTest'
import '../../styles/components/_stripe.scss'
import { STRIPE_KEY, STRIPE_URL } from '../../config'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material'

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
// Stripe publish key
const stripePromise = loadStripe(STRIPE_KEY)

export default function StripeAppTest({
  userId,
  offreId,
  offrePeriode = 0,
  type = 'offer',
  offrePrice,
  onSuccess,
  onClose,
  codePromoObject,
}) {
  const { i18n } = useTranslation()
  const [clientSecret, setClientSecret] = useState('')
  const [paymentIntentId, setPaymentIntentId] = useState('')
  const [loading, setLoading] = useState(true)

  /* 
  useEffect(() => {
    const loadStripeJs = async () => {
      const stripePromise = await loadStripe(STRIPE_KEY);
      setLoading(false);
      // Set the stripe instance globally if needed
      // setStripe(stripeInstance);
    };

    loadStripeJs();
  }, []); */

  useEffect(() => {
    // Créer PaymentIntent dès que la page se charge
    fetch(`${STRIPE_URL}create_payment_intent`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id_offre: offreId,
        type: type,
        periode: offrePeriode,
        id_client: userId,
        prix: parseInt(offrePrice),
        devise: sessionStorage.getItem('currency')
          ? JSON.parse(sessionStorage.getItem('currency')).devise
          : 'CHF',
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (clientSecret === '') setClientSecret(data.clientSecret)
        if (paymentIntentId === '') setPaymentIntentId(data.id)
        setLoading(false)
      })
  }, [userId, clientSecret, offreId, paymentIntentId, offrePeriode, offrePrice, type])

  const appearance = {
    theme: 'stripe',
  }
  const options = {
    clientSecret,
    appearance,
    locale: i18n.language,
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className="App">
      {clientSecret && paymentIntentId && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutFormTest
            paymentIntentId={paymentIntentId}
            userId={userId}
            offrePrice={offrePrice}
            offreId={offreId}
            type={type}
            periode={offrePeriode}
            onSuccess={onSuccess}
            onClose={onClose}
            codePromoObject={codePromoObject}
          />
        </Elements>
      )}
    </div>
  )
}
