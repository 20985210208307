import { FormControl, styled } from "@mui/material";
import lightTheme from "../../styles/theme/lightTheme";

const CustomTextarea = styled("textarea")(({theme, type}) => ({
  height: 60,
  borderRadius: "5px",
  width: "100%",
  fontFamily: "Century Gothic",
  fontSize: 14,
  lineHeight: "140%",
  letterSpacing: "0.2px",
  color: lightTheme.palette.text.primary,
  padding: "10px 15px",
  borderWidth: 0,
  border: type === "border"
    ? `1px solid ${lightTheme.palette.grey.main}`
    : "none",

  "&::placeholder": {
    fontFamily: "Century Gothic",
    fontSize: 14,
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: lightTheme.palette.text.primary,
  },

  "&:active, &:focus": {
    outline: "none"
  },
}));
const TextareaInputClassic = ({placeholder, register, type, onChange}) => {
  return (
    <FormControl>
      <CustomTextarea
        onChange={onChange}
        placeholder={placeholder}
        {...register}
        type={type}
      />
    </FormControl>
  )
}

export default TextareaInputClassic;